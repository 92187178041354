import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
    disconnectSocket,
    sendMessage,
    emitMessage,
    initiateSocketIntern,
    subscribeToChatIntern,
    newUserResponseIntern,
    disconnectSocketIntern,
    sendMessageIntern,
    emitMessageIntern,
} from "../../services/Socket";
import {
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Stack,
    TextField,
    Typography,
    Dialog,
    DialogContent,
    Slide,
    DialogTitle,
    IconButton,
    Box,
    Button,
    LinearProgress,
    FormControlLabel,
    Switch,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useSelector } from "react-redux";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import RemoveIcon from "@mui/icons-material/Remove";
import CancelIcon from "@mui/icons-material/Cancel";
/** MUI imports */
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import moment from "moment";
import casesService from "../../services/case";
import notiService from "../../services/notification";
import ITTeam from "../Js/ITTeam";
import PDFIcon from "../../assets/pdf.svg";
import ImageIcon from "../../assets/image.png";
import ExcelIcon from "../../assets/microsoft-excel.svg";
import TxtIcon from "../../assets/txtFileIcon.svg";
import conversionService from "../../services/conversion";
import casesServices from "../../services/case";
import DownloadIcon from "@mui/icons-material/Download";
import BaseSnackbar from "./BaseSnackbar";
import SimpleBackdrop from "./SimpleBackdrop";

const PREFIX = "DialogAlerts";

const classes = {
    icon: `${PREFIX}-icon`,
};

const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: "30px 30px 0px",
    },
    "& .MuiDialog-paper": {
        borderRadius: "25px",
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },

    [`& .${classes.icon}`]: {
        marginRight: 10,
        height: 30,
    },
}));

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MAX_COUNT = 3;

function LinearProgressWithLabel(props) {
    return (
        <Grid
            item
            xs={12}
            sx={{
                padding: "5px 25px 5px 25px",
            }}
        >
            <LinearProgress
                variant="determinate"
                {...props}
                sx={{
                    height: 10,
                    borderRadius: 5,
                }}
            />
            <Typography variant="h3">{`${Math.round(props.value)}%`}</Typography>
        </Grid>
    );
}

const ChatModal = ({
    room,
    setRoom,
    open,
    setOpen,
    setChat,
    chat,
    type,
    users,
    franchiseName,
    reloadCases,
    chatDisabled,
    setCaseInfo,
    caseInfo,
    chatInfo,
    setChatInfo,
    createCase,
    connectSocket,
    params,
}) => {
    const todos = useSelector((state) => state.value);

    const listRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileLimit, setFileLimit] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [progress, setProgress] = useState(0);
    const [loadingFile, setLoadingFile] = useState(false);
    const [cancelToken, setCancelToken] = useState(null);
    const inputRef = useRef(null);
    const [checked, setChecked] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [isLoading, setIsLoading] = useState(false);

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const handleChangeSwitch = async (event) => {
        setChecked(event.target.checked);
        setChat([]);
        if (event.target.checked) {
            setIsLoading(true);
            disconnectSocket(room, todos.userInfo, "");

            setRoom(chatInfo._id);

            initiateSocketIntern(chatInfo._id, todos.userInfo);
            subscribeToChatIntern((err, data) => {
                if (err) return;
                setChat((oldChats) => [...oldChats, data]);
            });
            newUserResponseIntern((err, data) => {
                if (err) return;
                //setUsers(data);
            });
            setIsLoading(false);
        } else {
            disconnectSocketIntern(room, todos.userInfo);
            if (params === 3) {
                connectSocket(caseInfo._id, caseInfo.origin, caseInfo.conversion_id);
            } else {
                connectSocket(caseInfo._id, caseInfo.origin, caseInfo.conversion_id, caseInfo.status === 5);
            }
        }
    };

    const cancelUpload = () => {
        if (cancelToken) {
            cancelToken.cancel("Upload canceled by the user.");
        }
    };

    const formatBytes = (bytes, decimals = 0) => {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const onClose = async () => {
        setOpen(false);
        setSelectedFiles([]);
        setFileLimit(false);
        setShowButtons(false);
        setProgress(0);
        setLoadingFile(false);
        cancelUpload();
        if (room) {
            setIsLoading(true);
            if (checked) {
                disconnectSocketIntern(room, todos.userInfo);
            } else {
                disconnectSocket(room, todos.userInfo, "");
                if (type && chat.length === 1) {
                    await casesService.deleteCase({ case_id: room }).catch((err) => {
                        console.log(err);
                    });
                }
                if (type === 3) {
                    await reloadCases();
                }
            }

            setRoom(null);
            setChat([]);
            if (inputRef.current) {
                inputRef.current.value = "";
            }
            if (setCaseInfo) {
                setCaseInfo({});
            }
            setIsLoading(false);
        }
        setChecked(false);
    };

    const handleFileChange = (e) => {
        const chosenFiles =
            e._reactName === "onDrop"
                ? Array.prototype.slice.call(e.dataTransfer.files)
                : Array.prototype.slice.call(e.target.files);

        const uploaded = [...selectedFiles];
        let limitExceeded = false;

        chosenFiles.forEach((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push({ filename: file.name, size: file.size, buffer: file });

                if (uploaded.length > MAX_COUNT) {
                    limitExceeded = true;
                }
            }
        });
        if (uploaded.length === MAX_COUNT) setFileLimit(true);

        if (!limitExceeded) setSelectedFiles(uploaded);
    };

    const deleteFiles = (index) => {
        const uploaded = [...selectedFiles];

        uploaded.splice(index, 1);

        if (uploaded.length < MAX_COUNT) {
            setFileLimit(false);
        }

        setSelectedFiles(uploaded);
    };

    const convertTZ = (date, tzString) => {
        return new Date(
            (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString })
        );
    };

    useEffect(() => {
        listRef.current?.lastElementChild?.scrollIntoView();
    }, [chat]);

    useEffect(() => {}, [users]);

    const [t] = useTranslation("global");

    const downloadFile = async (originalname, file_name) => {
        let params = {
            uploaded_file_s3: file_name,
        };
        setIsLoading(true);

        await casesService
            .getChatFileS3(params)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", originalname);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                // setTexto(t("converter.dnldError"));
                // setAlertType("error");
                // setShow(true);
                console.log(err);
            });

        setIsLoading(false);
    };

    const getIcon = (filename) =>
        filename.substring(filename.lastIndexOf(".") + 1) === "pdf"
            ? PDFIcon
            : filename.substring(filename.lastIndexOf(".") + 1) === "xlsx" ||
              filename.substring(filename.lastIndexOf(".") + 1) === "csv" ||
              filename.substring(filename.lastIndexOf(".") + 1) === "xls"
            ? ExcelIcon
            : filename.substring(filename.lastIndexOf(".") + 1) === "txt"
            ? TxtIcon
            : ImageIcon;

    const onKeyPress = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            if (selectedFiles.length > 0) {
                sMessageF(event);
            } else {
                sMessage(event);
            }
        }
    };

    const handleKeyUp = (event) => {
        if (event.key === "Enter" && event.shiftKey) {
            event.preventDefault();
            if (!inputRef.current.value.endsWith("\n")) {
                const updatedMessage = `${inputRef.current.value} \n `;
                inputRef.current.value = updatedMessage;
            }
        }
    };

    const development = async (e) => {
        e.preventDefault();
        setShowButtons(true);

        let params1 = {
            case_id: caseInfo._id,
            lang: t("language.locale"),
        };

        await casesServices
            .returnAdminCase(params1)
            .then(async (data) => {
                if (data.caseInfo) {
                    setCaseInfo(data.caseInfo);
                }
                if (data.message) {
                    let messageId = data.message;
                    if (checked && room === chatInfo._id) {
                        emitMessageIntern(room, messageId);
                    }
                }
                if (data.chatInfo) {
                    setChatInfo(data.chatInfo);
                }
                await reloadCases();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const success = async (e) => {
        e.preventDefault();
        setShowButtons(true);

        let params1 = {
            case_id: caseInfo._id,
            lang: t("language.locale"),
        };

        await casesServices
            .approveAdminCase(params1)
            .then(async (data) => {
                if (data.caseInfo) {
                    setCaseInfo(data.caseInfo);
                }
                if (data.message) {
                    let messageId = data.message;
                    if (!checked && room === caseInfo._id) {
                        emitMessage(room, messageId);
                    }
                }
                if (data.messageI) {
                    let messageIdI = data.messageI;
                    if (checked && room === chatInfo._id) {
                        emitMessageIntern(room, messageIdI);
                    }
                }
                if (data.chatInfo) {
                    setChatInfo(data.chatInfo);
                }
                await reloadCases();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const sMessageF = async (event) => {
        event.preventDefault();
        const value = inputRef.current.value;

        if (!checked) {
            if (type && chat.length === 1) {
                const params = {
                    franchise_id: todos.franchiseID,
                    company_id: todos.companyID,
                    type,
                    franchiseName,
                };

                // Crear notificacion
                await notiService.newNotification(params).catch((err) => {
                    console.log(err);
                });

                setTexto(`${t("inbox.dialogCreatedCase")} ${caseInfo.case_number}. ${t("inbox.dialogCreatedCase2")}`);
                setAlertType("success");
                setShow(true);
            }
        }

        const data = new FormData();

        if (selectedFiles.length > 0) {
            for (let x = 0; x < selectedFiles.length; x++) {
                data.append("files", selectedFiles[x].buffer);
            }
        }

        data.append("checked", checked);
        data.append("message", value);
        data.append("room", room);
        data.append("user", todos.userInfo._id);
        data.append("type", type);
        data.append("franchise_name", franchiseName);
        data.append("lang", t("language.locale"));

        setLoadingFile(true);
        const source = axios.CancelToken.source();
        setCancelToken(source);

        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded / total) * 100);

                if (percent < 100) {
                    setProgress(percent);
                }
            },
            cancelToken: source.token,
        };

        await conversionService
            .uploadFilesChat(data, options)
            .then((messages) => {
                if (checked) {
                    emitMessageIntern(room, messages);
                } else {
                    emitMessage(room, messages);
                }
                setProgress(0);
            })
            .catch((err) => {
                console.log(err);
                setProgress(0);
                setTexto(t("converter.errorUpload"));
                setAlertType("error");
                setShow(true);
            });

        setLoadingFile(false);
        setFileLimit(false);

        inputRef.current.value = ""
        setSelectedFiles([])

    };

    const sMessage = async (event) => {
        event.preventDefault();
        const value = inputRef.current.value;
        if (checked) {
            sendMessageIntern(event, room, value, todos.userInfo, inputRef);
        } else {
            if (type && chat.length === 1) {
                const params = {
                    franchise_id: todos.franchiseID,
                    company_id: todos.companyID,
                    type,
                    franchiseName,
                };

                // Crear notificacion
                await notiService.newNotification(params).catch((err) => {
                    console.log(err);
                });

                setTexto(`${t("inbox.dialogCreatedCase")} ${caseInfo.case_number}. ${t("inbox.dialogCreatedCase2")}`);
                setAlertType("success");
                setShow(true);
            }
            sendMessage(event, room, value, todos.userInfo, inputRef, type, franchiseName);
        }
    };

    const finConversionId = (caseInfo) => {
       
        if (caseInfo.check_conversion_id) {
            return [caseInfo.check_conversion_id, "check"]
        } else if (caseInfo.conversion_id) {
            return [caseInfo.conversion_id, "conversion"]
        } else if (caseInfo.invoice_conversion_id) {
            return [caseInfo.case_id, "invoice"]
        } else if (caseInfo.consolidated_conversion_id) {
            return [caseInfo.consolidated_conversion_id, "consolidated"]
        }
        return [null, null]
    }

    const downloadEcc = async () => {

        let [conversionId, type] = finConversionId(caseInfo)

        let params = {
            conversion_id: conversionId,
            type: type,
        };

        await conversionService
            .getFileUrlS3(params)
            .then((response) => {
                // Crea un enlace temporal y haz clic en él para iniciar la descarga
                const link = document.createElement("a");
                link.href = response.url;
                document.body.appendChild(link);
                link.click();

                // Limpia el enlace temporal
                document.body.removeChild(link);
            })
            .catch((err) => {
                setTexto(t("converter.dnldError"));
                setAlertType("error");
                setShow(true);
                console.log(err);
            });
    };

    return (
        <>
            <CustomDialog
                open={open}
                onClose={onClose}
                TransitionComponent={Transition}
                maxWidth="md"
                PaperProps={{
                    width: "100%",
                    height: "80vh",
                }}
                fullWidth
            >
                <DialogTitle id="alert-dialog-title" sx={{ backgroundColor: checked ? "#361A52" : "#361A52" }}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ bgColor: "#361A52" }}
                    >
                        <Stack direction="column">
                            <Typography fontSize={18} fontWeight={600} color={checked ? "#DCCFFF" : "#FFF"}>
                                {createCase
                                    ? todos.franchiseID && !todos.companyID
                                        ? (franchiseName + " | " ?? "") +
                                          (ITTeam.includes(todos.userInfo.email)
                                              ? caseInfo.global_case_number
                                              : caseInfo.case_number)
                                        : (caseInfo?.companyInfo?.company_name + " | " ?? "") +
                                          (ITTeam.includes(todos.userInfo.email)
                                              ? caseInfo.global_case_number
                                              : caseInfo.case_number)
                                    : checked
                                    ? "Equipo de soporte"
                                    : caseInfo.franchise_id && !caseInfo.company_id
                                    ? (caseInfo?.franchiseInfo?.franchise_name + " | " ?? "") +
                                      (ITTeam.includes(todos.userInfo.email)
                                          ? caseInfo.global_case_number
                                          : caseInfo.case_number)
                                    : (caseInfo?.companyInfo?.company_name + " | " ?? "") +
                                      (ITTeam.includes(todos.userInfo.email)
                                          ? caseInfo.global_case_number
                                          : caseInfo.case_number)}
                            </Typography>
                            <Typography fontSize={13} color={checked ? "#DCCFFF" : "#FFF"} sx={{ mt: 0.3 }}>
                                {checked
                                    ? caseInfo.franchise_id && !caseInfo.company_id
                                        ? (caseInfo?.franchiseInfo?.franchise_name + " | " ?? "") +
                                          (ITTeam.includes(todos.userInfo.email)
                                              ? caseInfo.global_case_number
                                              : caseInfo.case_number)
                                        : (caseInfo?.companyInfo?.company_name + " | " ?? "") +
                                          (ITTeam.includes(todos.userInfo.email)
                                              ? caseInfo.global_case_number
                                              : caseInfo.case_number)
                                    : users.length === 0
                                    ? ""
                                    : users.length === 1
                                    ? users[0].name + " " + t("inbox.connectedChat")
                                    : users.length === 2
                                    ? users.map((item) => item.name).join(t("inbox.and")) +
                                      " " +
                                      t("inbox.connectedChat2")
                                    : users
                                          .map((item) => item.name)
                                          .slice(0, users.length - 2)
                                          .join(", ") +
                                      ", " +
                                      users
                                          .map((item) => item.name)
                                          .slice(users.length - 2, users.length)
                                          .join(t("inbox.and")) +
                                      " " +
                                      t("inbox.connectedChat2")}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            {ITTeam.includes(todos.userInfo.email) &&
                                !caseInfo?.global_case_number?.includes("OTR") && (
                                    <>
                                        <span>
                                            <Button
                                                onClick={(e) => downloadEcc(e)}
                                                sx={{
                                                    color: checked ? "#DCCFFF" : "#FFF",
                                                    fontWeight: 600,
                                                    px: 3,
                                                    py: 0,
                                                    borderRadius: "5px",
                                                    width: "162px",
                                                    border: checked ? "0.5px solid #DCCFFF" : "0.5px solid #FFF",
                                                    height: "42px",
                                                    backgroundColor: checked ? "#361A52" : "#4A22D4",
                                                    "&:hover": {
                                                        backgroundColor: "#FFFFFF",
                                                        color: checked ? "#361A52" : "#4A22D4",
                                                    },
                                                    lineHeight: 1,
                                                    whiteSpace: "nowrap",
                                                    fontSize: "13px",
                                                }}
                                                endIcon={<DownloadIcon />}
                                            >
                                                Descargar EC
                                            </Button>
                                        </span>
                                    </>
                                )}
                            <IconButton
                                aria-label="close"
                                onClick={onClose}
                                sx={{
                                    // position: "absolute",
                                    // right: 16,
                                    // top: 10,
                                    color: "#fff",
                                }}
                            >
                                <RemoveIcon />
                            </IconButton>
                        </Stack>
                    </Stack>
                </DialogTitle>
                <Divider />
                <DialogContent style={{ padding: "0px" }}>
                    {room && (
                        <form
                            onSubmit={
                                selectedFiles.length > 0 ? (event) => sMessageF(event) : (event) => sMessage(event)
                            }
                        >
                            <List
                                sx={{
                                    height: "40vh",
                                    overflowY: "auto",
                                    //border: "1px solid rgba(0, 0, 0, 0.12)",
                                    bgcolor: "#FFF",
                                }}
                                ref={listRef}
                            >
                                {chat.map((m) => (
                                    <>
                                        <ListItem
                                            key={m.date}
                                            sx={{
                                                justifyContent:
                                                    m.user && todos.userInfo._id === m.user._id ? "end" : "start",
                                            }}
                                        >
                                            {Object.keys(m).user}
                                            <Box
                                                sx={{
                                                    bgcolor:
                                                        m.user && todos.userInfo._id === m.user._id
                                                            ? "#E9E6EC" // Usuario actual, gris
                                                            : m.converter_bot === true &&
                                                              (m.message.includes(
                                                                  "La conversión no ha sido completada con éxito"
                                                              ) ||
                                                                  m.message.includes(
                                                                      "The conversion has not been completed successfully"
                                                                  ) ||
                                                                  m.message.includes(
                                                                      "ha regresado el caso a desarrollo"
                                                                  ) ||
                                                                  m.message.includes(
                                                                      "has returned the case to development"
                                                                  ))
                                                            ? "#FDE8F1" //Convertidor de pruebas, mensaje de error, rojo
                                                            : m.converter_bot === true &&
                                                              (m.message.includes(
                                                                  "La conversión se ha completado de manera exitosa"
                                                              ) ||
                                                                  m.message.includes(
                                                                      "The conversion has been completed successfully"
                                                                  ) ||
                                                                  m.message.includes(
                                                                      "confirmó que la conversión es exitosa"
                                                                  ) ||
                                                                  m.message.includes(
                                                                      "confirmó que la conversión es exitosa"
                                                                  ) ||
                                                                  m.message.includes(
                                                                      "confirmed that the conversion is successful"
                                                                  ))
                                                            ? "#E6F3E9" //Convertidor de pruebas, mensaje de exito, azul
                                                            : m.converter_bot === true
                                                            ? "#FFF2DA" //Convertidor de pruebas, naranja
                                                            : (!ITTeam.includes(todos.userInfo.email) &&
                                                                  m.user &&
                                                                  !ITTeam.includes(m.user.email)) ||
                                                              (ITTeam.includes(todos.userInfo.email) &&
                                                                  (!("user" in m) ||
                                                                      (m.user && ITTeam.includes(m.user.email))))
                                                            ? "#E5D1FF" // Que el usuario actual no sea de ITTeam. Otro user diferente de ITTeam el color es lila O que el usuario actual sea de ITTeam. Bot o user de ITTeam el color es lila
                                                            : "#DCCFFF",
                                                    py: 1,
                                                    px: 2,
                                                    borderRadius: 3,
                                                    ml: m.user && todos.userInfo._id === m.user._id ? 20 : 0,
                                                    mr: m.user && todos.userInfo._id === m.user._id ? 0 : 20,
                                                    minWidth: "20%",
                                                }}
                                            >
                                                <Stack direction="column">
                                                    <ListItemText
                                                        primary={
                                                            <Stack direction="row" alignItems="center">
                                                                <Typography fontWeight={600}>
                                                                    {m.user
                                                                        ? m.user.user_name
                                                                        : m.converter_bot
                                                                        ? "Convertidor de pruebas"
                                                                        : "KiiperBot"}{" "}
                                                                    {t("inbox.commented")}:
                                                                </Typography>
                                                            </Stack>
                                                        }
                                                    ></ListItemText>
                                                    <ListItemText
                                                        primary={
                                                            <>
                                                                {m.message.includes("\n") ? (
                                                                    m.message.split("\n").map((line, index) => (
                                                                        <>
                                                                            {line}
                                                                            <br />
                                                                        </>
                                                                    ))
                                                                ) : m.message.includes("<b>") ? (
                                                                    <Typography
                                                                        className={classes.expiredText}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: m.message,
                                                                        }}
                                                                    ></Typography>
                                                                ) : (
                                                                    m.message
                                                                )}
                                                                {m.attachments &&
                                                                    m.attachments.map((item, index) => (
                                                                        <Stack
                                                                            key={"stack_" + index}
                                                                            direction="row"
                                                                            alignItems="center"
                                                                            justifyContent="end"
                                                                            width="fit-content"
                                                                            sx={{
                                                                                backgroundColor: "#FFF",
                                                                                borderRadius: 2,
                                                                                py: 2,
                                                                                pl: 3,
                                                                                pr: 4,
                                                                                my: 1,
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={() =>
                                                                                downloadFile(
                                                                                    item.originalname,
                                                                                    item.filenames3
                                                                                )
                                                                            }
                                                                        >
                                                                            <Stack
                                                                                direction="row"
                                                                                spacing={2}
                                                                                alignItems="center"
                                                                                justifyContent="start"
                                                                            >
                                                                                <Box
                                                                                    component="img"
                                                                                    sx={{
                                                                                        height: 40,
                                                                                        width: 40,
                                                                                        maxHeight: { xs: 40, md: 40 },
                                                                                        maxWidth: { xs: 40, md: 40 },
                                                                                    }}
                                                                                    src={getIcon(item.originalname)}
                                                                                />
                                                                                <Box sx={{ textAlign: "start" }}>
                                                                                    <Typography variant="h4">
                                                                                        {item.originalname}
                                                                                    </Typography>
                                                                                    <Typography
                                                                                        fontSize={12}
                                                                                        fontWeight={600}
                                                                                    >
                                                                                        {formatBytes(item.size)}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Stack>
                                                                        </Stack>
                                                                    ))}
                                                            </>
                                                        }
                                                    ></ListItemText>
                                                    <ListItemText
                                                        secondary={
                                                            <Typography fontSize={12} color="gray">
                                                                {moment(convertTZ(m.date, "America/New_York")).format(
                                                                    `${todos.dateFormat} hh:mm A`
                                                                )}
                                                            </Typography>
                                                        }
                                                    ></ListItemText>
                                                </Stack>
                                            </Box>
                                        </ListItem>
                                        {m.buttons === true && (
                                            <Stack spacing={1} justifyContent="end" mr={1}>
                                                <Box display="flex" justifyContent="end">
                                                    <Button
                                                        variant="contained"
                                                        sx={{ fontWeight: 500 }}
                                                        onClick={(e) => development(e)}
                                                        disabled={showButtons}
                                                    >
                                                        Devolver a desarrollo 🔧
                                                    </Button>
                                                </Box>
                                                <Box display="flex" justifyContent="end">
                                                    <Button
                                                        variant="contained"
                                                        sx={{ fontWeight: 500 }}
                                                        onClick={(e) => success(e)}
                                                        disabled={showButtons}
                                                    >
                                                        La conversión es correcta ✅
                                                    </Button>
                                                </Box>
                                            </Stack>
                                        )}
                                    </>
                                ))}
                            </List>
                            {ITTeam.includes(todos.userInfo.email) && (
                                <Box sx={{ bgcolor: checked ? "rgba(3, 24, 81, 0.03)" : "#FFF", px: 2.5, py: 0.5 }}>
                                    <FormControlLabel
                                        sx={{ alignItems: "center" }}
                                        control={
                                            <Switch
                                                id="disabledAccounts"
                                                checked={checked}
                                                onChange={handleChangeSwitch}
                                                inputProps={{ "aria-label": "controlled" }}
                                            />
                                        }
                                        label={
                                            <Typography fontWeight={600} fontSize={14} noWrap>
                                                Chat interno
                                            </Typography>
                                        }
                                    />
                                </Box>
                            )}
                            <Divider />
                            <Grid container spacing={0} alignItems="center" justifyContent="space-between">
                                <Grid item xs={10} sx={{ px: 3, py: 2 }}>
                                    <TextField
                                        inputRef={inputRef}
                                        variant="standard"
                                        placeholder={
                                            chatDisabled ? t("inbox.disabledChat") : loadingFile ? t("inbox.disabledChat2") : t("inbox.queryHere")
                                        }
                                        fullWidth
                                        name="name"
                                        disabled={!room || chatDisabled || loadingFile}
                                        InputProps={{ disableUnderline: true }}
                                        onKeyPress={onKeyPress}
                                        onKeyUp={handleKeyUp}
                                        multiline
                                        sx={{
                                            input: {
                                                // "&::placeholder": {
                                                //     opacity: 1,
                                                // },
                                                "&.Mui-disabled": {
                                                    "&::placeholder": {
                                                        opacity: 1,
                                                    },
                                                },
                                            },
                                        }}
                                        onDragOver={handleDragOver}
                                        onDrop={handleFileChange}
                                    />
                                </Grid>
                                <Grid item sx={{ pr: 3 }}>
                                    <Stack direction="row" sx={{ display: "flex", alignItems: "center" }} spacing={1}>
                                        <IconButton
                                            component="label"
                                            disabled={!room || fileLimit || chatDisabled || loadingFile}
                                        >
                                            <AttachFileIcon fontSize="small" sx={{ color: "#131F3EB2" }} />
                                            <VisuallyHiddenInput
                                                type="file"
                                                onChange={handleFileChange}
                                                multiple
                                                accept="application/pdf, image/*, .csv, .txt, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                            />
                                        </IconButton>
                                        <IconButton type="submit" disabled={!room || chatDisabled || loadingFile}>
                                            <SendIcon sx={{ color: "#131F3EB2" }} fontSize="small" />
                                        </IconButton>
                                    </Stack>
                                </Grid>
                                {progress > 0 && <LinearProgressWithLabel value={progress} />}
                                {selectedFiles.map((item, index) => (
                                    <Grid
                                        item
                                        key={item.name}
                                        xs={12}
                                        sx={{
                                            padding:
                                                selectedFiles.length - 1 === index
                                                    ? "5px 25px 15px 25px"
                                                    : "0px 25px 5px 25px",
                                        }}
                                    >
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            sx={{ backgroundColor: "#D9D9D9", borderRadius: 2, padding: "10px 10px" }}
                                        >
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        height: 40,
                                                        width: 40,
                                                        maxHeight: { xs: 40, md: 40 },
                                                        maxWidth: { xs: 40, md: 40 },
                                                    }}
                                                    src={getIcon(item.filename)}
                                                />
                                                <Box>
                                                    <Typography variant="h4">{item.filename}</Typography>
                                                    <Typography fontSize={12} fontWeight={600}>
                                                        {formatBytes(item.size)}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                            <IconButton onClick={() => deleteFiles(index)}>
                                                <CancelIcon />
                                            </IconButton>
                                        </Stack>
                                    </Grid>
                                ))}
                            </Grid>
                        </form>
                    )}
                </DialogContent>
            </CustomDialog>
            <SimpleBackdrop open={isLoading} />
            <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleCloseSnackBar} />
        </>
    );
};

export default ChatModal;
