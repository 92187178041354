/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import introJs from "intro.js";

/** MUI icons */
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import accountsService from "../../services/accounts";
// import ArchiveIcon from "@mui/icons-material/Archive";
import AlertDialog from "../ui/AlertDialog";
import FormDialog from "../ui/FormDialog";
import { TableColumnVisibility } from "@devexpress/dx-react-grid-material-ui";

/** MUI imports */
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
    TableSelection,
} from "@devexpress/dx-react-grid-material-ui";
import {
    SearchState,
    IntegratedFiltering,
    PagingState,
    IntegratedPaging,
    SelectionState,
    IntegratedSelection,
} from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import { Box, Button, FormControlLabel, Stack, Switch, Typography, Skeleton } from "@mui/material";
import LightTooltip from "../ui/LightTooltip";
import GridUI from "@mui/material/Grid";
import FiltersButton from "./FiltersButton";
import SimpleBackdrop from "../ui/SimpleBackdrop";
import AccountDetails from "./AccountDetails";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addTutorial } from "../../redux/userSlice";
import BaseButton from "../ui/BaseButton";

const PREFIX = "ConverterTable";

const classes = {
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
    tableHeaderBand: `${PREFIX}-tableHeaderBand`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.tableHeaderBand}`]: {
        backgroundColor: "transparent",
        color: "#4a22d4",
        fontSize: "14px",
        fontWeight: 600,
        border: "none !important",
        paddingBottom: 0,
        [theme.breakpoints.up("md")]: {
            whiteSpace: "pre-wrap",
        },
    },

    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "#f8f8f9",
        fontSize: window.innerWidth > 1440 ? 15 : 14,
        fontWeight: 600,
        color: "#131F3E !important",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
            paddingRight: "24px",
        },
    },

    [`& .${classes.tableBody}`]: {
        backgroundColor: "#FFF",
        borderBottom: "5px solid #f8f8f9",
        fontSize: window.innerWidth > 1440 ? 14 : 12,
        fontWeight: 400,
        color: "#131f3e",
        "&:first-of-type": {
            borderRadius: "10px 0 0 10px",
        },
        "&:last-child": {
            borderRadius: "0 10px 10px 0",
        },
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#361A52",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "#D8D3E5",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
        width: "100%",
    },

    [`& .${classes.searchInput}`]: {
        color: "#131F3E",
        backgroundColor: "#f2f2f2",
        borderRadius: 8,
        borderBottom: "none",
        paddingTop: "11px",
        paddingBottom: "11px",
        paddingLeft: "20px",
        paddingRight: "40px",
        marginBottom: "35px",
        "&:before": {
            borderBottom: "none",
            color: "#131F3E",
        },
        "&:hover:before": {
            borderBottom: "none",
            color: "#131F3E",
        },
        width: "100%",
    },
}));

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const tableHeaderSelCellBase = ({ ...props }) => (
    <TableSelection.HeaderCell {...props} className={classes.tableHeader} /> // disabled={disabledSelectedAll}
);

const tableSelectionCell = ({ ...props }) => <TableSelection.Cell {...props} className={classes.tableBody} />;

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        name: <Skeleton variant="text" animation="wave" />,
        country: <Skeleton variant="text" animation="wave" />,
        currency: <Skeleton variant="text" animation="wave" />,
        bank: <Skeleton variant="text" animation="wave" />,
        platform: <Skeleton variant="text" animation="wave" />,
        transMonitoring: <Skeleton variant="text" animation="wave" />,
        edit: <Skeleton variant="text" animation="wave" />,
    });
}

const AccountSetupTable = (props) => {
    const {
        accounts,
        setAccounts,
        setReload,
        setAlertType,
        setTexto,
        setShow,
        banksFilters,
        banksFiltersConfig,
        currenciesFilters,
        countriesFilters,
        companyID,
        franchiseID,
        grupal,
        integration,
        showSkeleton,
        setShowSkeleton,
        allAccounts,
        setCountriesFilters,
        setCurrenciesFilters,
        setBanksFiltersConfig,
        handleClickHelp,
        disabledCount,
        setdisabledCount,
    } = props;

    const [t] = useTranslation("global");
    const location = useLocation();
    const todos = useSelector((state) => state.value);

    const dispatch = useDispatch();

    const [searchValue, setSearchState] = useState("");
    const [selectionAcc, setSelectionAcc] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [isError, setIsError] = useState(false);
    const [selectedAcc, setSelectedAcc] = useState({});
    const navigate = useNavigate();
    const [checked, setChecked] = useState(false);
    const [rows, setRows] = useState([]);
    const [editMode, setEditMode] = useState(true);

    window.history.replaceState(null, "");

    const bodyMess = (
        <>
            <Stack
                direction={{ xs: "column", md: "row" }}
                justifyContent="space-between"
                spacing={2}
                sx={{ mt: 1, textAlign: "justify", mb: 2 }}
            >
                <Typography
                    fontStyle="normal"
                    fontSize={14}
                    fontWeight={300}
                    sx={{
                        color: "#131F3E",
                    }}
                >
                    {t("accounts.disabledMsg")}
                </Typography>
            </Stack>
            <Typography
                fontStyle="normal"
                fontSize={14}
                fontWeight={300}
                sx={{
                    color: "#131F3E",
                    textAlign: "center",
                    mb: 2,
                }}
            >
                {t("dialog.warningConfirm2")}
            </Typography>
        </>
    );

    const bodyMess2 = (
        <>
            <Stack
                direction={{ xs: "column", md: "row" }}
                justifyContent="space-between"
                spacing={2}
                sx={{ mt: 2, textAlign: "justify", mb: 3 }}
            >
                <Typography
                    fontStyle="normal"
                    fontSize={14}
                    fontWeight={300}
                    sx={{
                        color: "#131F3E",
                    }}
                >
                    {t("accounts.reactivateMsg")}
                </Typography>
            </Stack>
        </>
    );

    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const handleOpenEdit = (item) => {
        setSelectedAcc(item);
        setOpenEdit(true);
    };

    const handleClose = () => {
        setOpenEdit(false);
        setIsError(false);
        setSelectedAcc({});
        setEditMode(true);
    };

    const handleClickDelete = () => {
        setOpenAlert(true);
    };

    const handleClickActivate = () => {
        setOpenAlert(true);
    };

    useEffect(() => {
        (async () => {
            if (location.state?.addAccount) {
                setEditMode(false);
                setOpenEdit(true);
            }
        })();
    }, [location]);

    useEffect(() => {
        if (todos.steps.step5 && !todos.dontShowAgain) {
            let isDone = false;

            setTimeout(() => {
                introJs()
                    .setOptions({
                        disableInteraction: true,
                        exitOnOverlayClick: false,
                        keyboardNavigation: false,
                        nextLabel: t("dialog.continue"),
                        prevLabel: t("miscellaneous.goBack"),
                        doneLabel: t("miscellaneous.accept"),
                        steps: [
                            {
                                title: `${t("miscellaneous.step")} 5`,
                                element: "#account3-tutorial",
                                intro: t("miscellaneous.tutorialAcc3"),
                            },
                        ],
                    })
                    .oncomplete(() => {
                        isDone = true;
                        dispatch(addTutorial({ ...todos.steps, step5: false, step6: true }));
                    })
                    .onexit(() => {
                        if (!isDone) {
                            dispatch(addTutorial({ ...todos.steps, step5: false }));
                        }
                    })
                    .start();
            }, 3000);
        }
    }, [dispatch, t, todos.dontShowAgain, todos.steps]);

    useEffect(() => {
        if (showSkeleton) {
            setRows(rowsSkeleton);
        } else {
            const goToConvert = async (id) => {
                navigate("/convert", { state: { account_id: id } });
            };

            setRows(
                accounts
                    ? accounts.map((item, index) => ({
                          id: item._id,
                          name: checked ? (
                              item.account_name
                          ) : (
                              <BaseButton
                                  id={index === 0 ? "account3-tutorial" : ""}
                                  onClick={() => goToConvert(item._id)}
                                  variantType="errorText"
                              >
                                  {item.account_name}
                              </BaseButton>
                          ),
                          nameSearch: item.account_name,
                          country: item.country_name,
                          currency: (
                              <LightTooltip title={item.currency_short_name}>
                                  <>{item.currency_iso_name}</>
                              </LightTooltip>
                          ),
                          bank: item.bank_name,
                          platform:
                              item.system_integration === 1
                                  ? "Xero"
                                  : item.system_integration === 2
                                  ? "Quickbooks"
                                  : "Manual",
                          transMonitoring: item.system_integration === 3 ? "N/A" : item.frequency,
                          edit: (
                              <>
                                  {!checked && (
                                      <EditIcon
                                          fontSize="small"
                                          sx={{ color: "#361A52", cursor: "pointer" }}
                                          onClick={() => handleOpenEdit(item)}
                                      />
                                  )}
                                  {/* <ArchiveIcon fontSize="small" sx={{ color: "#361A52", cursor: "pointer" }} /> */}
                              </>
                          ),
                      }))
                    : []
            );
        }
    }, [showSkeleton, accounts, checked, navigate]);

    const columns = [
        //{ name: "id", title: "ID" },
        { name: "name", title: t("accounts.description") },
        { name: "bank", title: t("dashboard.bank") },
        { name: "nameSearch", title: t("login.name") },
        { name: "country", title: t("register.country") },
        { name: "currency", title: t("dashboard.currency") },
        { name: "platform", title: t("accounts.platform") },
        {
            name: "transMonitoring",
            title: (
                <LightTooltip title={t("accounts.transMonitoring1")}>
                    <Typography sx={{ lineHeight: "1.15rem", cursor: "default", fontSize: "15px" }} fontWeight={600}>
                        {t("accounts.transMonitoring")}
                    </Typography>
                </LightTooltip>
            ),
        },
        {
            name: "edit",
            title: " ",
        },
    ];

    const tableColumnExtensions = [
        { columnName: "name", width: "25%" },
        { columnName: "bank" },
        { columnName: "country", width: "14%" },
        { columnName: "currency" },
        { columnName: "platform", width: "10%" },
        { columnName: "transMonitoring", wordWrapEnabled: true },
        { columnName: "edit", width: "5%" },
    ];

    const tableContentComponent = ({ column, children, ...props }) => (
        <TableHeaderRow.Content {...props}>
            {children}
            {column.name === "lastTrans" || column.name === "lastPeriod" || column.name === "latestBalance" ? (
                <LightTooltip title="" aria-label="lastTrans">
                    <>
                        <InfoIcon fontSize="inherit" sx={{ ml: 1, color: "#361A52" }} />
                    </>
                </LightTooltip>
            ) : null}
        </TableHeaderRow.Content>
    );

    const tableMessages = {
        noData: <Typography fontSize={14}>{t("dashboard.noInfo")}</Typography>,
    };

    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("dashboard.pagingOf")} ${count}`,
        rowsPerPage: t("dashboard.rowsPerPage"),
    };

    const searchMessages = {
        searchPlaceholder: t("dashboard.search"),
    };

    const setSelection = (gridSelectedRows) => {
        setSelectionAcc(gridSelectedRows);
    };

    const handleCloseDialog = () => {
        setOpenAlert(false);
    };

    const deleteAccounts = async (array) => {
        setIsLoading(true);

        let arrayValidation = [];

        selectionAcc.forEach((selectedRow) => {
            arrayValidation.push(rows[selectedRow].id);
        });

        const params = {
            accounts: arrayValidation,
        };
        await accountsService
            .disableAccounts(params)
            .then(() => {
                setReload();
                setSelectionAcc([]);
                setAlertType("success");
                if (arrayValidation.length > 1) {
                    setTexto(t("accounts.deleteSuccess"));
                } else {
                    setTexto(t("accounts.deleteSuccessSing"));
                }
                setShow(true);
                handleCloseDialog();
            })
            .catch((err) => {
                setTexto("Error");
                setAlertType("error");
                setShow(true);
            });
        setIsLoading(false);
    };

    const activateAccounts = async (array) => {
        setIsLoading(true);

        let arrayValidation = [];

        selectionAcc.forEach((selectedRow) => {
            arrayValidation.push(rows[selectedRow].id);
        });

        const params = {
            accounts: arrayValidation,
        };
        await accountsService
            .reactivateAccounts(params)
            .then(() => {
                setReload();
                setSelectionAcc([]);
                setAlertType("success");
                setChecked(false);
                if (arrayValidation.length > 1) {
                    setTexto(t("accounts.reactivateSuccess"));
                } else {
                    setTexto(t("accounts.reactivateSuccessSing"));
                }
                setShow(true);
                handleCloseDialog();
            })
            .catch((err) => {
                setTexto("Error");
                setAlertType("error");
                setShow(true);
            });
        setIsLoading(false);
    };

    const handleUpdate = async () => {
        if (
            !selectedAcc.account_name ||
            !selectedAcc.country_id ||
            !selectedAcc.currency_id ||
            !selectedAcc.bank_id ||
            (selectedAcc.system_integration === 1 &&
                selectedAcc.account_id_system !== null &&
                selectedAcc.account_id_system !== undefined &&
                selectedAcc.account_id_system !== "" &&
                !selectedAcc.frequency)
        ) {
            setIsError(true);
            setTexto(t("accounts.requireFields"));
            setAlertType("warning");
            setShow(true);
            return;
        }

        setIsLoading(true);

        let bank_id = selectedAcc.bank_id;

        if (selectedAcc.bank_id.includes(",")) {
            let banksId = selectedAcc.bank_id.split(",");
            let finalBank = banksFilters.find(
                (bank) =>
                    banksId.includes(bank._id) &&
                    selectedAcc.country_id === bank.country_id &&
                    selectedAcc.currency_id === bank.currency_id
            );
            bank_id = finalBank._id;
        }

        const params = {
            account_id: selectedAcc._id,
            account_name: selectedAcc.account_name,
            company_id: selectedAcc.company_id,
            country_id: selectedAcc.country_id,
            currency_id: selectedAcc.currency_id,
            bank_id: bank_id,
            frequency: selectedAcc.frequency,
        };

        await accountsService
            .updateAccount(params)
            .then(() => {
                setTexto(t("accounts.successUpdate"));
                setAlertType("success");
                setShow(true);
                handleClose();
                setReload();
            })
            .catch((err) => {
                setTexto("Error");
                setAlertType("error");
                setShow(true);
            });

        setIsLoading(false);
    };

    const handleNew = async () => {
        if (
            !selectedAcc.account_name ||
            !selectedAcc.country_id ||
            !selectedAcc.currency_id ||
            !selectedAcc.bank_id ||
            (selectedAcc.system_integration === 1 &&
                selectedAcc.account_id_system !== null &&
                selectedAcc.account_id_system !== undefined &&
                selectedAcc.account_id_system !== "" &&
                !selectedAcc.frequency)
        ) {
            setIsError(true);
            setTexto(t("accounts.requireFields"));
            setAlertType("warning");
            setShow(true);
            return;
        }

        setIsLoading(true);

        let bank_id = selectedAcc.bank_id;

        if (selectedAcc.bank_id.includes(",")) {
            let banksId = selectedAcc.bank_id.split(",");
            let finalBank = banksFilters.find(
                (bank) =>
                    banksId.includes(bank._id) &&
                    selectedAcc.country_id === bank.country_id &&
                    selectedAcc.currency_id === bank.currency_id
            );
            bank_id = finalBank._id;
        }

        const params = {
            account_name: selectedAcc.account_name,
            company_id: companyID,
            country_id: selectedAcc.country_id,
            currency_id: selectedAcc.currency_id,
            bank_id: bank_id,
            frequency: selectedAcc.frequency,
        };

        await accountsService
            .addAccount(params)
            .then(() => {
                setTexto(t("accounts.successNew"));
                setAlertType("success");
                setShow(true);
                handleClose();
                setReload();
            })
            .catch((err) => {
                setTexto("Error");
                setAlertType("error");
                setShow(true);
            });

        setIsLoading(false);
    };

    const editAccButtons = (
        <GridUI container justifyContent="space-between">
            <GridUI item>
                <Button onClick={handleClose}>{t("team.cancel")}</Button>
            </GridUI>
            <GridUI item>
                <Button variant="contained" onClick={editMode ? handleUpdate : handleNew}>
                    {t("team.save")}
                </Button>
            </GridUI>
        </GridUI>
    );

    const Root = useCallback(
        (props) => {
            const handleChangeSwitch = async (event) => {
                setChecked(event.target.checked);
                setShowSkeleton(true);
                setAccounts({
                    ...allAccounts,
                    setup: [],
                });
                setSelectionAcc([]);

                if (event.target.checked) {
                    await accountsService
                        .getAccounts({ company_id: companyID, active: false, language: sessionStorage.getItem("lng") })
                        .then(async (response) => {
                            setAccounts(response);
                            setdisabledCount(response.disabledCount);
                            await accountsService
                                .getBanks()
                                .then((data) => {
                                    setBanksFiltersConfig(
                                        data.filter((item) =>
                                            response.setup.some((item2) => item2.bank_id === item._id)
                                        )
                                    );
                                })
                                .catch((error) => {
                                    console.log(error);
                                });

                            await accountsService
                                .getCurrencies()
                                .then((data) => setCurrenciesFilters(data))
                                .catch((error) => {
                                    console.log(error);
                                });
                            await accountsService
                                .getCountries()
                                .then((data) => setCountriesFilters(data))
                                .catch((error) => {
                                    console.log(error);
                                });
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    await accountsService
                        .getAccounts({ company_id: companyID, language: sessionStorage.getItem("lng") })
                        .then(async (response) => {
                            setAccounts(response);
                            setdisabledCount(response.disabledCount);
                            await accountsService
                                .getBanks()
                                .then((data) => {
                                    setBanksFiltersConfig(
                                        data.filter((item) =>
                                            response.setup.some((item2) => item2.bank_id === item._id)
                                        )
                                    );
                                })
                                .catch((error) => {
                                    console.log(error);
                                });

                            await accountsService
                                .getCurrencies()
                                .then((data) => setCurrenciesFilters(data))
                                .catch((error) => {
                                    console.log(error);
                                });
                            await accountsService
                                .getCountries()
                                .then((data) => setCountriesFilters(data))
                                .catch((error) => {
                                    console.log(error);
                                });
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
                setShowSkeleton(false);
            };

            return (
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    justifyContent={{ xs: "center", md: "space-between" }}
                    spacing={2}
                >
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        alignItems={{ xs: "center", sm: "start" }}
                        spacing={{ xs: 2, sm: "25px" }}
                        justifyContent={{ xs: "center" }}
                    >
                        <Box>
                            <FormControlLabel
                                sx={{ alignItems: "center", mt: 1 }}
                                control={
                                    <Switch
                                        id="disabledAccounts"
                                        checked={checked}
                                        onChange={handleChangeSwitch}
                                        inputProps={{ "aria-label": "controlled" }}
                                    />
                                }
                                label={
                                    <Typography fontSize={16} noWrap>
                                        {t("accounts.viewDisabled")} {disabledCount > 0 ? `(${disabledCount})` : ""}
                                    </Typography>
                                }
                            />
                        </Box>
                    </Stack>
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        justifyContent={{ xs: "center", md: "end" }}
                        alignItems={{ xs: "center", sm: "start" }}
                        spacing={{ xs: 2, sm: "25px" }}
                    >
                        <Box sx={{ width: { xs: "100%", sm: "150px" } }}>
                            <FiltersButton
                                banks={banksFiltersConfig}
                                currencies={currenciesFilters.filter((item) =>
                                    allAccounts.setup.some((item2) => item2.currency_id === item._id)
                                )}
                                countries={countriesFilters.filter((item) =>
                                    allAccounts.setup.some((item2) => item2.country_id === item._id)
                                )}
                                setShowSkeleton={setShowSkeleton}
                                setAccounts={setAccounts}
                                companyID={companyID}
                                franchiseID={franchiseID}
                                grupal={grupal}
                                showSkeleton={showSkeleton}
                            />
                        </Box>
                        <Box sx={{ width: { xs: "100%", md: 200, lg: "auto" } }}>
                            <Toolbar.Root {...props} className={classes.toolbarSearch} />
                        </Box>
                    </Stack>
                </Stack>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            banksFiltersConfig,
            checked,
            companyID,
            countriesFilters,
            currenciesFilters,
            franchiseID,
            grupal,
            setAccounts,
            setShowSkeleton,
            t,
        ]
    );

    return (
        <>
            <AlertDialog
                open={openAlert}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("dialog.cancel")}
                type={!checked ? "warning" : "info2"}
                title={!checked ? t("dialog.warning") : t("login.confirm")}
                message={!checked ? bodyMess : bodyMess2}
                agreeAction={!checked ? () => deleteAccounts(selectionAcc) : () => activateAccounts(selectionAcc)}
                maxWidth="sm"
            />
            <SimpleBackdrop open={isLoading} />
            <StyledContainer
                component="main"
                sx={{
                    p: "35px",
                    backgroundColor: "#F8F8F9",
                    borderRadius: 5,
                    textAlign: { md: "initial", xs: "center" },
                }}
            >
                <Typography variant="h2" gutterBottom>{t("accounts.accSetup")}</Typography>
                {selectionAcc.length > 0 ? (
                    !checked ? (
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={handleClickDelete}
                            sx={{
                                padding: "12px 18px",
                                position: { md: "absolute" },
                                marginLeft: { md: 26 },
                            }}
                        >
                            {t("accounts.disable")} {`(${selectionAcc.length})`}
                        </Button>
                    ) : (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleClickActivate}
                            sx={{
                                padding: "12px 18px",
                                position: { md: "absolute" },
                                marginLeft: { md: 26 },
                            }}
                        >
                            {t("accounts.activate")} {`(${selectionAcc.length})`}
                        </Button>
                    )
                ) : (
                    <></>
                )}
                <GridUI container spacing={2}>
                    <GridUI item xs={12}>
                        <Grid rows={rows} columns={columns}>
                            <SelectionState selection={selectionAcc} onSelectionChange={setSelection} />
                            <SearchState value={searchValue} onValueChange={handleSearch} />
                            <IntegratedFiltering />
                            <PagingState defaultCurrentPage={0} pageSize={10} />
                            <IntegratedPaging />
                            <IntegratedSelection />
                            <Table
                                columnExtensions={tableColumnExtensions}
                                cellComponent={TableCellComponent}
                                messages={tableMessages}
                            />
                            <TableColumnVisibility
                                hiddenColumnNames={
                                    integration !== 1 ? ["transMonitoring", "nameSearch"] : ["nameSearch"]
                                }
                            />
                            <TableHeaderRow cellComponent={tableHeaderCell} contentComponent={tableContentComponent} />
                            <TableSelection
                                showSelectAll
                                headerCellComponent={tableHeaderSelCellBase}
                                cellComponent={tableSelectionCell}
                            />
                            <Toolbar rootComponent={Root} />
                            <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                            <PagingPanel containerComponent={PagingPanelContainer} messages={pagingMessages} />
                        </Grid>
                    </GridUI>
                </GridUI>
            </StyledContainer>
            <FormDialog
                open={openEdit}
                handleClose={handleClose}
                maxWidth="md"
                title={
                    editMode ? (
                        <Stack direction="row" justifyContent="space-between">
                            {t("team.edit")} {selectedAcc.account_name}
                            <Button onClick={handleClickHelp}>{t("accounts.needHelp")}</Button>
                        </Stack>
                    ) : (
                        <Stack direction="row" justifyContent="space-between">
                            {t("accounts.createAccount1")}
                            <Button onClick={handleClickHelp}>{t("accounts.needHelp")}</Button>
                        </Stack>
                    )
                }
                content={
                    <AccountDetails
                        data={selectedAcc}
                        setSelectedAcc={setSelectedAcc}
                        countries={countriesFilters}
                        currencies={currenciesFilters}
                        banks={banksFilters}
                        accounts={accounts}
                        isError={isError}
                        setIsLoading={setIsLoading}
                        editMode={editMode}
                        handleClickHelp={handleClickHelp}
                    />
                }
                actions={editAccButtons}
            />
        </>
    );
};

export default AccountSetupTable;
