import { Badge, Button, Grid, Link, Popover, Typography, Avatar, Stack } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useTranslation } from "react-i18next";
import xImage from "../../assets/x.svg"
import BaseButton from "./BaseButton";

/** MUI imports */
const FiltersPopover = (props) => {
    const { children, id, open, anchorEl, handleClose, cleanFilters, handleClick, length, addFilter, disabled, disabledButton = false } = props;
    const [t] = useTranslation("global");

    return (
        <>
            <Badge
                badgeContent={<Avatar alt="x" src={xImage} sx={{ width: "17px", height: "17px" }} />}
                invisible={length > 0
                    ? false
                    : true
                }
                color="primary"
                sx={{
                    cursor: disabledButton ? "auto" : "pointer", width: { xs: "100%", md: "160px" }, fontSize: "inherit", '& .MuiBadge-badge': {
                        height: "17px !important",
                        padding: "0px !important",
                        minWidth: "17px !important",
                        zIndex: 0
                    }
                }}
                onClick={cleanFilters}
            >
                <BaseButton
                    fullWidth
                    disableElevation
                    onClick={handleClick}
                    variantType="filtersButton"
                    disabled={disabledButton ?? false}
                >
                    {<Stack direction="row" alignItems="start" display="flex" spacing={1}>
                        <FilterAltIcon sx={{ width: "17px", height: "17px" }} />
                        <Typography fontWeight={600} sx={{ lineHeight: "normal" }}>{t("dashboard.filters")}</Typography>
                        {length > 0 ? (
                            <Avatar id="filterButton" sx={{
                                backgroundColor: "#361A52",
                                color: "#FFF",
                                transition: "0.3s",
                                width: "17px",
                                height: "17px",
                                fontSize: "9px",
                                ml: "20px",
                            }}>{length}</Avatar>
                        ) : (
                            ""
                        )}
                    </Stack>}
                </BaseButton>
            </Badge>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                sx={{
                    textAlign: "left",
                }}
                PaperProps={{
                    style: { width: "40%", padding: 20, borderRadius: 25 },
                }}
            >
                <Typography sx={{ pb: 2, textAlign: "left", fontSize: 14, fontWeight: 600 }}>
                    {t("dashboard.searchFilters")}
                </Typography>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Link
                        component={Button}
                        onClick={addFilter}
                        sx={{ fontSize: 14, fontWeight: 600, textDecoration: "none" }}
                        disabled={disabled}
                    >
                        + {t("filters.addFilter")}
                    </Link>
                </Grid>
            </Popover>
        </>
    );
};

export default FiltersPopover;
