import {
    Checkbox,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import LightTooltip from "../../ui/LightTooltip";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "5px 15px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: "white",
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& td": {
        border: 0,
    },
    "& td:first-of-type": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "& td:last-child": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 310,
            width: 200,
        },
    },
};

const NewInvoiceTableItems = (props) => {
    const {
        rows,
        setRows,
        edited,
        setEdited,
        editGlobal,
        setEditGlobal,
        xeroTaxRates,
        setTexto,
        setAlertType,
        setShow,
        selected,
        setSelected,
        accountsXero,
    } = props;

    const [t] = useTranslation("global");

    const todos = useSelector((state) => state.value);

    const columns = [
        { name: "item_code", title: t("register.code") },
        { name: "description", title: t("accounts.description"), width: "15%" },
        { name: "quantity", title: t("converter.qty") },
        { name: "unit_price", title: t("payment.unitPrice") },
        { name: "account_code", title: t("accounts.accountNum") },
        { name: "discount", title: t("converter.discount") },
        { name: "tax_type", title: t("accounts.tax") },
        { name: "line_amount", title: t("converter.totalAmount") },
        { name: "actions", title: <></>, width: "8%" },
    ];

    const handleChangeRow = (event, id, name) => {
        setRows(
            rows.map((aux) => {
                if (id === aux._id) {
                    return {
                        ...aux,
                        [name]: event.target.value,
                    };
                } else {
                    return aux;
                }
            })
        );
    };

    const isEdited = (id) => edited.indexOf(id) !== -1;
    const handleEditRow = (id, isEdited, rowsParam, fromAddRow, isEditIcon) => {
        if (!isEdited) {
            setEditGlobal(!editGlobal);
        }

        const checkedIndex = edited.indexOf(id);
        let newChecked = [];

        if (checkedIndex === -1) {
            newChecked = newChecked.concat(edited, id);
        } else if (checkedIndex === 0) {
            newChecked = newChecked.concat(edited.slice(1));
        } else if (checkedIndex === edited.length - 1) {
            newChecked = newChecked.concat(edited.slice(0, -1));
        } else if (checkedIndex > 0) {
            newChecked = newChecked.concat(edited.slice(0, checkedIndex), edited.slice(checkedIndex + 1));
        }

        if (!isEdited) {
            setEdited(newChecked);
        }

        if (isEdited) {
            let findEdited = rowsParam.find((e) => e._id === id);
            if (findEdited.description !== "" && findEdited.account_code !== "" && findEdited.quantity !== 0 && findEdited.unit_price !== 0 /*&& findEdited.tax_type !== ""*/) {
                setEditGlobal(!editGlobal);
                setEdited(newChecked);
            } else {
                setTexto(t("converter.cannotBeSaveInv"));
                setAlertType("error");
                setShow(true);
            }
        } else if (!isEditIcon) {
            if (!fromAddRow) {
                let findEdited = rowsParam.find((e) => e._id === id);
                if (findEdited?.new) {
                    if (findEdited.description !== "" && findEdited.account_code !== "" && findEdited.quantity !== 0 && findEdited.unit_price !== 0 /*&& findEdited.tax_type !== ""*/) {
                        setRows(rowsParam);
                    } else {
                        // Se descarta la línea
                        const filterRows = rowsParam.filter((item) => item._id !== id);
                        setRows(filterRows);
                        setEditGlobal(!editGlobal);
                    }
                } else {
                    setRows(rowsParam);
                }
            } else {
                setRows(rowsParam);
            }
        }
    };

    const getAmount = (unit_price, quantity, discount = 0, tax = 0) => {
        const subTotal = Number(unit_price) * Number(quantity);
        const taxRate = Number(xeroTaxRates.find((item) => item?.value === tax)?.taxRate || 0);
        const discountRate = Number(discount);

        const totalTax = subTotal * (taxRate / 100);
        const totalDiscount = subTotal * (discountRate / 100);

        return subTotal + totalTax - totalDiscount;
    };

    const handleChangeAmount = (value, id, blur = false) => {

        let newValue;
        if (blur) {
            newValue = value === "" ? 0 : Number(value);
        } else {
            newValue = value === "" ? "" : Number(value);
        }

        if (newValue >= 0 || newValue === "") {
            setRows(
                rows.map((aux) => {
                    if (id === aux._id) {
                        return {
                            ...aux,
                            quantity: newValue,
                            amount: getAmount(
                                aux?.unit_price ?? 0,
                                newValue ?? 0,
                                aux?.discount ?? 0,
                                aux?.tax_type ?? 0
                            ),
                        };
                    } else {
                        return aux;
                    }
                })
            );
        }
    };

    const handleChangeUP = (value, id, blur = false) => {

        let newValue;
        if (blur) {
            newValue = value === "" ? 0 : Number(value);
        } else {
            newValue = value === "" ? "" : Number(value);
        }

        if (newValue >= 0 || newValue === "") {
            setRows(
                rows.map((aux) => {
                    if (id === aux._id) {
                        return {
                            ...aux,
                            unit_price: newValue,
                            amount: getAmount(
                                newValue ?? 0,
                                aux?.quantity ?? 0,
                                aux?.discount ?? 0,
                                aux?.tax_type ?? 0
                            ),
                        };
                    } else {
                        return aux;
                    }
                })
            );
        }
    };

    const handleChangeSelect = async (event, id) => {
        const newRows = rows.map((aux) => {
            if (id === aux._id) {
                return {
                    ...aux,
                    account_code: event.target.value,
                };
            } else {
                return aux;
            }
        });
        setRows(newRows);
    };

    const handleChangeDiscount = (event, id) => {
        if (event.target.value > 0 || event.target.value === "") {
            setRows(
                rows.map((aux) => {
                    if (id === aux._id) {
                        return {
                            ...aux,
                            discount: Number(event.target.value),
                            amount: getAmount(
                                aux?.unit_price ?? 0,
                                aux?.quantity ?? 0,
                                event.target.value,
                                aux?.tax_type ?? 0
                            ),
                        };
                    } else {
                        return aux;
                    }
                })
            );
        }
    };

    const handleChangeSelectTax = async (event, id) => {
        const taxRate = xeroTaxRates.find((item) => item.value === event.target.value).taxRate;
        const newRows = rows.map((aux) => {
            if (id === aux._id) {
                return {
                    ...aux,
                    tax_type: event.target.value,
                    tax_amount: (taxRate * (aux?.unit_price ?? 0 * aux?.quantity ?? 0)) / 100,
                    amount: getAmount(aux?.unit_price ?? 0, aux?.quantity ?? 0, aux?.discount ?? 0, event.target.value),
                };
            } else {
                return aux;
            }
        });
        setRows(newRows);
    };

    const handleDeleteRow = (id) => {
        const newRows = rows.filter((item) => item._id !== id);

        setRows(newRows);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClickCheck = (id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    return (
        <TableContainer>
            <Table
                sx={{
                    borderCollapse: "separate",
                    borderSpacing: "0 6px",
                    minWidth: 1300,
                }}
            >
                <TableHead>
                    <TableRow
                        sx={{
                            "& th": { border: "none" },
                        }}
                    >
                        <StyledTableCell>
                            <Checkbox
                                color="primary"
                                indeterminate={selected.length > 0 && selected.length < rows.length}
                                checked={rows.length > 0 && selected.length === rows.length}
                                onChange={handleSelectAllClick}
                            />
                        </StyledTableCell>
                        {columns.map((headCell) => (
                            <StyledTableCell
                                key={headCell.name}
                                align={headCell.align ?? "left"}
                                style={{
                                    width: headCell.width,
                                }}
                            >
                                {headCell.title}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => {
                        const isItemSelected = isSelected(row._id);
                        return (
                            <StyledTableRow key={row._id}>
                                <StyledTableCell>
                                    <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                        onClick={() => handleClickCheck(row._id)}
                                    />
                                </StyledTableCell>
                                <StyledTableCell>
                                    {isEdited(row._id) ? (
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-textfield"
                                            variant="filled"
                                            hiddenLabel
                                            value={row.code}
                                            name="code"
                                            onChange={(event) => handleChangeRow(event, row._id, "code")}
                                            size="small"
                                        />
                                    ) : (
                                        row.code
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {isEdited(row._id) ? (
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-textfield"
                                            variant="filled"
                                            hiddenLabel
                                            value={row.description}
                                            name="description"
                                            onChange={(event) => handleChangeRow(event, row._id, "description")}
                                            size="small"
                                            error={row.new && (!row.description || row.description === "")}
                                        />
                                    ) : (
                                        row.description
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {isEdited(row._id) ? (
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-textfield"
                                            variant="filled"
                                            hiddenLabel
                                            value={row.quantity}
                                            name="quantity"
                                            onChange={(event) => handleChangeAmount(event.target.value, row._id)}
                                            onBlur={() => {
                                                let finalValue = row.quantity ?? ""

                                                let formattedVal;
                                                // Si el valor está vacío, establecer a 0
                                                if (!finalValue || finalValue === "" || finalValue === 0) {
                                                    formattedVal = "";
                                                } else {
                                                   formattedVal = finalValue.toString()?.includes('.') ? parseFloat(finalValue).toFixed(2) : finalValue;
                                                }
                                                handleChangeAmount(formattedVal, row._id, true);
                                            }}
                                            size="small"
                                            type="number"
                                            inputProps={{ min: 1 }}
                                            error={row.new && (!row.quantity || row.quantity === 0)}
                                        />
                                    ) : (
                                        row.quantity
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {isEdited(row._id) ? (
                                        <TextField
                                            id="input-with-icon-textfield"
                                            variant="filled"
                                            hiddenLabel
                                            value={row.unit_price}
                                            name="unit_price"
                                            onChange={(event) => handleChangeUP(event.target.value, row._id)}
                                            size="small"
                                            type="number"
                                            sx={{
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                    {
                                                        display: "none",
                                                    },
                                            }}
                                            onBlur={() => {
                                                let finalValue = row.unit_price ?? ""

                                                let formattedVal;
                                                // Si el valor está vacío, establecer a 0
                                                if (!finalValue || finalValue === "" || finalValue === 0) {
                                                    formattedVal = "";
                                                } else {
                                                   formattedVal = finalValue.toString()?.includes('.') ? parseFloat(finalValue).toFixed(2) : finalValue;
                                                }
                                                handleChangeUP(formattedVal, row._id, true);
                                            }}
                                            error={row.new && (!row.unit_price || row.unit_price === 0)}
                                        />
                                    ) : (
                                        ` $${parseFloat(row.unit_price ?? 0).toLocaleString(todos.amountFormat, {
                                            minimumFractionDigits: 2,
                                        })}
                                    `
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {isEdited(row._id) ? (
                                        <FormControl
                                            variant="filled"
                                            fullWidth
                                            hiddenLabel
                                            error={row.new && !row.account_code}
                                        >
                                            <Select
                                                id="demo-simple-select-standard"
                                                name="account_code"
                                                value={row.account_code ?? ""}
                                                onChange={(event) => handleChangeSelect(event, row._id)}
                                                size="small"
                                                MenuProps={MenuProps}
                                                displayEmpty
                                            >
                                                <MenuItem disabled value="">
                                                    <em>{t("converter.chooseAccCont")}</em>
                                                </MenuItem>
                                                {accountsXero.map((item) => (
                                                    <MenuItem value={item.value} key={item.value}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        accountsXero.find((acc) => acc?.value === row.account_code)?.name ?? ''
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {isEdited(row._id) ? (
                                        <TextField
                                            fullWidth
                                            id="input-with-icon-textfield"
                                            variant="filled"
                                            hiddenLabel
                                            value={row.discount}
                                            name="discount"
                                            onChange={(event) => handleChangeDiscount(event, row._id)}
                                            size="small"
                                            type="number"
                                            inputProps={{ min: 0.00, step: 0.01, max: 100 }}
                                        />
                                    ) : (
                                        (row?.discount ?? 0) + " %"
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {isEdited(row._id) ? (
                                        <FormControl
                                            variant="filled"
                                            fullWidth
                                            hiddenLabel
                                            error={row.new && !row.tax_type}
                                        >
                                            <Select
                                                id="demo-simple-select-standard"
                                                name="tax_type"
                                                value={row.tax_type}
                                                onChange={(event) => handleChangeSelectTax(event, row._id)}
                                                size="small"
                                                MenuProps={MenuProps}
                                                displayEmpty
                                            >
                                                <MenuItem disabled value="">
                                                    <em>{t("converter.chooseTax")}</em>
                                                </MenuItem>
                                                {xeroTaxRates.map((item) => (
                                                    <MenuItem value={item.value} key={item.value}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        xeroTaxRates.find((tax) => tax?.value === row?.tax_type)?.name ?? ''
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    ${" "}
                                    {parseFloat(row.quantity * row.unit_price ?? 0).toLocaleString(todos.amountFormat, {
                                        minimumFractionDigits: 2,
                                    })}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {!isEdited(row._id) ? (
                                        <Stack direction="row" spacing={1} justifyContent="center">
                                            <LightTooltip title={t("team.edit")}>
                                                <IconButton
                                                    size="small"
                                                    disabled={editGlobal && !isEdited(row._id)}
                                                    onClick={() => handleEditRow(row._id, false, rows, false, true)}
                                                >
                                                    <EditIcon
                                                        sx={{
                                                            color: edited.length === 0 ? "#4A22D4" : "",
                                                        }}
                                                    />
                                                </IconButton>
                                            </LightTooltip>
                                            {rows.length > 1 && (
                                                <LightTooltip title={t("miscellaneous.delete")}>
                                                    <IconButton
                                                        size="small"
                                                        disabled={editGlobal && !isEdited(row._id)}
                                                        onClick={() => handleDeleteRow(row._id)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            )}
                                        </Stack>
                                    ) : (
                                        <Stack direction="row" spacing={1}>
                                            <IconButton
                                                size="small"
                                                onClick={() => handleEditRow(row._id, true, rows, false, false)}
                                            >
                                                <CheckIcon color="success" />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                onClick={() => handleEditRow(row._id, false, rows, false, false)}
                                            >
                                                <CloseIcon color="error" />
                                            </IconButton>
                                        </Stack>
                                    )}
                                </StyledTableCell>
                            </StyledTableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default NewInvoiceTableItems;
