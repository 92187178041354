import { Box, FilledInput, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ReactComponent as WarningIcon } from "../../assets/warning.svg";
import LightTooltip from "./LightTooltip";

export default function CustomFilledSelect(props) {
    const { id, label, value, onChange, error, disabled, values, idName, valueName, required, name, warningIcon = false } = props;

    return (
        <FormControl
            fullWidth
            variant="filled"
            required={required}
            error={error}
            disabled={disabled ?? false}
        >
            <InputLabel
                sx={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    color: "#131F3E",
                }}
            >
                {label}
            </InputLabel>
            <Select
                id={id}
                name={name}
                IconComponent={(props) => (
                    <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                        {warningIcon && 
                            <LightTooltip title="Completa los datos más relevantes de tu contacto " placement="top">
                                <Box
                                    sx={{
                                        marginRight: '0.5rem',
                                        position: 'absolute',
                                        right: '2rem',
                                        mt: 1
                                    }}
                                >
                                    <WarningIcon width={20} />
                                </Box>
                            </LightTooltip>
                        }
                    </div>
                  )}
                value={value}
                onChange={onChange}
                input={
                    <FilledInput
                        label={label}
                        sx={{
                            fontStyle: "normal",
                            color: "#131F3E",
                        }}
                    />
                }
                MenuProps={{
                    PaperProps: {
                        sx: {
                            maxHeight: 48 * 4.5 + 8,
                            mt: 0.7,
                            borderRadius: 2,
                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                        },
                    },
                }}
            >
                {values.map((item) => (
                    <MenuItem key={item[valueName] + "_" + item[idName]} value={item[idName]} sx={{display: item[idName] === "hidden" ? "none" : "block"}}>{item[valueName]}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
