import { Box, Card, CardContent, CardHeader, Grid } from "@mui/material";

import { useTranslation } from "react-i18next";
import paymentMethodsService from "../../../services/payment_methods";
import subscriptionService from "../../../services/subscription";

import ShoppingItemsReg from "./ShoppingItemsReg";
import ShoppingSummaryReg from "./ShoppingSummaryReg";
import { useEffect, useState } from "react";

const ShoppingCartReg = (props) => {
    const {
        currentPlan,
        setCurrentPlan,
        paymentType,
        setPaymentType,
        currentLevel,
        priceMonthly,
        setPriceMonthly,
        priceMonthlyOG,
        setActiveStep,
        userEmail,
        setIsLoading,
        origin,
        allCompanies,
        activePlan,
        smbInfo,
        finalF,
        setShowCart,
        subsType
    } = props;

    const [t] = useTranslation("global");

    const [payments, setPayments] = useState([]);
    const [reload, setReload] = useState(false);

    const showStep3 = () => {
        setShowCart(false);
    };

    const deleteItem = async () => {
        //Se deben borrar los paquetes de créditos asociados al plan
        setCurrentPlan({
            value: 0,
            custom: false,
        });

        setIsLoading(true);

        const params = {
            company_id: smbInfo._id,
            cart_lines: [],
        };

        await subscriptionService
            .updateCart(params)
            .then()
            .catch((error) => {
                console.log(error);
            });

        setIsLoading(false);
        showStep3();
    };

    useEffect(() => {
        (async () => {
            await paymentMethodsService
                .getPaymentMethods({
                    franchise_id: smbInfo.franchise_id._id,
                    userEmail: userEmail,
                })
                .then((response) => {
                    setPayments(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        })();
    }, [smbInfo.franchise_id._id, reload, userEmail]);

    return (
        <Box paddingY={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                    <Card sx={{ p: 2, borderRadius: "10px", boxShadow: "2px 2px 15px 5px rgba(0, 0, 0, 0.1)" }}>
                        <CardContent>
                            <ShoppingItemsReg
                                deleteItem={deleteItem}
                                currentPlan={currentPlan}
                                currentLevel={currentLevel}
                                origin={origin}
                                activePlan={activePlan}
                                smbInfo={smbInfo}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Card sx={{ p: 2, borderRadius: "10px", boxShadow: "2px 2px 15px 5px rgba(0, 0, 0, 0.1)" }}>
                        <CardHeader
                            title={t("subscription.orderSummary")}
                            titleTypographyProps={{ variant: "h1", fontSize: 22 }}
                            sx={{ pb: "0 !important" }}
                        />
                        <CardContent>
                            <ShoppingSummaryReg
                                paymentType={paymentType}
                                setPaymentType={setPaymentType}
                                priceMonthly={priceMonthly}
                                currentLevel={currentLevel}
                                setPriceMonthly={setPriceMonthly}
                                priceMonthlyOG={priceMonthlyOG}
                                currentPlan={currentPlan}
                                payments={payments}
                                setReload={setReload}
                                setActiveStep={setActiveStep}
                                userEmail={userEmail}
                                showStep3={showStep3}
                                origin={origin}
                                allCompanies={allCompanies}
                                activePlan={activePlan}
                                smbInfo={smbInfo}
                                finalF={finalF}
                                setShowCart={setShowCart}
                                subsType={subsType}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ShoppingCartReg;
