/** Imports ---------------------------------------------------------------- */

/** React imports */
import React from "react";

/** Icons */
import CheckIcon from "@mui/icons-material/Check";
import { ReactComponent as ErrorIcon } from "../../assets/error-icon.svg";
import { ReactComponent as InfoIcon } from "../../assets/info-icon.svg";
import { ReactComponent as WarningIcon } from "../../assets/warning.svg";
import { ReactComponent as CustomPlanIcon } from "../../assets/custom-plan.svg";
import BusinessIcon from "@mui/icons-material/Business";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIconMui from '@mui/icons-material/Info';

/** MUI imports */
import { styled } from "@mui/material/styles";
import { Dialog, DialogActions, DialogContent, Slide, Typography, Box } from "@mui/material";
import BaseButton from "./BaseButton";
import { useTheme } from '@mui/material/styles';

const PREFIX = "DialogAlerts";

const classes = {
    icon: `${PREFIX}-icon`,
};

const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: "30px 30px 0px",
    },
    "& .MuiDialog-paper": {
        borderRadius: "50px",
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },

    [`& .${classes.icon}`]: {
        marginRight: 10,
        height: 30,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialog = (props) => {
    const {
        type,
        open,
        onClose,
        title,
        message,
        disagreeBtnLabel,
        agreeAction,
        agreeBtnLabel,
        maxWidth,
        onlyAction,
        oneAction,
        error,
        justifyContent,
        origin,
    } = props;

    const theme = useTheme();

    let dialogIcon = "";
    if (type === "success") {
        dialogIcon = <CheckIcon sx={{ color: "#00B147" }} className={classes.icon} />;
    } else if (type === "info") {
        dialogIcon = <InfoIcon fill="#3FC3EE" className={classes.icon} />;
    } else if (type === "warning") {
        dialogIcon = <WarningIcon fill="#F8BB86" className={classes.icon} />;
    } else if (type === "error") {
        dialogIcon = <ErrorIcon fill="#ff5968" className={classes.icon} />;
    } else if (type === "cancelPlan") {
        dialogIcon = <BusinessIcon color="error" className={classes.icon} />;
    } else if (type === "cart") {
        dialogIcon = <ShoppingCartIcon sx={{ color: theme.palette.text.primary }} className={classes.icon} />;
    } else if (type === "config") {
        dialogIcon = <SettingsIcon sx={{ color: theme.palette.text.primary }} className={classes.icon} />;
    } else if (type === "info2") {
        dialogIcon = <InfoIconMui sx={{ color: theme.palette.text.primary, fontSize: "20px" }} className={classes.icon} />;
    } else {
        dialogIcon = <CustomPlanIcon fill={theme.palette.text.primary} className={classes.icon} />;
    }

    return (
        <CustomDialog
            open={open}
            onClose={onClose}
            maxWidth={maxWidth}
            TransitionComponent={Transition}
            PaperProps={{
                sx: {
                    pb: { xs: 1, md: 2 },
                    px: { xs: 1, md: 3 },
                },
            }}
            fullWidth
        >
            <DialogContent>
                <Box display="flex" justifyContent="center" alignItems="center" sx={{ pb: 3 }}>
                    {dialogIcon}
                    <Typography
                        variant="h1"
                        color={
                            type === "customPlan" || type === "cart" || type === "config" || type === "info" || type === "info2"
                                ? theme.palette.text.primary
                                : type === "success"
                                ? "#00B147"
                                : "#FF007A"
                        }
                        align="center"
                        fontSize={origin === "converter" ? 20 : 25}
                        fontWeight={type === "customPlan" ? 300 : 600}
                    >
                        {title}
                    </Typography>
                </Box>
                {message}
            </DialogContent>
            <DialogActions sx={{ justifyContent: justifyContent ?? "space-evenly" }}>
                <BaseButton
                    disableElevation
                    variantType="primaryOutlined"
                    onClick={onlyAction ? oneAction : onClose}
                    color={error ? "error" : "primary"}
                    sizebutton="l"
                >
                    {disagreeBtnLabel}
                </BaseButton>
                <BaseButton
                    disableElevation
                    onClick={agreeAction}
                    sizebutton="l"
                >
                    {agreeBtnLabel}
                </BaseButton>
            </DialogActions>
        </CustomDialog>
    );
};

export default AlertDialog;
