/* Componentes */
import {
    Box,
    Tabs,
    Tab,
    Stack,
    Card,
    CardContent,
    Container,
    TextField,
    InputAdornment,
    Typography,
    CardActionArea,
    Grid,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import CaseTab from "./CaseTab";
import NotificationTab from "./NotificationTab";
import CommunicationTab from "./CommunicationTab";
import SimpleBackdrop from "../ui/SimpleBackdrop";

/* Estilos */
import BaseSnackbar from "../ui/BaseSnackbar";
import { useDispatch, useSelector } from "react-redux";

import SearchIcon from "@mui/icons-material/Search";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CampaignIcon from "@mui/icons-material/Campaign";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";

import { styled } from "@mui/material/styles";

import FiltersButtonAdmin from "./FiltersButtonAdmin";

import mainUsers from "../Js/productTeam";
import devUsers from "../Js/devTeam";
import ITTeam from "../Js/ITTeam";
import ClientsDir from "./ClientsDir";
import Billing from "./Billing";
import ChatTab from "./ChatTab";
import casesService from "../../services/case";
import { addCases } from "../../redux/userSlice";
import OpenTableAdmin from "./OpenTableAdmin";
import SolvedTableAdmin from "./SolvedTableAdmin";
import PendingTableAdmin from "./PendingTableAdmin";
import ClasiDoc from "./ClasiDoc";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    sx={{
                        py: 2,
                        px: { xs: 2, md: 5 },
                        backgroundColor: "#F8F8F9",
                        pb: 4,
                        height: "calc(100vh - 180px)",
                    }}
                >
                    {children}
                </Box>
            )}
        </div>
    );
}

const CardActionAreaCust = styled(CardActionArea)(() => ({
    "&:hover": {
        backgroundColor: "#C4B8EF",
    },
}));

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const TabProps = {
    whiteSpace: "nowrap",
    fontSize: 16,
    fontWeight: 300,
    mr: 3,
    backgroundColor: "#f6f4fd",
    borderRadius: 3,
    minHeight: 35,
    height: 35,
    color: "#131F3E !important",
    "&.Mui-selected": {
        backgroundColor: "#c9bdf2",
        fontWeight: 600,
        borderRadius: 3,
    },
};

const defaultState = { clientDir: false, support: false, comms: false, billing: false };

const defaultFilter = {
    filtersArray: [
        {
            type: "",
            value: "",
            since: "",
            until: "",
            multiple: [],
        },
    ],
};

const MainInbox = (props) => {
    const {
        franchise_id,
        company_id,
        cases,
        casesOG,
        user_id,
        setCases,
        comm,
        setComm,
        notif,
        setNotif,
        showSkeleton,
        setShowSkeleton,
        accountsFilter,
        usersFilter,
        banksFilter,
        setCasesOG,
        loadingF,
        subsFilter,
        comFilter,
        countriesFilter,
        subsInvFilter,
        countriesInvFilter,
        subsDirFilter,
        countriesDirFilter,
    } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);
    const dispatch = useDispatch();

    const [value, setValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [searchValue, setSearchValue] = useState("");
    const [option, setOption] = useState(null);
    const [filters, setFilters] = useState(defaultFilter);

    const attributes = [
        { id: 1, name: t("converter.status"), query: "id_status" },
        { id: 2, name: t("payment.concept"), query: "concept" },
        { id: 3, name: t("inbox.mainUser"), query: "case_responsible" },
        { id: 4, name: t("inbox.devUser"), query: "case_developer_manager" },
        { id: 5, name: t("inbox.testUser"), query: "case_test_manager" },
        { id: 6, name: t("inbox.priority"), query: "priority" },
        { id: 7, name: t("navBar.subscription"), query: "subscription" },
        { id: 8, name: t("dashboard.company"), query: "company" },
        { id: 9, name: t("inbox.creationDate"), query: "creation_date" },
    ];

    const columns = [
        { name: "global_case", title: t("inbox.case"), width: "10%" },
        { name: "franchise", title: t("navBar.subscription"), width: "10%" },
        { name: "creation_date", title: t("inbox.creationDate"), align: "center", width: "10%" },
        { name: "status", title: t("converter.status"), width: "10%" },
        { name: "mainUser", title: t("inbox.mainUser"), width: "10%" },
        { name: "devUser", title: t("inbox.devUser"), width: "12%" },
        { name: "testUser", title: t("inbox.testUser"), width: "10%" },
        { name: "priority", title: t("inbox.priority"), width: "8%" },
        { name: "actions", title: <></>, width: "10%" },
    ];

    const priorities = [
        {
            name: t("inbox.urgent"),
            value: 1,
            color: "#FF007A",
        },
        {
            name: t("inbox.high"),
            value: 2,
            color: "#FFB23F",
        },
        {
            name: t("inbox.medium"),
            value: 3,
            color: "#3FA7D6",
        },
        {
            name: t("inbox.low"),
            value: 4,
            color: "#131F3E",
        },
    ];

    useEffect(() => {
        setFilters({
            filtersArray: [
                {
                    type: "",
                    value: "",
                    since: "",
                    until: "",
                    multiple: [],
                },
            ],
        });
    }, []);

    const reloadCases = async () => {
        if (!(JSON.stringify(filters.filtersArray) === JSON.stringify(defaultFilter.filtersArray))) {
            let aux = [...filters.filtersArray];
            let objectFinal = {};

            aux.forEach((item) => {
                const query = attributes.find((item2) => item2.id === item.type).query;
                if (item.value && item.type) {
                    objectFinal[query] = item.value;
                }
                if (item.type && item.since !== "") {
                    objectFinal[query + "_since"] = item.since.format("YYYY-MM-DD");
                }
                if (item.type && item.until !== "") {
                    objectFinal[query + "_until"] = item.until.format("YYYY-MM-DD");
                }
                if (item.multiple && item.multiple.length > 0) {
                    objectFinal[query] = item.multiple;
                }
            });

            let params = {
                ...objectFinal,
            };

            await casesService
                .getAdminCases(params)
                .then((data) => {
                    if (searchValue !== "") {
                        const filterCases = {
                            ...data,
                            resolved: data.resolved.filter(
                                (item) =>
                                    item.global_case_number?.toLowerCase().includes(searchValue.toLowerCase()) ||
                                    item.franchise_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                                    item.company_name?.toLowerCase().includes(searchValue.toLowerCase())
                            ),
                            pending: data.pending.filter(
                                (item) =>
                                    item.global_case_number?.toLowerCase().includes(searchValue.toLowerCase()) ||
                                    item.franchise_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                                    item.company_name?.toLowerCase().includes(searchValue.toLowerCase())
                            ),
                            open: data.open.filter(
                                (item) =>
                                    item.global_case_number?.toLowerCase().includes(searchValue.toLowerCase()) ||
                                    item.franchise_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                                    item.company_name?.toLowerCase().includes(searchValue.toLowerCase())
                            ),
                        };

                        setCases(filterCases);
                        setCasesOG(data);
                        dispatch(addCases(data.finalCount));
                    } else {
                        setCases(data);
                        setCasesOG(data);
                        dispatch(addCases(data.finalCount));
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            await casesService
                .getAdminCases()
                .then((data) => {
                    if (searchValue !== "") {
                        const filterCases = {
                            ...data,
                            resolved: data.resolved.filter(
                                (item) =>
                                    item.global_case_number?.toLowerCase().includes(searchValue.toLowerCase()) ||
                                    item.franchise_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                                    item.company_name?.toLowerCase().includes(searchValue.toLowerCase())
                            ),
                            pending: data.pending.filter(
                                (item) =>
                                    item.global_case_number?.toLowerCase().includes(searchValue.toLowerCase()) ||
                                    item.franchise_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                                    item.company_name?.toLowerCase().includes(searchValue.toLowerCase())
                            ),
                            open: data.open.filter(
                                (item) =>
                                    item.global_case_number?.toLowerCase().includes(searchValue.toLowerCase()) ||
                                    item.franchise_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                                    item.company_name?.toLowerCase().includes(searchValue.toLowerCase())
                            ),
                        };

                        setCases(filterCases);
                        setCasesOG(data);
                        dispatch(addCases(data.finalCount));
                    } else {
                        setCases(data);
                        setCasesOG(data);
                        dispatch(addCases(data.finalCount));
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleChangeOp = (key) => {
        setOption({ defaultState, [key]: true });
    };

    const handleChangeSearchValue = async (event) => {
        setSearchValue(event.target.value);

        if (event.target.value === "") {
            if (!(JSON.stringify(filters.filtersArray) === JSON.stringify(defaultFilter.filtersArray))) {
                let aux = [...filters.filtersArray];
                let objectFinal = {};

                aux.forEach((item) => {
                    const query = attributes.find((item2) => item2.id === item.type).query;
                    if (item.value && item.type) {
                        objectFinal[query] = item.value;
                    }
                    if (item.type && item.since !== "") {
                        objectFinal[query + "_since"] = item.since.format("YYYY-MM-DD");
                    }
                    if (item.type && item.until !== "") {
                        objectFinal[query + "_until"] = item.until.format("YYYY-MM-DD");
                    }
                    if (item.multiple && item.multiple.length > 0) {
                        objectFinal[query] = item.multiple;
                    }
                });

                let params = {
                    ...objectFinal,
                };

                await casesService
                    .getAdminCases(params)
                    .then((data) => {
                        setCases(data);
                        setCasesOG(data);
                        dispatch(addCases(data.finalCount));
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                await casesService
                    .getAdminCases()
                    .then((data) => {
                        setCases(data);
                        setCasesOG(data);
                        dispatch(addCases(data.finalCount));
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        } else {
            const filterCases = {
                ...casesOG,
                resolved: casesOG.resolved.filter(
                    (item) =>
                        item.global_case_number?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                        item.franchise_name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                        item.company_name?.toLowerCase().includes(event.target.value.toLowerCase())
                ),
                pending: casesOG.pending.filter(
                    (item) =>
                        item.global_case_number?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                        item.franchise_name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                        item.company_name?.toLowerCase().includes(event.target.value.toLowerCase())
                ),
                open: casesOG.open.filter(
                    (item) =>
                        item.global_case_number?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                        item.franchise_name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                        item.company_name?.toLowerCase().includes(event.target.value.toLowerCase())
                ),
            };
            setCases(filterCases);
        }
    };

    const handleChangeTab = async (event, newValue) => {
        setShowSkeleton(true);
        setValue(newValue);
        setShowSkeleton(false);
    };

    /** Component functions */
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    useEffect(() => {
        if (todos.reset?.all) {
            setOption(null);
        } else if (todos.reset?.supp) {
            setOption({ defaultState, support: true });
        }
    }, [todos.reset]);

    return (
        <>
            {!ITTeam.includes(todos.userInfo.email) && (
                <Card elevation={0}>
                    <CardContent sx={{ mt: 1, mr: 1 }}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                            <Tabs
                                orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                                value={value}
                                onChange={handleChangeTab}
                                aria-label="Horizontal tabs"
                                sx={{
                                    p: 1,
                                }}
                                TabIndicatorProps={{
                                    style: { display: "none" },
                                }}
                            >
                                <Tab
                                    label={
                                        t("inbox.cases") +
                                        (ITTeam.includes(todos.userInfo.email) &&
                                        cases &&
                                        cases?.pending &&
                                        cases.pending.length + cases.open.length > 0
                                            ? ` (${cases.pending.length + cases.open.length})`
                                            : cases && cases?.pending && cases.pending.length > 0
                                            ? ` (${cases.pending.length})`
                                            : "")
                                    }
                                    {...a11yProps(0)}
                                    sx={TabProps}
                                />
                                {/* <Tab
                                label={
                                    t("inbox.notifications") +
                                    (notif && notif.filter((item) => item.notification_status === 1).length > 0
                                        ? ` (${notif.filter((item) => item.notification_status === 1).length})`
                                        : "")
                                }
                                {...a11yProps(1)}
                                sx={TabProps}
                            />
                            <Tab
                                label={
                                    t("inbox.communications") +
                                    (comm && comm.filter((item) => item.comunication_status === 1).length > 0
                                        ? ` (${comm.filter((item) => item.comunication_status === 1).length})`
                                        : "")
                                }
                                {...a11yProps(2)}
                                sx={TabProps}
                            /> */}
                            </Tabs>
                        </Stack>
                    </CardContent>
                </Card>
            )}
            {ITTeam.includes(todos.userInfo.email) && !option && (
                <Container maxWidth="xl" sx={{ pt: 5 }}>
                    <Grid container justifyContent="space-evenly" spacing={2}>
                        <Grid item>
                            <Card sx={{ borderRadius: 5 }}>
                                <CardActionAreaCust onClick={() => handleChangeOp("clientDir")}>
                                    <CardContent sx={{ textAlign: "center", minWidth: 300 }}>
                                        <RecentActorsIcon color="primary" sx={{ fontSize: 80 }} />
                                        <Typography variant="h1">{t("inbox.clientDir")}</Typography>
                                    </CardContent>
                                </CardActionAreaCust>
                            </Card>
                        </Grid>
                        <Grid item>
                            <Card sx={{ borderRadius: 5 }}>
                                <CardActionAreaCust onClick={() => handleChangeOp("support")}>
                                    <CardContent sx={{ textAlign: "center", minWidth: 300 }}>
                                        <SupportAgentIcon color="primary" sx={{ fontSize: 80 }} />
                                        <Typography variant="h1">{t("plans.support")}</Typography>
                                    </CardContent>
                                </CardActionAreaCust>
                            </Card>
                        </Grid>
                        <Grid item>
                            <Card sx={{ borderRadius: 5 }}>
                                <CardActionAreaCust onClick={() => handleChangeOp("supporcommst")}>
                                    <CardContent sx={{ textAlign: "center", minWidth: 300 }}>
                                        <CampaignIcon color="primary" sx={{ fontSize: 80 }} />
                                        <Typography variant="h1">{t("inbox.communications")}</Typography>
                                    </CardContent>
                                </CardActionAreaCust>
                            </Card>
                        </Grid>
                        <Grid item>
                            <Card sx={{ borderRadius: 5 }}>
                                <CardActionAreaCust onClick={() => handleChangeOp("billing")}>
                                    <CardContent sx={{ textAlign: "center", minWidth: 300 }}>
                                        <ReceiptLongIcon color="primary" sx={{ fontSize: 80 }} />
                                        <Typography variant="h1">{t("payment.billing")}</Typography>
                                    </CardContent>
                                </CardActionAreaCust>
                            </Card>
                        </Grid>
                        <Grid item>
                            <Card sx={{ borderRadius: 5 }}>
                                <CardActionAreaCust onClick={() => handleChangeOp("clasiDoc")}>
                                    <CardContent sx={{ textAlign: "center", minWidth: 300 }}>
                                        <ContentPasteSearchIcon color="primary" sx={{ fontSize: 80 }} />
                                        <Typography variant="h1">{t("inbox.classDoc")}</Typography>
                                    </CardContent>
                                </CardActionAreaCust>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid item sx={{ py: 3 }}>
                            <iframe
                                title="Kiiper"
                                width="1300"
                                height="700"
                                src="https://app.powerbi.com/reportEmbed?reportId=eb5b3b6a-fa95-43c7-9fd8-f59337f98f66&autoAuth=true&ctid=b3c2a01d-4f1a-495d-8d53-0ae57c2200d2"
                                style={{ border: 0 }}
                                allowFullScreen={true}
                            ></iframe>
                        </Grid>
                    </Grid>
                </Container>
            )}
            <Box sx={{ background: value === 0 ? "rgba(19, 31, 62, 0.03)" : "white" }}>
                {ITTeam.includes(todos.userInfo.email) && option?.clientDir && (
                    <ClientsDir
                        setIsLoading={setIsLoading}
                        loadingF={loadingF}
                        subsFilter={subsDirFilter}
                        countriesFilter={countriesDirFilter}
                    />
                )}
                {ITTeam.includes(todos.userInfo.email) && option?.support && (
                    <>
                        <Card>
                            <CardContent sx={{ padding: 0, paddingBottom: "12px !important" }}>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                    sx={{ pb: 2, pt: 2, px: { xs: 1, md: 5 } }}
                                >
                                    <Grid item>
                                        <Stack direction="row" spacing={2} mt={2}>
                                            <Tabs
                                                orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                                                aria-label="Horizontal tabs"
                                                sx={{
                                                    p: 1,
                                                }}
                                                TabIndicatorProps={{
                                                    style: { display: "none" },
                                                }}
                                                value={value}
                                                onChange={handleChangeTab}
                                            >
                                                <Tab label={t("inbox.byAssigning")} {...a11yProps(0)} sx={TabProps} />
                                                <Tab label={t("inbox.open")} {...a11yProps(1)} sx={TabProps} />
                                                <Tab label={t("inbox.closed")} {...a11yProps(2)} sx={TabProps} />
                                                <Tab label="Chats" {...a11yProps(3)} sx={TabProps} />
                                            </Tabs>
                                        </Stack>
                                    </Grid>
                                    <Grid item>
                                        <Stack direction="row" spacing={2} mt={2}>
                                            {loadingF && (
                                                <FiltersButtonAdmin
                                                    mainUsers={mainUsers}
                                                    devUsers={devUsers}
                                                    setShowSkeleton={setShowSkeleton}
                                                    setCases={setCases}
                                                    subscriptionFilter={subsFilter}
                                                    companyFilter={comFilter}
                                                    countriesFilter={countriesFilter}
                                                    filters={filters}
                                                    setFilters={setFilters}
                                                    searchValue={searchValue}
                                                    setCasesOG={setCasesOG}
                                                />
                                            )}
                                            <TextField
                                                id="input-with-icon-textfield"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="filled"
                                                hiddenLabel
                                                placeholder="Buscar..."
                                                //size="small"
                                                value={searchValue}
                                                onChange={handleChangeSearchValue}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <TabPanel value={value} index={0}>
                            <PendingTableAdmin
                                columns={columns}
                                cases={cases}
                                mainUsers={mainUsers}
                                setIsLoading={setIsLoading}
                                user_id={user_id}
                                company_id={company_id}
                                franchise_id={franchise_id}
                                setCases={setCases}
                                devUsers={devUsers}
                                priorities={priorities}
                                setShow={setShow}
                                setTexto={setTexto}
                                setAlertType={setAlertType}
                                reloadCases={reloadCases}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <OpenTableAdmin
                                columns={columns}
                                cases={cases}
                                mainUsers={mainUsers}
                                devUsers={devUsers}
                                priorities={priorities}
                                setIsLoading={setIsLoading}
                                user_id={user_id}
                                company_id={company_id}
                                franchise_id={franchise_id}
                                setCases={setCases}
                                setShow={setShow}
                                setTexto={setTexto}
                                setAlertType={setAlertType}
                                reloadCases={reloadCases}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <SolvedTableAdmin
                                cases={cases}
                                columns={columns}
                                priorities={priorities}
                                setIsLoading={setIsLoading}
                                user_id={user_id}
                                company_id={company_id}
                                franchise_id={franchise_id}
                                setCases={setCases}
                                reloadCases={reloadCases}
                                mainUsers={mainUsers}
                                devUsers={devUsers}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <ChatTab cases={cases} setIsLoading={setIsLoading} reloadCases={reloadCases} />
                        </TabPanel>
                    </>
                )}
                {ITTeam.includes(todos.userInfo.email) && option?.billing && (
                    <Billing
                        setIsLoading={setIsLoading}
                        setOption={setOption}
                        subscriptionFilter={subsInvFilter}
                        countriesFilter={countriesInvFilter}
                        loadingF={loadingF}
                    />
                )}
                {ITTeam.includes(todos.userInfo.email) && option?.clasiDoc && <ClasiDoc setIsLoading={setIsLoading} />}
                {!ITTeam.includes(todos.userInfo.email) && (
                    <Container maxWidth="xl">
                        <TabPanel value={value} index={0}>
                            <CaseTab
                                franchise_id={franchise_id}
                                company_id={company_id}
                                setIsLoading={setIsLoading}
                                cases={cases}
                                user_id={user_id}
                                setCases={setCases}
                                showSkeleton={showSkeleton}
                                setShowSkeleton={setShowSkeleton}
                                accountsFilter={accountsFilter}
                                usersFilter={usersFilter}
                                banksFilter={banksFilter}
                                setShow={setShow}
                                setTexto={setTexto}
                                setAlertType={setAlertType}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <NotificationTab
                                user_id={user_id}
                                setIsLoading={setIsLoading}
                                notif={notif}
                                setNotif={setNotif}
                                setShow={setShow}
                                setTexto={setTexto}
                                setAlertType={setAlertType}
                                showSkeleton={showSkeleton}
                                setShowSkeleton={setShowSkeleton}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <CommunicationTab
                                comm={comm}
                                setComm={setComm}
                                franchise_id={franchise_id}
                                company_id={company_id}
                                user_id={user_id}
                                showSkeleton={showSkeleton}
                                setShowSkeleton={setShowSkeleton}
                                setShow={setShow}
                                setTexto={setTexto}
                                setAlertType={setAlertType}
                            />
                        </TabPanel>
                    </Container>
                )}
            </Box>
            <SimpleBackdrop open={isLoading} />
            <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleClose} />
        </>
    );
};

export default MainInbox;
