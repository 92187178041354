import {
    Box,
    FormControl,
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Skeleton,
    Stack,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Visa from "../../../assets/visa.svg";
import Mastercard from "../../../assets/mastercard.svg";
import Amex from "../../../assets/amex.svg";
import Discover from "../../../assets/discover.svg";
import Diners from "../../../assets/diners.svg";
import JCB from "../../../assets/jcb.svg";
import UnionPay from "../../../assets/unionpay.svg";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";

import customClasses from "../../subscription/SubscriptionTab2.module.css";

import moment from "moment";
import LightTooltip from "../../ui/LightTooltip";
import payService from "../../../services/payment_methods";
import paymentMethodsService from "../../../services/payment_methods";
import { useSelector } from "react-redux";
import AddPaymentDialog from "../../payments/AddPaymentDialog";
import EditPaymentDialog from "../../payments/EditPayDialog";
import AlertDialog from "../../ui/AlertDialog";
import BaseButton from "../../ui/BaseButton";

const rowsSkeletonPayments = [];

for (let i = 0; i < 15; i++) {
    rowsSkeletonPayments.push({
        id: i,
        payments: <Skeleton variant="text" animation="wave" />,
    });
}

const PaymentList = (props) => {
    const { setIsLoading, setShow, setTexto, setAlertType, payments, setPayments } = props;
    const [t] = useTranslation("global");

    const todos = useSelector((state) => state.value);

    const [selectedValue, setSelectedValue] = useState("");
    const [cardAddress, setCardAddress] = useState({});
    const [showEditPay, setShowEditPay] = useState(false);
    const [showAddPay, setShowAddPay] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(false);

    useEffect(() => {
        if (payments.length > 0) {
            setSelectedValue(payments.find((item) => item.default_method)._id);
        } else {
            setSelectedValue("");
        }
    }, [payments]);

    const bodyMess = (
        <Grid container justifyContent="center" spacing={2}>
            <Grid item>
                <Typography fontSize={14} textAlign="justify">
                    {payments.length === 1 ? t("payment.deleteMsg2") : t("payment.deleteMsg1")}
                </Typography>
            </Grid>
            {payments.length === 1 && (
                <Grid item>
                    <Typography fontSize={14} textAlign="justify">
                        {t("dialog.warningConfirm2")}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );

    const handleCloseModal = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowAddPay(false);
            setShowEditPay(false);
            setCardAddress({});
        }
    };

    const handleAddPaymentM = () => {
        setShowAddPay(true);
    };

    const handleCloseDialog = () => {
        setShowAlert(false);
    };

    const handleChange = async (event) => {
        setSelectedValue(event.target.value);

        const params = {
            payment_id: event.target.value,
            default_method: true,
        };

        setIsLoading(true);

        await payService
            .updateDefaultMethod(params)
            .then()
            .catch((err) => console.log(err));

        setIsLoading(false);
    };

    const handleOpenEdit = (value) => {
        setCardAddress(value);
        setShowEditPay(true);
    };

    const reloadData = async () => {
        setShowSkeleton(true);

        await paymentMethodsService
            .getPaymentMethods({
                franchise_id: todos.franchiseID,
            })
            .then((response) => {
                setPayments(response);
            })
            .catch((error) => {
                console.log(error);
            });

        setShowSkeleton(false);
    };

    const deleteCard = async () => {
        setIsLoading(true);

        const params = {
            id: cardAddress._id,
            payment_id: cardAddress.payment_id,
        };

        await payService
            .deletePaymentMethod(params)
            .then(() => {
                setShowAlert(false);
                setAlertType("success");
                setTexto(t("miscellaneous.deleteCardSuccess"));
                setShow(true);
                reloadData();
                handleCloseModal();
            })
            .catch((err) => console.log(err));

        setIsLoading(false);
    };

    useEffect(() => {
        reloadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [todos.franchiseID]);

    return (
        <Paper
            elevation={0}
            square={false}
            sx={{
                width: "100%",
                borderRadius: 2.5,
                minHeight: "calc(100vh - 300px)",
                p: 4,
                justifyContent: "space-between",
                flexDirection: "column",
                display: "flex",
            }}
        >
            <Box>
                <Typography sx={{ pb: 2 }} variant="h2">
                    {t("payment.paymentsMethod")}
                </Typography>
                {showSkeleton ? (
                    rowsSkeletonPayments.map((item, index) => (
                        <Box sx={{ p: 2 }} key={"skeleton_payment_" + index}>
                            {item.payments}
                        </Box>
                    ))
                ) : payments.length > 0 ? (
                    payments.map((item, index) => (
                        <Box className={customClasses.boxPay} key={"box_payment_" + index}>
                            <FormControl disabled={payments.length === 1} sx={{ width: "100%" }}>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    onChange={handleChange}
                                    value={selectedValue}
                                >
                                    <FormControlLabel
                                        value={item._id}
                                        control={<Radio size="small" />}
                                        disableTypography
                                        label={
                                            <Stack
                                                direction={{ xs: "column", sm: "row" }}
                                                spacing={2}
                                                alignItems="center"
                                                sx={{ width: "100%" }}
                                                justifyContent="space-between"
                                            >
                                                <Stack direction="row" spacing={2}>
                                                    <Box
                                                        component="img"
                                                        src={
                                                            item.brand === "visa"
                                                                ? Visa
                                                                : item.brand === "mastercard"
                                                                ? Mastercard
                                                                : item.brand === "amex"
                                                                ? Amex
                                                                : item.brand === "discover"
                                                                ? Discover
                                                                : item.brand === "diners"
                                                                ? Diners
                                                                : item.brand === "unionpay"
                                                                ? UnionPay
                                                                : JCB
                                                        }
                                                        sx={{ width: 45 }}
                                                    />
                                                    <Stack>
                                                        <Typography
                                                            fontWeight={600}
                                                            fontSize={13}
                                                            textAlign={{ xs: "center", sm: "left" }}
                                                        >
                                                            {item.brand.charAt(0).toUpperCase() + item.brand.slice(1)}
                                                            {" *"}
                                                            {item.card_number.split("-").pop()}
                                                            {moment
                                                                .utc(item.due_date)
                                                                .subtract(3, "M")
                                                                .isSameOrBefore(moment()) && (
                                                                <LightTooltip
                                                                    title={t("payment.dueSoon")}
                                                                    position="top"
                                                                >
                                                                    <InfoIcon
                                                                        fontSize="inherit"
                                                                        sx={{
                                                                            ml: 1,
                                                                            mb: -0.2,
                                                                            color: "#FF9900",
                                                                        }}
                                                                    />
                                                                </LightTooltip>
                                                            )}
                                                        </Typography>
                                                        <BaseButton variantType="primaryText" onClick={() => handleOpenEdit(item)}>
                                                            {t("team.edit")}
                                                        </BaseButton>
                                                    </Stack>
                                                </Stack>
                                                {item?.default_method && item.default_method === true && (
                                                    <Box
                                                        sx={{
                                                            backgroundColor: "#dcdee2",
                                                            borderRadius: "8px",
                                                            padding: "12px 14px",
                                                        }}
                                                    >
                                                        <Typography>{t("payment.default")}</Typography>
                                                    </Box>
                                                )}
                                            </Stack>
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    ))
                ) : (
                    <Box className={customClasses.boxPay}>{t("subscription.noPayMethod")}</Box>
                )}
            </Box>

            <Stack direction="row" justifyContent="end">
                <BaseButton disableElevation sizebutton="xl" onClick={handleAddPaymentM} endIcon={<AddIcon />}>
                    {t("subscription.addPayMethod")}
                </BaseButton>
            </Stack>
            <AlertDialog
                open={showAlert}
                onClose={handleCloseDialog}
                type="warning"
                maxWidth="sm"
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("dialog.cancel")}
                title={t("dialog.warning")}
                message={bodyMess}
                agreeAction={deleteCard}
            />
            {showAddPay && (
                <AddPaymentDialog
                    open={showAddPay}
                    handleClose={handleCloseModal}
                    maxWidth="sm"
                    setShow={setShow}
                    setMessage={setTexto}
                    setAlertType={setAlertType}
                    reloadData={reloadData}
                    setIsLoading={setIsLoading}
                    isTab2={true}
                />
            )}
            {showEditPay && (
                <EditPaymentDialog
                    open={showEditPay}
                    handleClose={handleCloseModal}
                    maxWidth="sm"
                    setShow={setShow}
                    setMessage={setTexto}
                    setAlertType={setAlertType}
                    addressData={cardAddress}
                    reloadData={reloadData}
                    setIsLoading={setIsLoading}
                    setShowAlert={setShowAlert}
                    setShowEditPay={setShowEditPay}
                />
            )}
        </Paper>
    );
};

export default PaymentList;
