/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

/** MUI Imports */
import { Typography, MenuItem, Divider, Box, TextField, Popover, Stack } from "@mui/material";
import LightTooltip from "../../ui/LightTooltip";

/** MUI Icons */
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import classes from "./DropdownNavBar.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
    addIsGrouped,
    addFranchiseID,
    addCompanyID,
    addCredits,
    addSubscription,
    addAllComData,
    addUpXero,
    addUserCompRol,
    addChangeRoute,
} from "../../../redux/userSlice";
import subscriptionService from "../../../services/subscription";
import userCompanyService from "../../../services/user_company";
import teamService from "../../../services/team";
import moment from "moment";

const DropdownNavBar = (props) => {
    const {
        company,
        openModalNewCompany,
        franchise,
        setSelectedCompany,
        setSelectedFranchise,
        franchisesCompany,
        setShowModalConfig,
        allData,
        subscription,
        setIsLoading,
        rol,
        setFranchisesCompany,
        setRol,
        getMessages,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Navigate */
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.value);

    /** Component states */
    const [anchorEl, setAnchorEl] = useState(null);
    const [changeOrg, setChangeOrg] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [mergedCompanies, setMergedCompanies] = useState([]);
    const [searchValueFranchise, setSearchValueFranchise] = useState("");
    const [searchResultsFranchise, setSearchResultsFranchise] = useState([]);

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    /** Component functions */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setChangeOrg(false);
        setSearchValue("");
        setSearchResults([]);
        setSearchValueFranchise("");
        setSearchResultsFranchise([]);
    };

    const handleClickChangeOrg = () => {
        setChangeOrg((prevState) => !prevState);
        setSearchValue("");
        setSearchResults([]);
    };

    const selectCompany = async (event, company) => {
        dispatch(addIsGrouped(false));
        handleClose();
        setIsLoading(true);

        let idFranchise = null;

        if (company?.id_status === 6) {
            navigate("/subscription", {
                state: {
                    tab: 0,
                },
            });
        } else {
            if (todos.selected === "ddlSalesInv") {
                navigate(`/convert/invoices`);
                dispatch(addChangeRoute("newCompany"));
            } else if (todos.selected === "ddlPurchaseInv") {
                navigate(`/convert/bills`);
                dispatch(addChangeRoute("newCompany"));
            } else {
                navigate("/dashboard");
            }
        }

        if (company.franchise_name) {
            setSelectedFranchise(company);
            setSelectedCompany({});
            dispatch(addFranchiseID(company._id));
            dispatch(addCompanyID(null));
            dispatch(
                addSubscription({
                    value: company.id_status,
                    last_value: company.id_last_status,
                    plan: company.subscription_plan,
                })
            );

            idFranchise = company._id;

            let today = moment().format();
            let params = { franchise_id: company._id, date: today };
            await userCompanyService
                .updateLastAccess(params)
                .then()
                .catch((error) => {
                    console.log(error);
                });

            const result = await userCompanyService.getFranchiseInfo(company._id);

            setFranchisesCompany(result.franchiseChildren.slice(0, 6));

            let allCopy = [...todos.allComData];

            allCopy = allCopy.map((item) => {
                if (item._id.toString() === company._id.toString()) {
                    let finalItem = {
                        ...item,
                        last_access: today,
                    };
                    return finalItem;
                } else {
                    return item;
                }
            });
            dispatch(addAllComData(allCopy));
            getMessages(company._id, "");

            let filterCompanies = allCopy.filter((e) => e.franchise_id === company._id);

            if (filterCompanies.length === 1) {
                dispatch(addCompanyID(filterCompanies[0]._id));

                await subscriptionService
                    .getFranchiseCredits({ company_id: filterCompanies[0]._id })
                    .then((response) => dispatch(addCredits(response)))
                    .catch((err) => console.log(err));

                setSelectedCompany(filterCompanies[0]);
            }
        } else {
            setSelectedCompany(company);
            dispatch(addCompanyID(company._id));
            dispatch(addUpXero(false));

            idFranchise = company.franchise_id;
            setSelectedFranchise(allData.find((item) => item._id === company.franchise_id));
            dispatch(addFranchiseID(company.franchise_id));

            dispatch(
                addSubscription({
                    value: company.id_status,
                    last_value: company.id_last_status,
                    plan: company.subscription_plan,
                })
            );

            await subscriptionService
                .getFranchiseCredits({ company_id: company._id })
                .then((response) => dispatch(addCredits(response)))
                .catch((err) => console.log(err));

            let today = moment().format();
            let params = { company_id: company._id, date: today };
            await userCompanyService
                .updateLastAccess(params)
                .then()
                .catch((error) => {
                    console.log(error);
                });

            const result = await userCompanyService.getFranchiseInfo(company.franchise_id);

            setFranchisesCompany(result.franchiseChildren.slice(0, 6));
            getMessages(company.franchise_id, company._id);
        }

        const paramsNew = {
            userID: todos.userInfo._id,
            franchiseID: idFranchise,
        };

        await teamService
            .getUserCompanyDetails(paramsNew)
            .then((data) => {
                setRol(data.user_permission);
                dispatch(addUserCompRol(data.user_permission));
            })
            .catch((error) => {
                console.log(error);
            });

        setIsLoading(false);
    };

    const handleClickManageTeam = () => {
        if (rol !== 3) {
            navigate("/team");
            handleClose();
        }
    };

    const handleChangeSearchValue = (event) => {
        setSearchValue(event.target.value);
        let filterCompanies = allData.filter(
            (company) =>
                company?.company_name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                company?.franchise_name?.toLowerCase().includes(event.target.value.toLowerCase())
        );

        filterCompanies = changeOrg
            ? filterCompanies.filter((company) => company.franchise_name && company._id !== todos.franchiseID)
            : filterCompanies;
        setSearchResults(filterCompanies);
    };

    const handleChangeSearchValueFranchise = (event) => {
        setSearchValueFranchise(event.target.value);

        const filterCompanies = allData.filter(
            (company) =>
                company?.company_name?.toLowerCase().includes(event.target.value.toLowerCase()) &&
                company.franchise_id === franchise._id.toString()
        );
        setSearchResultsFranchise(filterCompanies);
    };

    const handleClickTooltip = () => {
        if (rol !== 3) {
            navigate("/subscription", {
                state: {
                    tab: 0,
                },
            });
            handleClose();
        }
    };

    useEffect(() => {
        let newArray = allData.filter((company) => !company.franchise_id && company._id !== todos.franchiseID);

        newArray = newArray.sort(function (a, b) {
            let date1 = new Date(b.last_access ?? "0001-01-01T00:00:00Z");
            let date2 = new Date(a.last_access ?? "0001-01-01T00:00:00Z");
            return date1.toISOString().localeCompare(date2.toISOString());
        });

        newArray = newArray.slice(0, 6);
        setMergedCompanies(newArray);
    }, [allData, todos.franchiseID]);

    const handleClickFranq = () => {
        navigate("/management", {
            state: {
                tab: 0,
                createCompany: true,
            },
        });
        handleClose();
    };

    const handleOpenCompanyDir = () => {
        navigate("/management", {
            state: {
                tab: 0,
            },
        });
        handleClose();
    };

    useEffect(() => {
        const handleBackButton = () => {
            openModalNewCompany(false);
            setShowModalConfig(false);
        };
        window.addEventListener("popstate", handleBackButton);

        return () => {
            window.removeEventListener("popstate", handleBackButton);
        };
    }, [openModalNewCompany, setShowModalConfig]);

    return (
        <>
            <Box
                sx={{
                    borderRadius: "6px",
                    border: "1px solid #8F809F",
                    color: "#361A52",
                    cursor: "pointer",
                    display: "flex",
                    whiteSpace: "nowrap",
                    width: { xs: 180, sm: 200, lg: 230 },
                }}
                onClick={(event) => handleClick(event)}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                        width: { xs: 180, sm: 200, lg: 230 },
                        minHeight: "36.5px",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        sx={{
                            padding: "6px 5px 6px 15px",
                            maxWidth: 230,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            fontSize: { md: 12, lg: 13 },
                        }}
                    >
                        {todos.isGrouped
                            ? t("miscellaneous.all")
                            : !todos.companyID
                            ? franchise.franchise_name
                            : company.company_name}
                    </Typography>
                    {open ? (
                        <ArrowDropDownIcon
                            sx={{
                                transform: "rotate(180deg)",
                                mr: 0.5,
                            }}
                        />
                    ) : (
                        <ArrowDropDownIcon
                            sx={{
                                mr: 0.5,
                            }}
                        />
                    )}
                </Stack>
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: {
                        width: 300,
                        borderRadius: 20,
                        backgroundColor: "#fff",
                        padding: 6,
                    },
                }}
            >
                {!changeOrg && !todos.isGrouped ? (
                    <>
                        <TextField
                            fullWidth
                            placeholder={t("navBar.search")}
                            value={searchValueFranchise}
                            onChange={handleChangeSearchValueFranchise}
                            className={classes.search}
                        />
                        <Typography sx={{ p: "5px 20px 10px 15px" }}>
                            {`${t("navBar.lastOrganisations")} ${t("navBar.of")}`}{" "}
                            <Typography fontWeight={600} component="span">
                                {franchise?.franchise_name}
                            </Typography>
                        </Typography>
                        <LightTooltip
                            title={
                                subscription === 1 || subscription === 4 ? (
                                    <div style={{ cursor: "default" }}>
                                        {t("navBar.tooltipNewCompany")}{" "}
                                        <span
                                            onClick={(event) => handleClickTooltip(event)}
                                            style={{
                                                fontWeight: "bold",
                                                color: "#0FF",
                                                cursor: "pointer",
                                            }}
                                        >
                                            {t("navBar.reviewPlans")}
                                        </span>
                                    </div>
                                ) : rol === 3 ? (
                                    t("navBar.tooltipNewCompany2")
                                ) : (
                                    ""
                                )
                            }
                        >
                            <span>
                                <MenuItem onClick={handleClickFranq}>
                                    <Typography
                                        fontWeight={600}
                                        color="primary"
                                    >
                                        + {t("navBar.newCompany")}
                                    </Typography>
                                </MenuItem>
                            </span>
                        </LightTooltip>
                        {searchValueFranchise ? (
                            searchResultsFranchise.length > 0 ? (
                                searchResultsFranchise.map((company) => (
                                    <MenuItem
                                        key={company._id}
                                        onClick={(event) => selectCompany(event, company)}
                                        sx={{ ml: 1.5 }}
                                    >
                                        <LightTooltip
                                            title={
                                                (company.franchise_name
                                                    ? company.franchise_name.length
                                                    : company.company_name.length) <= 30
                                                    ? ""
                                                    : company.franchise_name
                                                    ? company.franchise_name
                                                    : company.company_name
                                            }
                                        >
                                            <Typography
                                                key={company._id}
                                                sx={{
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                }}
                                            >
                                                {company.franchise_name ? company.franchise_name : company.company_name}
                                            </Typography>
                                        </LightTooltip>
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem sx={{ ml: 1.5, pointerEvents: "none" }}>
                                    <Typography>{t("navBar.noResults")}</Typography>
                                </MenuItem>
                            )
                        ) : (
                            <>
                                {franchisesCompany.map((company) => (
                                    <MenuItem
                                        key={company._id}
                                        onClick={(event) => selectCompany(event, company)}
                                        sx={{ ml: 1.5 }}
                                    >
                                        <LightTooltip
                                            title={
                                                (company.franchise_name
                                                    ? company.franchise_name?.length
                                                    : company.company_name?.length) <= 30
                                                    ? ""
                                                    : company.franchise_name
                                                    ? company.franchise_name
                                                    : company.company_name
                                            }
                                        >
                                            <Typography
                                                key={company._id}
                                                sx={{
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                }}
                                            >
                                                {company.franchise_name ? company.franchise_name : company.company_name}
                                            </Typography>
                                        </LightTooltip>
                                    </MenuItem>
                                ))}
                                {franchisesCompany.length === 0 && (
                                    <MenuItem sx={{ ml: 1.5, pointerEvents: "none" }}>
                                        <Typography>{t("navBar.noResults")}</Typography>
                                    </MenuItem>
                                )}
                            </>
                        )}
                        <MenuItem onClick={handleOpenCompanyDir}>
                            <Typography
                                fontWeight={600}
                                color="primary"
                            >
                                {t("navBar.seeAllCompany")}
                            </Typography>
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={(event) => handleClickChangeOrg(event)}>
                            <Typography variant="h3">{t("navBar.changeOrg")}</Typography>
                            <SwapHorizIcon
                                sx={{
                                    ml: "auto",
                                    color: "rgba(19, 31, 62, 0.70)",
                                    mr: 2,
                                }}
                            />
                        </MenuItem>
                        <MenuItem
                            onClick={handleClickManageTeam}
                            sx={{ cursor: rol === 3 ? "default" : "pointer", py: 1.2 }}
                            disabled={rol === 3}
                        >
                            <Typography variant="h3">{t("navBar.teamConfig")}</Typography>
                        </MenuItem>
                    </>
                ) : (
                    <>
                        <TextField
                            fullWidth
                            placeholder={t("navBar.search")}
                            value={searchValue}
                            onChange={handleChangeSearchValue}
                            className={classes.search}
                        />
                        {searchValue ? (
                            searchResults.length > 0 ? (
                                searchResults.map((company) => (
                                    <MenuItem
                                        key={company._id}
                                        onClick={(event) => selectCompany(event, company)}
                                        sx={{ ml: 1.5 }}
                                    >
                                        <Typography key={company._id} fontWeight={600}>
                                            {company.franchise_name ? company.franchise_name : company.company_name}
                                        </Typography>
                                    </MenuItem>
                                ))
                            ) : (
                                <Typography sx={{ textAlign: "center", pb: 2 }}>{t("navBar.noResults")}</Typography>
                            )
                        ) : (
                            <>
                                <Typography sx={{ p: "5px 20px 10px 15px" }}>
                                    {t("navBar.otherOrg")}
                                </Typography>
                                {mergedCompanies.map((company) => (
                                    <MenuItem
                                        key={company._id}
                                        onClick={(event) => selectCompany(event, company)}
                                        sx={{ ml: 1.5 }}
                                    >
                                        <LightTooltip
                                            title={
                                                (company.franchise_name
                                                    ? company.franchise_name.length
                                                    : company.company_name.length) <= 30
                                                    ? ""
                                                    : company.franchise_name
                                                    ? company.franchise_name
                                                    : company.company_name
                                            }
                                        >
                                            <Typography
                                                key={company._id}
                                                fontWeight={600}
                                                sx={{
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                }}
                                            >
                                                {company.franchise_name ? company.franchise_name : company.company_name}
                                            </Typography>
                                        </LightTooltip>
                                    </MenuItem>
                                ))}
                                {mergedCompanies.length === 0 && (
                                    <Typography sx={{ textAlign: "center", pb: 2 }}>{t("navBar.noResults")}</Typography>
                                )}
                            </>
                        )}
                        {!todos.isGrouped && (
                            <>
                                <Divider />
                                <MenuItem onClick={() => handleClickChangeOrg()}>
                                    <ArrowBackIcon sx={{ width: "12px" }} />
                                    <Typography sx={{ ml: 1 }}>{t("navBar.back")}</Typography>
                                </MenuItem>
                            </>
                        )}
                    </>
                )}
            </Popover>
        </>
    );
};

export default DropdownNavBar;
