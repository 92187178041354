import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    addUserInfo,
    addAuth,
    addDateFormat,
    addAmountFormat,
    clearInfo,
    addCompanyID,
    addFranchiseID,
    addPermissions,
    addTutorial,
    addSelected,
} from "../../redux/userSlice";

/* Componentes */
import {
    Typography,
    Grid,
    Link,
    Stack,
    InputAdornment,
    IconButton,
    OutlinedInput,
    FormControl,
    InputLabel,
} from "@mui/material";
import CustTextField from "../ui/CustTextField";
import BaseCard from "../ui/BaseCard";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

/* Estilos */
import classes from "./LoginCard.module.css";

/* Services */
import authService from "../../services/auth";
import userConfigService from "../../services/user_config";
import userService from "../../services/user";
import ITTeam from "../Js/ITTeam";
import BaseButton from "../ui/BaseButton";

const LoginCard = (props) => {
    const {
        handleShowForgotCard,
        setTexto,
        setAlertType,
        setShow,
        handleShowTryCard,
        setIsLoading,
        setShowRegister,
        setIndependentUser,
        setRegister,
        setUserEmail,
        isEmail,
        isCart,
        tab,
        language,
    } = props;

    /** Internationalization i18n */
    const [t, i18n] = useTranslation("global");

    /* React redux */
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isError, setIsError] = useState(false);
    const [emailVal, setEmailVal] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [searchParams] = useSearchParams();
    const [disabled, setDisabled] = useState(false);
    const [counter, setCounter] = useState(120);

    const handleResend = async () => {
        await userService
            .getUserData(emailVal)
            .then(async (response) => {
                const params = {
                    user_email: emailVal,
                    user_name: response.user_name,
                    language: sessionStorage.getItem("lng"),
                };

                await authService
                    .resend(params)
                    .then(() => {
                        setDisabled(true);
                        setTexto(t("login.verifyEmail"));
                        setAlertType("success");
                        setShow(true);
                    })
                    .catch((err) => {});
            })
            .catch((error) => {
                console.log(error.response);
            });
    };

    useEffect(() => {
        if (disabled && counter > 0) {
            setTimeout(() => setCounter(counter - 1), 1000);
        }
        if (disabled && counter === 0) {
            setDisabled(false);
            setCounter(120);
        }
    }, [counter, disabled]);

    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language);
        }
    }, [language, i18n]);

    const login = async (event) => {
        event.preventDefault();

        if (emailVal.trim() && password) {
            if (/.+@.+\..+/.test(emailVal)) {
                setIsLoading(true);

                const params = {
                    user_email: emailVal,
                    password: password,
                };

                await authService
                    .login(params)
                    .then(async (responseLogin) => {
                        await userConfigService
                            .getUserConfig(responseLogin.data.user._id)
                            .then((response) => {
                                if (response.status === 204) {
                                    sessionStorage.setItem("lng", "es");
                                    i18n.changeLanguage("es");

                                    dispatch(addDateFormat("DD/MM/YYYY"));
                                    dispatch(addAmountFormat("de-DE"));
                                } else {
                                    sessionStorage.setItem("lng", response.language);
                                    i18n.changeLanguage(response.language);

                                    dispatch(addDateFormat(response.date_format));
                                    dispatch(addAmountFormat(response.amount_format));
                                }
                            })
                            .catch((error) => {
                                console.log(error.response);
                            });

                        await userService
                            .getUserData(params.user_email)
                            .then(async (response) => {
                                if (
                                    !response.country_name ||
                                    !response.phone_code ||
                                    !response.phone_number ||
                                    !response.user_name ||
                                    !response.kiiper_discovery
                                ) {
                                    setIndependentUser(true);
                                    setShowRegister(true);
                                    setRegister(true);
                                    setUserEmail(params.user_email);
                                    await authService
                                        .logout()
                                        .then(() => {
                                            dispatch(clearInfo());
                                        })
                                        .catch((err) => console.log(err));
                                } else {
                                    dispatch(addUserInfo(responseLogin.data.user));
                                    dispatch(addPermissions(responseLogin.data.permissions));
                                    dispatch(addAuth(true));

                                    const defaultTut = {
                                        step1: false,
                                        step2: false,
                                        step4: false,
                                        step5: false,
                                        step6: false,
                                        step7: false,
                                        step8: false,
                                        step9: false,
                                    };

                                    dispatch(addTutorial(defaultTut));

                                    if (searchParams.get("type") === "3") {
                                        if (searchParams.has("company-id")) {
                                            dispatch(
                                                addCompanyID(
                                                    searchParams.get("company-id")
                                                        ? searchParams.get("company-id")
                                                        : null
                                                )
                                            );
                                        }
                                        if (searchParams.has("franchise-id")) {
                                            dispatch(
                                                addFranchiseID(
                                                    searchParams.get("franchise-id")
                                                        ? searchParams.get("franchise-id")
                                                        : null
                                                )
                                            );
                                        }
                                    }

                                    if (ITTeam.includes(responseLogin.data.user.email)) {
                                        navigate("/inbox");
                                    } else if (isEmail) {
                                        dispatch(addSelected("ddlAccounts"));
                                        if (responseLogin.data.redirect) {
                                            setShowRegister(true);
                                            setRegister(true);
                                            setUserEmail(params.user_email);
                                            window.location.href = responseLogin.data.redirect;
                                        } else {
                                            navigate("/dashboard", {
                                                state: {
                                                    tab: tab,
                                                    redirect: true,
                                                    isCart: isCart,
                                                },
                                            });
                                        }
                                    } else {
                                        dispatch(addSelected("ddlAccounts"));
                                        if (responseLogin.data.redirect) {
                                            setShowRegister(true);
                                            setRegister(true);
                                            setUserEmail(params.user_email);
                                            window.location.href = responseLogin.data.redirect;
                                        } else {
                                            navigate("/dashboard");
                                        }
                                    }
                                }
                            })
                            .catch((error) => {
                                console.log(error.response);
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                        switch (err.response.status) {
                            case 401:
                                setTexto(t("login.loginError401"));
                                break;
                            case 403:
                                setTexto(
                                    <Typography>
                                        {t("login.loginError403")}{" "}
                                        <Link
                                            sx={{ fontWeight: 600, cursor: "pointer", textDecoration: "none" }}
                                            onClick={handleResend}
                                            disabled={disabled}
                                        >
                                            {t("team.resend")}
                                        </Link>
                                    </Typography>
                                );
                                break;
                            case 404:
                                setTexto(t("login.loginError404"));
                                break;
                            default:
                                setTexto(t("login.loginError500"));
                                break;
                        }
                        setAlertType("error");
                        setShow(true);
                    });

                setIsError(false);
                setIsLoading(false);
            } else {
                setIsError(true);
            }
        } else {
            setIsError(true);
        }
    };

    const handleNext = () => {
        setEmailVal("");
        setPassword("");
        setIsError(false);
        handleShowForgotCard();
    };

    const handleNextTry = () => {
        setEmailVal("");
        setPassword("");
        setIsError(false);
        handleShowTryCard();
    };

    const handleChangeEmail = (event) => {
        setEmailVal(event.target.value);
    };

    const handleChangePass = (event) => {
        setPassword(event.target.value);
    };

    const handleClickShowPassword = (event) => {
        event.preventDefault();
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <BaseCard>
            <form onSubmit={login}>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12} pb={2}>
                        <Typography variant="h2">{t("login.login")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustTextField
                            id="outlined-mail"
                            type="email"
                            label={t("login.email")}
                            value={emailVal}
                            onChange={handleChangeEmail}
                            error={isError && !/.+@.+\..+/.test(emailVal)}
                            fullWidth
                            shrink
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel
                                htmlFor="outlined-pass"
                                shrink
                                sx={{
                                    color: "#361A52",
                                    fontWeight: 600,
                                    "& label.Mui-focused": {
                                        color: "#361A52",
                                    },
                                }}
                            >
                                {t("login.password")}
                            </InputLabel>
                            <OutlinedInput
                                notched
                                id="outlined-pass"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                onChange={handleChangePass}
                                value={password}
                                error={isError && !password}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            name={"showPassword"}
                                        >
                                            {showPassword ? (
                                                <Visibility fontSize="small" color="primary" />
                                            ) : (
                                                <VisibilityOff fontSize="small" color="primary" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label={t("login.password")}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <BaseButton id="myLoginBtn" type="submit" fullWidth>
                            {t("login.loginText")}
                        </BaseButton>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                            <Typography className={classes.noAcc}>{t("login.noAccount")}</Typography>
                            <BaseButton onClick={handleNextTry} variantType="errorText">
                                {t("login.testKiiper")}
                            </BaseButton>
                        </Stack>
                        <BaseButton onClick={handleNext} variantType="errorText">
                            {t("login.forgotText")}
                        </BaseButton>
                    </Grid>
                </Grid>
            </form>
        </BaseCard>
    );
};

export default LoginCard;
