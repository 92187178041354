import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const CssTextField = styled(TextField)({
    "& label": {
        color: "#361A52",
        fontWeight: 600,
    },
    "& label.Mui-focused": {
        color: "#361A52",
    },
});

export default function CustTextField(props) {
    const {
        id,
        name,
        label,
        type,
        InputProps,
        value,
        onChange,
        error,
        onBlur,
        helperText,
        onKeyDown,
        shrink,
        size,
        variant,
        fullWidth,
        required,
        disabled
    } = props;

    return (
        <CssTextField
            InputLabelProps={{ shrink: shrink }}
            id={id}
            name={name}
            label={label}
            size={size}
            type={type}
            fullWidth={fullWidth}
            value={value}
            onChange={onChange}
            error={error}
            onBlur={onBlur}
            InputProps={InputProps}
            helperText={helperText}
            onKeyDown={onKeyDown}
            variant={variant}
            required={required}
            disabled={disabled}
        />
    );
}
