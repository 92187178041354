import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Typography } from "@mui/material";
import LightTooltip from "./LightTooltip";

const styles = {
    select: {
        backgroundColor: "white", // Set the background color to white
    },
    selectBasic: {
        backgroundColor: "rgba(0, 0, 0, 0.06)",
    },
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 310,
            width: 200,
        },
    },
};

export default function BaseSelect(props) {
    const { id, value, onChange, values, label, error, color, type, disabled, hasTooltip, language, name } = props;

    if (hasTooltip) {
        return (
            <LightTooltip title={values.find((item) => item.id === value)?.name} position="top">
                <FormControl fullWidth error={error} disabled={disabled}>
                    <InputLabel id={"customized-base-select-label" + id}>
                        {label}
                    </InputLabel>
                    <Select
                        labelId={"customized-base-select-label" + id}
                        id={"customized-base-select" + id}
                        value={value}
                        onChange={onChange}
                        name={name}
                        style={color === "white" ? styles.select : styles.selectBasic}
                        MenuProps={MenuProps}
                        label={label}
                    >
                        {values.map((val) => (
                            <MenuItem key={val.id} value={val.id}>
                                {type === "bank" ? (
                                    <>
                                        <Typography variant="h3" sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                            {val.name}&nbsp;
                                        </Typography>
                                        <Typography>{val.bankName}</Typography>
                                    </>
                                ) : (
                                    val.name
                                )}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </LightTooltip>
        );
    }

    return (
        <FormControl fullWidth error={error} disabled={disabled}>
            <InputLabel id={"customized-base-select-label" + id}>
                {label}
            </InputLabel>
            <Select
                labelId={"customized-base-select-label" + id}
                id={"customized-base-select" + id}
                value={value}
                onChange={onChange}
                name={name}
                style={color === "white" ? styles.select : styles.selectBasic}
                MenuProps={MenuProps}
                label={label}
            >
                {values.map((val) => (
                    <MenuItem key={val.id} value={val.id}>
                        {type === "bank" ? (
                            <>
                                <Typography variant="h3" sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                    {val.name}&nbsp;
                                </Typography>
                                <Typography>{val.bankName}</Typography>
                            </>
                        ) : type === "conversionFormats" ? (
                            language === "es" ? (
                                val.name
                            ) : (
                                val.name_en
                            )
                        ) : (
                            val.name
                        )}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
