import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

/* Componentes */
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import FormDialog from "../ui/FormDialog";
import UserForm from "./UserForm";

/* Iconos */
import AddIcon from "@mui/icons-material/Add";
import BusinessIcon from "@mui/icons-material/Business";
// import StarIcon from "@mui/icons-material/Star";

/* Estilos */
import classes from "./TeamHeader.module.css";
import { useTranslation } from "react-i18next";

/* Services */
import authService from "../../services/auth";
import teamService from "../../services/team";

import DashboardBreadcrumbs from "../dashboard/DashboardBreadcrumbs";
import { useDispatch } from "react-redux";
import { addCompanyID } from "../../redux/userSlice";
import LightTooltip from "../ui/LightTooltip";
import AlertDialog from "../ui/AlertDialog";
import CustTextField from "../ui/CustTextField";

const TeamHeader = (props) => {
    const {
        children,
        franchiseID,
        setIsLoading,
        setTexto,
        setAlertType,
        setShow,
        companyID,
        company,
        franchise,
        setReload,
        setInvitations,
        allInvitations,
        usersCounter,
        subscription,
        hasAccess,
        setCompany,
        historyInvitationsOG,
        setHistoryInvitations,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.value);

    const rolNames = [
        { id: 1, name: t("team.owner") },
        { id: 2, name: t("team.admin") },
        { id: 3, name: t("team.colab") },
    ];

    const tooltipTextDisabled1 = t("team.tooltipLimitTeam");
    const tooltipTextDisabled2 = t("team.tooltipLimitTeam2");

    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [userRol, setUserRol] = useState("");
    const [isError, setIsError] = useState(false);
    const [verified, setVerified] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [isDisabled, setDisabled] = useState(false);
    const [usersQuantity, setUsersQuantity] = useState("");
    const [isDisabledAddTeam, setIsDisabledAddTeam] = useState(false);
    const [showText, setShowText] = useState(1);
    const [openConfirmInv, setOpenConfirmInv] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setIsError(false);
        setIsValidEmail(false);
        setVerified(false);
        setName("");
        setEmail("");
        setUserRol("");
        setDisabled(false);
    };

    const openAddUser = () => {
        setOpen(true);
    };

    const handleChangeSearchValue = (event) => {
        setSearchValue(event.target.value);

        const filterUsers = allInvitations.filter(
            (item) =>
                item.user_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.invite_user_name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.invite_email.toLowerCase().includes(event.target.value.toLowerCase()) ||
                rolNames
                    .find((x) => x.id === item.user_permission)
                    .name.toLowerCase()
                    .includes(event.target.value.toLowerCase())
        );

        const filterHistory = historyInvitationsOG.filter(
            (item) =>
                item.user_name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.invite_user_name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.invite_email?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                rolNames
                    .find((x) => x.id === item.user_permission)
                    .name.toLowerCase()
                    .includes(event.target.value.toLowerCase())
        );

        setInvitations(filterUsers);
        setHistoryInvitations(filterHistory);
    };

    const handleOpenModalInv = () => {
        if (!companyID) {
            inviteUser(true);
        } else {
            setOpenConfirmInv(true);
        }
    };

    const handleCloseModal = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setOpenConfirmInv(false);
        }
    };

    const inviteUser = async (isFirm) => {
        if (/.+@.+\..+/.test(email) && name.trim() && userRol) {
            setIsLoading(true);

            const params = {
                invite_email: email.trim(),
                invite_user_name: name,
                user_permission: userRol,
                franchise_id: isFirm ? franchiseID : null,
                company_id: companyID,
                language: t("language.locale"),
            };

            await teamService
                .inviteUser(params)
                .then(() => {
                    setTexto(t("team.invSuccess"));
                    setAlertType("success");
                    setShow(true);
                    setReload((prevState) => !prevState);
                    handleClose();
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 404:
                            setTexto(t("team.invError"));
                            setAlertType("error");
                            setShow(true);
                            break;
                        default:
                            setTexto(t("team.errorInv"));
                            setAlertType("error");
                            setShow(true);
                            break;
                    }
                });

            setOpenConfirmInv(false);
            setIsLoading(false);
        } else {
            setIsError(true);
        }
    };

    const verifyEmail = async (emailInput) => {
        if (emailInput.trim() && /.+@.+\..+/.test(emailInput)) {
            await authService
                .getUserData(emailInput.trim())
                .then((user) => {
                    if (user.verified) {
                        setVerified(true);
                        setIsValidEmail(false);
                    } else {
                        setVerified(false);
                        setIsValidEmail(true);
                    }
                    setName(user.user_name);
                    setDisabled(true);
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 404:
                            setVerified(false);
                            setIsValidEmail(true);
                            setDisabled(false);
                            break;
                        default:
                            setTexto(t("team.verifyError500"));
                            setAlertType("error");
                            setShow(true);
                            break;
                    }
                });
        } else {
            setIsError(true);
        }
    };

    const title = (
        <>
            {t("team.addTo")}{" "}
            <span style={{ color: "#4A22D4" }}>
                {franchiseID && !company ? franchise?.franchise_name : company?.company_name}
            </span>
        </>
    );

    useEffect(() => {
        if (subscription) {
            switch (subscription.subscription_plan) {
                case 0:
                    setUsersQuantity(2);
                    break;
                default:
                    setUsersQuantity("unlimited");
                    break;
            }
        }
    }, [subscription]);

    useEffect(() => {
        switch (usersQuantity) {
            case 2:
                if (usersCounter >= 2) {
                    setIsDisabledAddTeam(true);
                } else {
                    setIsDisabledAddTeam(false);
                }
                break;
            default:
                setIsDisabledAddTeam(false);
                break;
        }
    }, [usersQuantity, usersCounter]);

    useEffect(() => {
        if (todos?.subscription) {
            if (todos.subscription?.value && (todos.subscription.value === 1 || todos.subscription.value === 2)) {
                setShowText(1);
            }

            if (todos.subscription?.value && todos.subscription.value === 3) {
                setShowText(2);
            }
        }
    }, [todos.subscription]);

    const content = (
        <UserForm
            name={name}
            email={email}
            userRol={userRol}
            isError={isError}
            setName={setName}
            setEmail={setEmail}
            setUserRol={setUserRol}
            isEdit={false}
            verifyEmail={verifyEmail}
            isValidEmail={isValidEmail}
            verified={verified}
            isDisabled={isDisabled}
            hasCompanyUser={companyID}
        />
    );

    const actions = (
        <>
            <Button onClick={handleClose}>{t("team.skip")}</Button>
            <Button variant="contained" onClick={handleOpenModalInv}>
                {t("team.invite")}
            </Button>
        </>
    );

    const goToTeamFirm = () => {
        dispatch(addCompanyID(null));
        setCompany(null);
    };

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Box className={classes.subheader}>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                        <Grid item>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography className={classes.breadcrumbs}>{t("team.teamOf")}</Typography>
                                <DashboardBreadcrumbs noPadding={true} franchise={franchise} company={company} />
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center" spacing={4}>
                                <Grid item>
                                    {franchiseID && companyID && (
                                        <Button variant="contained" startIcon={<BusinessIcon />} onClick={goToTeamFirm}>
                                            {t("team.manageFirm")}
                                        </Button>
                                    )}
                                </Grid>
                                {hasAccess && (
                                    <LightTooltip
                                        title={
                                            !isDisabledAddTeam
                                                ? ""
                                                : t(showText === 1 ? tooltipTextDisabled1 : tooltipTextDisabled2)
                                        }
                                    >
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                startIcon={<AddIcon />}
                                                onClick={openAddUser}
                                                disabled={isDisabledAddTeam}
                                            >
                                                {t("team.addUser")}
                                            </Button>
                                        </Grid>
                                    </LightTooltip>
                                )}
                                <Grid item>
                                    <CustTextField
                                        id="filled-basic"
                                        label={t("team.search")}
                                        value={searchValue}
                                        onChange={handleChangeSearchValue}
                                        variant="filled"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} className={classes.spacing}>
                {/* {company?.first_company && (
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                        <StarIcon sx={{ color: "#FEE71A" }} fontSize="small" />
                        <LightTooltip title={t("team.firmCompTool")}>
                            <Typography variant="h3">{t("team.firmCompany")}</Typography>
                        </LightTooltip>
                    </Stack>
                )} */}
                {children}
            </Grid>
            <FormDialog
                open={open}
                handleClose={handleClose}
                title={title}
                content={content}
                actions={actions}
                maxWidth="sm"
            />
            <AlertDialog
                open={openConfirmInv}
                onClose={handleCloseModal}
                agreeBtnLabel={t("team.onlyThis")}
                disagreeBtnLabel={t("team.all")}
                type={"warning"}
                title={t("dialog.warning")}
                message={
                    <Typography textAlign="justify" fontSize={14}>
                        {t("team.invAlert")}
                    </Typography>
                }
                agreeAction={() => inviteUser(false)}
                oneAction={() => inviteUser(true)}
                onlyAction={true}
                maxWidth="sm"
            />
        </Grid>
    );
};

export default TeamHeader;
