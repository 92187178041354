/* Componentes */
import { Box, Tabs, Tab, Stack, Container, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SubscriptionTab3 from "./SubscriptionTab3";

import paymentMethodsService from "../../services/payment_methods";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

/* Estilos */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ShoppingCartReg from "./shoppingCart/ShoppingCartReg";
import PlanSubs from "./plans/PlansMain";
import DiscountCartBanner from "./DiscountCartBanner";
import { addCompanyID, addCredits, addIsGrouped, addSubscription } from "../../redux/userSlice";
import userCompanyService from "../../services/user_company";
import { getCompanyCredits } from "../../utils/utils";
import BaseButton from "../ui/BaseButton";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const MainSubscription = (props) => {
    const {
        franchise_id,
        company_id,
        currentPlan,
        setIsLoading,
        setPayments,
        showSkeleton,
        setShowSkeleton,
        plansInfoNew,
        setCurrentPlan,
        paymentType,
        setPaymentType,
        currentLevel,
        priceMonthly,
        priceMonthlyOG,
        setPriceMonthly,
        setPriceMonthlyOG,
        setCurrentLevel,
        setTitleModal,
        setAgreeBtnMsg,
        setDisagreeBtnMsg,
        setBodyMess,
        setActiveStep,
        setShowCart,
        showCart,
        companyDetails,
        allCompanies,
        setOpenAlert,
        activeStep,
        setTexto,
        setShow,
        setAlertType,
        activePlan,
        handleShowCart,
        setShowModalCredits,
        handleClickHelp,
        setCompanyDetails,
    } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);
    const location = useLocation();
    const [value, setValue] = useState(0);

    const dispatch = useDispatch();

    var userEmail = "";
    if (todos) {
        if (todos.userInfo) {
            if (todos.userInfo.email) {
                userEmail = todos.userInfo.email;
            }
        }
    }

    useEffect(() => {
        if (userEmail === "support@kiiper.app") {
            setValue(1);
        }
    }, [userEmail]);

    const navigate = useNavigate();

    const handleChangeTab = async (event, newValue) => {
        if (userEmail === "support@kiiper.app") {
            newValue += 1;
        }
        setValue(newValue);
        setShowCart(false);
        if (newValue === 1) {
            reloadData();
        }
        navigate("/subscription", {
            state: {
                tab: newValue,
            },
        });
    };

    useEffect(() => {
        (async () => {
            if (location?.search?.includes("tab=0") || location.state?.tab === 0) {
                setValue(0);
            } else if (location?.search?.includes("tab=1") || location.state?.tab === 1) {
                setValue(1);
            }
        })();
    }, [location?.search, location.state]);

    const finalF = async () => {
        if (location?.state?.companyID ?? companyDetails._id) {
            setIsLoading(true);
            await userCompanyService
                .getBusinessDirectoryDetails({ company_id: location?.state?.companyID ?? companyDetails._id })
                .then(async (data) => {
                    // Se verifica si la empresa que se está updateando es la misma que se encuentra actualmente seleccionada, para actualizar navbars
                    if ("last_cancel" in data?.subscription) {
                        if (companyDetails._id.toString() === todos.companyID) {
                            dispatch(addCompanyID(null));
                        }
                        navigate("/management", {
                            state: {
                                tab: 0,
                                redirect_cancel: companyDetails._id,
                            },
                        });
                    } else {
                        setShowCart(false);
                        setActiveStep(0);
                        setCompanyDetails(data);
                        dispatch(addIsGrouped(false));
                        dispatch(
                            addSubscription({
                                value: data.id_status,
                                last_value: data.id_last_status,
                                plan: data.subscription.subscription_plan,
                            })
                        );

                        const credits = await getCompanyCredits(companyDetails._id);
                        dispatch(addCredits(credits));
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            setIsLoading(false);
        }
    };

    const reloadData = async () => {
        setShowSkeleton(true);

        await paymentMethodsService
            .getPaymentMethods({
                franchise_id: todos.franchiseID,
            })
            .then((response) => {
                setPayments(response);
            })
            .catch((error) => {
                console.log(error);
            });

        setShowSkeleton(false);
    };

    useEffect(() => {
        if (location?.search?.includes("tab=0") || location.state?.tab === 0) {
            setValue(0);
        } else if (location?.search?.includes("tab=1") || location.state?.tab === 1) {
            setValue(1);
        } else if (location?.search?.includes("tab=2") || location.state?.tab === 2) {
            setValue(2);
        }
    }, [location?.search, location.state?.tab]);

    const showStep3 = () => {
        setShowCart(false);
    };

    return (
        <Container maxWidth="lg">
            <Typography variant="h1" color="secondary" mt={6} gutterBottom>
                {t("subscription.plansPay")}
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                    value={value}
                    onChange={handleChangeTab}
                    aria-label="Horizontal tabs"
                >
                    {userEmail !== "support@kiiper.app" && (
                        <Tab label={t("plans.compPlan")} {...a11yProps(0)} sx={{ px: 4 }} />
                    )}
                    <Tab label={t("subscription.conversBalance")} {...a11yProps(1)} sx={{ px: 4 }} />
                </Tabs>
            </Box>
            {showCart ? (
                <>
                    <Stack direction="row" spacing={2} justifyContent="space-between" pt={4}>
                        <BaseButton sizebutton="m" variantType="primaryOutlined" onClick={showStep3}>
                            {t("miscellaneous.goBack")}
                        </BaseButton>
                        <BaseButton
                            sizebutton="m"
                            variantType="primaryTextButton"
                            onClick={() => handleClickHelp("generalConv", "", "")}
                        >
                            {t("accounts.needHelp")}
                        </BaseButton>
                    </Stack>
                    {allCompanies.length >= 11 && companyDetails.franchise_id.type === 1 && (
                        <DiscountCartBanner allCompaniesLength={allCompanies.length} currentPlan={currentPlan} />
                    )}
                    <ShoppingCartReg
                        origin="updateSusbcription"
                        currentPlan={currentPlan}
                        setCurrentPlan={setCurrentPlan}
                        paymentType={paymentType}
                        setPaymentType={setPaymentType}
                        currentLevel={currentLevel}
                        priceMonthly={priceMonthly}
                        priceMonthlyOG={priceMonthlyOG}
                        setPriceMonthly={setPriceMonthly}
                        setActiveStep={setActiveStep}
                        allCompanies={allCompanies}
                        smbInfo={companyDetails}
                        finalF={finalF}
                        setShowCart={setShowCart}
                        activePlan={
                            companyDetails.id_status === 3
                                ? companyDetails?.subscription?.subscription_plan ?? null
                                : null
                        }
                        //userEmail={userEmail}
                        setIsLoading={setIsLoading}
                        subsType={companyDetails.franchise_id.type}
                    />
                </>
            ) : (
                <>
                    <TabPanel value={value} index={0}>
                        {activeStep === 0 && (
                            <PlanSubs
                                setTitleModal={setTitleModal}
                                setOpenAlert={setOpenAlert}
                                setAgreeBtnMsg={setAgreeBtnMsg}
                                setDisagreeBtnMsg={setDisagreeBtnMsg}
                                setBodyMess={setBodyMess}
                                setCurrentPlan={setCurrentPlan}
                                paymentType={paymentType}
                                setPaymentType={setPaymentType}
                                setCurrentLevel={setCurrentLevel}
                                setPriceMonthly={setPriceMonthly}
                                setPriceMonthlyOG={setPriceMonthlyOG}
                                currentLevel={currentLevel}
                                priceMonthly={priceMonthly}
                                origin="updateSubscription"
                                currentPlan={currentPlan}
                                activePlan={
                                    companyDetails.id_status === 3
                                        ? companyDetails?.subscription?.subscription_plan ?? null
                                        : null
                                }
                                handleShowCart={handleShowCart}
                                companyDetails={companyDetails}
                                setIsLoading={setIsLoading}
                                setShowCart={setShowCart}
                                maxFreeCompanies={companyDetails.free_companies_max_count}
                                currentFreeCompanies={companyDetails.free_companies_count}
                                subsType={companyDetails?.franchise_id?.type}
                                setShow={setShow}
                                setAlertType={setAlertType}
                                setMessage={setTexto}
                                isCancelled={
                                    "subscription" in companyDetails && "last_cancel" in companyDetails.subscription
                                }
                            />
                        )}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <SubscriptionTab3
                            setIsLoading={setIsLoading}
                            franchise_id={franchise_id}
                            company_id={company_id}
                            showSkeleton={showSkeleton}
                            setShowSkeleton={setShowSkeleton}
                            plansInfo={plansInfoNew}
                            activePlan={activePlan}
                            setShowModalCredits={setShowModalCredits}
                        />
                    </TabPanel>
                </>
            )}
        </Container>
    );
};

export default MainSubscription;
