import { Box } from "@mui/material";

import classes from "./BackGeneral.module.css";

const BackGeneral = (props) => {
    const { children } = props;

    return (
        <Box className={classes.background}>
            {children}
        </Box>
    );
};

export default BackGeneral;
