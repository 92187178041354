import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import authService from "../../services/auth";

/* Componentes */
import { Typography, Grid, Stack } from "@mui/material";
import BaseCard from "../ui/BaseCard";

/* Estilos */
import classes from "./ResetCard.module.css";

/** MUI icons */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BaseOutlinedInput from "../ui/BaseOutlinedInput";
import BaseButton from "../ui/BaseButton";

const ResetCard = (props) => {
    const { setIsLoading, setTexto, setAlertType, setShow, email, resetLogin, language } = props;

    /* RegExp */
    let uppercaseLetter = /[A-Z]/;
    let lowercaseLetter = /[a-z]/;
    let requiredNumber = /\d/;
    let requiredSymbol = /[,.#\-@$!?*/+]/;

    /** Internationalization i18n */
    const [t, i18n] = useTranslation("global");

    const [newPassword, setNewPassword] = useState("");
    const [confiPassword, setConfiPassword] = useState("");
    const [isError, setIsError] = useState(false);
    const [validate, setValidate] = useState({
        uppercaseLetter: false,
        lowercaseLetter: false,
        requiredNumber: false,
        requiredSymbol: false,
        eightCharacters: false,
    });

    const handleChangePass = (event) => {
        setNewPassword(event.target.value);
        checkPassword(event.target.value);
    };

    const handleChangeConfPass = (event) => {
        setConfiPassword(event.target.value);
    };

    const checkPassword = (str) => {
        const hasUppercase = uppercaseLetter.test(str);
        const hasLowercase = lowercaseLetter.test(str);
        const hasNumber = requiredNumber.test(str);
        const hasSymbol = requiredSymbol.test(str);
        const hasMinLength = str.length >= 8;

        setValidate({
            uppercaseLetter: hasUppercase,
            lowercaseLetter: hasLowercase,
            requiredNumber: hasNumber,
            requiredSymbol: hasSymbol,
            eightCharacters: hasMinLength,
        });
    };

    const savePass = async () => {
        let isValid = true;

        if (!newPassword || !confiPassword) {
            isValid = false;
        }
        if (newPassword !== confiPassword) {
            setTexto(t("login.matchPass"));
            setAlertType("warning");
            setShow(true);

            isValid = false;
        }

        if (isValid) {
            setIsLoading(true);

            const params = {
                user_email: email,
                new_password: newPassword,
            };

            await authService
                .updatePassword(params)
                .then(() => {
                    setTexto(t("login.successReset"));
                    setAlertType("success");
                    setShow(true);
                    resetLogin();
                })
                .catch((err) => {
                    setTexto(t("login.generalError500"));
                    setAlertType("error");
                    setShow(true);
                });

            setIsLoading(false);
            setIsError(false);
        } else {
            setIsError(true);
        }
    };

    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language);
        }
    }, [language, i18n]);

    return (
        <BaseCard>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    <Typography className={classes.title} gutterBottom>
                        {t("login.restorePass")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <BaseOutlinedInput
                        id="outlined-adornment-newpassword"
                        label={t("login.newPass")}
                        value={newPassword}
                        onChange={handleChangePass}
                        error={isError}
                        shrink={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BaseOutlinedInput
                        id="outlined-adornment-configPass"
                        label={t("login.newRepPass")}
                        value={confiPassword}
                        onChange={handleChangeConfPass}
                        error={isError}
                        shrink={true}
                    />
                </Grid>
                {newPassword && confiPassword && newPassword !== confiPassword &&
                    <Grid item xs={12} mt={-1}>
                        {<Typography sx={{ fontSize: "12px" }} color="error">
                            {t("register.passwordError")}
                        </Typography>}
                    </Grid>}
                <Grid item xs={12} className={classes.regex}>
                    <Typography sx={{ mb: 1 }}>
                        {t("register.requirePassText")}
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <CheckCircleIcon color={validate.eightCharacters ? "success" : "disabled"} />
                        <Typography>{t("register.eightCharacters")}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <CheckCircleIcon color={validate.uppercaseLetter ? "success" : "disabled"} />
                        <Typography>{t("register.uppercaseLetter")}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <CheckCircleIcon color={validate.lowercaseLetter ? "success" : "disabled"} />
                        <Typography>{t("register.lowercaseLetter")}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <CheckCircleIcon color={validate.requiredNumber ? "success" : "disabled"} />
                        <Typography>{t("register.requiredNumber")}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <CheckCircleIcon color={validate.requiredSymbol ? "success" : "disabled"} />
                        <Typography>{t("register.requiredSymbol")}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <BaseButton
                        fullWidth
                        onClick={savePass}
                    >
                        {t("login.savePass")}
                    </BaseButton>
                </Grid>
            </Grid>
        </BaseCard>
    );
};

export default ResetCard;
