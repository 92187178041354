import { Box, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import conversionService from "../../../services/conversion";
import companyService from "../../../services/company";
import invoicesService from "../../../services/invoices";
import CloseIcon from "@mui/icons-material/Close";

import SimpleDialog from "../../ui/SimpleDialog";
import CompanyData from "./CompanyData";
import BaseSelect from "../../ui/BaseSelect";
import BasePreviewFile from "../BasePreviewFile";
import NewInvoiceTab1 from "./NewInvoiceTab1";
import ResumeInv from "./ResumeInv";
import moment from "moment";
import AlertDialog from "../../ui/AlertDialog";
import BaseButton from "../../ui/BaseButton";

const NewInvoice = (props) => {
    const {
        valueAddInv,
        setShowNewInv,
        setIsLoading,
        setTexto,
        setAlertType,
        setShow,
        replaceInvBill,
        fileData,
        fileNameFull,
        conversionData,
        conversion_type,
        setCurrentInv,
        currentInv,
        setConversionData,
        setFileData,
        reloadInvoices
    } = props;

    const [t] = useTranslation("global");

    const generateUniqueId = () => Date.now().toString(36) + Math.random().toString(36).substring(2, 9);

    const insertLine = {
        new: true,
        item_code: "",
        description: "",
        amount: 0,
        unit_price: 0,
        quantity: 0,
        account_code: "",
        tax_type: "",
        tax_amount: 0,
    };

    const todos = useSelector((state) => state.value);
    const [currency, setCurrency] = useState("");
    const [allCurrencies, setAllCurrencies] = useState([]);
    const [rate, setRate] = useState("");
    const [systemCurrency, setSystemCurrency] = useState("");
    const [invInfo, setInvInfo] = useState({
        invoiceType: 1,
        invoiceNumber: conversionData.invoice_id ?? "",
        controlNumber: conversionData.control_number ?? "",
        invoiceDate: moment(conversionData.formatted_invoice_date) ?? null,
        invoiceDueDate: moment(conversionData.formatted_due_date) ?? null,
        contactXeroID: conversionData.contactXeroID ?? "",
        contactXeroName: conversionData?.contact_name_xero ?? "",
        currency: "",
        details: "",
        subtotal: 0.0,
        discount: 0.0,
        tax: 0.0,
        total: 0.0,
    });
    const [contactsXeroOriginal, setContactsXeroOriginal] = useState([]);
    const [showCompanyDet, setShowCompanyDet] = useState(false);
    const [accountPrev, setAccountPrev] = useState(0);
    const [rows, setRows] = useState([]);
    const [edited, setEdited] = useState([]);
    const [editGlobal, setEditGlobal] = useState(false);
    const [accountsXero, setAccountsXero] = useState([]);
    const [xeroTaxRates, setXeroTaxRates] = useState([]);
    const [selected, setSelected] = useState([]);
    const [rowsModal, setRowsModal] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [invChanges, setInvChanges] = useState(false);
    const previousRowsRef = useRef(null);
    const previousInvInfoRef = useRef(null);
    const [openAlertChanges, setOpenAlertChanges] = useState(false);
    // const [openAlertSendInv, setOpenAlertSendInv] = useState(false);

    // const [sendEmailInfo, setSendEmailInfo] = useState({
    //     to: "",
    //     cc: "",
    //     cco: "",
    //     subject: "",
    //     description: "",
    // });
    // const [isErrorTab3, setIsErrorTab3] = useState(false);

    const goBack = () => {
        setCurrentInv({});
        setConversionData({});
        setInvInfo({
            invoiceType: 1,
            invoiceNumber: "",
            controlNumber: "",
            invoiceDate: null,
            invoiceDueDate: null,
            contactXeroID: "",
            contactXeroName: "",
            currency: "",
            details: "",
            subtotal: 0.0,
            discount: 0.0,
            tax: 0.0,
            total: 0.0,
        });
        setShowNewInv(false);
        setRows([]);
        setRowsModal([]);
        setFileData(null);
        reloadInvoices();
        // setConvFormat(conversionFormats);
        // setAcceptedFiles([]);
        // setShowBanner(false);
        // setDisabledInput(false);
        // setEndConversion(false);
        // setConversionId(null);
        // setConvertInvalid(false);
        // setConvertRev(false);
        // setStatus(0);
        // setConvertDoneNew(false);
        // setRows([]);
        // setRowsModal([]);
        // setS3FileName([]);
    };

    const [openPreview, setOpenPreview] = useState(false);
    const [previewFile, setPreviewFile] = useState({});
    // const [isCustomEditing, setIsCustomEditing] = useState(false);

    const handleCloseModal = () => {
        setShowCompanyDet(false);
    };

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            if (Object.keys(conversionData).length > 0) {
                setCurrentInv(conversionData);
                previousInvInfoRef.current = conversionData;

                if (conversionData?.line_items) {
                    let fRows = conversionData.line_items.map((item) => ({
                        _id: item._id,
                        item_code: item.code ?? "",
                        description: item.description ?? "",
                        amount: item.formatted_amount ?? 0,
                        unit_price: item.formatted_unit_price ?? 0,
                        quantity: item.formatted_quantity ?? 0,
                        account_code: item.account_code ?? "",
                        discount: item.discount ?? "",
                        tax_type: "",
                        tax_amount: 0,
                    }));
                    setRows(fRows);
                    previousRowsRef.current = fRows;
                } else {
                    const newId = generateUniqueId()
                    let newLine = {
                        _id: newId,
                        ...insertLine,
                    }
                    setRows([newLine])
                    handleEditRow(newId, false, [...rows, newLine], true);
                }
            } else {
                const newId = generateUniqueId()
                let newLine = {
                    _id: newId,
                    ...insertLine,
                }
                setRows([newLine])
                handleEditRow(newId, false, [...rows, newLine], true);
            }

            if (fileData) {
                setOpenPreview(true);
                setPreviewFile({ fileName: fileNameFull, fileData: fileData });
                let newRows = [
                    {
                        id: generateUniqueId(),
                        file_name: fileNameFull,
                        file_name_s3: "",
                        fileData: fileData,
                    },
                ];

                setRowsModal(newRows);
            }
            let companyCurrency = "";
            await companyService
                .getCompanyDetails(todos.companyID)
                .then((data) => {
                    if (data.system_currency) setSystemCurrency(data.system_currency);
                    companyCurrency = data.system_currency;
                })
                .catch((error) => {
                    console.log(error);
                });

            await conversionService
                .getXeroCurrencies({
                    company_id: todos.companyID,
                })
                .then((data) => {
                    if (data.length > 0) {
                        const currencyMap = data.map((item) => ({
                            id: item.Code,
                            name: item.Description,
                        }));
                        setAllCurrencies(currencyMap);
                        const findCurrency = currencyMap.find((currency) => currency.id === companyCurrency);
                        //invoicesData.currency_iso_code ? invoicesData.currency_iso_code :
                        setCurrency(findCurrency?.id ?? "");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            await conversionService
                .getContacts({
                    company_id: todos.companyID,
                })
                .then((data) => {
                    setContactsXeroOriginal(
                        data.map((item) => ({
                            value: item.ContactID,
                            name: item.Name,
                            currency: item.currency_contact,
                        }))
                    );
                })
                .catch((err) => {
                    console.log(err);
                });

            await conversionService
                .getChartOfAccounts({
                    company_id: todos.companyID,
                    type: 1,
                })
                .then((data) => {
                    setAccountsXero(data.map((item) => ({ value: item.Code, name: item.Name })));
                })
                .catch((err) => {
                    console.log(err);
                });

            await conversionService
                .getTaxRates({
                    company_id: todos.companyID,
                    type: 2, // 2 o 3
                })
                .then((data) => {
                    setXeroTaxRates(
                        data.map((item) => ({
                            value: item.TaxType,
                            name: `${item.Name} - ${item.EffectiveRate}%`,
                            taxRate: item.EffectiveRate,
                            EffectiveRate: item.EffectiveRate,
                            isZeroTax: item.TaxComponents[0].Name === "No Tax",
                        }))
                    );
                })
                .catch((err) => {
                    console.log(err);
                });

            setIsLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsLoading, todos.companyID]);

    const accountsPrev = [{ id: 0, name: previewFile.fileName }];

    const handleChangeDoc = async (event) => {
        setIsLoading(true);

        setIsLoading(false);

        setAccountPrev(event.target.value);
    };

    const handleHidePrev = () => setOpenPreview(false);

    // useEffect(() => {
    //     // Solo actualiza la descripción si no se está editando manualmente
    //     if (!isCustomEditing) {
    //         setSendEmailInfo((prevState) => ({
    //             ...prevState,
    //             description: `Hi ${invInfo.contactName || "Customer"},\n\nHere's invoice ${
    //                 invInfo.invoiceNumber || "N/A"
    //             } for $USD ${parseFloat(invInfo.total ?? 0.0).toLocaleString(todos.amountFormat, {
    //                 minimumFractionDigits: 2,
    //             })}.\n\nThe amount outstanding of $USD ${parseFloat(invInfo.total ?? 0.0).toLocaleString(
    //                 todos.amountFormat,
    //                 {
    //                     minimumFractionDigits: 2,
    //                 }
    //             )} is due on ${
    //                 invInfo.invoiceDueDate !== "" ? invInfo.invoiceDueDate.format("D MMM YYYY") : "N/A"
    //             }.\n\nView and pay your bill online: [Online Invoice Link]\n\nFrom your online bill you can print a PDF, export a CSV, or create a free login and view your outstanding bills.If you have any questions, please let us know.\n\nThanks,\n${
    //                 company_name || "Your Company"
    //             }`,
    //         }));
    //     }
    // }, [invInfo, company_name, todos.dateFormat, todos.amountFormat, isCustomEditing]);

    useEffect(() => {
        // Actualizar total en factura cada vez que cambian los items
        const getSubTotal = () => {
            return rows.reduce(function (sum, item) {
                return sum + (item?.unit_price ?? 0.0) * (item?.quantity ?? 0);
            }, 0.0);
        };

        const getDiscount = () => {
            return rows.reduce(function (sum, item) {
                return sum + ((item?.unit_price ?? 0.0) * (item?.quantity ?? 0) * (item?.discount ?? 0)) / 100;
            }, 0.0);
        };

        const getTaxes = () => {
            return rows.reduce(function (sum, item) {
                const taxRate = Number(xeroTaxRates.find((tax) => tax?.value === item?.tax_type)?.taxRate || 0);
                return sum + ((item?.unit_price ?? 0.0) * (item?.quantity ?? 0) * (taxRate ?? 0)) / 100;
            }, 0.0);
        };

        const getTotal = () => {
            return rows.reduce(function (sum, item) {
                const subTotal = (item?.unit_price ?? 0.0) * (item?.quantity ?? 0);
                const taxRate = Number(xeroTaxRates.find((tax) => tax?.value === item?.tax_type)?.taxRate || 0);
                return sum + subTotal + (subTotal * (taxRate ?? 0)) / 100 - (subTotal * (item?.discount ?? 0)) / 100;
            }, 0.0);
        };

        setInvInfo((prevState) => ({
            ...prevState,
            subtotal: getSubTotal(),
            discount: getDiscount(),
            tax: getTaxes(),
            total: getTotal(),
        }));
    }, [rows, xeroTaxRates]);

    useEffect(() => {
        // Compara si rows o invInfo han cambiado respecto a los valores anteriores
        const rowsChanged = JSON.stringify(previousRowsRef.current) !== JSON.stringify(rows);
        const invInfoChanged = JSON.stringify(previousInvInfoRef.current) !== JSON.stringify(invInfo);

        // Solo actualizar si currentInv.id_status es 4 y hay cambios en rows o invInfo
        if (currentInv.id_status === 4 && (rowsChanged || invInfoChanged)) {
            setInvChanges(true);
        } else {
            setInvChanges(false);
        }
    }, [rows, invInfo, currentInv]);

    const bodyMess = (
        <>
            <Typography
                fontStyle="normal"
                fontSize={14}
                fontWeight={300}
                sx={{
                    color: "#131F3E",
                    mt: 2,
                }}
                textAlign="justify"
            >
                {replaceInvBill(t("invoicing.unsavedChangesWarning"))}
            </Typography>
            <Typography
                fontStyle="normal"
                fontSize={14}
                fontWeight={300}
                sx={{
                    color: "#131F3E",
                    mt: 2,
                    mb: 3,
                }}
                textAlign="justify"
            >
                {replaceInvBill(t("invoicing.confirmExitWithoutSaving"))}
            </Typography>
        </>
    );

    // const bodyMess2 = (
    //     <>
    //         <Typography
    //             fontStyle="normal"
    //             fontSize={14}
    //             fontWeight={300}
    //             sx={{
    //                 color: "#131F3E",
    //                 mt: 2,
    //             }}
    //             textAlign="justify"
    //         >
    //             ¿Deseas enviar la factura de una vez al cliente con la sincronización de esta factura?
    //         </Typography>
    //     </>
    // );

    const handleCloseDialog = () => {
        setOpenAlert(false);
    };

    const handleAlertModal = () => {
        if (Object.keys(currentInv).length === 0) {
            setOpenAlert(true);
        } else {
            goBack();
        }
    };

    // const handleCloseDialogSendInv = () => {
    //     setOpenAlertSendInv(false);
    // };

    const handleAlertModalSendInv = async () => {
        setIsLoading(true);

        const paramsSync = {
            invoices: [{
                _id: currentInv._id,
                ContactXeroID: invInfo?.contactName ?? undefined,
                ContactXeroName: contactsXeroOriginal.find((item) => item.value === invInfo?.contactName)?.name ?? "",
                currency: currency,
                currency_rate: rate,
                invoice_date: moment(invInfo?.invoiceDate),
                line_items: JSON.stringify(currentInv.line_items),
            }],
        };

        // console.log("paramsSync", paramsSync);

        await invoicesService
            .markInvoiceAsApproved({
                invoice_id: currentInv._id,
                sync_data: paramsSync
            })
            .then((response) => {
                setCurrentInv(response.data);
                previousRowsRef.current = rows;
                previousInvInfoRef.current = invInfo;
                // setOpenAlertSendInv(true);
                setTexto(t("register.saveSuccessfully"));
                setAlertType("success");
                setShow(true);
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false)

        await updateInvoice(true)
    };

    // const sendInvoiceClient = () => {
    //     console.log("Factura enviada")
    // }

    const handleCloseDialogChanges = () => {
        setOpenAlertChanges(false);
    };

    const calcSubtotal = (obj) => {
        return parseFloat(obj.unit_price ?? 0) * parseFloat(obj.quantity ?? 1);
    };

    const calcTax = (obj) => {
        if (xeroTaxRates.length > 0 && "tax_type" in obj && obj.tax_type !== "") {
            const taxRate = xeroTaxRates.find((item) => item.value === obj.tax_type).taxRate;
            return (taxRate * calcSubtotal(obj)) / 100;
        }
    };

    const calcTotal = (obj) => {
        return calcTax(obj) + calcSubtotal(obj);
    };

    const updateInvoice = async (setApprove = false) => {
        setOpenAlertChanges(false);
        const copyInv = JSON.parse(JSON.stringify(invInfo));
        const copyRows = [...rows];

        const fInvInfo = {
            invoice_id: currentInv._id,
            invoice: {
                contactXeroID: copyInv.contactXeroID !== "first" ? copyInv.contactXeroID : undefined,
                contactXeroName: copyInv.contactXeroName,
                // currency: "",
                // total: "",
                company_id: todos?.companyID,
                observations: copyInv.details,
                conversion_type: conversion_type,
                invoice_type: copyInv.invoiceType,
                invoice_id: copyInv.invoiceNumber,
                control_number: copyInv.controlNumber,
                invoice_date: copyInv.invoiceDate,
                due_date: copyInv.invoiceDueDate,
                currency_rate: rate, //Tasa de cambio.
                sub_total: copyInv.subtotal, //Subtotal.
                invoice_tax: copyInv.tax, //Tax.
                invoice_discount: copyInv.discount, //Descuento.
                invoice_total: copyInv.total, //Total.
                // line_items: //Array de objetos con las siguientes propiedades:
                approve: setApprove,
                line_items: copyRows.map((item) => ({
                    _id: item?._id ?? undefined,
                    code: item.code, //Código.
                    description: item.description, //Descripción.
                    quantity: item.quantity, //Cantidad.
                    unit_price: item.unit_price, //Precio unitario.
                    account_code: item.account_code, //Cuenta contable.
                    discount: item.discount, //Descuento.
                    tracking1: item.tracking1, //Tracking 1,
                    tracking2: item.tracking2, //Tracking 2,
                    tax_type: item.tax_type,
                    tax_amount: calcTax(item), //Monto de impuestos.
                    amount: calcTotal(item), //Monto total de la línea
                })),
            },
        };

        setIsLoading(true);
        await invoicesService
            .updateInvoice(fInvInfo)
            .then((response) => {
                setCurrentInv(response.data);
                previousRowsRef.current = rows;
                previousInvInfoRef.current = invInfo;
                setTexto(t("register.saveSuccessfully"));
                setAlertType("success");
                setShow(true);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
    };

    const bodyMess3 = (
        <>
            <Typography
                fontStyle="normal"
                fontSize={14}
                fontWeight={300}
                sx={{
                    color: "#131F3E",
                    mt: 2,
                }}
                textAlign="justify"
            >
                {t("invoicing.confirmChangesMessage")}
            </Typography>
        </>
    );

    const handleModalupdateInvoice = () => {
        setOpenAlertChanges(true);
    };

    const handleEditRow = (id, isEdited, rowsParam, fromAddRow) => {
        if (!isEdited) {
            setEditGlobal(!editGlobal);
        }

        const checkedIndex = edited.indexOf(id);
        let newChecked = [];

        if (checkedIndex === -1) {
            newChecked = newChecked.concat(edited, id);
        } else if (checkedIndex === 0) {
            newChecked = newChecked.concat(edited.slice(1));
        } else if (checkedIndex === edited.length - 1) {
            newChecked = newChecked.concat(edited.slice(0, -1));
        } else if (checkedIndex > 0) {
            newChecked = newChecked.concat(edited.slice(0, checkedIndex), edited.slice(checkedIndex + 1));
        }

        if (!isEdited) {
            setEdited(newChecked);
        }

        if (isEdited) {
            let findEdited = rowsParam.find((e) => e._id === id);
            if (findEdited?.new) {
                if (findEdited.description !== "") {
                    setEditGlobal(!editGlobal);
                    setEdited(newChecked);
                } else {
                    setTexto(t("converter.cannotBeSaveInv"));
                    setAlertType("error");
                    setShow(true);
                }
            } else {
                if (findEdited.description !== "") {
                    setEditGlobal(!editGlobal);
                    setEdited(newChecked);
                } else {
                    setTexto(t("converter.cannotBeSaveInv"));
                    setAlertType("error");
                    setShow(true);
                }
            }
        } else {
            if (!fromAddRow) {
                let findEdited = rowsParam.find((e) => e._id === id);
                if (findEdited?.new) {
                    if (findEdited.description !== "") {
                        setRows(rowsParam);
                    } else {
                        // Se descarta la línea
                        const filterRows = rowsParam.filter((item) => item._id !== id);
                        setRows(filterRows);
                    }
                } else {
                    setRows(rowsParam);
                }
            } else {
                setRows(rowsParam);
            }
        }
    };

    return (
        <>
            <Box
                sx={{
                    backgroundColor: "#F8F8F9",
                    p: 3,
                }}
            >
                <BaseButton variantType="primaryOutlined" onClick={handleAlertModal} sizebutton="m">
                    {t("miscellaneous.goBack")}
                </BaseButton>
                <Grid container spacing={2} mt={0.5}>
                    {openPreview && valueAddInv !== 1 && (
                        <Grid item xs={12} md={4}>
                            <Stack
                                direction="row"
                                spacing={2}
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                    backgroundColor: "white",
                                    p: 2,
                                    mb: 1,
                                    borderRadius: 4,
                                    boxShadow: "4px 4px 10px rgba(74, 34, 212, 0.1)",
                                }}
                            >
                                <Box sx={{ width: 250 }}>
                                    <BaseSelect
                                        value={accountPrev}
                                        values={accountsPrev}
                                        onChange={handleChangeDoc}
                                        label={t("management.attachment")}
                                    ></BaseSelect>
                                </Box>
                                <BaseButton variantType="errorText" endIcon={<CloseIcon />} onClick={handleHidePrev}>
                                    {t("management.hide")}
                                </BaseButton>
                            </Stack>
                            <BasePreviewFile filename={previewFile.fileName} filedata={previewFile.fileData} />
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={12}
                        md={openPreview ? 8 : valueAddInv === 1 || valueAddInv === 2 ? 7 : 12}
                        sx={{ borderRadius: "8px", mt: 2 }}
                    >
                        <NewInvoiceTab1
                            setShowCompanyDet={setShowCompanyDet}
                            invInfo={invInfo}
                            setInvInfo={setInvInfo}
                            setCurrency={setCurrency}
                            systemCurrency={systemCurrency}
                            setRate={setRate}
                            editGlobal={editGlobal}
                            setEditGlobal={setEditGlobal}
                            edited={edited}
                            setEdited={setEdited}
                            setRows={setRows}
                            setTexto={setTexto}
                            setAlertType={setAlertType}
                            setShow={setShow}
                            rows={rows}
                            xeroTaxRates={xeroTaxRates}
                            setIsLoading={setIsLoading}
                            setPreviewFile={setPreviewFile}
                            setOpenPreview={setOpenPreview}
                            contactsXeroOriginal={contactsXeroOriginal}
                            currency={currency}
                            rate={rate}
                            allCurrencies={allCurrencies}
                            selected={selected}
                            setSelected={setSelected}
                            accountsXero={accountsXero}
                            replaceInvBill={replaceInvBill}
                            conversion_type={conversion_type}
                            setCurrentInv={setCurrentInv}
                            currentInv={currentInv}
                            handleAlertModalSendInv={handleAlertModalSendInv}
                            goBack={goBack}
                            invChanges={invChanges}
                            // setOpenAlertSendInv={setOpenAlertSendInv}
                            previousRowsRef={previousRowsRef}
                            previousInvInfoRef={previousInvInfoRef}
                            handleModalupdateInvoice={handleModalupdateInvoice}
                            setRowsModal={setRowsModal}
                            rowsModal={rowsModal}
                            conversionData={conversionData}
                            updateInvoice={updateInvoice}
                            handleEditRow={handleEditRow}
                        />
                    </Grid>
                    {valueAddInv === 1 && <ResumeInv />}
                    {/* {valueAddInv === 2 && (
                        <Grid item xs={12} md={5} mt={{ xs: 1, md: "66.75px" }}>
                            <NewInvoiceSendSettings
                                setTexto={setTexto}
                                setAlertType={setAlertType}
                                setShow={setShow}
                                sendEmailInfo={sendEmailInfo}
                                setIsError={setIsErrorTab3}
                            />
                            <NewInvoiceMailingLog />
                        </Grid>
                    )} */}
                </Grid>
                <SimpleDialog
                    open={showCompanyDet}
                    handleClose={handleCloseModal}
                    maxWidth="md"
                    content={
                        <CompanyData
                            setShowCompanyDet={setShowCompanyDet}
                            setIsLoading={setIsLoading}
                            setContactsXeroOriginal={setContactsXeroOriginal}
                        />
                    }
                />
            </Box>
            <AlertDialog
                open={openAlert}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("dialog.cancel")}
                type={"warning"}
                title={t("dialog.warning")}
                message={bodyMess}
                agreeAction={goBack}
                maxWidth="sm"
            />
            {/* <AlertDialog
                open={openAlertSendInv}
                onClose={handleCloseDialogSendInv}
                agreeBtnLabel={t("converter.send")}
                disagreeBtnLabel={t("miscellaneous.goBack")}
                type={"info2"}
                title={"Envío de la factura al cliente"}
                message={bodyMess2}
                agreeAction={sendInvoiceClient}
                maxWidth="sm"
            /> */}
            <AlertDialog
                open={openAlertChanges}
                onClose={handleCloseDialogChanges}
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("dialog.cancel")}
                type={"info2"}
                title={t("invoicing.approvedInvoiceChanges")}
                message={bodyMess3}
                agreeAction={() => updateInvoice(false)}
                maxWidth="sm"
            />
        </>
    );
};

export default NewInvoice;
