import axios from "axios";

/* Script que contiene las llamadas a la API */
const company = {
    getCompanyDetails: (id, options = {}) => {
        const request = axios.get(`/getCompanyDetails?company_id=${id}`, { ...options });

        return request.then((response) => response.data);
    },

    addNewCompany: (params) => {
        const request = axios.post("/addNewCompany", params);

        return request.then((response) => response.data);
    },

    updateCompany: (params) => {
        const request = axios.post("/updateCompany", params);

        return request.then((response) => response.data);
    },

    getFranchises: () => {
        const request = axios.get(`/getFranchises`);

        return request.then((response) => response);
    },

    getFranchiseDetails: (id) => {
        const request = axios.get(`/getFranchiseDetails?franchise_id=${id}`);

        return request.then((response) => response.data);
    },

    getFranchiseDetailsCustom: (id) => {
        const request = axios.get(`/getFranchiseDetailsCustom?franchise_id=${id}`);

        return request.then((response) => response.data);
    },

    addNewFranchise: (params) => {
        const request = axios.post("/addNewFranchise", params);

        return request.then((response) => response);
    },

    updateFranchise: (params) => {
        const request = axios.post("/updateFranchise", params);

        return request.then((response) => response);
    },

    disableCompany: (params) => {
        const request = axios.post("/disableCompany", params);

        return request.then((response) => response);
    },

    deleteNewCompany: (params) => {
        const request = axios.post("/deleteNewCompany", params);

        return request.then((response) => response);
    },

    validateCompanyName: async (params) => {
        const request = axios.get("/validateCompanyName", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    validateInvoiceEmail: async (params) => {
        const request = axios.get("/validateInvoiceEmail", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    validateTaxId: async (params) => {
        const request = axios.get("/validateTaxId", {
            params: params,
        });

        return request.then((response) => response.data);
    },
};

export default company;
