import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseButton from "../ui/BaseButton";

const GroupedButtons = (props) => {
    const { convertAll, handleCloseDialog, isHist, modalType } = props;

    const [t] = useTranslation("global");

    return (
        <Grid container justifyContent={modalType === "sync" ? "end" : "space-between"} sx={{ px: 8 }}>
            {modalType !== "sync" && <Grid item>
                <BaseButton
                    disableElevation
                    onClick={handleCloseDialog}
                    variantType="primaryTextButton"
                    sizebutton="s"
                >
                    {t("miscellaneous.goBack")}
                </BaseButton>
            </Grid>}
            {!isHist && (
                <Grid item>
                    <BaseButton
                        disableElevation
                        sizebutton="s"
                        onClick={convertAll}
                    >
                        {t("dialog.continue")}
                    </BaseButton>
                </Grid>
            )}
            {modalType === "sync" && <Grid item>
                <BaseButton
                    disableElevation
                    onClick={handleCloseDialog}
                    sizebutton="s"
                >
                    {t("dialog.continue")}
                </BaseButton>
            </Grid>}
        </Grid>
    );
};

export default GroupedButtons;
