import axios from "axios";

/* Script que contiene las llamadas a la API */
const ocr = {

  recognize: (params) => {
    const request = axios.post("/recognize", params);

    return request.then((response) => response);
  },
  recognizeChecks: (params) => {
    const request = axios.post("/recognizeChecks", params);

    return request.then((response) => response);
  },

  analyzeChecks: (params) => {
    const request = axios.post("/analyzeChecks", params);

    return request.then((response) => response.data);
  },

  analyzeInvoices: (params) => {
    const request = axios.post("/analyzeInvoices", params);

    return request.then((response) => response.data);
  },

  getModelVersionsDocumentAI: (params) => {
    const request = axios.get("/getModelVersionsDocumentAI", {
      params: params,
    });

    return request.then((response) => response.data);
  },

  getCustomModels: () => {
    const request = axios.get("/getCustomModels");

    return request.then((response) => response.data);
  },

  
  getCustomModelsChecks: () => {
    const request = axios.get("/getCustomModelsChecks");

    return request.then((response) => response.data);
  },

  analyzeChecksDocumentAI: (params) => {
    const request = axios.post("/analyzeChecksDocumentAI", params);

    return request.then((response) => response.data);
  },

  getCustomModelsCheckbooks: () => {
    const request = axios.get("/getCustomModelsCheckbooks");

    return request.then((response) => response.data);
  },

};

export default ocr;
