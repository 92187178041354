/** Imports ---------------------------------------------------------------- */
import moment from "moment";
/** React imports */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/es";


/** MUI icons */
import DeleteIcon from "@mui/icons-material/Delete";

/** MUI imports */
import {
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Select,
    FilledInput,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Switch,
    Button,
} from "@mui/material";
import casesService from "../../services/case";
import FiltersPopover from "../ui/FiltersPopover";

const defaultFilter = {
    filtersArray: [
        {
            type: "",
            value: "",
            since: "",
            until: "",
            multiple: [],
        },
    ],
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 45 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
    variant: "menu",
    getcontentanchorel: null,
};

const FiltersButtonClientDir = (props) => {
    const { subscriptionFilter, countriesFilter, setRows, handleOpenBill, todos, setPage } = props;

    const [t] = useTranslation("global");

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filters, setFilters] = React.useState(defaultFilter);

    const [subsFilter, setSubsFilter] = React.useState(subscriptionFilter);

    const [counFilter, setCounFilter] = React.useState(countriesFilter);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    /* Filtraríamos por Banco (cuenta bancaria), fecha de carga (campo desde y hasta), formato, fecha de estados de cuenta (fecha desde y hasta), usuario y estatus.*/

    const attributes = [
        { id: 1, name: t("navBar.subscription"), query: "subscription" },
        { id: 2, name: t("inbox.creationDate"), query: "creation_date" },
        { id: 3, name: t("register.country"), query: "country" },
        { id: 4, name: t("credits.sortCases"), query: "sort" },
        { id: 5, name: t("converter.status"), query: "status" },
        { id: 6, name: t("inbox.nextRenew"), query: "renew" },
    ];

    //1. Free trial, 2. On demand, 3. Mensual Plan X, 4. On hold, 5. Cancelado, 6. Inactivo
    const status_franchise = [
        { id: 1, name: t("navBar.freeTrial"), },
        { id: 2, name: t("navBar.onDemand"), },
        { id: 3, name: `${t("navBar.monthly")} Plan `, },
        { id: 4, name: t("navBar.onHold"), },
        { id: 5, name: t("navBar.cancelled"), },
        { id: 6, name: t("navBar.inactive"), },
    ];


    const limit = 6;

    const addFilter = () => {
        let array = [...filters.filtersArray];

        if (array.length <= limit) {
            setFilters({
                ...filters,
                filtersArray: [
                    ...filters.filtersArray,
                    {
                        type: "",
                        value: "",
                        since: "",
                        until: "",
                        multiple: [],
                    },
                ],
            });
        }
    };

    const apiCall = async (params) => {
        await casesService
            .getClients(params)
            .then((data) => {
                const notAssingData = data.map((item) => {
                    return {
                        id: item.franchise_id,
                        franchise_name: item.franchise_name,
                        franchise: <Button size="small">{item.franchise_name}</Button>,
                        creation_date: moment.utc(item.creation_date).format(todos.dateFormat),
                        owner_name: item.owner_name,
                        country_name: item.country_name,
                        status: item.status,
                        credits_count: item.credits_count,
                        renew_date: item.renew_date ? moment.utc(item.renew_date).format(todos.dateFormat) : "N/A",
                        last_invoice_date: item.last_invoice_date ? (
                            <Button size="small" onClick={() => handleOpenBill(item.invoice_id)}>
                                {moment.utc(item.last_invoice_date).format(todos.dateFormat)}
                            </Button>
                        ) : (
                            "-"
                        ),
                        open_cases: `${item.open_cases ?? 0} (${item.all_cases ?? 0})`,
                        invoice_id: item.invoice_id,
                    };
                });

                let suscriptionF =  notAssingData.map((item) => {
                    return {
                        franchise_id: item.id,
                        franchise_name: item.franchise_name,
                    };
                });;
                suscriptionF = [...new Map(suscriptionF.map(item => [item['franchise_id'], item])).values()]
                suscriptionF.sort((a, b) => a.franchise_name.localeCompare(b.franchise_name));

                let countriesF = notAssingData.map((item) => item.country_name);
                countriesF = [...new Set(countriesF)];
                countriesF.sort((a, b) => a.localeCompare(b));

                setRows(notAssingData);
                setCounFilter(countriesF);
                //setSubsFilter(suscriptionF);
                setPage(0);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const removeFilter = async (index) => {
        let array = [...filters.filtersArray];

        if (index !== -1) {

            if (array[index].type === 1) {
                setSubsFilter(subscriptionFilter)
            }

            if (array[index].type === 3) {
                setCounFilter(countriesFilter)
            }

            array.splice(index, 1);
            setFilters({
                ...filters,
                filtersArray: array,
            });

            let objectFinal = {};

            array.forEach((item) => {
                const query = attributes.find((item2) => item2.id === item.type)?.query;
                if (query) {
                    if (item.value && item.type) {
                        objectFinal[query] = item.value
                    }
                    if (item.type && item.since !== "") {
                        objectFinal[query + "_since"] = item.since.format("YYYY-MM-DD")
                    }
                    if (item.type && item.until !== "") {
                        objectFinal[query + "_until"] = item.until.format("YYYY-MM-DD")
                    }
                    if (item.multiple && item.multiple.length > 0) {
                        objectFinal[query] = item.multiple;
                    }
                }

            });

            let params = {
                ...objectFinal,
            };

            await apiCall(params)

        }
    };

    const removeFirstFilter = async () => {
        setFilters({
            filtersArray: [
                {
                    type: "",
                    value: "",
                    since: "",
                    until: "",
                    multiple: [],
                },
            ],
        });

        await apiCall({})

        setSubsFilter(subscriptionFilter)
        setCounFilter(countriesFilter)

    };

    const handleChangeType = (event, index) => {
        let aux = [...filters.filtersArray];
        aux[index].type = event.target.value;
        aux[index].value = "";
        aux[index].since = "";
        aux[index].until = "";
        aux[index].multiple = [];

        setFilters({
            ...filters,
            filtersArray: aux,
        });
    };

    const handleChangeFilter = async (event, index, filterType) => {
        let aux = [...filters.filtersArray];

        if (filterType === 1 || filterType === 3 || filterType === 5) {
            aux[index].value = "";
            aux[index].since = "";
            aux[index].until = "";
            aux[index].multiple = event.target.value;
        } else if (filterType === 4) {
            aux[index].value = event.target.checked;
            aux[index].since = "";
            aux[index].until = "";
            aux[index].multiple = [];
        } else {
            aux[index].value = event.target.value;
            aux[index].since = "";
            aux[index].until = "";
            aux[index].multiple = [];
        }

        let objectFinal = {};

        aux.forEach((item) => {
            const query = attributes.find((item2) => item2.id === item.type)?.query;
            if (query) {
                if (item.value && item.type) {
                    objectFinal[query] = item.value;
                }
                if (item.type && item.since !== "") {
                    objectFinal[query + "_since"] = item.since.format("YYYY-MM-DD");
                }
                if (item.type && item.until !== "") {
                    objectFinal[query + "_until"] = item.until.format("YYYY-MM-DD");
                }
                if (item.multiple && item.multiple.length > 0) {
                    objectFinal[query] = item.multiple;
                }
            }
        });

        let params = {
            ...objectFinal,
        };

        await apiCall(params)

        setFilters({
            ...filters,
            filtersArray: aux,
        });

    };

    const handleChangeFilterRange = async (event, index, filterType, range) => {
        let aux = [...filters.filtersArray];
        aux[index][range] = event;
        aux[index].value = "";

        let objectFinal = {};


        aux.forEach((item) => {
            const query = attributes.find((item2) => item2.id === item.type)?.query;
            if (query) {
                if (item.value && item.type) {
                    objectFinal[query] = item.value;
                }
                if (item.type && item.since) {
                    objectFinal[query + "_since"] = item.since.format("YYYY-MM-DD");
                }
                if (item.type && item.until) {
                    objectFinal[query + "_until"] = item.until.format("YYYY-MM-DD");
                }
                if (item.multiple && item.multiple.length > 0) {
                    objectFinal[query] = item.multiple;
                }
            }
        });

        let params = {
            ...objectFinal,
        };

        await apiCall(params)

        setFilters({
            ...filters,
            filtersArray: aux,
        });
    };

    const cleanFilters = async (e) => {
        if (e.target.classList.contains("MuiAvatar-img")) {
            setFilters({
                filtersArray: [
                    {
                        type: "",
                        value: "",
                        since: "",
                        until: "",
                        multiple: [],
                    },
                ],
            });

            await apiCall({})

            setSubsFilter(subscriptionFilter)
        }
    };

    useEffect(() => {
        setFilters({
            filtersArray: [
                {
                    type: "",
                    value: "",
                    since: "",
                    until: "",
                    multiple: [],
                },
            ],
        });
    }, []);

    return (
        <FiltersPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            cleanFilters={cleanFilters}
            handleClick={handleClick}
            length={filters.filtersArray.filter(
                (item) => item.type && (item.value || item.since || item.until || item.multiple.length > 0)
            ).length}
            addFilter={addFilter}
            disabled={filters.filtersArray.length === limit}
        >
            {filters.filtersArray.map((value, index) => (
                <Grid container spacing={2} alignItems="center" key={index} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={11}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel
                                        id="clientStatus-simple-select-label"
                                        sx={{
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            color: "#361A52",
                                        }}
                                    >
                                        {t("filters.attribute")}
                                    </InputLabel>
                                    <Select
                                        size="small"
                                        value={value.type}
                                        displayEmpty
                                        onChange={(e) => handleChangeType(e, index)}
                                        className="ddlGeneral"
                                        input={
                                            <FilledInput
                                                label={t("filters.attribute")}
                                                sx={{
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    color: "#361A52",
                                                }}
                                            />
                                        }
                                        MenuProps={MenuProps}
                                        defaultValue=""
                                    >
                                        {value.type !== ""
                                            ? attributes
                                                .filter(
                                                    (item) =>
                                                        !filters.filtersArray
                                                            .map((item) => item.type)
                                                            .includes(item.id)
                                                )
                                                .concat(
                                                    attributes.filter((item) => item.id === value.type)
                                                )
                                                .map((item) => (
                                                    <MenuItem value={item.id} key={"attr_" + item.id}>
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                            sx={{
                                                                fontStyle: "normal",
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    </MenuItem>
                                                ))
                                            : attributes
                                                .filter(
                                                    (item) =>
                                                        !filters.filtersArray
                                                            .map((item) => item.type)
                                                            .includes(item.id)
                                                )
                                                .map((item) => (
                                                    <MenuItem value={item.id} key={"attr_" + item.id}>
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                            sx={{
                                                                fontStyle: "normal",
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {value.type === "" && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled" disabled>
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#361A52",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            value={value.type}
                                            displayEmpty
                                            onChange={(e) => handleChangeType(e, index)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#361A52",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                        ></Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 1 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#361A52",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            value={value.multiple}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#361A52",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                            multiple
                                            renderValue={(selected) => {
                                                return selected
                                                    .map((item) => {
                                                        return subscriptionFilter.filter((item2) => item2.franchise_id.toString() === item.toString())[0]
                                                            .franchise_name;
                                                    })
                                                    .join(", ");
                                            }}
                                        >
                                            {subsFilter.map((item) => (
                                                <MenuItem value={item.franchise_id} key={"franchise_id_" + item.franchise_id}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        <Checkbox checked={value.multiple.includes(item.franchise_id)} />
                                                        {item.franchise_name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {(value.type === 2 || value.type === 6) && (
                                <>
                                    <Grid item xs={6} md={3}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale={t("language.locale")}
                                            localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                                        >
                                            <DatePicker
                                                label={
                                                    <Typography
                                                        sx={{
                                                            fontStyle: "normal",
                                                            fontWeight: "600",
                                                            color: "#361A52",
                                                        }}
                                                    >
                                                        {t("filters.since")}
                                                    </Typography>
                                                }
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        style: { width: "100%" },
                                                        variant: "filled",
                                                        readOnly: true,
                                                    },
                                                    actionBar: { actions: ["clear"] },
                                                }}
                                                maxDate={
                                                    filters.filtersArray[index]?.until
                                                        ? filters.filtersArray[index].until
                                                        : null
                                                }
                                                value={value.since ? value.since : null}
                                                onChange={(e) => handleChangeFilterRange(e, index, value.type, "since")}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale={t("language.locale")}
                                            localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                                        >
                                            <DatePicker
                                                label={
                                                    <Typography
                                                        sx={{
                                                            fontStyle: "normal",
                                                            fontWeight: "600",
                                                            color: "#361A52",
                                                        }}
                                                    >
                                                        {t("filters.until")}
                                                    </Typography>
                                                }
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        style: { width: "100%" },
                                                        variant: "filled",
                                                        readOnly: true,
                                                    },
                                                    actionBar: { actions: ["clear"] },
                                                }}
                                                minDate={
                                                    filters.filtersArray[index]?.since
                                                        ? filters.filtersArray[index].since
                                                        : null
                                                }
                                                value={value.until ? value.until : null}
                                                onChange={(e) => handleChangeFilterRange(e, index, value.type, "until")}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </>
                            )}
                            {value.type === 3 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#361A52",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            value={value.multiple}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#361A52",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                            multiple
                                            renderValue={(selected) => {
                                                return selected.join(", ");
                                            }}
                                        >
                                            {counFilter.map((item) => (
                                                <MenuItem value={item} key={"country_" + item}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        <Checkbox checked={value.multiple.includes(item)} />
                                                        {item}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 4 && (
                                <Grid item xs={12} md={6}>
                                    <FormControlLabel
                                        sx={{ alignItems: "center" }}
                                        control={
                                            <Switch
                                                id="sortCases"
                                                checked={value.value}
                                                onChange={(e) => handleChangeFilter(e, index, value.type)}
                                                inputProps={{ "aria-label": "controlled" }}
                                            />
                                        }
                                        label={
                                            <Typography fontWeight={600} fontSize={14} noWrap>
                                                {t("credits.sortCasesLabel")}
                                            </Typography>
                                        }
                                    />
                                </Grid>
                            )}
                            {value.type === 5 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#361A52",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            value={value.multiple}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#361A52",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                            multiple
                                            renderValue={(selected) => {
                                                return selected
                                                    .map((item) => {
                                                        return status_franchise.filter((item2) => item2.id.toString() === item.toString())[0].name;
                                                    })
                                                    .join(", ");
                                            }}
                                        >
                                            {status_franchise.map((item) => (
                                                <MenuItem value={item.id} key={"stat_franc_" + item.id}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        <Checkbox checked={value.multiple.includes(item.id)} />
                                                        {item.name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}

                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={1} display="flex" justifyContent="center">
                        {filters.filtersArray.length > 1 && (
                            <DeleteIcon
                                color="primary"
                                sx={{ cursor: "pointer" }}
                                onClick={() => removeFilter(index)}
                            />
                        )}
                        {filters.filtersArray.length === 1 &&
                            (value.value !== "" || value.since !== "" || value.until !== "" ||
                                value.multiple.length > 0) && (
                                <DeleteIcon
                                    color="primary"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => removeFirstFilter()}
                                />
                            )}
                    </Grid>
                </Grid>
            ))}
        </FiltersPopover>
    );
};

export default FiltersButtonClientDir;
