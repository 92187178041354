import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import authService from "../../services/auth";

/* Componentes */
import { Alert, Grid, Link, Paper, Typography } from "@mui/material";
import BaseCard from "../ui/BaseCard";

/* Estilos */
import classes from "./ExpiredCard.module.css";
import BaseButton from "../ui/BaseButton";

const ExpiredCard = (props) => {
    const { handleShowExpiredCard, setIsLoading, email, setShow, setTexto, setAlertType, isExpiredInv, language } =
        props;

    /** Internationalization i18n */
    const [t, i18n] = useTranslation("global");

    const [searchParams, setSearchParams] = useSearchParams();
    const [reseted, setReseted] = useState(false);

    const sendLinkAgain = async () => {
        setIsLoading(true);

        const params = {
            user_email: email,
            language: language,
        };

        await authService
            .forgotPassword(params)
            .then(() => {
                setReseted(true);
            })
            .catch((err) => {
                switch (err.response.status) {
                    case 404:
                        setTexto(t("login.loginError404"));
                        break;
                    default:
                        setTexto(t("login.resetError500"));
                        break;
                }
                setAlertType("error");
                setShow(true);
            });

        setIsLoading(false);
    };

    const resendLink = async () => {
        setIsLoading(true);

        const params = {
            user_name: searchParams.get("user-name"),
            language: language,
            user_email: email,
        };

        await authService
            .resend(params)
            .then(() => {
                setTexto(t("login.verifyEmail"));
                setAlertType("success");
                setShow(true);
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
    };

    const handleGoLogin = () => {
        searchParams.delete("user-email");
        searchParams.delete("type");
        searchParams.delete("expired");
        searchParams.delete("language");

        setSearchParams(searchParams);
        handleShowExpiredCard();
    };

    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language);
        }
    }, [language, i18n]);

    return (
        <BaseCard>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                {!reseted ? (
                    <>
                        {isExpiredInv === "4" ? (
                            <>
                                <Grid item xs={12}>
                                    <Typography className={classes.title} gutterBottom>
                                        {t("login.uselessLink")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>{t("login.uselessLinkText")}</Typography>
                                </Grid>
                                <Grid item xs={12} textAlign="center">
                                    <BaseButton
                                        fullWidth
                                        onClick={handleGoLogin}
                                    >
                                        {t("login.backToLoginVerified")}
                                    </BaseButton>
                                </Grid>
                            </>
                        ) : isExpiredInv !== "2" ? (
                            <>
                                <Grid item xs={12}>
                                    <Typography className={classes.title} gutterBottom>
                                        {t("login.expiredLink")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>{t("login.expiredLinkText")}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <BaseButton
                                        fullWidth
                                        onClick={sendLinkAgain}
                                    >
                                        {t("login.sendLink")}
                                    </BaseButton>
                                </Grid>
                                <Grid item xs={12} textAlign="center">
                                    <Typography className={classes.haveAcc}>
                                        <Link
                                            onClick={handleGoLogin}
                                            className={classes.login}
                                            sx={{ textDecoration: "none", cursor: "pointer" }}
                                        >
                                            {t("login.wrongEmail")}
                                        </Link>
                                    </Typography>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12}>
                                    <Typography className={classes.title} gutterBottom>
                                        {t("login.expiredLink2")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        {t("login.expiredLinkText2")}
                                        <b>{email}</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <BaseButton
                                        fullWidth
                                        onClick={resendLink}
                                    >
                                        {t("login.sendLink")}
                                    </BaseButton>
                                </Grid>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Alert severity="success">
                                <Typography fontSize={14}>
                                    {t("login.sendTo")} <b>{email}</b>
                                </Typography>
                            </Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={0} className={classes.message}>
                                <Typography>{t("login.goToMail")}</Typography>
                            </Paper>
                        </Grid>
                    </>
                )}
            </Grid>
        </BaseCard>
    );
};

export default ExpiredCard;
