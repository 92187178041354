import { useTranslation } from "react-i18next";

/* Componentes */
import { Box, Grid, InputAdornment, Typography } from "@mui/material";
import BaseSelect from "../ui/BaseSelect";
import LightTooltip from "../ui/LightTooltip";

/* Estilos */
import classes from "./UserForm.module.css";

/* Iconos */
import InfoIcon from "@mui/icons-material/Info";
import CustTextField from "../ui/CustTextField";

const UserForm = (props) => {
    const {
        name,
        email,
        userRol,
        isError,
        setName,
        setEmail,
        setUserRol,
        verifyEmail,
        isValidEmail,
        verified,
        isEdit,
        isDisabled,
        hasCompanyUser,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const rols = [
        { id: 1, name: t("team.owner") },
        { id: 2, name: t("team.admin") },
        { id: 3, name: t("team.colab") },
    ];

    const handleChangeName = (event) => {
        setName(event.target.value);
    };
    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
        verifyEmail(event.target.value);
    };
    const handleChangeRol = (event) => {
        setUserRol(event.target.value);
    };

    return (
        <Grid container alignItems="center" spacing={2}>
            {!hasCompanyUser && (
                <Grid item xs={12}>
                    <Typography>{t("miscellaneous.userAddPractice")}</Typography>
                </Grid>
            )}
            <Grid item xs={12} md={6}>
                <CustTextField
                    id="filled-mail"
                    label={t("login.email")}
                    value={email}
                    onChange={handleChangeEmail}
                    error={isError && !/.+@.+\..+/.test(email)}
                    disabled={userRol === 1}
                    InputProps={{
                        endAdornment:
                            (email.trim() && isValidEmail && (
                                <InputAdornment position="end">
                                    <LightTooltip title={t("team.noRegister")}>
                                        <Box className={classes.new}>
                                            <Typography>{t("team.new")}</Typography>
                                        </Box>
                                    </LightTooltip>
                                </InputAdornment>
                            )) ||
                            (email.trim() && !isValidEmail && verified && (
                                <InputAdornment position="end">
                                    <LightTooltip title={t("team.isRegister")}>
                                        <Box className={classes.active}>
                                            <Typography>{t("team.active")}</Typography>
                                        </Box>
                                    </LightTooltip>
                                </InputAdornment>
                            )),
                    }}
                    variant="filled"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CustTextField
                    id="filled-name"
                    label={t("team.name")}
                    value={name}
                    onChange={handleChangeName}
                    error={isError && !name.trim()}
                    disabled={isDisabled}
                    variant="filled"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <BaseSelect
                    values={isEdit && userRol === 1 ? rols : rols.slice(1)}
                    value={userRol}
                    onChange={handleChangeRol}
                    label={t("team.profile")}
                    error={isError && !userRol}
                    color="normal"
                    disabled={userRol === 1}
                />
            </Grid>
            {!isEdit && (
                <Grid item xs={12} md={1}>
                    <LightTooltip
                        position="right"
                        title={
                            <>
                                <Typography className={classes.toolTip}>
                                    <span>{t("team.owner")}:</span> {t("team.ownerDesc")}
                                </Typography>
                                <br />
                                <Typography className={classes.toolTip}>
                                    <span>{t("team.admin")}:</span> {t("team.adminDesc")}
                                </Typography>
                                <br />
                                <Typography className={classes.toolTip}>
                                    <span>{t("team.colab")}:</span> {t("team.colabDesc")}
                                </Typography>
                            </>
                        }
                    >
                        <InfoIcon fontSize="small" color="primary" />
                    </LightTooltip>
                </Grid>
            )}
        </Grid>
    );
};

export default UserForm;
