import {
    Box,
    Button,
    Checkbox,
    FilledInput,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import countriesProject from "../../Js/countries";
import invoicesService from "../../../services/invoices";
/** Country flags */
import Flag from "react-world-flags";
import { useSelector } from "react-redux";
import conversionService from "../../../services/conversion";

const CustomTextField = styled(TextField)({
    "& label": {
        color: "#361A52",
        fontWeight: 600,
    },
});

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 310,
            width: 200,
        },
    },
};

const CompanyData = (props) => {
    const todos = useSelector((state) => state.value);
    const { setShowCompanyDet, setIsLoading, setContactsXeroOriginal } = props;
    const [t] = useTranslation("global");

    /** Countries */
    const allCountries = [...countriesProject];

    allCountries.sort((a, b) => {
        if (sessionStorage.getItem("lng") === "en") {
            return a.name_en.localeCompare(b.name_en);
        } else {
            return a.name_es.localeCompare(b.name_es);
        }
    });

    const [contactData, setContactData] = useState({
        fullName: "",
        role: "",
        countryCode: "",
        phoneCode: "",
        email: "",
        phoneNumber: "",
    });
    const [companyInfo, setCompanyInfo] = useState({
        companyName: "",
        taxID: "",
        taxAddress: "",
        countryCode: "",
        phoneCode: "",
        email: "",
        phoneNumber: "",
    });
    const [isError, setIsError] = useState(false);
    const [inheritData, setInheritData] = useState(false);

    const handleChangeInherit = (event) => {
        setInheritData(event.target.checked);
        if (event.target.checked) {
            setContactData({
                ...contactData,
                fullName: companyInfo.companyName,
                email: companyInfo.email,
                phoneNumber: companyInfo.phoneNumber,
                countryCode: companyInfo.countryCode,
                phoneCode: companyInfo.phoneCode
            });
        } else {
            setContactData({
                ...contactData,
                fullName: "",
                email: "",
                phoneNumber: "",
                countryCode: "",
                phoneCode: ""
            });
        }
    };

    const handleChangeContactData = (event) => {
        setContactData({ ...contactData, [event.target.name]: event.target.value });
    };

    const handleChangeData = (event) => {
        setCompanyInfo({ ...companyInfo, [event.target.name]: event.target.value });
    };

    const handleChangePhoneCode = (event) => {
        let codeFilter = allCountries.find((country) => country.country_code === event.target.value);
        if (codeFilter) {
            setCompanyInfo({ ...companyInfo, countryCode: event.target.value, phoneCode: codeFilter.phone_code });
        }
    };

    const handleChangeContactPhone = (event) => {
        let codeFilter = allCountries.find((country) => country.country_code === event.target.value);
        if (codeFilter) {
            setContactData({ ...contactData, countryCode: event.target.value, phoneCode: codeFilter.phone_code });
        }
    };

    const handleSaveData = async () => {
        if(companyInfo.companyName){
            setIsLoading(true);

            await invoicesService
                .createNewContact({
                    contact_name: companyInfo.companyName,
                    contact_tax_number: companyInfo.taxID,
                    contact_email: companyInfo.email,
                    company_id: todos.companyID,
                    contact_phone: contactData.phoneNumber,
                    contact_role: contactData.role,
                }) 
                .then(async (response) => {
                    await conversionService
                        .getContacts({
                            company_id: todos.companyID,
                        })
                        .then((data) => {
                            setContactsXeroOriginal(
                                data.map((item) => ({
                                    value: item.ContactID,
                                    name: item.Name,
                                    currency: item.currency_contact
                                }))
                            );
                        })
                        .catch((err) => {
                            console.log(err);
                        });

                    setShowCompanyDet(false);
                })
                .catch((err) => {
                    console.log(err);
                });
            setIsLoading(false);
        }
        else{
            setIsError(true)
        }
    };

    return (
        <Box sx={{ px: 2, width: 800 }}>
            <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                <Typography variant="h1">{t("dialog.companyData")}</Typography>
                {/* <Button variant="outlined" endIcon={<DocumentScannerIcon />} sx={{ px: 4, py: 1 }}>
                    Escanear RIF
                </Button> */}
            </Stack>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                    <CustomTextField
                        fullWidth
                        label={t("converter.subscriptionName")}
                        name="companyName"
                        required
                        onChange={handleChangeData}
                        value={companyInfo.companyName}
                        variant="filled"
                        inputProps={{
                            fontStyle: "normal",
                            color: "#361A52",
                        }}
                        error={isError && !companyInfo.companyName.trim()}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomTextField
                        fullWidth
                        label="Tax ID"
                        name="taxID"
                        onChange={handleChangeData}
                        value={companyInfo.taxID}
                        variant="filled"
                        inputProps={{
                            fontStyle: "normal",
                            color: "#361A52",
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomTextField
                        fullWidth
                        label={t("plans.email")}
                        name="email"
                        onChange={handleChangeData}
                        value={companyInfo.email}
                        variant="filled"
                        inputProps={{
                            fontStyle: "normal",
                            color: "#361A52",
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomTextField
                        fullWidth
                        label={t("invoicing.taxAddress")}
                        name="taxAddress"
                        onChange={handleChangeData}
                        value={companyInfo.taxAddress}
                        variant="filled"
                        inputProps={{
                            fontStyle: "normal",
                            color: "#361A52",
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <FormControl fullWidth variant="filled">
                        <InputLabel
                            id="type-simple-select-label"
                            sx={{
                                fontStyle: "normal",
                                fontWeight: "600",
                                color: "#361A52",
                            }}
                        >
                            {t("register.code")}
                        </InputLabel>
                        <Select
                            value={companyInfo.countryCode}
                            onChange={handleChangePhoneCode}
                            fullWidth
                            input={
                                <FilledInput
                                    label={t("register.code")}
                                    sx={{
                                        fontStyle: "normal",
                                        color: "#361A52",
                                    }}
                                />
                            }
                            MenuProps={MenuProps}
                            renderValue={() => (
                                <Stack direction="row" spacing={0.5} alignItems="center">
                                    <Flag code={companyInfo.countryCode} height="12" width="20" />
                                    <Typography>{companyInfo.phoneCode}</Typography>
                                </Stack>
                            )}
                        >
                            {allCountries.map((option) => (
                                <MenuItem value={option.country_code} key={option.country_code}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Flag code={option.country_code} height="14" width="22" />
                                        <Typography>
                                            {sessionStorage.getItem("lng") === "en" ? option.name_en : option.name_es}
                                            {" ("}
                                            {option.phone_code + ")"}
                                        </Typography>
                                    </Stack>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomTextField
                        fullWidth
                        label={t("register.phone")}
                        name="phoneNumber"
                        onChange={handleChangeData}
                        value={companyInfo.phoneNumber}
                        variant="filled"
                        autoComplete="one-time-code"
                    />
                </Grid>
                <Grid item xs={12} mt={1}>
                    <Typography variant="h1">{t("invoicing.contactData")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox checked={inheritData} onChange={handleChangeInherit} size="small" />}
                        label={<Typography>{t("register.copySubsInfo")}</Typography>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomTextField
                        fullWidth
                        label={t("login.name")}
                        name="fullName"
                        onChange={handleChangeContactData}
                        value={contactData.fullName}
                        variant="filled"
                        inputProps={{
                            fontStyle: "normal",
                            color: "#361A52",
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomTextField
                        fullWidth
                        label={t("login.email")}
                        name="email"
                        onChange={handleChangeContactData}
                        value={contactData.email}
                        variant="filled"
                        inputProps={{
                            fontStyle: "normal",
                            color: "#361A52",
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <FormControl fullWidth variant="filled">
                        <InputLabel
                            id="type-simple-select-label"
                            sx={{
                                fontStyle: "normal",
                                fontWeight: "600",
                                color: "#361A52",
                            }}
                        >
                            {t("register.code")}
                        </InputLabel>
                        <Select
                            value={contactData.countryCode}
                            onChange={handleChangeContactPhone}
                            fullWidth
                            input={
                                <FilledInput
                                    label={t("register.code")}
                                    sx={{
                                        fontStyle: "normal",
                                        color: "#361A52",
                                    }}
                                />
                            }
                            MenuProps={MenuProps}
                            renderValue={() => (
                                <Stack direction="row" spacing={0.5} alignItems="center">
                                    <Flag code={contactData.countryCode} height="12" width="20" />
                                    <Typography>{contactData.phoneCode}</Typography>
                                </Stack>
                            )}
                        >
                            {allCountries.map((option) => (
                                <MenuItem value={option.country_code} key={option.country_code}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Flag code={option.country_code} height="14" width="22" />
                                        <Typography>
                                            {sessionStorage.getItem("lng") === "en" ? option.name_en : option.name_es}
                                            {" ("}
                                            {option.phone_code + ")"}
                                        </Typography>
                                    </Stack>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomTextField
                        fullWidth
                        label={t("register.phone")}
                        name="phoneNumber"
                        onChange={handleChangeContactData}
                        value={contactData.phoneNumber}
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomTextField
                        fullWidth
                        label="Rol"
                        name="rol"
                        onChange={handleChangeContactData}
                        value={companyInfo.rol}
                        variant="filled"
                        inputProps={{
                            fontStyle: "normal",
                            color: "#361A52",
                        }}
                    />
                </Grid>
            </Grid>
            <Stack direction="row" justifyContent="flex-end" mt={2}>
                <Button variant="contained" sx={{ px: 5, py: 1 }} onClick={handleSaveData}>
                    {t("team.save")}
                </Button>
            </Stack>
        </Box>
    );
};

export default CompanyData;
