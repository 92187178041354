import { DialogActions, DialogContent } from "@mui/material";
import { PaymentElement, AddressElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import payService from "../../services/payment_methods";
import { useSelector } from "react-redux";

import moment from "moment";
import BaseButton from "../ui/BaseButton";

const AddPayForm = (props) => {
    const { handleClose, setMessage, setShow, setAlertType, setIsLoading, reloadData, isTab2, handleClose2, converter, userEmail } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);

    const stripe = useStripe();
    const elements = useElements();

    const [address, setAddress] = useState("");
    const [name, setName] = useState("");

    const handleChangeAddress = (event) => {
        if (event.complete) {
            // Extract potentially complete address
            setAddress(event.value.address);
            setName(event.value.name);
        }
    };

    const handleError = (error) => {
        setIsLoading(false);
        setAlertType("error");
        setMessage(error.message);
        setShow(true);
    };

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            handleError(submitError);
            return;
        }

        // Se obtienen los datos de la tarjeta ingresada
        const result = await stripe.createPaymentMethod({
            elements,
        });

        let clientSecret = "";
        let cardExists = false;

        const params = {
            total_amount: 50,
            company_id: todos.companyID,
            franchise_id: todos.franchiseID,
            userEmail: userEmail
        };

        // Obtenemos los metodos de pago para validar si existe un Customer de Stripe y validamos si la tarjeta existe
        await payService
            .getPaymentMethods(params)
            .then((response) => {
                response.forEach((element) => {
                    if (element.customer_id) {
                        let cardNumber = element.card_number.split("-").pop();
                        let dueDate = moment(
                            `${result.paymentMethod.card.exp_month}/${result.paymentMethod.card.exp_year}`,
                            "M/YYYY"
                        )
                            .endOf("month")
                            .format("YYYY-MM-DD");

                        if (
                            result.paymentMethod.card.brand === element.brand &&
                            result.paymentMethod.card.last4 === cardNumber &&
                            dueDate === moment(element.due_date).format("YYYY-MM-DD")
                        ) {
                            cardExists = true;
                        } else {
                            params.customer_id = element.customer_id;
                        }
                    }
                });
            })
            .catch((err) => console.log(err.message));

        if (!cardExists) {
            // Creamos el intento de pago en Stripe
            await payService
                .createPaymentIntent(params)
                .then((response) => {
                    clientSecret = response.clientSecret;
                })
                .catch((err) => console.log(err.response.data.error));

            const { error, paymentIntent } = await stripe.confirmPayment({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: `${window.location.origin}/subscription`,
                },
                redirect: "if_required",
            });

            if (paymentIntent) {
                const params = {
                    payID: paymentIntent.payment_method,
                    company_id: todos.companyID,
                    franchise_id: todos.franchiseID,
                    card_holder: name,
                    address: address,
                    PayIntent: paymentIntent.id,
                };

                // Asociamos un cliente y guardamos el metodo de pago
                await payService
                    .addPaymentMethod(params)
                    .then((response) => {
                        if (response.status === 204) {
                            setAlertType("warning");
                            setMessage(t("miscellaneous.saveCardWarning"));
                            setShow(true);
                        } else {
                            setAlertType("success");
                            setMessage(t("miscellaneous.saveCardSuccess"));
                            setShow(true);
                            if (converter) {
                                handleClose2();
                            } else {
                                handleClose();
                            }
                            
                        }
                    })
                    .catch((err) => console.log(err.message));

                // Reembolsamos el monto al cliente por asociar su tarjeta
                await payService
                    .refundPayment(params)
                    .then()
                    .catch((err) => console.log(err.response.data.error));

                if (isTab2) {
                    reloadData();
                }
            }

            if (error) {
                handleError(error);
            }
        } else {
            setAlertType("warning");
            setMessage(t("miscellaneous.saveCardWarning"));
            setShow(true);
        }

        setIsLoading(false);
    };

    return (
        <>
            <DialogContent>
                <PaymentElement />
                <AddressElement options={{ mode: "billing" }} onChange={handleChangeAddress} />
            </DialogContent>
            <DialogActions>
                <BaseButton
                    disableElevation
                    variantType="primaryTextButton"
                    onClick={handleClose}
                    sizebutton="m"
                    disabled={!stripe || !elements}
                >
                    {t("miscellaneous.goBack")}
                </BaseButton>
                <BaseButton
                    disableElevation
                    sizebutton="m"
                    disabled={!stripe || !elements}
                    onClick={handleSubmit}
                >
                    {t("dialog.continue")}
                </BaseButton>
            </DialogActions>
        </>
    );
};

export default AddPayForm;
