import {
    Box,
    Button,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    styled,
    Typography,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addAllComData, addCompanyID, addCredits, addSubscription } from "../../../redux/userSlice";

import CheckIcon from "@mui/icons-material/Check";
import Visa from "../../../assets/visa.svg";
import Mastercard from "../../../assets/mastercard.svg";
import Amex from "../../../assets/amex.svg";
import Discover from "../../../assets/discover.svg";
import Diners from "../../../assets/diners.svg";
import JCB from "../../../assets/jcb.svg";
import UnionPay from "../../../assets/unionpay.svg";

import moment from "moment/moment";
import PlanSubs from "../../subscription/plans/PlansMain";
import ShoppingCartReg from "../../subscription/shoppingCart/ShoppingCartReg";
import AlertDialog from "../../ui/AlertDialog";
import Integrations from "../../subscription/Integrations";
import subscriptionService from "../../../services/subscription";
import authService from "../../../services/auth";
import companyService from "../../../services/user_company";
import { getCompanyCredits, getCustomPlanId, getPlanInfo, getPlanLevelInfo, isCustomPlan, updateCartPlan } from "../../../utils/utils";
import CustomForm from "../../subscription/CustomForm";
import DiscountCartBanner from "../../subscription/DiscountCartBanner";
import FormDialog from "../../ui/FormDialog";
import CancelPlanForm from "../../subscription/CancelPlanForm";
import BaseButton from "../../ui/BaseButton";

const ColorButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#4A22D4",
    color: "#FFF",
    fontStyle: "normal",
    fontWeight: "350",
    border: "1px solid #4A22D4",
    borderRadius: "10px",
    "&:hover": {
        backgroundColor: "#2f3190",
        border: "1px solid #2f3190",
    },
    "&:disabled": {
        backgroundColor: "#B3B3B3",
        border: "1px solid #B3B3B3",
        color: "#FFF",
    },
}));

const CompanyDet = (props) => {
    const {
        setOpenCompanyDet,
        companyDetails,
        setShowCompanyDetails,
        allCompanies,
        activeStep,
        setActiveStep,
        smbInfo,
        setShow,
        setTexto,
        setAlertType,
        setIsLoading,
        setCompanyDetails,
        maxFreePlan,
        currentFreePlan,
        handleClickHelp,
    } = props;
    const location = useLocation();

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);
    const dispatch = useDispatch();

    const theme = useTheme(); // Obtener el tema actual

    const [openAlert, setOpenAlert] = useState(false);
    const [titleModal, setTitleModal] = useState("");
    const [bodyMess, setBodyMess] = useState("");
    const [agreeBtnMsg, setAgreeBtnMsg] = useState(t("dialog.continue"));
    const [disagreeBtnMsg, setDisagreeBtnMsg] = useState(t("dialog.cancel"));
    const [questionCustom, setQuestionCustom] = useState({
        question1: "",
        question2: "",
        question3: "",
    });
    const [currentPlan, setCurrentPlan] = useState({
        value: 0,
        custom: false,
    });
    const [paymentType, setPaymentType] = useState("monthly");
    const [currentLevel, setCurrentLevel] = useState("");
    const [priceMonthly, setPriceMonthly] = useState("");
    const [priceMonthlyOG, setPriceMonthlyOG] = useState("");
    const [showCart, setShowCart] = useState(false);
    const [showPlans, setShowPlans] = useState(false);
    const [integ, setInteg] = useState(companyDetails.system_integration);
    const [errorCustomPlan, setErrorCustomPlan] = useState(false);
    const [isCancel, setIsCancel] = useState(
        (companyDetails?.subscription && "last_cancel" in companyDetails?.subscription) ?? false
    );
    const [openAlertCancel, setOpenAlertCancel] = useState(false);
    const [reasons, setReasons] = useState(null);
    const [details, setDetails] = useState("");
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if ((companyDetails?.subscription && "last_cancel" in companyDetails?.subscription) ?? false) {
            setIsCancel(true);
        } else {
            setIsCancel(false);
        }
    }, [companyDetails]);

    const goBack = () => setOpenCompanyDet(false);

    const goToSettings = () => {
        if ((Object.keys(companyDetails).length > 0 && "subscription" in companyDetails && companyDetails.subscription === null) || !("subscription" in companyDetails) || (Object.keys(companyDetails).length > 0 && "subscription" in companyDetails && companyDetails.subscription !== null && "last_cancel" in companyDetails.subscription)) {
            setShowCompanyDetails(true);
        } else {
            dispatch(addCompanyID(companyDetails._id));
            setShowCompanyDetails(true);
        }
    };

    const planFeatures = ["Onboarding", t("plans.training"), t("plans.channels"), t("plans.people")];

    const planFeatures2 = [t("plans.users1"), t("plans.panelMonit"), t("plans.productUpda"), t("plans.exten")];

    const getFeatures = (featureName, plan) => {
        let newText = "";

        const planData = getPlanInfo(plan)

        if (featureName === "Onboarding") {
            newText = t(planData.planType);
        }
        if (featureName === t("plans.training")) {
            newText = t(planData.item1);
        }
        if (featureName === t("plans.channels")) {
            newText = t(planData.commType);
        }
        if (featureName === t("plans.people")) {
            newText = t(planData.item2);
        }
        if (featureName === t("plans.users1")) {
            if (plan === 0) {
                newText = `2 ${t("plans.users")}`;
            } else {
                newText = t("plans.unlimitedUpp");
            }
        }
        if (featureName === t("plans.panelMonit")) {
            newText = t(planData.item3);
        }
        if (featureName === t("plans.productUpda")) {
            newText = t(planData.item4);
        }
        if (featureName === t("plans.exten")) {
            newText = `${planData.extension} ${t("miscellaneous.days")}`;
        }

        return newText;
    };

    const showStep3 = () => {
        setShowCart(false);
    };

    
    const checkRequestCustom = "request" in companyDetails && companyDetails.request.id_status === 1;
    const checkRequestCustomPending = "request" in companyDetails && companyDetails.request.id_status === 2;

    const handlePlans = async () => {
        if (checkRequestCustomPending) {

            let request = companyDetails.request

            const planCustomId = getCustomPlanId()
            const rollOver = getPlanInfo(planCustomId).extension

            //Downgrades
            const newVAl = {
                subscription_plan: planCustomId,
                line_type: 1,
                line_amount: request.final_price,
                line_unit_amount: (request.final_price).toFixed(2),
                line_qty: 1,
                line_since_date: moment().format(),
                line_end_date: moment().add(30 + rollOver, "days").format(),
                custom: true
            };

            const params = {
                franchise_id: todos.franchiseID,
                company_id: todos.companyID,
                cart_lines: [newVAl],
            };

            setIsLoading(true);

            setCurrentPlan({
                value: planCustomId,
                custom: true,
                pages: request.final_pages,
                price: request.final_price
            });

            setPriceMonthly(request.final_price);
            setPriceMonthlyOG(request.final_price);
            setCurrentLevel(15);

            await subscriptionService
                .updateCart(params)
                .then()
                .catch((error) => {
                    console.log(error);
                });

            setIsLoading(false);

            setShowCart(true);
            setShowPlans(false);
        } else {
            showStep3();
            setShowPlans(true);
            setActiveStep(0);
        }
    };

    const handleCloseDialog = () => {
        setQuestionCustom({
            question1: "",
            question2: "",
            question3: "",
        });
        setOpenAlertCancel(false);
        setOpenAlert(false);
    };

    const handleShowCart = async (e, planId = null) => {
        handleCloseDialog(false);

        const finalPlan = planId !== null ? planId : currentLevel;
        let activePlan = companyDetails?.subscription?.subscription_plan ?? null;
        let isCancelled = "subscription" in companyDetails && "last_cancel" in companyDetails.subscription

        if (activePlan && activePlan !== null && !(companyDetails.id_status === 1 || (companyDetails.id_status === 4 && companyDetails.id_last_status === 1)) && !isCancelled) {
            // 3: Downgrade
            // 4: Upgrade
            await updateCartPlan(
                activePlan > finalPlan ? 3 : 4,
                finalPlan,
                paymentType,
                companyDetails,
                setIsLoading,
                allCompanies.length,
                "month"
            );
        } else {
            console.log("pasaa")
            await updateCartPlan(1, finalPlan, paymentType, companyDetails, setIsLoading, allCompanies.length, "month");
        }
        setShowCart(true);
    };

    const handleSubmitCustomPlan = async () => {
        if (questionCustom.question1 === "" || questionCustom.question2 === "" || questionCustom.question3 === "") {
            setAlertType("error");
            setShow(true);
            setTexto(t("register.requiredData"));
            setErrorCustomPlan(true);
        } else {
            setErrorCustomPlan(false);
            handleCloseDialog();
            setIsLoading(true);

            let activePlan = companyDetails?.subscription?.subscription_plan ?? null;
            if (activePlan !== null) {
                
            } else {
                const planCustomId = getCustomPlanId()
                const paramsC = {
                    company_id: companyDetails._id,
                    planId: planCustomId,
                    origin_form: "companyDet",
                    origin: 3,
                    question1: questionCustom.question1,
                    question2: questionCustom.question2,
                    question3: questionCustom.question3,
                    user_email: todos?.userInfo?.email,
                };
    
                // Se agrega free trial del plan 11
                await authService
                    .addFreeTrialPlan(paramsC)
                    .then(async () => {
                        await updateCompany();
                    })
                    .catch((error) => console.log(error.response));
    
            }
            
            setIsLoading(false);
        }
    };

    const updateMicroPlan = async () => {
        handleCloseDialog();
        setIsLoading(true);
        await authService
            .addMicroPlan({ company_id: companyDetails._id, origin: "editCompany", user_email: todos?.userInfo?.email })
            .then(async () => {
                await updateCompany();
            })
            .catch((error) => console.log(error.response));
        setIsLoading(false);
    };

    const updateCompany = async () => {
        setIsLoading(true);
        await companyService
            .getBusinessDirectoryDetails({ company_id: location?.state?.companyID ?? companyDetails._id })
            .then(async (data) => {
                // Se verifica si la empresa que se está updateando es la misma que se encuentra actualmente seleccionada, para actualizar navbars
                if (companyDetails._id.toString() === todos.companyID) {
                    if ("last_cancel" in data?.subscription) {
                        dispatch(addCompanyID(null));
                    } else {
                        dispatch(
                            addSubscription({
                                value: data.id_status,
                                last_value: data.id_last_status,
                                plan: data.subscription.subscription_plan,
                            })
                        );

                        const credits = await getCompanyCredits(companyDetails._id);
                        dispatch(addCredits(credits));
                    }
                }

                const result = await companyService.getCompanies();

                dispatch(addAllComData(result.records));
                setShowCart(false);
                setShowPlans(false);
                setOpenCompanyDet(true);
                setActiveStep(0);
                setCompanyDetails(data);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
    };

    const handleOpenModal = () => {
        setOpenAlertCancel(true);
    };

    const cancelPlan = async () => {
        if (!reasons) {
            setIsError(true);
        } else {
            if (reasons === "6" && details.trim() === "") {
                setIsError(true);
            } else {
                setOpenAlertCancel(false);
                
                const newVAl = {
                    subscription_plan: companyDetails.subscription.subscription_plan,
                    line_type: 7,
                    line_amount: 0.0,
                    line_unit_amount: 0.0,
                    line_qty: 1,
                    line_since_date: moment().format(),
                    line_end_date: moment().add(1, "M").format(),
                };

                const params = {
                    company_id: companyDetails._id,
                    cart_lines: [newVAl],
                };

                setCurrentPlan({
                    value: companyDetails.subscription.subscription_plan,
                    custom: isCustomPlan(companyDetails.subscription.subscription_plan),
                    endDate: companyDetails.subscription.end_date,
                    type: "cancel",
                });

                setPriceMonthlyOG(0);
                setCurrentLevel(companyDetails.subscription.subscription_plan);

                setIsLoading(true);

                await subscriptionService
                    .updateCart(params)
                    .then()
                    .catch((error) => {
                        console.log(error);
                    });

                setIsLoading(false);

                setShowCart(true);
            }
        }
    };

    const cancelPlanButtons = (
        <Grid container justifyContent="space-between" sx={{ width: 600 }}>
            <Grid item>
                <BaseButton variantType="primaryTextButton" sizebutton="m" onClick={handleCloseDialog}>{t("team.cancel")}</BaseButton>
            </Grid>
            <Grid item>
                <BaseButton onClick={cancelPlan} sizebutton="m">
                    {t("subscription.confirm")}
                </BaseButton>
            </Grid>
        </Grid>
    );

    return (
        <>
            {!showCart && (
                <BaseButton variantType="primaryOutlined" onClick={goBack} sx={{ mb: 1 }} sizebutton="m">
                    {t("miscellaneous.goBack")}
                </BaseButton>
            )}

            {!showPlans && !showCart && (
                <Paper elevation={0} sx={{ p: 4, borderRadius: 3, mt: 2 }}>
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                        <Stack direction="row" spacing={2}>
                            <Typography variant="h2" color="primary">
                                {companyDetails.company_name}
                            </Typography>
                            <Typography>
                                {`${t("management.companyCreated")} ${moment
                                    .utc(companyDetails.creation_date)
                                    .format(todos.dateFormat)}`}
                            </Typography>
                        </Stack>
                        <BaseButton variantType="primaryOutlined" sizebutton="m" onClick={goToSettings} disabled={checkRequestCustom || checkRequestCustomPending}>
                            {t("management.configComp")}
                        </BaseButton>
                    </Stack>
                    <Box
                        sx={{
                            border: `1px solid #ccc`,
                            borderRadius: 3,
                            p: 3,
                            mt: 2,
                        }}
                    >
                        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                            <Typography>
                                <span
                                    style={{
                                        fontSize: 18,
                                        color: isCancel ? theme.palette.secondary.main : theme.palette.primary.main,
                                        fontWeight: 600,
                                    }}
                                >
                                    {checkRequestCustom || checkRequestCustomPending
                                        ? "Plan " + t("plans.customize")
                                        : isCancel
                                        ? t("invoicing.noPlanAct")
                                        : getPlanInfo(companyDetails?.subscription?.subscription_plan)?.planTitle
                                        ? "Plan " +
                                        getPlanInfo(companyDetails?.subscription?.subscription_plan)?.planTitle
                                        : t("invoicing.noPlanAct")}{" "}
                                </span>
                                {checkRequestCustom || isCancel
                                    ? ""
                                    : checkRequestCustomPending
                                    ? companyDetails.request.final_pages + " " + t("register.pagesMonthly")
                                    : getPlanLevelInfo(companyDetails?.subscription?.subscription_plan)
                                          ?.pages > 0
                                    ? getPlanLevelInfo(companyDetails?.subscription?.subscription_plan)
                                          ?.pages +
                                      " " +
                                      t("register.pagesMonthly")
                                    : ""}
                            </Typography>
                            <Stack direction="row" spacing={2}>
                                {!checkRequestCustom && !checkRequestCustomPending && !isCancel &&
                                    companyDetails?.subscription?.subscription_plan >= 0 && (
                                        <BaseButton variantType="errorTextButton" color="error" sizebutton="m" sx={{ mr: 4 }} onClick={handleOpenModal}>
                                            {t("management.cancelPlan")}
                                        </BaseButton>
                                    )}
                                {isCancel && <BaseButton variantType="primaryTextButton" sizebutton="m">{t("management.reactivatePlan")}</BaseButton>}
                                <BaseButton
                                    disableElevation
                                    sizebutton="m"
                                    onClick={handlePlans}
                                    disabled={checkRequestCustom}
                                >
                                    {isCancel ? t("management.activatePlan") : checkRequestCustom || checkRequestCustomPending ? t("navBar.freeEnded2") : t("subscription.changePlan")}
                                </BaseButton>
                            </Stack>
                        </Stack>
                        {!checkRequestCustom && !isCancel && companyDetails?.subscription?.subscription_plan >= 0 && (
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <List>
                                        {planFeatures.map((item, index) => (
                                            <ListItem key={index} disablePadding>
                                                <ListItemIcon sx={{ minWidth: 35 }}>
                                                    <CheckIcon sx={{ color: "#00B147" }} fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Stack direction="row" spacing={2}>
                                                            <b>{item}</b>{" "}
                                                            <Typography>
                                                                {getFeatures(
                                                                    item,
                                                                    checkRequestCustomPending ? 15 : companyDetails?.subscription?.subscription_plan
                                                                )}
                                                            </Typography>
                                                        </Stack>
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <List>
                                        {planFeatures2.map((item, index) => (
                                            <ListItem key={index} disablePadding>
                                                <ListItemIcon sx={{ minWidth: 35 }}>
                                                    <CheckIcon sx={{ color: "#00B147" }} fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Stack direction="row" spacing={2}>
                                                            <b>{item}</b>{" "}
                                                            <Typography>
                                                                {getFeatures(
                                                                    item,
                                                                    checkRequestCustomPending ? 15 : companyDetails?.subscription?.subscription_plan
                                                                )}
                                                            </Typography>
                                                        </Stack>
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                            </Grid>
                        )}
                        {(companyDetails?.subscription?.subscription_plan >= 0 || checkRequestCustom || checkRequestCustomPending || isCancel) && (
                            <Stack
                                direction="row"
                                spacing={2}
                                justifyContent="space-between"
                                sx={{ backgroundColor: "#f8f8f9", p: 2, mt: checkRequestCustom || checkRequestCustomPending || isCancel ? 2 : 0 }}
                            >
                                {!checkRequestCustom && !checkRequestCustomPending && !isCancel ? (
                                    <>
                                        <Typography variant="h3">{t("converter.price")}</Typography>
                                        <Typography>{`US$ ${parseFloat(
                                            getPlanLevelInfo(companyDetails?.subscription?.subscription_plan)?.price ?? 0
                                        ).toLocaleString(todos.amountFormat, {
                                            minimumFractionDigits: 2,
                                        })}/${t("converter.month")}`}</Typography>
                                    </>
                                ) : isCancel ? (
                                    <Typography mt={2}>
                                        <b>
                                            {"Plan " +
                                                getPlanInfo(companyDetails?.subscription?.subscription_plan)
                                                ?.planTitle}
                                        </b>{" "}
                                        -{" "}
                                        {getPlanLevelInfo(companyDetails?.subscription?.subscription_plan)?.pages +
                                            " " +
                                            t("register.pagesMonthly")}{" "}
                                        <span style={{ color: "#FF5E77" }}>
                                            {t("management.cancelTo")} {moment().format(todos.dateFormat)}
                                        </span>
                                    </Typography>
                                    
                                ) : checkRequestCustomPending ? (
                                    <>
                                        <Typography variant="h3">{t("converter.price")}</Typography>
                                        <Typography>{`US$ ${parseFloat(companyDetails.request.final_price).toLocaleString(todos.amountFormat, {
                                            minimumFractionDigits: 2,
                                        })}/${t("converter.month")}`}</Typography>
                                    </>
                                ) : (
                                    <Typography mt={2} fontWeight={600}>
                                        {t("plans.customMsg")}
                                    </Typography>
                                )}
                            </Stack>
                        )}
                    </Box>
                    {!checkRequestCustom && !isCancel && companyDetails?.subscription?.subscription_plan >= 0 && (
                        <Box
                            sx={{
                                border: `1px solid #ccc`,
                                borderRadius: 3,
                                p: 3,
                                mt: 2,
                            }}
                        >
                            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                                <Box>
                                    <Typography variant="h3">{t("payment.paymentMethod")}</Typography>
                                    <Typography>
                                        {t("payment.nextPayment")}{" "}
                                        {moment.utc(companyDetails?.subscription?.since_date).format(todos.dateFormat)}
                                    </Typography>
                                </Box>
                                {companyDetails?.payment_method && (
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Box
                                            component="img"
                                            src={
                                                companyDetails.payment_method.brand === "visa"
                                                    ? Visa
                                                    : companyDetails.payment_method.brand === "mastercard"
                                                    ? Mastercard
                                                    : companyDetails.payment_method.brand === "amex"
                                                    ? Amex
                                                    : companyDetails.payment_method.brand === "discover"
                                                    ? Discover
                                                    : companyDetails.payment_method.brand === "diners"
                                                    ? Diners
                                                    : companyDetails.payment_method.brand === "unionpay"
                                                    ? UnionPay
                                                    : JCB
                                            }
                                            sx={{ width: 45 }}
                                        />
                                        <Typography variant="h3">
                                            {companyDetails.payment_method.brand.charAt(0).toUpperCase() +
                                                companyDetails.payment_method.brand.slice(1)}{" "}
                                            {t("credits.endingIn")} *
                                            {companyDetails.payment_method.card_number.split("-").pop()}
                                        </Typography>
                                    </Stack>
                                )}
                            </Stack>
                        </Box>
                    )}
                </Paper>
            )}
            {showPlans && !showCart && (
                <Box
                    sx={{
                        borderRadius: "8px",
                        px: { xs: 3, md: 10 },
                        py: { xs: 3, md: 4 },
                        minWidth: "980px",
                        backgroundColor: "#FFF",
                    }}
                >
                    <Box sx={{ width: "100%" }}>
                        <>
                            {activeStep === 0 && !showCart && showPlans && (
                                <Box mt={3}>
                                    <PlanSubs
                                        setTitleModal={setTitleModal}
                                        setOpenAlert={setOpenAlert}
                                        setAgreeBtnMsg={setAgreeBtnMsg}
                                        setDisagreeBtnMsg={setDisagreeBtnMsg}
                                        setBodyMess={setBodyMess}
                                        setCurrentPlan={setCurrentPlan}
                                        paymentType={paymentType}
                                        setPaymentType={setPaymentType}
                                        setCurrentLevel={setCurrentLevel}
                                        setPriceMonthly={setPriceMonthly}
                                        setPriceMonthlyOG={setPriceMonthlyOG}
                                        currentLevel={currentLevel}
                                        priceMonthly={priceMonthly}
                                        origin="editCompany"
                                        activePlan={
                                            companyDetails.id_status === 3
                                                ? companyDetails?.subscription?.subscription_plan ?? null
                                                : null
                                        }
                                        currentPlan={currentPlan}
                                        setIsLoading={setIsLoading}
                                        setShowCart={setShowCart}
                                        companyDetails={companyDetails}
                                        handleShowCart={handleShowCart}
                                        maxFreeCompanies={maxFreePlan}
                                        currentFreeCompanies={currentFreePlan}
                                        subsType={companyDetails.franchise_id.type}
                                        setShow={setShow}
                                        setAlertType={setAlertType}
                                        setMessage={setTexto}
                                        isCancelled={Object.keys(companyDetails).length > 0 && "subscription" in companyDetails && companyDetails.subscription !== null && "last_cancel" in companyDetails.subscription}
                                    />
                                </Box>
                            )}
                            {activeStep === 1 && (
                                <Box mt={3}>
                                    <Integrations
                                        smbInfo={smbInfo}
                                        setTexto={setTexto}
                                        setShow={setShow}
                                        setAlertType={setAlertType}
                                        integ={integ}
                                        setInteg={setInteg}
                                        origin="company"
                                    />
                                </Box>
                            )}
                        </>
                    </Box>
                </Box>
            )}
            {activeStep === 0 && showCart && (
                <>
                    <Stack direction="row" spacing={2} justifyContent="space-between" mt={2}>
                        <BaseButton variantType="primaryOutlined" onClick={goBack} sizebutton="m">
                            {t("miscellaneous.goBack")}
                        </BaseButton>
                        <BaseButton sizebutton="m" variantType="primaryTextButton" onClick={() => handleClickHelp("generalConv", "", "")}>{t("accounts.needHelp")}</BaseButton>
                    </Stack>
                    {allCompanies.length >= 11 && companyDetails.franchise_id.type === 1 && (
                        <DiscountCartBanner allCompaniesLength={allCompanies.length} currentPlan={currentPlan} />
                    )}
                    <ShoppingCartReg
                        origin="editCompany"
                        currentPlan={currentPlan}
                        setCurrentPlan={setCurrentPlan}
                        paymentType={paymentType}
                        setPaymentType={setPaymentType}
                        currentLevel={currentLevel}
                        priceMonthly={priceMonthly}
                        priceMonthlyOG={priceMonthlyOG}
                        setPriceMonthly={setPriceMonthly}
                        setActiveStep={setActiveStep}
                        allCompanies={allCompanies}
                        activePlan={companyDetails?.subscription?.subscription_plan ?? null}
                        setIsLoading={setIsLoading}
                        smbInfo={companyDetails}
                        finalF={updateCompany}
                        setShowCart={setShowCart}
                        setIsCancel={setIsCancel}
                        subsType={companyDetails.franchise_id.type}
                    />
                </>
            )}
            {activeStep === 1 && showCart && (
                <>
                    <Box mt={3}>
                        <Integrations
                            smbInfo={smbInfo}
                            setTexto={setTexto}
                            setShow={setShow}
                            setAlertType={setAlertType}
                            integ={integ}
                            setInteg={setInteg}
                            origin="company"
                        />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 5, justifyContent: "end" }}>
                        <ColorButton onClick={updateCompany} disableElevation variant="contained">
                            {integ === 3 ? t("register.continueWithoutInteg") : t("dialog.continue")}
                        </ColorButton>
                    </Box>
                </>
            )}
            <AlertDialog
                open={openAlert}
                onClose={handleCloseDialog}
                agreeBtnLabel={agreeBtnMsg}
                disagreeBtnLabel={disagreeBtnMsg}
                type="info2"
                title={titleModal}
                message={
                    currentPlan.value === 3 && currentPlan.custom === true ? (
                        <CustomForm
                            setQuestionCustom={setQuestionCustom}
                            questionCustom={questionCustom}
                            errorCustomPlan={errorCustomPlan}
                        />
                    ) : (
                        bodyMess
                    )
                }
                agreeAction={
                    !currentPlan.custom
                        ? currentPlan.value === 0
                            ? companyDetails?.subscription && companyDetails?.subscription?.subscription_plan >= 0 // Downgrade
                                ? handleShowCart
                                : updateMicroPlan
                            : handleShowCart
                        : handleSubmitCustomPlan
                }
                maxWidth="sm"
                origin="converter"
                onlyAction={true}
                oneAction={handleCloseDialog}
            />
            <FormDialog
                open={openAlertCancel}
                handleClose={handleCloseDialog}
                maxWidth="md"
                title={
                    <>
                        <Typography component="span" variant="h3" sx={{ color: theme.palette.secondary.main }}>{todos?.userInfo?.user_name ?? ""}</Typography>,{" "}
                        <Typography component="span" variant="h3">{t("plans.regretDes")}</Typography>
                    </>
                }
                content={
                    <CancelPlanForm
                        reasons={reasons}
                        setReasons={setReasons}
                        details={details}
                        setDetails={setDetails}
                        isError={isError}
                        currentPlanInfo={companyDetails}
                    />
                }
                actions={cancelPlanButtons}
                custom={true}
            />
        </>
    );
};

export default CompanyDet;
