import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    ToggleButton,
    Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import ToggleButtonGroup, { toggleButtonGroupClasses } from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import Slider from "react-slick";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        border: 0,
        borderRadius: 10,
    },
    [`& .${toggleButtonGroupClasses.firstButton},& .${toggleButtonGroupClasses.lastButton}`]: {
        width: 120,
    },
}));

const StyledTableRowB = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& td": {
        border: 0,
    },
    "& td:first-of-type": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "& td:last-child": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        lineHeight: 3,
    },
}));

function Arrows(props) {
    const { className, style, onClick, isDisabled } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", pointerEvents: isDisabled ? "none" : "all" }}
            onClick={onClick}
        />
    );
}

const greenBoxes = {
    borderRadius: 2,
    backgroundColor: "#DBF4E5",
    padding: 2,
};

const purpleBoxes = {
    borderRadius: 2,
    backgroundColor: "#F6F4FD",
    padding: 2,
    width: 200,
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: "#f8f8f9",
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& td": {
        border: 0,
    },
    "& td:first-of-type": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "& td:last-child": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const settings = {
    speed: 500,
    slidesToShow: 4,
    nextArrow: <Arrows />,
    prevArrow: <Arrows />,
    infinite: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const ResumeInv = () => {
    const [currency, setCurrency] = useState("dollar");
    const [paymentSlide, setPaymentSlide] = useState({});

    const todos = useSelector((state) => state.value);
    const [t] = useTranslation("global");

    const payments = [
        { _id: 0, date: new Date(), amount1: 500, amount2: 20000 },
        { _id: 1, date: new Date(), amount1: 0, amount2: 0 },
        { _id: 2, date: new Date(), amount1: 0, amount2: 0 },
        { _id: 3, date: new Date(), amount1: 0, amount2: 0 },
    ];

    const columns = [
        { name: "none", title: "" },
        { name: "base", title: "Base", align: "right" },
        { name: "ITBIS", title: "ITBIS", align: "right" },
        { name: "total", title: "Total", align: "right" },
    ];

    const rows = [
        {
            title: t("invoicing.salePur"),
            base: 100,
            itbis: 16,
            total: 116,
        },
        {
            title: t("invoicing.reten"),
            base: 3,
            itbis: 12,
            total: -15,
        },
        {
            title: t("invoicing.netAmount"),
            base: 97,
            itbis: 4,
            total: 101,
        },
        {
            title: t("invoicing.chargePay"),
            base: "-",
            itbis: "-",
            total: 94,
        },
        {
            title: t("invoicing.tocharPay"),
            base: "-",
            itbis: "-",
            total: 7,
        },
    ];

    const handlePayment = (item) => {
        setPaymentSlide(item);
    };

    const handleCurrency = (event, newCurrency) => {
        if (newCurrency !== null) {
            setCurrency(newCurrency);
        }
    };

    return (
        <Grid item xs={12} md={5} mt={8.5}>
            <Box sx={{ backgroundColor: "white", p: 5, borderRadius: "24px" }}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                    <Typography variant="h1">{t("invoicing.resume")}</Typography>
                    <Paper
                        elevation={0}
                        sx={{
                            display: "flex",
                            border: `1px solid #6544DB`,
                            flexWrap: "wrap",
                            borderRadius: 3,
                        }}
                    >
                        <StyledToggleButtonGroup
                            value={currency}
                            exclusive
                            onChange={handleCurrency}
                            aria-label="currency"
                            size="small"
                        >
                            <ToggleButton
                                value="dollar"
                                aria-label="dollar"
                                sx={{
                                    backgroundColor: currency === "dollar" ? "#E5E0F9 !important" : "white",
                                    "&:hover": {
                                        backgroundColor: currency === "dollar" ? "#EAE6FA !important" : "lightgray",
                                    },
                                }}
                            >
                                US$
                            </ToggleButton>
                            <ToggleButton
                                value="bolivar"
                                aria-label="bolivar"
                                sx={{
                                    backgroundColor: currency === "bolivar" ? "#E5E0F9 !important" : "white",
                                    "&:hover": {
                                        backgroundColor: currency === "bolivar" ? "#EAE6FA !important" : "lightgray",
                                    },
                                }}
                            >
                                Bs.
                            </ToggleButton>
                        </StyledToggleButtonGroup>
                    </Paper>
                </Stack>
                <TableContainer sx={{ pt: 2 }}>
                    <Table size="small" sx={{ borderCollapse: "separate", borderSpacing: "0 6px" }}>
                        <TableHead>
                            <StyledTableRowB>
                                {columns.map((item) => (
                                    <StyledTableCell
                                        key={item.name}
                                        align={item.align ?? "left"}
                                        style={{ minWidth: item.minWidth }}
                                    >
                                        {item.title}
                                    </StyledTableCell>
                                ))}
                            </StyledTableRowB>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <StyledTableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <StyledTableCell>{row.title}</StyledTableCell>
                                    <StyledTableCell align="right">{row.base}</StyledTableCell>
                                    <StyledTableCell align="right">{row.itbis}</StyledTableCell>
                                    <StyledTableCell align="right">{row.total}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack direction="row" justifyContent="flex-end" spacing={2} mt={1}>
                    <Box sx={greenBoxes}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="h3">{t("invoicing.realGains")}</Typography>
                            <InfoIcon sx={{ color: "#4A22D4", fontSize: 18 }} />
                        </Stack>
                        <Typography>1.10 USD</Typography>
                    </Box>
                    <Box sx={greenBoxes}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="h3">{t("invoicing.noRealGains")}</Typography>
                            <InfoIcon sx={{ color: "#4A22D4", fontSize: 18 }} />
                        </Stack>
                        <Typography>1.17 USD</Typography>
                    </Box>
                </Stack>
                <Typography variant="h2" mt={3}>
                    {t("subscription.myPayments")} ({payments.length})
                </Typography>
                <Slider {...settings} style={{ margin: 20 }}>
                    {payments.map((item) => (
                        <div key={item._id}>
                            <Card
                                sx={{
                                    margin: "0 10px",
                                    backgroundColor: paymentSlide._id === item._id ? "#4a22d4" : "#F9F8FD",
                                    boxShadow: "4px 4px 5px 0px rgba(74, 34, 212, 0.1)",
                                    borderRadius: 3,
                                }}
                            >
                                <CardActionArea
                                    onClick={() => handlePayment(item)}
                                    sx={{
                                        minHeight: 120,
                                    }}
                                >
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            color={paymentSlide._id === item._id ? "white" : "#131f3e"}
                                        >
                                            {moment.utc(item.date).format(todos.dateFormat)}
                                        </Typography>
                                        <Typography
                                            color={paymentSlide._id === item._id ? "white" : "#131f3e"}
                                            fontWeight={600}
                                        >
                                            US$
                                        </Typography>
                                        <Typography color={paymentSlide._id === item._id ? "white" : "#131f3e"}>
                                            {parseFloat(item.amount1).toLocaleString(todos.amountFormat, {
                                                minimumFractionDigits: 2,
                                            })}
                                        </Typography>
                                        <Typography
                                            color={paymentSlide._id === item._id ? "white" : "#131f3e"}
                                            fontWeight={600}
                                        >
                                            Bs
                                        </Typography>
                                        <Typography color={paymentSlide._id === item._id ? "white" : "#131f3e"}>
                                            {parseFloat(item.amount2).toLocaleString(todos.amountFormat, {
                                                minimumFractionDigits: 2,
                                            })}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                    ))}
                </Slider>
                {Object.keys(paymentSlide).length !== 0 && (
                    <>
                        <Box sx={{ backgroundColor: "#F8F8F9", borderRadius: 4, p: 2 }}>
                            <Typography gutterBottom>
                                <b>{t("invoicing.payID")}</b> 12345
                            </Typography>
                            <Typography gutterBottom>
                                <b>{t("invoicing.state")}</b> Completado
                            </Typography>
                            <Typography gutterBottom>
                                <b>{t("inbox.bankAcc")}</b> ES91 00XX XXXX XXXX XXXX
                            </Typography>
                            <Typography gutterBottom>
                                <b>{t("invoicing.payMethod")}</b> Tarjeta de Crédito
                            </Typography>
                            <Typography gutterBottom>
                                <b>{t("invoicing.emit")}</b> Juan Perez
                            </Typography>
                            <Typography gutterBottom>
                                <b>{t("accounts.description")}</b> Pago de factura de electricidad
                            </Typography>
                        </Box>
                        <Typography variant="h1" mt={2} gutterBottom>
                            {t("invoicing.reten")} (2)
                        </Typography>
                        <Stack direction="row" spacing={2}>
                            <Box sx={purpleBoxes}>
                                <Typography gutterBottom>{moment().format(todos.dateFormat)}</Typography>
                                <Typography fontWeight={600}>US$</Typography>
                                <Typography>
                                    {parseFloat(500).toLocaleString(todos.amountFormat, {
                                        minimumFractionDigits: 2,
                                    })}
                                </Typography>
                                <Typography fontWeight={600}>Bs</Typography>
                                <Typography>
                                    {parseFloat(20000).toLocaleString(todos.amountFormat, {
                                        minimumFractionDigits: 2,
                                    })}
                                </Typography>
                            </Box>
                            <Box sx={purpleBoxes}>
                                <Typography gutterBottom>{moment().format(todos.dateFormat)}</Typography>
                                <Typography fontWeight={600}>US$</Typography>
                                <Typography>
                                    {parseFloat(500).toLocaleString(todos.amountFormat, {
                                        minimumFractionDigits: 2,
                                    })}
                                </Typography>
                                <Typography fontWeight={600}>Bs</Typography>
                                <Typography>
                                    {parseFloat(20000).toLocaleString(todos.amountFormat, {
                                        minimumFractionDigits: 2,
                                    })}
                                </Typography>
                            </Box>
                        </Stack>
                        <Typography variant="h1" mt={2} gutterBottom>
                            {t("invoicing.creditNote")} (1)
                        </Typography>
                        <Box sx={purpleBoxes}>
                            <Typography gutterBottom>{moment().format(todos.dateFormat)}</Typography>
                            <Typography fontWeight={600}>US$</Typography>
                            <Typography>
                                {parseFloat(500).toLocaleString(todos.amountFormat, {
                                    minimumFractionDigits: 2,
                                })}
                            </Typography>
                            <Typography fontWeight={600}>Bs</Typography>
                            <Typography>
                                {parseFloat(20000).toLocaleString(todos.amountFormat, {
                                    minimumFractionDigits: 2,
                                })}
                            </Typography>
                        </Box>
                    </>
                )}
            </Box>
        </Grid>
    );
};

export default ResumeInv;
