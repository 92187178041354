import { Dialog, DialogTitle, Typography } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import payService from "../../services/payment_methods";
import { useTranslation } from "react-i18next";
import AddPayForm from "./AddPayForm";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 20,
        padding: "20px 30px",
    },
    "& .MuiDialogTitle-root": {
        fontSize: 20,
        fontWeight: 600,
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
        justifyContent: "space-between",
    },
}));

export default function AddPaymentDialog(props) {
    const { open, handleClose, maxWidth, setMessage, setShow, setAlertType, setIsLoading, reloadData, isTab2, handleClose2, converter, userEmail } = props;
    const [stripePromise, setStripePromise] = useState(null);

    const [t] = useTranslation("global");

    useEffect(() => {
        (async () => {
            await payService.getConfigStripe().then((response) => {
                setStripePromise(loadStripe(response.publishableKey));
            });
        })();
    }, []);

    const options = {
        mode: "payment",
        amount: 50,
        currency: "usd",
        setup_future_usage: "off_session",
        appearance: {
            theme: "flat",
            variables: { colorPrimary: "#4a22d4", colorText: "#131f3e" },
        },
        paymentMethodCreation: 'manual',
        locale: t("language.locale")
    };

    return (
        <BootstrapDialog open={open} onClose={handleClose} maxWidth={maxWidth} fullWidth>
            <DialogTitle><Typography variant="h2">{t("dialog.addNewCard")}</Typography></DialogTitle>
            {stripePromise && (
                <Elements stripe={stripePromise} options={options}>
                    <AddPayForm
                        handleClose={handleClose}
                        setMessage={setMessage}
                        setShow={setShow}
                        setAlertType={setAlertType}
                        setIsLoading={setIsLoading}
                        reloadData={reloadData}
                        isTab2={isTab2}
                        handleClose2={handleClose2}
                        converter={converter}
                        userEmail={userEmail}
                    />
                </Elements>
            )}
        </BootstrapDialog>
    );
}
