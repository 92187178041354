import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material";

/** Styles */
import classes from "./DropdownUser.module.css";
import { useTranslation } from "react-i18next";
/** Redux imports */
import { useDispatch, useSelector } from "react-redux";
import { addDateFormat, addAmountFormat } from "../../../redux/userSlice";

/** Services */
import userConfigService from "../../../services/user_config";
import BaseButton from "../../ui/BaseButton";

const Settings = (props) => {
    const {
        setLanguage,
        setDateFormat,
        language,
        dateFormat,
        amountFormat,
        setAmountFormat,
        handleClose,
        setTexto,
        setalertType,
        setShow,
        setIsLoading,
        isMobile,
    } = props;

    /** Internationalization i18n */
    const [t, i18n] = useTranslation("global");
    /* React redux */
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.value);

    const updateLanguage = (event) => {
        setLanguage(event.target.value);
    };

    const updateDateFormat = (event) => {
        setDateFormat(event.target.value);
    };

    const updateAmountFormat = (event) => {
        setAmountFormat(event.target.value);
    };

    const saveUserConfig = async () => {
        setIsLoading(true);

        const params = {
            user_id: todos.userInfo?._id,
            language,
            date_format: dateFormat,
            amount_format: amountFormat,
        };

        await userConfigService
            .saveUserConfig(params)
            .then((response) => {
                if (response === "OK") {
                    sessionStorage.setItem("lng", language);
                    i18n.changeLanguage(language);

                    dispatch(addDateFormat(dateFormat));
                    dispatch(addAmountFormat(amountFormat));

                    handleClose();
                    setTexto(t("navBar.saveUserInfo"));
                    setalertType("success");
                    setShow(true);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setTexto(t("register.error"));
                setalertType("error");
                setShow(true);
                setIsLoading(false);
            });
    };

    return (
        <>
            <Box sx={{ pl: isMobile ? 4 : 0, width: 300 }}>
                <FormControl sx={{ ml: 2, mt: 2 }}>
                    <FormLabel id="language-radio-buttons-group-label">
                        <Typography variant="h4" className={classes.titleConfig}>
                            Language / Idioma
                        </Typography>
                    </FormLabel>
                    <RadioGroup
                        row={!isMobile}
                        aria-labelledby="language-radio-buttons-group-label"
                        name="language-radio-buttons-group"
                        value={language}
                        onChange={updateLanguage}
                    >
                        <FormControlLabel
                            value="en"
                            control={<Radio size="small" className={classes.radioConfig} />}
                            label="English"
                        />
                        <FormControlLabel
                            value="es"
                            control={<Radio size="small" className={classes.radioConfig} />}
                            label="Español"
                        />
                    </RadioGroup>
                </FormControl>
                <FormControl sx={{ pt: 1, ml: 2 }}>
                    <FormLabel id="date-format-radio-buttons-group-label">
                        <Typography variant="h4" className={classes.titleConfig}>
                            Date / Fecha
                        </Typography>
                    </FormLabel>
                    <RadioGroup
                        row={!isMobile}
                        aria-labelledby="date-format-radio-buttons-group-label"
                        name="date-format-radio-buttons-group"
                        value={dateFormat}
                        onChange={updateDateFormat}
                    >
                        <FormControlLabel
                            value="MM/DD/YYYY"
                            control={<Radio size="small" className={classes.radioConfig} />}
                            label="MM/DD/YYYY"
                        />
                        <FormControlLabel
                            value="DD/MM/YYYY"
                            control={<Radio size="small" className={classes.radioConfig} />}
                            label="DD/MM/YYYY"
                        />
                    </RadioGroup>
                </FormControl>
                <FormControl sx={{ pt: 1, pb: 1, ml: 2 }}>
                    <FormLabel id="amount-format-radio-buttons-group-label">
                        <Typography variant="h4" className={classes.titleConfig}>
                            Number / Número
                        </Typography>
                    </FormLabel>
                    <RadioGroup
                        row={!isMobile}
                        aria-labelledby="amount-format-radio-buttons-group-label"
                        name="amount-format-radio-buttons-group"
                        value={amountFormat}
                        onChange={updateAmountFormat}
                    >
                        <FormControlLabel
                            value="en-US"
                            control={<Radio size="small" className={classes.radioConfig} />}
                            label="9,999.99"
                        />
                        <FormControlLabel
                            value="de-DE"
                            control={<Radio size="small" className={classes.radioConfig} />}
                            label="9.999,99"
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box sx={{ ml: "6vh", mb: 2 }}>
                <BaseButton
                    disableElevation
                    onClick={saveUserConfig}
                    sizebutton="m"
                >
                    {t("navBar.update")}
                </BaseButton>
            </Box>
        </>
    );
};

export default Settings;
