import moment from "moment";
import conversionService from "../services/conversion";
import subscriptionService from "../services/subscription";
import plansInfo from "../components/Js/plans";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from "@mui/icons-material/WarningRounded";
import LightTooltip from "../components/ui/LightTooltip";

export function getFileExtension(fileName) {
    const parts = fileName ? fileName.split(".") : [];

    if (parts.length > 1) {
        return parts[parts.length - 1];
    } else {
        return "";
    }
}

export const zeroPad = (num, places) => String(num).padStart(places, "0");

// Función para obtener el MIME type basado en la extensión
const getMimeType = (extension) => {
    switch (extension.toLowerCase()) {
        case 'png':
            return 'image/png';
        case 'jpg':
        case 'jpeg':
            return 'image/jpeg';
        default:
            return '';
    }
};

export async function fetchFileFromS3(filename) {

    const params = { uploaded_file_s3: filename };

    try {
        const response = await conversionService.getFileS3(params);
        // Check if the file is an image
        const extension = getFileExtension(filename);
        const mimeType = getMimeType(extension);

        if (extension === "png" || extension === "jpg" || extension === "jpeg") {
            const blob = new Blob([response.data], { type: `image/${mimeType}` });
            const url = URL.createObjectURL(blob);
            return url;
        }
        return response.data;
    } catch (err) {
        console.error(err);
        return null;
    }
}

export async function getCompanyCredits(company) {
    try {
        return await subscriptionService.getFranchiseCredits({ company_id: company });
    } catch (err) {
        console.error(err);
        return 0;
    }
}

const getDiscountByPlan = (currentPlan, countCompanies) => {
    const generalPlan = plansInfo.find((item) => item.levels.some((item) => item.id === currentPlan))
    return generalPlan.discounts.find((item) => countCompanies >= item.min && countCompanies <= item.max)?.discount ?? 0;
};

const getTotal = (paymentType, priceMonthly, countCompanies, monthDays, currentPlan, subsType) => {

    const annualDiscount = 0.15

    if (paymentType === "annual") {
        const totalWithoutDisc = priceMonthly * 12;
        if (countCompanies >= 11 && subsType === 1) {
            return totalWithoutDisc - totalWithoutDisc * annualDiscount - totalWithoutDisc * getDiscountByPlan(currentPlan, countCompanies);
        } else {
            return totalWithoutDisc - totalWithoutDisc * annualDiscount;
        }
    } else {
        const totalWithoutDisc =
            monthDays === "month"
                ? priceMonthly
                : (priceMonthly / 30) * moment().endOf("month").diff(moment(), "days") + 1;

        if (countCompanies >= 11 && subsType === 1) {
            return totalWithoutDisc - totalWithoutDisc * getDiscountByPlan(currentPlan, countCompanies);
        } else {
            return totalWithoutDisc;
        }
    }
};

export async function updateCartPlan(line_type, currentPlanId, paymentType, company, setOpenBackdrop, countCompanies, monthDays) {
    
    setOpenBackdrop(true);
    const subsPlan = plansInfo.flatMap((plan) => plan.levels).find((level) => level.id === currentPlanId)

    const rollOver = plansInfo.find((item) => item.levels.some((item) => item.id === subsPlan.id)).extension

    const dayQty = paymentType === "annual" ? 365 : monthDays === "month" ? 30 : moment().endOf("month").diff(moment(), "days") + 1
    const subsType = company.franchise_id.type
    const total = getTotal(paymentType, subsPlan.price, countCompanies, monthDays, subsPlan.id, subsType)

    let finalDisc = []
    if (paymentType === "annual") {
        finalDisc.push({
            discount_type: 1,
            discount_percentage: 0.15
        })
    }

    if (countCompanies >= 11 && subsType === 1) {
        finalDisc.push({
            discount_type: 2,
            discount_percentage: getDiscountByPlan(subsPlan.id, countCompanies)
        })
    }

    if (company?.last_invoice && company.last_invoice !== null) {
        finalDisc.push({
            discount_type: 3,
            discount_amount: company.last_invoice.line_items[0].line_amount
        })
    }

    const newVAl = {
        subscription_plan: subsPlan.id,
        line_type: line_type,
        line_amount: parseFloat(total.toFixed(2)),
        line_unit_amount: subsPlan.price,
        line_qty: dayQty,
        line_since_date: moment().format(),
        line_end_date: moment().add(dayQty + rollOver, "days").format(),
    };

    const params = {
        company_id: company._id,
        cart_lines: [newVAl],
        discounts: finalDisc
    };

    await subscriptionService
        .updateCart(params)
        .then()
        .catch((error) => {
            console.log(error);
        });

    setOpenBackdrop(false);

    return [newVAl]
}

export function formatAmount(amount, format) {
    return parseFloat(amount).toLocaleString(format, {
        maximumFractionDigits: 2,
    });
}

export function getPlanInfo(plan) {
    return plansInfo.find((item) => item.levels.some((level) => level.id === plan));
}

export function getPlanLevelInfo(plan) {
    return plansInfo.flatMap((item) => item.levels).find((item) => item.id === plan);
}

export function isCustomPlan(plan) {
    return plansInfo.flatMap((item) => item.levels).find((item) => item.id === plan)?.custom === true;
}

export function getCustomPlanId() {
    return plansInfo.flatMap((item) => item.levels).find((item) => item?.custom === true).id;
}

export function formatDate(date, format) {
    return moment.utc(date).format(format);
}

export async function CheckSubscriptionAndRedirect(redirect) {

    const navigate = useNavigate();
  
    try {
        // Realiza una llamada API a la ruta del backend
        const response = await fetch('/check-subscription');
        const result = await response.json();

        if (result.redirect) {
        // Si el backend devuelve una URL de redirección, realiza la redirección aquí
            window.location.href = result.redirect;
        } else {
        // Si no hay redirección, navega al dashboard
            navigate(redirect);
        }
    } catch (error) {
        console.error('Error al verificar la suscripción:', error);
    }
}

// https://stackoverflow.com/a/36566052
function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    var costs = [];
    for (var i = 0; i <= s1.length; i++) {
        var lastValue = i;
        for (var j = 0; j <= s2.length; j++) {
            if (i === 0) costs[j] = j;
            else {
                if (j > 0) {
                    var newValue = costs[j - 1];
                    if (s1.charAt(i - 1) !== s2.charAt(j - 1))
                        newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
                    costs[j - 1] = lastValue;
                    lastValue = newValue;
                }
            }
        }
        if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
}

export function similarity(s1, s2) {
    if (s1 && s2) {
        if (s1.toLowerCase().includes(s2.toLowerCase()) || s2.toLowerCase().includes(s1.toLowerCase())) {
            return 1;
        }

        var longer = s1;
        var shorter = s2;
        if (s1.length < s2.length) {
            longer = s2;
            shorter = s1;
        }
        var longerLength = longer.length;
        if (longerLength === 0) {
            return 1.0;
        }
        return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
    } else {
        return 0;
    }
}

export const getContactIcon = (finalName, contactsXeroOriginal, contactsXero, selectedContact, t, stringNewContact = "new") => {

    const exactMatchByName = contactsXeroOriginal.find(item => item.name === finalName);
    const similarityMatch = contactsXeroOriginal.find(item => similarity(item.name, finalName) >= 0.85);
    const isNewContact = contactsXero.find(item => item.value === selectedContact?.value && item[stringNewContact]);
    const isFirstContact = contactsXero.find(item => item.value === selectedContact?.value && item.value === "first");

    if ((exactMatchByName && selectedContact?.name === finalName)) {
        // Coincidencia perfecta por TaxID o comparación exacta de nombres
        return (
            <LightTooltip title={t("converter.tooltipSyncMatch")}>
                {<CheckIcon color={"success"} />}
            </LightTooltip>
        )
    } else if (similarityMatch && similarity(selectedContact?.name, finalName) >= 0.85 && !isFirstContact) {
        // Coincidencia válida pero no exacta
        return (
            <LightTooltip title={t("converter.tooltipSyncAlmostMatch")}>
                {<WarningIcon htmlColor="#ffb23f" />}
            </LightTooltip>
        )
    } else if (isNewContact || (contactsXeroOriginal.find((item) => item.value === selectedContact?.value) && !isFirstContact)) {
        // Contacto cambiado manualmente o editado, importado como nuevo
        return (
            <LightTooltip title={t("converter.tooltipNewContact")}>
                {<CheckIcon color={"primary"} />}
            </LightTooltip>
        )
    } else if (!selectedContact?.value) {
        // Sin coincidencia válida y contacto no seleccionado
        return (
            <LightTooltip title={t("converter.tooltipSyncNotMatch")}>
                {<WarningIcon color="primary" />}
            </LightTooltip>
        )
    }

    return (
        <LightTooltip title={t("converter.tooltipSyncNotMatch")}>
            {<WarningIcon color="primary" />}
        </LightTooltip>
    )
};
