import axios from "axios";

/* Script que contiene las llamadas a la API */
const reports = {
    convertReports: (params) => {
        const request = axios.post("/convertReports", params);

        return request.then((response) => response);
    },

    getReportConversions: (params) => {
        const request = axios.get("/getReportConversions", { params: params });

        return request.then((response) => response.data);
    },

    getReportDetails: (params) => {
        const request = axios.get("/getReportDetails", { params: params });

        return request.then((response) => response.data);
    },

    downloadSyncReport: (params) => {
        const request = axios.post("/downloadSyncReport", params);

        return request.then((response) => response.data);
    },

    syncReport: (params) => {
        const request = axios.post("/syncReport", params);

        return request.then((response) => response.data);
    },

    updateReports: (params) => {
        const request = axios.post("/updateReports", params);

        return request.then((response) => response);
    },

    addLineItemReport: (params) => {
        const request = axios.post("/addLineItemReport", params);

        return request.then((response) => response.data);
    },
};

export default reports;