/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import { Grid, Table, TableHeaderRow, PagingPanel, Toolbar, SearchPanel } from "@devexpress/dx-react-grid-material-ui";
import { SearchState, IntegratedFiltering, PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import { Box, FormControlLabel, Stack, Switch, Typography, Skeleton, Link, IconButton } from "@mui/material";
import LightTooltip from "../ui/LightTooltip";
import DashboardHeader from "./DashboardHeader";
import GridUI from "@mui/material/Grid";
import FiltersButton from "./FiltersButtonHistory";
import DashboardBreadcrumbs from "./DashboardBreadcrumbs";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch, useSelector } from "react-redux";
import { addIsGrouped } from "../../redux/userSlice";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Loading from "../../assets/loading.png";
import CloseIcon from "@mui/icons-material/Close";
import conversionFormats from "../Js/conversionFormats";
import DownloadIcon from "@mui/icons-material/Download";
import conversionService from "../../services/conversion";
import { useNavigate } from "react-router-dom";
import BackGeneral from "../layout/BackGeneral";
import ConversionPreview from "../converter/bankstatements/ConversionPreview";
import BaseSnackbar from "../ui/BaseSnackbar";
import ChatModal from "../ui/Chat";
import FormDialog from "../ui/FormDialog";
import DownloadFormatContent from "../converter/DownloadFormatContent";
import DownloadFormatButtons from "../converter/DownloadFormatButtons";
import accountsService from "../../services/accounts";
import caseService from "../../services/case";
import qboBanks from "../Js/quickbooksBIDs";

import InfoIcon2 from "@mui/icons-material/Info";

import { initiateSocket, newUserResponse, subscribeToChat } from "../../services/Socket";

import { read, utils } from "xlsx";

import { getFileExtension, zeroPad } from "../../utils/utils";
import BaseButton from "../ui/BaseButton";

const PREFIX = "Banking";

const classes = {
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
    tableHeaderBand: `${PREFIX}-tableHeaderBand`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.tableHeaderBand}`]: {
        backgroundColor: "transparent",
        color: "#4a22d4",
        fontSize: "14px",
        fontWeight: 600,
        border: "none !important",
        textAlign: "center",
        paddingBottom: 0,
        [theme.breakpoints.up("md")]: {
            whiteSpace: "pre-wrap",
        },
    },

    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "#f8f8f9",
        fontSize: "15px",
        fontWeight: 600,
        color: "#131F3E !important",
        textAlign: "center !important",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
            paddingRight: "24px",
        },
    },

    [`& .${classes.tableHeader} div`]: {
        justifyContent: "center !important",
    },

    [`& .${classes.tableBody}`]: {
        backgroundColor: "#FFF",
        borderBottom: "5px solid #f8f8f9",
        fontSize: "14px",
        fontWeight: 400,
        color: "#131f3e",
        "&:first-of-type": {
            borderRadius: "10px 0 0 10px",
        },
        "&:last-child": {
            borderRadius: "0 10px 10px 0",
        },
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#361A52",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "#D8D3E5",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
        width: "100%",
    },

    [`& .${classes.searchInput}`]: {
        color: "#131F3E",
        backgroundColor: "#f2f2f2",
        borderRadius: 8,
        borderBottom: "none",
        paddingTop: "11px",
        paddingBottom: "11px",
        paddingLeft: "20px",
        paddingRight: "40px",
        marginBottom: "45px",
        "&:before": {
            borderBottom: "none",
            color: "#131F3E",
        },
        "&:hover:before": {
            borderBottom: "none",
            color: "#131F3E",
        },
        width: "100%",
    },
}));

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        number: <Skeleton variant="text" animation="wave" />,
        subscription: <Skeleton variant="text" animation="wave" />,
        company: <Skeleton variant="text" animation="wave" />,
        account: <Skeleton variant="text" animation="wave" />,
        uploadDate: <Skeleton variant="text" animation="wave" />,
        bankStatement: <Skeleton variant="text" animation="wave" />,
        startDate: <Skeleton variant="text" animation="wave" />,
        endDate: <Skeleton variant="text" animation="wave" />,
        user: <Skeleton variant="text" animation="wave" />,
        balance: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        chat: <Skeleton variant="text" animation="wave" />,
    });
}

const HistoryConv = (props) => {
    const {
        integration,
        grupal,
        setXeroView,
        xeroView,
        franchise,
        company,
        data,
        type,
        setDashboardInfoXero,
        setDashboardInfo,
        franchiseInfo,
        companyInfo,
        checkGrouped,
        showSkeleton,
        setShowSkeleton,
        history,
        setHistory,
        setConversions,
        setIsLoading,
        usersFilters,
        setAccounts,
        accounts,
    } = props;

    const [t, i18n] = useTranslation("global");

    const finalQBOBanks = qboBanks.concat({
        value: "03000",
        value2: "03000",
        value3: "03000",
        value4: "03000",
        name: `${t("converter.defaultBank")} (03000)`,
    });

    const [searchValue, setSearchState] = useState("");
    const [pageSize, setPageSize] = useState(15);
    const [pageSizes] = useState([15, 30, 50]);
    const [currentPage, setCurrentPage] = useState(0);
    const [showPrev, setShowPrev] = useState(false);

    const dispatch = useDispatch();
    const todos = useSelector((state) => state.value);
    const [rows, setRows] = useState(rowsSkeleton);
    const [language, setLanguage] = useState(sessionStorage.getItem("lng"));
    const [account, setAccount] = useState("");
    const [accountDet, setAccountDet] = useState({});
    const [conversionId, setConversionId] = useState(null);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [openModalDownloadF, setOpenModalDownloadF] = useState(false);
    const [accountType, setAccountType] = useState("");
    const [accountNum, setAccountNum] = useState("");
    const [bankInst, setBankInst] = useState(null);
    const [correlative, setCorrelative] = useState(null);
    const [fileNameFull, setFileNameFull] = useState("");
    const [formatConv, setFormatConv] = useState("");
    const [caseInfo, setCaseInfo] = useState({});
    const [room, setRoom] = useState(null);
    const [open, setOpen] = useState(false);
    const [chat, setChat] = useState([]);
    const [fileData, setFileData] = useState(null);
    const [__html, setHTML] = useState("");
    const [conversionData, setConversionData] = useState({});
    const [checkedInvSigngs, setCheckedInvSigngs] = useState(false);
    const [checkedInvDay, setCheckedInvDay] = useState(false);
    const [checkedDesRef, setCheckedDesRef] = useState(false);
    const [fileNameSaved, setFileNameSaved] = useState("");
    const [statusCase, setStatusCase] = useState();
    const [users, setUsers] = useState([]);
    const [downloadFormat, setDownloadFormat] = useState("");
    const [qbFormat, setQbFormat] = useState(false)

    const handleCloseDialog = () => {
        setOpenModalDownloadF(false);
        setDownloadFormat("")
        setAccountNum("")
        setAccountType("")
        setBankInst(null)
        setQbFormat(false)
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const connectSocket = async (case_id) => {
        setRoom(case_id);

        let params1 = {
            case_id: case_id,
        };

        await caseService
            .getCaseInfo(params1)
            .then(async (data) => {
                setCaseInfo(data.caseInfo);

                initiateSocket(case_id, todos.userInfo, "general", "");
                subscribeToChat((err, data) => {
                    if (err) return;
                    setChat((oldChats) => [...oldChats, data]);
                });
                newUserResponse((err, data) => {
                    if (err) return;
                    setUsers(data);
                });
                setOpen(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const downloadConversionFunc = async (params) => {
        await conversionService
            .downloadConversion(params)
            .then(async (response) => {
                // Crea un enlace temporal y haz clic en él para iniciar la descarga
                const link = document.createElement("a");
                link.href = response.url;
                document.body.appendChild(link);
                link.click();

                // Limpia el enlace temporal
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const navigate = useNavigate();

    let columns = [
        //{ name: "id", title: "ID" },
        { name: "number", title: t("converter.convertNumber") },
        { name: "company", title: t("dashboard.company") },
        { name: "account", title: t("converter.account") },
        { name: "uploadDate", title: t("converter.uploadDate") },
        { name: "bankStatement", title: t("converter.bankStatement") },
        { name: "startDate", title: t("converter.startDate") },
        { name: "endDate", title: t("converter.endDate") },
        { name: "user", title: t("converter.user") },
        { name: "balance", title: t("converter.balance") },
        { name: "format", title: t("converter.format") },
        { name: "status", title: t("converter.status") },
        { name: "chat", title: <></> },
    ];

    let columnsGrupal = [
        //{ name: "id", title: "ID" },
        { name: "number", title: t("converter.convertNumber") },
        { name: "subscription", title: t("navBar.subscription") },
        { name: "company", title: t("dashboard.company") },
        { name: "account", title: t("converter.account") },
        { name: "uploadDate", title: t("converter.uploadDate") },
        { name: "bankStatement", title: t("converter.bankStatement") },
        { name: "startDate", title: t("converter.startDate") },
        { name: "endDate", title: t("converter.endDate") },
        { name: "user", title: t("converter.user") },
        { name: "balance", title: t("converter.balance") },
        { name: "format", title: t("converter.format") },
        { name: "status", title: t("converter.status") },
        { name: "chat", title: <></> },
    ];

    columns = grupal ? columnsGrupal : columns;

    const tableColumnExtensions = [
        { columnName: "number", width: "10%", align: "center" },
        { columnName: "subscription", width: "10%", align: "left" },
        { columnName: "company", width: "20%", align: "left" },
        { columnName: "account", width: "15%", align: "left" },
        { columnName: "uploadDate", width: "10%", align: "center" },
        { columnName: "bankStatement", width: "10%", align: "left" },
        { columnName: "startDate", width: "7%", align: "center" },
        { columnName: "endDate", width: "7%", align: "center" },
        { columnName: "user", width: "7%", align: "left" },
        { columnName: "balance", width: "7%", align: "right" },
        { columnName: "format", width: "7%", align: "left" },
        { columnName: "status", width: "8%", align: "center" },
        { columnName: "chat", width: "5%", align: "center" },
    ];

    const handleOpenDownloadModal = async (event) => {
        setOpenModalDownloadF(true);
    }

    const handleClickDownload = async (event) => {
        setIsLoading(true);

        if (event.target.value === 16 || event.target.value === 15) {
            if (conversionData.account_number_qb && conversionData.account_type_qb && conversionData.account_bid_qb) {
                setAccountNum(conversionData.account_number_qb);
                setAccountType(conversionData.account_type_qb);
                setBankInst(finalQBOBanks.find((item) => item.value === conversionData.account_bid_qb) ?? null);
            } else {
                await accountsService
                    .getAccountDetails(account)
                    .then((response) => {
                        setAccountNum(response.account_number_qb ?? "");
                        setAccountType(response.account_type_qb ?? "");
                        setBankInst(finalQBOBanks.find((item) => item.value === response.account_bid_qb) ?? null);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }

            setQbFormat(true)
        } else {
            setAccountNum("");
            setAccountType("");
            setBankInst(null);
            setQbFormat(false)
        }
        setDownloadFormat(event.target.value);
        setIsLoading(false);
    };

    // Funcion para descargar el estado de cuenta QB Desktop
    const downloadQBO = async () => {
        setIsLoading(true);

        const params1 = {
            conversion_id: conversionId,
        };

        await conversionService
            .getConversionDetails(params1)
            .then(async (data) => {
                const formatFile = ".qbo";

                const params = {
                    file_name: `Conv_${fileNameSaved}${formatFile}`,
                    type: downloadFormat.toString(),
                    account_type_qb: accountType,
                    account_number_qb: accountNum.trim(),
                    account_bid_qb: bankInst.value,
                    conversion_id: conversionId,
                    signs: checkedInvSigngs ? -1 : 1,
                    date: checkedInvDay ? "DD/MM/YYYY" : "MM/DD/YYYY",
                    reverse: checkedDesRef,
                };

                await downloadConversionFunc(params);
            })
            .catch((err) => {
                console.log(err);
            });

        //handleCloseDialog();
        setIsLoading(false);
        //setShowPrev(false);
        setFileData(null);
    };

    // Funcion para descargar el estado de cuenta
    const downloadFormats = async () => {
        setIsLoading(true);

        let formatFile = downloadFormat === 1 ? ".xml" : downloadFormat === 4 ? ".xlsx" : ".csv";
        let params = {
            conversion_id: conversionId,
            file_name: `Conv_${fileNameSaved}${formatFile}`,
            type: downloadFormat.toString(),
            signs: checkedInvSigngs ? -1 : 1,
            date: checkedInvDay ? "DD/MM/YYYY" : "MM/DD/YYYY",
            reverse: checkedDesRef,
        };

        await downloadConversionFunc(params);

        if (accountDet.system_integration === 1 && accountDet.account_id_system && [13, 14].includes(formatConv)) {
            await conversionService
                .getImportUrl(params)
                .then((response) => {
                    window.open(response, "_blank");
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        setIsLoading(false);
    };


    useEffect(() => {
        const downloadFile = async (id, file_name) => {
            let params = {
                conversion_id: id,
            };
            setIsLoading(true);
            await conversionService
                .getFileUrlS3(params)
                .then((response) => {
                    // Crea un enlace temporal y haz clic en él para iniciar la descarga
                    const link = document.createElement("a");
                    link.href = response.url;
                    document.body.appendChild(link);
                    link.click();

                    // Limpia el enlace temporal
                    document.body.removeChild(link);
                })
                .catch((err) => {
                    console.log(err);
                });
            setIsLoading(false);
        };

        const handleShowPrev = async (convID, Downloadformat, AccID, fileName, correlative) => {
            let params = {
                conversion_id: convID,
            };
            setIsLoading(true);

            await accountsService
                .getAccountDetails(AccID)
                .then((response) => {
                    setAccountNum(response.account_number_qb ?? "");
                    setAccountType(response.account_type_qb ?? "");
                    setBankInst(finalQBOBanks.find((item) => item.value === response.account_bid_qb) ?? null);
                    setAccountDet(response);
                })
                .catch((err) => {
                    console.log(err);
                });

            await conversionService
                .getTempConversionDetails({ conversion_id: convID })
                .then(async (response) => setConversionData(response))
                .catch((err) => console.log(err));

            await conversionService
                .getFileS3(params)
                .then(async (response) => {
                    setFileData(response.data);
                    setFormatConv(Downloadformat);
                    setAccount(AccID);
                    setConversionId(convID);
                    setFileNameFull(fileName);
                    setCorrelative(correlative);
                    setFileNameSaved(fileName.replace(/\.[^/.]+$/, ""));

                    if (getFileExtension(fileName) === "xlsx") {
                        const f = await response.data.arrayBuffer();

                        /* parse workbook */
                        const workbook = read(f, { type: "binary" });
                        /* get first worksheet */
                        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                        /* generate and display HTML */
                        const table = utils.sheet_to_html(worksheet);
                        setHTML(table);
                    }
                })
                .catch((err) => {
                    setTexto(t("converter.dnldError"));
                    setAlertType("error");
                    setShow(true);
                    console.log(err);
                });

            setShowPrev(true);
            setIsLoading(false);
        };

        if (showSkeleton) {
            setRows(rowsSkeleton);
        } else {
            setRows(
                data
                    ? data.map((item) => ({
                          id: item._id,
                          number:
                              item.id_status === 6
                                  ? ""
                                  : item.correlative != null && item.correlative !== undefined
                                  ? "#" + zeroPad(item.correlative, 5)
                                  : "",
                          subscription: item.franchise_name,
                          company: item.account_id?.company_id?.company_name ?? "",
                          account: item.account_id.account_name,
                          uploadDate: moment.utc(item.current_date).format(todos.dateFormat),
                          // bankStatement: item.uploaded_file,
                          bankStatement: item.uploaded_file_s3 ? (
                              <LightTooltip title={item.uploaded_file} aria-label="lastTrans">
                                  <Link
                                      onClick={() => downloadFile(item._id, item.uploaded_file)}
                                      target="_blank"
                                      sx={{ cursor: "pointer", fontWeight: 600, textDecoration: "none" }}
                                  >
                                      {item.uploaded_file}
                                  </Link>
                              </LightTooltip>
                          ) : (
                              item.uploaded_file
                          ),
                          format:
                              item.download_format && item.download_format !== null
                                  ? conversionFormats
                                        .find((item2) => item2.id === item.download_format)
                                        ?.name.includes("Xero")
                                      ? conversionFormats
                                            .find((item2) => item2.id === item.download_format)
                                            ?.name.split(" - ")[0]
                                      : language === "es"
                                      ? conversionFormats.find((item2) => item2.id === item.download_format)?.name
                                      : conversionFormats.find((item2) => item2.id === item.download_format)?.name_en ??
                                        ""
                                  : "",
                          startDate:
                              item.initial_date === null
                                  ? "N/A"
                                  : item.initial_date
                                  ? moment.utc(item.initial_date).format(todos.dateFormat)
                                  : "",
                          endDate:
                              item.end_date === null
                                  ? "N/A"
                                  : item.end_date
                                  ? moment.utc(item.end_date).format(todos.dateFormat)
                                  : "",
                          user: item.user_name,
                          balance:
                              item.id_status === 6
                                  ? ""
                                  : item.balance === null
                                  ? ""
                                  : item.balance || parseFloat(item.balance) === 0
                                  ? parseFloat(item.balance).toLocaleString(todos.amountFormat, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })
                                  : "",
                          status: (
                              <Box alignItems="center" display="flex" justifyContent="left">
                                    {item.id_status === 1 && <img src={Loading} alt="loading" width={20} />}
                                    {item.id_status === 2 && (
                                        <DownloadIcon sx={{ color: "#361A52" }} fontSize="inherit" />
                                    )}
                                    {item.id_status === 3 && <CheckIcon sx={{ color: "#00B147" }} fontSize="inherit" />}
                                    {item.id_status === 4 && (
                                        <AccessTimeIcon sx={{ color: "#FFB23F" }} fontSize="inherit" />
                                    )}
                                    {(item.id_status === 5) && (
                                        <CloseIcon sx={{ color: "#DA0069" }} fontSize="inherit" />
                                    )}
                                  &nbsp;
                                  <Typography>
                                      {item.id_status === 1
                                          ? t("converter.inProc")
                                          : item.id_status === 2
                                          ? t("converter.convertible")
                                          : item.id_status === 3
                                          ? t("converter.successful")
                                          : item.id_status === 4
                                          ? t("converter.inReview")
                                          : t("converter.invalid")}
                                  </Typography>
                              </Box>
                          ),
                          chat: item.id_status === 1 && (
                              <IconButton
                                  onClick={() =>
                                      handleShowPrev(
                                          item._id,
                                          item.download_format,
                                          item.account_id._id,
                                          item.uploaded_file,
                                          item.correlative
                                      )
                                  }
                                  size="small"
                                  color="primary"
                              >
                                  <DownloadIcon fontSize="small" />
                              </IconButton>
                          ),
                      }))
                    : []
            );
        }
    }, [
        data,
        navigate,
        setIsLoading,
        showSkeleton,
        t,
        todos.dateFormat,
        language,
        grupal,
        todos.amountFormat,
        finalQBOBanks,
    ]);

    useEffect(() => {
        if (i18n.language) {
            setLanguage(i18n.language);
        }
    }, [i18n.language]);

    const tableContentComponent = ({ column, children, ...props }) => (
        <TableHeaderRow.Content {...props}>
            {children}
            {column.name === "lastTrans" || column.name === "lastPeriod" || column.name === "latestBalance" ? (
                <LightTooltip title="" aria-label="lastTrans">
                    <InfoIcon fontSize="inherit" sx={{ ml: 1, color: "#361A52" }} />
                </LightTooltip>
            ) : null}
        </TableHeaderRow.Content>
    );

    const tableMessages = {
        noData: <Typography fontSize={14}>{t("dashboard.noInfo")}</Typography>,
    };

    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("dashboard.pagingOf")} ${count}`,
        rowsPerPage: t("dashboard.rowsPerPage"),
    };

    const searchMessages = {
        searchPlaceholder: t("dashboard.search"),
    };

    const Root = React.useCallback(
        (props) => {
            const handleDashboardSwitch = () => {
                dispatch(addIsGrouped(!todos.isGrouped));
            };

            const downloadReport = async () => {
                setIsLoading(true);

                let fileName = `StatementsHistory-${moment().format("MMDDYYYY")}`;

                await conversionService
                    .generateConversionsReport({
                        franchise_id: franchise ?? undefined,
                        origin: "dashboard",
                    })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName + ".csv");
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                setIsLoading(false);
            };

            return (
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    justifyContent={{ xs: "center", md: "space-between" }}
                    spacing={2}
                >
                    <Box>
                        <DashboardHeader
                            type={type}
                            integration={integration}
                            setXeroView={setXeroView}
                            xeroView={xeroView}
                            franchise={franchise}
                            company={company}
                            setDashboardInfoXero={setDashboardInfoXero}
                            setShowSkeleton={setShowSkeleton}
                            setDashboardInfo={setDashboardInfo}
                            grupal={grupal}
                            history={history}
                            setHistory={setHistory}
                            setConversions={setConversions}
                            setAccounts={setAccounts}
                        />
                    </Box>
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        justifyContent={{ xs: "center", md: "end" }}
                        alignItems={{ xs: "center", sm: "start" }}
                        spacing={{ xs: 2, sm: "25px" }}
                    >
                        {todos?.allComData?.filter((x) => x.company_name).length > 1 && (
                            <FormControlLabel
                                labelPlacement="start"
                                control={<Switch checked={checkGrouped} onChange={handleDashboardSwitch} />}
                                label={
                                    <Typography variant="h3" noWrap>
                                        {t("navBar.grupalDashboard")}
                                    </Typography>
                                }
                            />
                        )}
                        {type === "Firm" && !checkGrouped && (
                            <Box sx={{ width: { xs: "100%", sm: "160px" } }}>
                                <BaseButton
                                    fullWidth
                                    onClick={downloadReport}
                                    disableElevation
                                    id="dashboard-tutorial"
                                    endIcon={<SimCardDownloadIcon fontSize="inherit" />}
                                >
                                    {t("converter.export")}
                                </BaseButton>
                            </Box>
                        )}
                        <Box sx={{ width: { xs: "100%", sm: "160px" } }}>
                            <FiltersButton
                                accounts={accounts}
                                users={usersFilters}
                                company={company}
                                setConversions={setConversions}
                                setShowSkeleton={setShowSkeleton}
                                franchise={franchise}
                                grupal={grupal}
                                todos={todos}
                            />
                        </Box>
                        <Box sx={{ width: { xs: "100%", sm: "auto" } }}>
                            <Toolbar.Root {...props} className={classes.toolbarSearch} />
                        </Box>
                    </Stack>
                </Stack>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            accounts,
            checkGrouped,
            company,
            dispatch,
            franchise,
            grupal,
            history,
            integration,
            setAccounts,
            setConversions,
            setDashboardInfo,
            setDashboardInfoXero,
            setHistory,
            setShowSkeleton,
            setXeroView,
            t,
            type,
            usersFilters,
            xeroView,
            todos,
            language,
            setIsLoading,
        ]
    );

    const handleClickHelp = async (type) => {
        setIsLoading(true);

        let params = {
            company_id: todos.companyID,
            franchise_id: todos.franchiseID,
            user_email: todos.userInfo.email,
            type: type,
            conversion_id: conversionId,
            conversion_type: "bankStatement"
        };

        let caseInfo = null;

        if (type !== "generalConv") {
            await caseService
                .getCaseInfo(params)
                .then(async (response) => {
                    if (response) {
                        setCaseInfo(response.caseInfo);
                        caseInfo = response.caseInfo;
                        setStatusCase("");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        if (!caseInfo) {
            await caseService
                .createNewCase(params)
                .then(async (response) => {
                    setCaseInfo(response);
                    caseInfo = response;
                    setStatusCase(1);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        await connectSocket(caseInfo._id);

        setIsLoading(false);
    };

    const goToDash = () => {
        setShowPrev(false);
    };

    if (showPrev) {
        return (
            <BackGeneral colorBg="#F7F8FA">
                <ConversionPreview
                    handleClickDownload={handleOpenDownloadModal}
                    account={account}
                    accounts={accounts}
                    correlative={correlative}
                    handleClickHelp={handleClickHelp}
                    fileData={fileData}
                    __html={__html}
                    conversionData={conversionData}
                    checkedInvSigngs={checkedInvSigngs}
                    setCheckedInvSigngs={setCheckedInvSigngs}
                    checkedInvDay={checkedInvDay}
                    setCheckedInvDay={setCheckedInvDay}
                    fileNameFull={fileNameFull}
                    checkedDesRef={checkedDesRef}
                    setCheckedDesRef={setCheckedDesRef}
                    format={formatConv}
                    removeBanner={goToDash}
                    isHistory={true}
                />
                <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleCloseSnackBar} />
                {open && <ChatModal
                    room={room}
                    setRoom={setRoom}
                    open={open}
                    setOpen={setOpen}
                    setChat={setChat}
                    chat={chat}
                    type={statusCase}
                    users={users}
                    franchiseName={
                        todos.allComData?.find(
                            (franchise) => franchise.franchise_name && franchise._id === todos.franchiseID
                        )?.franchise_name
                    }
                    setCaseInfo={setCaseInfo}
                    caseInfo={caseInfo}
                />}
                <FormDialog
                    open={openModalDownloadF}
                    handleClose={handleCloseDialog}
                    maxWidth="sm"
                    title={
                        <Stack direction="row" justifyContent="center" spacing={1.5} alignItems="center">
                            <InfoIcon2 fontSize="small" sx={{ color: "#361A52" }} />
                            <Typography fontSize={24} color="primary" fontWeight={600} textAlign="center">{t("converter.selectDownloadFormat")}</Typography>
                        </Stack>
                    }
                    content={
                        <DownloadFormatContent
                            setBankInst={setBankInst}
                            setAccountNum={setAccountNum}
                            setAccountType={setAccountType}
                            accountNum={accountNum}
                            accountType={accountType}
                            finalQBOBanks={finalQBOBanks}
                            bankInst={bankInst}
                            downloadFormat={downloadFormat}
                            handleChangeDownloadFormat={handleClickDownload}
                            qbFormat={qbFormat}
                        />
                    }
                    actions={
                        <DownloadFormatButtons
                            handleCloseDialog={handleCloseDialog}
                            downloadQBO={downloadFormat === 15 || downloadFormat === 16 ? () => downloadQBO() : () => downloadFormats()}
                            accountNum={accountNum}
                            accountType={accountType}
                            bankInst={bankInst}
                            downloadFormat={downloadFormat}
                        />
                    }
                    align="center"
                />
            </BackGeneral>
        );
    }

    return (
        <>
            {franchise && companyInfo && !grupal && (
                <Box sx={{ backgroundColor: "#fff", px: { xs: 2, md: 5 }, mt: 5 }}>
                    <DashboardBreadcrumbs company={companyInfo} franchise={franchiseInfo} isGrouped={grupal} />
                </Box>
            )}
            <StyledContainer
                component="main"
                sx={{
                    flexGrow: 1,
                    px: { xs: 2, md: 8 },
                    py: franchise && companyInfo && !grupal ? 2 : 6,
                    width: "100%",
                }}
            >
                <GridUI container justifyContent="space-between" spacing={2}>
                    <GridUI item xs={12}>
                        <Grid rows={rows} columns={columns}>
                            <SearchState value={searchValue} onValueChange={handleSearch} />
                            <IntegratedFiltering />
                            {rows.length > 0 && (
                                <PagingState
                                    currentPage={currentPage}
                                    onCurrentPageChange={setCurrentPage}
                                    pageSize={pageSize}
                                    onPageSizeChange={setPageSize}
                                />
                            )}
                            {rows.length > 0 && <IntegratedPaging />}
                            <Table
                                columnExtensions={tableColumnExtensions}
                                cellComponent={TableCellComponent}
                                messages={tableMessages}
                            />
                            <TableHeaderRow cellComponent={tableHeaderCell} contentComponent={tableContentComponent} />
                            <Toolbar rootComponent={Root} />
                            <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                            {rows.length > 0 && (
                                <PagingPanel
                                    containerComponent={PagingPanelContainer}
                                    messages={pagingMessages}
                                    pageSizes={pageSizes}
                                />
                            )}
                        </Grid>
                    </GridUI>
                </GridUI>
            </StyledContainer>
        </>
    );
};

export default HistoryConv;
