import { Box, Button, CircularProgress, Grid, IconButton, Link, Stack, Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import TuneIcon from "@mui/icons-material/Tune";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckIcon from "@mui/icons-material/Check";
import CachedIcon from "@mui/icons-material/Cached";
import CloseIcon from "@mui/icons-material/Close";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import ErrorIconConverter from "../../../assets/error-icon-converter.svg";
import BaseSelect from "../../ui/BaseSelect";
import LightTooltip from "../../ui/LightTooltip";
import classes from "../MainConverter.module.css";
import accountsService from "../../../services/accounts";
import { useNavigate } from "react-router-dom";
import { fetchFileFromS3, getCompanyCredits, zeroPad } from "../../../utils/utils";
import BaseLinearProgress from "../../ui/BaseLinearProgress";
import AddCreditPacksDialog from "../../credits/AddCreditPacksDialog";
import { addCredits } from "../../../redux/userSlice";

import subscriptionService from "../../../services/subscription";
import paymentMethodsService from "../../../services/payment_methods";
import reportService from "../../../services/reports";
import AddPaymentDialog from "../../payments/AddPaymentDialog";
import ChangePayDialog from "../../subscription/shoppingCart/ChangePayDialog";
import UploadFileInc from "./UploadFileInc";
import BaseButton from "../../ui/BaseButton";

const incFormats = [
    { id: 0, name: "pdf" },
    { id: 1, name: "jpg" },
    { id: 3, name: "png" },
];

const IncomeConverter = (props) => {
    const {
        handleClickHelp,
        accounts,
        account,
        setAccount,
        setIsLoading,
        setAccountType,
        setAccountNum,
        setFormats,
        setEndPoint,
        setAccountDet,
        setTexto,
        setAlertType,
        setShow,
        setFileData,
        setActivePlan,
        activePlan,
        companyDetails,
        allCompanies,
        paymentType,
        setConversionData,
        setFileNameFull,
        setConversionId,
        setBasePreviewData,
        setOpenPreview,
        setPreviewInc,
        setIsReload
    } = props;

    const dispatch = useDispatch();
    const todos = useSelector((state) => state.value);
    const [t] = useTranslation("global");

    const [status, setStatus] = useState(0); //0: Sin iniciar, 1: Exitoso, 2: Revision, 3: Error
    const [progress, setProgress] = useState(0);
    const [acceptedFiles, setAcceptedFiles] = useState([]);
    const [disabledInput, setDisabledInput] = useState(false);
    const [fileUpInfo, setFileUpInfo] = useState(null);
    const [isLoadingConv, setIsLoadingConv] = useState(false);
    const [showModalCredits, setShowModalCredits] = useState(false);
    const [showAddPay, setShowAddPay] = useState(false);
    const [showChangePay, setShowChangePay] = useState(false); // Modal para cambiar tarjeta
    const [defaultPayMethod, setDefaultPayMethod] = useState({});
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [timer, setTimer] = useState(0);

    const isDisabled = account === "" || acceptedFiles.length === 0;

    const navigate = useNavigate();

    useEffect(() => {
        let timeout;

        if (isLoadingConv) {
            let finalTime = 7;
            let ticker = 0;
            ticker = 100 / 7;

            if (timer < finalTime) {
                timeout = setTimeout(() => {
                    setProgress((prev) => prev + ticker);
                    setTimer((prev) => prev + 1);
                }, 1000);
            }
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [isLoadingConv, setProgress, timer]);

    const reloadInc = () => {
        setIsReload((prev) => !prev);
    }

    const handleClose = () => setShowModalCredits(false);

    const handleSetShowModalCredits = () => setShowModalCredits(true);

    const handleCloseModal = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowAddPay(false);
            setShowChangePay(true);
            getPaymentMethods(true);
        }
    };

    const handleCloseModal2 = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowAddPay(false);
            setShowChangePay(false);
            getPaymentMethods(true);
            setShowModalCredits(true);
        }
    };

    const handleAddPaymentM = () => {
        setShowAddPay(true);
        setShowChangePay(false);
    };

    const handleCloseChangePay = () => {
        setShowChangePay(false);
        setShowModalCredits(true);
    };

    const resetConversion = () => {
        setProgress(0);
        setFileData(null);
        setAcceptedFiles([]);
        setStatus(0);
        setConversionId(null);
        setDisabledInput(false);
    };

    const getPaymentMethods = async (reload) => {
        setIsLoading(true);

        const params = {
            franchise_id: todos.franchiseID,
            companyID: todos.companyID,
        };

        try {
            if (todos.companyID || todos.franchiseID) {
                const result = await paymentMethodsService.getPaymentMethods(params);
                const defaultPaymentMethod = result.find((card) => card.default_method);
                setDefaultPayMethod(defaultPaymentMethod);
                setPaymentMethods(result);
            }
        } catch (error) {
            console.log(error);
        }

        if (!reload) {
            await subscriptionService
                .getSubscription({
                    company_id: todos.companyID,
                })
                .then((response) => {
                    if (response) {
                        setActivePlan(response.subscription_plan);
                    } else {
                        setActivePlan(null);
                    }
                })
                .catch((err) => console.log(err));
        }

        setIsLoading(false);
    };

    const previewDoc = async (e) => {
        e.stopPropagation();

        setIsLoading(true);
        let dataFile = await fetchFileFromS3(fileUpInfo.filenameS3);
        setIsLoading(false);

        if (!dataFile) {
            setTexto(t("converter.dnldError"));
            setAlertType("error");
            setShow(true);
        } else {
            setFileData(dataFile);
        }

        setFileNameFull(fileUpInfo.filenameS3);

        setBasePreviewData({
            type: "report",
            file_s3: fileUpInfo.filenameS3,
            file: acceptedFiles[0].file_name,
        });
        // Open modal
        setOpenPreview(true);
    };

    const handleChange = async (event) => {
        setIsLoading(true);

        setAccount(event.target.value);
        await accountsService
            .getAccountDetails(event.target.value)
            .then((response) => {
                setAccountNum(response.account_number_qb ?? "");
                setAccountType(response.account_type_qb ?? "");
                setEndPoint(response.bank.endpoint);
                setFormats(incFormats);
                setAccountDet(response);
            })
            .catch((err) => {
                console.log(err);
            });

        resetConversion();
        setIsLoading(false);
    };

    const handleNavigation = () => {
        navigate("/accounts");
    };

    const handleShowPrevDownload = async () => {
        setIsLoadingConv(true);
        setDisabledInput(true);

        let statusConv = 0;

        const params = {
            accountId: account,
            data: [
                {
                    file_name_s3: fileUpInfo.filenameS3,
                    file_name: fileUpInfo.filenameOG,
                },
            ],
        };

        let isReview = false;

        await reportService
            .convertReports(params)
            .then(async (response) => {
                switch (response.data.status) {
                    case "review":
                        setStatus(2);
                        statusConv = 2;
                        isReview = true;
                        break;
                    default:
                        setStatus(1);
                        statusConv = 1;
                        const finalData = response.data.reports;
                        setConversionData(finalData);

                        let paramsGetFile = "";

                        setConversionId(finalData._id);
                        paramsGetFile = finalData.file_name;
                        setFileNameFull(finalData.uploaded_file);

                        const data = await fetchFileFromS3(paramsGetFile);

                        if (!data) {
                            setTexto(t("converter.dnldError"));
                            setAlertType("error");
                            setShow(true);
                        } else {
                            setBasePreviewData({
                                type: "report",
                                file_s3: finalData.file_name,
                                file: finalData.uploaded_file,
                            });
                            setFileData(data);
                        }
                        break;
                }
            })
            .catch((err) => {
                console.log(err);
                setStatus(3);
                statusConv = 3;
            });

        if (!isReview) {
            setDisabledInput(false);
        }

        setProgress(0);
        setTimer(0);
        setIsLoadingConv(false);

        if (statusConv === 1) {
            const credits = await getCompanyCredits(todos.companyID);
            dispatch(addCredits(credits));

            setPreviewInc(true);
            setAcceptedFiles([]);
            setFileUpInfo({});
        } else if (statusConv === 2) {
            reloadInc();
        }
    };

    return (
        <>
            <Box
                className={
                    status === 2
                        ? classes.backYellowConv
                        : status === 1
                        ? classes.backGreenConv
                        : status === 3
                        ? classes.backRed
                        : classes.backPurpleInc
                }
                mt={2}
                mx={4}
                id="account4-tutorial"
            >
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="h3" sx={{ mb: 1.5 }}>
                            {t("incomes.importAma")}
                        </Typography>
                        <LightTooltip title={t("miscellaneous.help")}>
                            <IconButton>
                                <HelpIcon fontSize="small" color="primary" />
                            </IconButton>
                        </LightTooltip>
                    </Stack>
                    <BaseButton variantType="errorText" onClick={() => handleClickHelp("generalConv", "", "")}>{t("accounts.needHelp")}</BaseButton>
                </Stack>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={2.5}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <BaseSelect
                                value={account}
                                values={accounts}
                                onChange={handleChange}
                                label={t("converter.selectBank")}
                                color="white"
                                type="bank"
                                disabled={disabledInput}
                                hasTooltip={true}
                            />
                            <LightTooltip title={t("converter.goManageAcc")}>
                                <IconButton size="small" color="primary" onClick={handleNavigation}>
                                    <TuneIcon sx={{ transform: "rotate(90deg)" }} fontSize="small" />
                                </IconButton>
                            </LightTooltip>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4.5}>
                        <UploadFileInc
                            acceptedFiles={acceptedFiles}
                            setAcceptedFiles={setAcceptedFiles}
                            formats={incFormats}
                            disabled={disabledInput || !account}
                            setTexto={setTexto}
                            setAlertType={setAlertType}
                            setShow={setShow}
                            setFileUpInfo={setFileUpInfo}
                            accountId={account}
                            setProgress={setProgress}
                            progress={progress}
                            setStatus={setStatus}
                            type="report"
                            isLoadingConv={isLoadingConv}
                            previewDoc={previewDoc}
                            fileUpInfo={fileUpInfo}
                        />
                    </Grid>
                    <Grid item xs={12} md={3.5}>
                        {(status === 1 || status === 2) && (
                            <Stack spacing={1} alignItems="flex-start">
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography variant="h3" sx={{ color: status === 2 ? "#FFB23F" : "#34bd6a" }}>
                                        {"#" + zeroPad(fileUpInfo.correlative, 5)}
                                    </Typography>
                                    <Typography variant="h3">
                                        {status === 2 ? t("converter.conversionRev") : t("converter.readyConv")}
                                    </Typography>
                                    {status === 2 ? (
                                        <AccessTimeIcon sx={{ color: "#FFB23F" }} fontSize="small" />
                                    ) : (
                                        <CheckIcon sx={{ color: "#00943b" }} fontSize="small" />
                                    )}
                                </Stack>
                                <Box className={classes.backYellow}>
                                    {status === 2 ? (
                                        <Typography variant="h3">{t("converter.convRev")}</Typography>
                                    ) : (
                                        <Typography>
                                            <b>{t("converter.price")}</b>:{" "}
                                            {`${fileUpInfo.price} ${t("converter.credits")}`}
                                        </Typography>
                                    )}
                                </Box>
                                {status === 2 && (
                                    <Typography variant="h3" fontWeight={500}>
                                        {t("converter.conversionRevMsg2")}
                                    </Typography>
                                )}
                                {status === 1 && todos.credits - fileUpInfo.price > 0 && (
                                    <Typography className={classes.creditsText}>
                                        <b>{t("converter.rest")}:</b>{" "}
                                        <span
                                            style={{
                                                display: "inline",
                                                color: todos.credits - fileUpInfo.price < 0 ? "#FF5E77" : "auto",
                                            }}
                                        >
                                            {todos.credits - fileUpInfo.price}
                                        </span>{" "}
                                        {t("register.conversions")}
                                    </Typography>
                                )}
                            </Stack>
                        )}
                        {status === 3 && (
                            <Grid container spacing={1} justifyContent="center">
                                <Grid item xs={12} md={10}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography variant="h3" sx={{ color: "#FF5E77" }}>
                                            {"#" + zeroPad(fileUpInfo.correlative ?? 0, 5)}
                                        </Typography>
                                        <Typography variant="h3">{t("converter.invalidLec")}</Typography>
                                        <CloseIcon sx={{ color: "#FF5E77" }} fontSize="small" />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <Typography>{t("converter.kiiperInvalid")}</Typography>
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <Typography>
                                        <b
                                            style={{ color: "#4A22D4", cursor: "pointer" }}
                                            onClick={() => handleClickHelp("conversion", "check", fileUpInfo._id)}
                                        >
                                            {t("converter.contactSupport")}
                                        </b>{" "}
                                        {t("converter.contactSupp")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12} md={1.5}>
                        {progress > 0 ? (
                            <>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <BaseLinearProgress progress={progress} />
                                    {progress === 100 && isLoadingConv && <CircularProgress color="secondary" />}
                                </Stack>
                                <Typography variant="h2" sx={{ pl: 3, pt: 1 }}>
                                    {isLoadingConv ? t("converter.calculate") : t("converter.uploadingCheck")}
                                </Typography>
                            </>
                        ) : (
                            <Button
                                variant={status === 1 ? "contained" : "outlined"}
                                endIcon={<CachedIcon style={{ width: "27px", height: "27px" }} />}
                                disabled={isDisabled || todos.credits - fileUpInfo?.price < 0 || status === 0}
                                onClick={status === 1 ? () => handleShowPrevDownload() : () => resetConversion()}
                                sx={{
                                    backgroundColor: status === 1 ? "#4a22d4" : "#FFF",
                                    py: 1.4,
                                    width: { xs: "100%", md: "100%" },
                                    justifyContent: "space-between",
                                    "&:disabled": {
                                        color: "#FFF",
                                        backgroundColor: "rgba(189, 189, 189, 1)",
                                    },
                                    "&:hover": {
                                        backgroundColor: status === 1 ? "#4a22d4" : "#FFF",
                                    },
                                }}
                            >
                                {status === 0 || status === 1 ? t("converter.convert") : t("converter.newConv")}
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
                        <Typography>{t("incomes.discConvIncome")}</Typography>
                    </Stack>
                    {todos.credits - fileUpInfo?.price < 0 && (
                        <Stack direction="row" alignItems="center" spacing={2} className={classes.backMiddle}>
                            <Box component="img" src={ErrorIconConverter} sx={{ width: 25 }} />
                            <Box>
                                <Typography className={classes.textBlue4}>{t("converter.noCreditsLeft")}</Typography>
                                <Link
                                    sx={{ textDecoration: "none", fontWeight: "bold", cursor: "pointer" }}
                                    onClick={handleSetShowModalCredits}
                                >
                                    {t("converter.rechargeCredits")}
                                </Link>
                            </Box>
                        </Stack>
                    )}
                </Stack>
            </Box>
            {showAddPay && (
                <AddPaymentDialog
                    open={showAddPay}
                    handleClose={handleCloseModal}
                    maxWidth="sm"
                    setShow={setShow}
                    setMessage={setTexto}
                    setAlertType={setAlertType}
                    setIsLoading={setIsLoading}
                    handleClose2={handleCloseModal2}
                    converter={true}
                />
            )}
            {showChangePay && (
                <ChangePayDialog
                    open={showChangePay}
                    handleClose={handleCloseChangePay}
                    payments={paymentMethods}
                    setIsLoading={setIsLoading}
                    reloadData={() => getPaymentMethods(true)}
                    defaultCard={defaultPayMethod}
                    handleAddPaymentM={handleAddPaymentM}
                />
            )}
            {showModalCredits && (
                <AddCreditPacksDialog
                    open={showModalCredits}
                    onClose={handleClose}
                    setShowAddPay={setShowAddPay}
                    setShowChangePay={setShowChangePay}
                    getPaymentMethods={() => getPaymentMethods(false)}
                    activePlan={activePlan}
                    paymentMethod={defaultPayMethod}
                    setIsLoading={setIsLoading}
                    companyDetails={companyDetails}
                    allCompanies={allCompanies}
                    paymentType={paymentType}
                />
            )}
        </>
    );
};

export default IncomeConverter;
