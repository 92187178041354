import { Snackbar, Typography, Alert } from "@mui/material";

const BaseSnackbar = (props) => {
    const { show, onClose, type, message } = props;

    return (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={show}
            autoHideDuration={8000}
            onClose={onClose}
        >
            <Alert onClose={onClose} severity={type}>
                <Typography color="textPrimary">
                    {message}
                </Typography>
            </Alert>
        </Snackbar>
    );
};

export default BaseSnackbar;
