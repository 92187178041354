/** React imports */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
// import introJs from "intro.js";

/** Icons */
import { ReactComponent as Logo } from "../../assets/logo-secondary.svg";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LanguageIcon from "@mui/icons-material/Language";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import InboxIcon from "@mui/icons-material/Inbox";
import LogoutIcon from "@mui/icons-material/Logout";
import DownloadIcon from "@mui/icons-material/Download";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuIcon from "@mui/icons-material/Menu";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SettingsIcon from "@mui/icons-material/Settings";
import PaymentsIcon from "@mui/icons-material/Payments";
import PersonIcon from "@mui/icons-material/Person";
import SalesInv from "../../assets/SalesInv.svg";
import PurchaseInv from "../../assets/PurchaseInv.svg";
import plansNew from "../Js/plans";

/** Styles */
import classes2 from "./NavBar.module.css";

/** MUI imports */
import {
    AppBar,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
    Collapse,
    ListItem,
    Stack,
    Badge,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";

import {
    addAccounts,
    addAllComData,
    addCompanyID,
    addFranchiseID,
    addCredits,
    clearInfo,
    addCases,
    addUserCompRol,
    addMessages,
    // addTutorial,
    addResetDash,
    addInvisibleNoti,
    addSelected,
} from "../../redux/userSlice";

/** Services */
import authService from "../../services/auth";
import userCompanyService from "../../services/user_company";
import accountsService from "../../services/accounts";
import subscriptionService from "../../services/subscription";
import teamService from "../../services/team";
import casesServices from "../../services/case";
import notificationsService from "../../services/notification";
import paymentMethodsService from "../../services/payment_methods";
import companyService from "../../services/company";

/** Components */
import SimpleBackdrop from "../ui/SimpleBackdrop";
import AddCompanyDialog from "../company/AddCompanyDialog";
import DropdownNavBar from "./navBarComponents/DropdownNavBar";
import CreditsComponent from "./navBarComponents/CreditsComponent";
import ConvertButton from "./navBarComponents/ConvertButton";
import EditCompany from "../company/EditCompany";
import BaseSnackbar from "../ui/BaseSnackbar";
import DropdownUser from "./navBarComponents/DropdownUser";
import AddCreditPacksDialog from "../credits/AddCreditPacksDialog";
import { useTranslation } from "react-i18next";
import Settings from "./navBarComponents/Settings";
import ITTeam from "../Js/ITTeam";
import LightTooltip from "../ui/LightTooltip";
import newPlans from "../Js/plans";

import { styled } from "@mui/material/styles";
import AddPaymentDialog from "../payments/AddPaymentDialog";
import ChangePayDialog from "../subscription/shoppingCart/ChangePayDialog";

const PREFIX = "NavBar";

const classes = {
    root: `${PREFIX}-root`,
    tooltip: `${PREFIX}-tooltip`,
    offset: `${PREFIX}-offset`,
    toolbar: `${PREFIX}-toolbar`,
    commonWhite: `${PREFIX}-commonWhite`,
    navbarLogo: `${PREFIX}-navbarLogo`,
    settingsBtn: `${PREFIX}-settingsBtn`,
    selectedItem: `${PREFIX}-selectedItem`,
};

const Root = styled("div")(({ theme }) => ({
    [`& .${classes.offset}`]: theme.mixins.toolbar,

    [`& .${classes.root}`]: {
        flexGrow: 1,
    },

    [`& .${classes.toolbar}`]: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#031851",
    },

    [`& .${classes.commonWhite}`]: {
        color: "#fff",
    },

    [`& .${classes.navbarLogo}`]: {
        display: "block",
        height: 50,
        [theme.breakpoints.down("sm")]: {
            width: "165px",
        },
    },

    [`& .${classes.settingsBtn}`]: {
        borderBottom: "1px solid #FFEEF0",
        borderBottomStyle: "dashed",
        "&:hover": {
            backgroundColor: "#f8f9f8",
        },
    },

    [`& .${classes.selectedItem}`]: {
        color: theme.palette.secondary.main,
        fontWeight: 600,
    },
}));

/** Global variables ----------------------------------------------------------- */
const drawerWidth = 300;

const openedMixin = (theme) => ({
    display: "flex",
    justifyContent: "space-between",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    display: "flex",
    justifyContent: "space-between",
    width: `calc(${theme.spacing(10)} + 1px)`,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        right: 7,
        top: 5,
    },
}));

const NavBar = (props) => {
    const { register, window } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /* React redux */
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.value);

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    /** Component states */
    const [isLoading, setIsLoading] = useState(false);
    const [showCompleteNavBar, setShowCompleteNavBar] = useState(false);
    const [openModalNewCompany, setOpenModalNewCompany] = useState(false);
    const [franchisesCompany, setFranchisesCompany] = useState([]); // Franquicias a las que tiene acceso el usuario.
    const [reload, setReload] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState({});
    const [selectedFranchise, setSelectedFranchise] = useState({});
    const [showModalConfig, setShowModalConfig] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [addNew, setAddNew] = useState(false);
    const [rol, setRol] = useState(0);
    const [openAddPacksDialog, setOpenAddPacksDialog] = useState(false);
    const [showAddPay, setShowAddPay] = useState(false);
    const [showChangePay, setShowChangePay] = useState(false); // Modal para cambiar tarjeta
    const [defaultPayMethod, setDefaultPayMethod] = useState({});
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [activePlan, setActivePlan] = useState(null); // Plan seleccionado
    const [companyDetails, setCompanyDetails] = useState({});
    const [allCompanies, setAllCompanies] = useState([]);
    const [paymentType, setPaymentType] = useState("monthly");

    /** Estados para dropdown de usuario */
    const [anchorElConfig, setAnchorElConfig] = useState(null);
    const [settings, setSettings] = useState(false);
    const [language, setLanguage] = useState(sessionStorage.getItem("lng") ?? "en");
    const [dateFormat, setDateFormat] = useState(todos.dateFormat ?? "MM/DD/YYYY");
    const [amountFormat, setAmountFormat] = useState(todos.amountFormat ?? "en-US");
    const [companyRedirect, setCompanyRedirect] = useState(null);
    const [openCollapse, setOpenCollapse] = useState(false);
    const [isInactive, setIsInactive] = useState(false);
    const [isLastFree, setIsLastFree] = useState(false);

    const open = Boolean(anchorElConfig);
    const id = open ? "simple-popover" : undefined;
    const isEmployee = ITTeam.includes(todos.userInfo?.email);

    const [openDrawer, setOpenDrawer] = useState(false);
    const [freeTrialIntegration, setFreeTrialIntegration] = useState(false); // Empresa con Plan Micro que si puede tener integraciones.

    /** Component functions */
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    // const handleBadgeVisibility = () => {
    //     setInvisible(true);
    // };

    /** Functions */
    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
        handleCloseAnalyticsPopover();
        handleCloseProfilePopover();
        setOpenDrawer(false);
        setMobileOpen(false);
    };

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
        setOpenCollapse(false);
    };

    const handleCloseProfilePopover = () => {
        setOpenDrawer(false);
        setMobileOpen(false);
    };

    const handleCloseAnalyticsPopover = () => {
        setOpenDrawer(false);
        setMobileOpen(false);
    };

    useEffect(() => {
        if (todos?.companyID) {
            let currentCompany = todos?.allComData?.find((item) => item._id.toString() === todos?.companyID);

            if (currentCompany && currentCompany?.free_trial_integration) {
                setFreeTrialIntegration(true);
            }
        }
    }, [todos?.companyID, todos?.allComData]);

    useEffect(() => {
        if (todos.subscription?.value === 6) {
            setIsInactive(true);
        } else if (todos.subscription?.value === 4 && todos.subscription?.last_value === 1) {
            setIsLastFree(true);
        } else {
            setIsInactive(false);
        }
    }, [todos.subscription?.value, todos.subscription?.last_value]);

    const selectMenuOption = (index) => {
        const newComp = todos.allComData.filter(
            (company) => !company.franchise_name && company.franchise_id === todos.franchiseID
        )[0];

        switch (index) {
            case 1:
                if (todos.selected !== "ddlAccounts" && todos.selected !== "ddlChecks") {
                    dispatch(addSelected("ddlAccounts"));
                }
                navigate(`/dashboard`);
                break;
            case 2:
                dispatch(addSelected("ddlSalesInv"));
                if (!todos.companyID) {
                    dispatch(addCompanyID(newComp._id));
                    setSelectedCompany(newComp);
                }
                navigate(`/convert/invoices`);

                break;
            case 3:
                dispatch(addSelected("ddlPurchaseInv"));
                if (!todos.companyID) {
                    dispatch(addCompanyID(newComp._id));
                    setSelectedCompany(newComp);
                }
                navigate(`/convert/bills`);
                break;
            case 4:
                if (rol !== 3) {
                    dispatch(addSelected("ddlSubscription"));
                    if (!todos.companyID) {
                        const newComp = todos.allComData.filter((company) => !company.franchise_name)[0];
                        dispatch(addCompanyID(newComp._id));
                        setSelectedCompany(newComp);
                    }
                    navigate("/subscription", {
                        state: {
                            tab: 0,
                        },
                    });
                    handleClose();
                }
                break;
            // case 4:
            //     dispatch(addSelected("ddlAccounts"));
            //     navigate(`/dashboard`);
            //     break;
            // case 5:
            //     dispatch(addSelected("ddlAccounts"));
            //     navigate(`/dashboard`);
            //     break;
            default:
                break;
        }
    };

    const subscriptions = [
        t("navBar.freeTrial"),
        t("navBar.onDemand"),
        `Plan `,
        t("navBar.onHold"),
        t("navBar.cancelled"),
        t("navBar.inactive"),
    ];

    const handleListItemClick = (index) => {
        handleDrawerClose();
        selectMenuOption(index);
        sessionStorage.setItem("option", index);
    };

    const handleClickConfig = () => {
        if (!isInactive && rol !== 3) {
            setShowModalConfig((prevState) => !prevState);
            handleClose();
        }
    };

    const listDrawer = (
        <Stack
            sx={{
                mt: "100px",
                mb: "16px",
                mx: 1.4,
                backgroundColor: "white"
            }}
            direction="column"
            justifyContent="space-between"
            height="100vh"
        >
            <List disablePadding sx={{ mt: "16px" }}>
                <ListItemButton
                    key={"Accounts"}
                    sx={{ p: "10px 16px 10px 2px" }}
                    className={
                        todos.selected === "ddlAccounts" || todos.selected === "ddlChecks" ? "navBarOptionSelected" : ""
                    }
                    onClick={() => handleListItemClick(1)}
                >
                    <ListItemIcon>
                        <AccountBalanceIcon
                            sx={{ margin: "auto", width: "1.3rem", height: "1.3rem" }}
                            color="primary"
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="h3" color="primary" ml={1.9}>
                                {t("dashboard.accounts")}
                            </Typography>
                        }
                    />
                </ListItemButton>
                <ListItemButton
                    key={"SalesInv"}
                    sx={{ p: "10px 16px 10px 2px" }}
                    className={todos.selected === "ddlSalesInv" ? "navBarOptionSelected" : ""}
                    onClick={() => handleListItemClick(2)}
                >
                    <ListItemIcon>
                        <Box
                            component="img"
                            sx={{
                                width: 28,
                                height: 28,
                                margin: "auto",
                            }}
                            src={SalesInv}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="h3" color="primary" ml={1.9}>
                                {t("navBar.salesInvoices")}
                            </Typography>
                        }
                    />
                </ListItemButton>
                <ListItemButton
                    key={"PurchaseInv"}
                    sx={{ p: "10px 16px 10px 2px" }}
                    className={todos.selected === "ddlPurchaseInv" ? "navBarOptionSelected" : ""}
                    onClick={() => handleListItemClick(3)}
                >
                    <ListItemIcon>
                        <Box
                            component="img"
                            sx={{
                                width: 23,
                                height: 23,
                                margin: "auto",
                            }}
                            src={PurchaseInv}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="h3" color="primary" ml={1.9}>
                                {t("navBar.purchaseInvoices")}
                            </Typography>
                        }
                    />
                </ListItemButton>
            </List>
            <List disablePadding sx={{ mb: 2 }}>
                <ListItemButton
                    key={"Config"}
                    aria-describedby={id}
                    sx={{ p: "10px 16px 10px 2px" }}
                    onClick={handleClickConfig}
                    disabled={rol === 3 || isInactive}
                >
                    <ListItemIcon>
                        <SettingsIcon sx={{ margin: "auto" }} color="primary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="h3" color="primary" ml={1.9}>
                                {t("navBar.config")}
                            </Typography>
                        }
                    />
                </ListItemButton>
                {todos.companyID && (
                    <ListItemButton
                        key={"Subscription"}
                        aria-describedby={id}
                        sx={{ p: "10px 16px 10px 2px" }}
                        disabled={rol === 3}
                        className={todos.selected === "ddlSubscription" ? "navBarOptionSelected" : ""}
                        onClick={() => handleListItemClick(4)}
                    >
                        <ListItemIcon>
                            {!openDrawer && todos.subscription?.value === 3 ? (
                                <Typography
                                    variant="h3"
                                    color="primary"
                                    sx={{ margin: "auto" }}
                                    fontWeight={700}
                                    fontSize="14px"
                                >
                                    {plansNew
                                        .flatMap((plan) => plan.levels)
                                        .find((level) => level.id === todos?.subscription?.plan)?.pages ?? 0}
                                </Typography>
                            ) : (
                                <PaymentsIcon sx={{ margin: "auto" }} color="primary" fontSize="small" />
                            )}
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography
                                    variant="h3"
                                    sx={{
                                        color:
                                            todos.subscription?.value === 1
                                                ? "#9470EE"
                                                : todos.subscription?.value === 2
                                                ? "#00C2A8"
                                                : todos.subscription?.value === 3
                                                ? "#361A52"
                                                : todos.subscription?.value === 4
                                                ? "#CC4E00"
                                                : todos.subscription?.value === 5
                                                ? "#F31212"
                                                : "#1071E5",
                                        fontWeight: "600",
                                        ml: 1.9,
                                    }}
                                >
                                    {subscriptions[todos.subscription?.value - 1] +
                                        (todos.subscription?.value === 3
                                            ? plansNew.find((plan) =>
                                                  plan.levels.some((level) => level.id === todos?.subscription?.plan)
                                              )?.planTitle
                                            : "")}
                                </Typography>
                            }
                        />
                    </ListItemButton>
                )}
            </List>
        </Stack>
    );

    const listDrawerMobile = (
        <>
            <Box sx={{ textAlign: "center" }}>
                <Logo onClick={(event) => handleClickKiiperLogo(event)} className={classes2.kiiperLogoMobile} />
            </Box>
            <Divider />
            <List disablePadding sx={{ mt: "20px" }}>
                <ListItemButton
                    key={"Accounts"}
                    sx={{ p: "10px 16px 10px 2px" }}
                    className={
                        todos.selected === "ddlAccounts" || todos.selected === "ddlChecks" ? "navBarOptionSelected" : ""
                    }
                    onClick={() => handleListItemClick(1)}
                >
                    <ListItemIcon>
                        <AccountBalanceIcon
                            sx={{ margin: "auto", width: "1.3rem", height: "1.3rem" }}
                            color="primary"
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="h3" color="primary" ml={1.9}>
                                {t("dashboard.accounts")}
                            </Typography>
                        }
                    />
                </ListItemButton>
                <ListItemButton
                    key={"SalesInv"}
                    sx={{ p: "10px 16px 10px 2px" }}
                    className={todos.selected === "ddlSalesInv" ? "navBarOptionSelected" : ""}
                    onClick={() => handleListItemClick(2)}
                >
                    <ListItemIcon>
                        <Box
                            component="img"
                            sx={{
                                width: 28,
                                height: 28,
                                margin: "auto",
                            }}
                            src={SalesInv}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="h3" color="primary" ml={1.9}>
                                {t("navBar.salesInvoices")}
                            </Typography>
                        }
                    />
                </ListItemButton>
                <ListItemButton
                    key={"PurchaseInv"}
                    sx={{ p: "10px 16px 10px 2px" }}
                    className={todos.selected === "ddlPurchaseInv" ? "navBarOptionSelected" : ""}
                    onClick={() => handleListItemClick(3)}
                >
                    <ListItemIcon>
                        <Box
                            component="img"
                            sx={{
                                width: 23,
                                height: 23,
                                margin: "auto",
                            }}
                            src={PurchaseInv}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="h3" color="primary" ml={1.9}>
                                {t("navBar.purchaseInvoices")}
                            </Typography>
                        }
                    />
                </ListItemButton>
            </List>
            <List disablePadding sx={{ mb: 3 }}>
                <ListItemButton
                    key={"Config"}
                    aria-describedby={id}
                    sx={{ p: "10px 16px 10px 2px" }}
                    onClick={handleClickConfig}
                    disabled={rol === 3 || isInactive}
                >
                    <ListItemIcon>
                        <SettingsIcon sx={{ margin: "auto" }} color="primary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="h3" color="primary" ml={1.9}>
                                {t("navBar.config")}
                            </Typography>
                        }
                    />
                </ListItemButton>
                {todos.companyID && (
                    <ListItemButton
                        key={"Subscription"}
                        aria-describedby={id}
                        sx={{ p: "10px 16px 10px 2px" }}
                        disabled={rol === 3}
                        className={todos.selected === "ddlSubscription" ? "navBarOptionSelected" : ""}
                        onClick={() => handleListItemClick(4)}
                    >
                        <ListItemIcon>
                            {!openDrawer && todos.subscription?.value === 3 ? (
                                <Typography
                                    variant="h3"
                                    color="primary"
                                    sx={{ margin: "auto" }}
                                >
                                    {plansNew
                                        .flatMap((plan) => plan.levels)
                                        .find((level) => level.id === todos?.subscription?.plan)?.pages ?? 0}
                                </Typography>
                            ) : (
                                <PaymentsIcon sx={{ margin: "auto" }} color="primary" fontSize="small" />
                            )}
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Stack direction="row" spacing={0.5} ml={1.9}>
                                    <Typography variant="h3">
                                        {t("navBar.subscription")}
                                    </Typography>
                                    <Typography
                                        variant="h3"
                                        sx={{
                                            color:
                                                todos.subscription?.value === 1
                                                    ? "#BA23F6"
                                                    : todos.subscription?.value === 2
                                                    ? "#00C2A8"
                                                    : todos.subscription?.value === 3
                                                    ? "#361A52"
                                                    : todos.subscription?.value === 4
                                                    ? "#CC4E00"
                                                    : todos.subscription?.value === 5
                                                    ? "#F31212"
                                                    : "#1071E5",
                                            fontWeight: "600",
                                        }}
                                    >
                                        {subscriptions[todos.subscription?.value - 1] +
                                            (todos.subscription?.value === 3
                                                ? plansNew
                                                      .flatMap((plan) => plan.levels)
                                                      .find((level) => level.id === todos?.subscription?.plan)?.pages ??
                                                  0
                                                : "")}
                                    </Typography>
                                </Stack>
                            }
                        />
                    </ListItemButton>
                )}
            </List>
        </>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    /** Component functions */
    useEffect(() => {
        if (!isEmployee) {
            // const showMainTutorial = () => {
            //     let isDone = false;

            //     introJs()
            //         .setOptions({
            //             disableInteraction: true,
            //             exitOnOverlayClick: false,
            //             keyboardNavigation: false,
            //             nextLabel: t("dialog.continue"),
            //             prevLabel: t("miscellaneous.goBack"),
            //             doneLabel: t("miscellaneous.accept"),
            //             steps: [
            //                 {
            //                     element: "#navbar-tutorial",
            //                     title: `${t("miscellaneous.step")} 1`,
            //                     intro: t("miscellaneous.tutorialNav1"),
            //                 },
            //                 {
            //                     element: "#navbar-tutorial2",
            //                     title: `${t("miscellaneous.step")} 1`,
            //                     intro: t("miscellaneous.tutorialNav2"),
            //                 },
            //                 {
            //                     element: "#navbar-tutorial3",
            //                     title: `${t("miscellaneous.step")} 1`,
            //                     intro: t("miscellaneous.tutorialNav3"),
            //                 },
            //                 {
            //                     element: "#navbar-tutorial4",
            //                     title: `${t("miscellaneous.step")} 1`,
            //                     intro: t("miscellaneous.tutorialNav4"),
            //                 },
            //                 {
            //                     element: "#navbar-tutorial5",
            //                     title: `${t("miscellaneous.step")} 1`,
            //                     intro: t("miscellaneous.tutorialNav5"),
            //                 },
            //                 {
            //                     element: "#navbar-tutorial6",
            //                     title: `${t("miscellaneous.step")} 1`,
            //                     intro: t("miscellaneous.tutorialNav6"),
            //                 },
            //                 {
            //                     element: "#navbar-tutorial7",

            //                     intro: t("miscellaneous.tutorialNav7"),
            //                 },
            //             ],
            //         })
            //         .oncomplete(() => {
            //             isDone = true;
            //             dispatch(addTutorial({ ...todos.steps, step1: false, step2: true }));
            //         })
            //         .onexit(() => {
            //             if (!isDone) {
            //                 dispatch(addTutorial({ ...todos.steps, step1: false }));
            //             }
            //         })
            //         .start();
            // };

            async function getUserCompanies() {
                setIsLoading(true);
                try {
                    const result = await userCompanyService.getCompanies();

                    let companies = [];
                    let franchises = [];
                    let index = 0;
                    let franchiseID = null;

                    result.records.forEach((company) => {
                        if (!company.franchise_name) {
                            companies.push(company);
                        } else {
                            franchises.push(company);
                        }
                    });

                    if (franchises.length !== 0 && !todos.companyID && !todos.steps?.step1) {
                        if (todos.franchiseID) {
                            index = franchises.findIndex((elem) => elem._id === todos.franchiseID);
                        }

                        franchiseID = franchises[index]._id;

                        dispatch(addFranchiseID(franchiseID));
                        setSelectedFranchise(franchises[index]);
                        setReload(false);

                        getMessages(franchiseID);

                        let filterCompanies = companies.filter((e) => e.franchise_id === franchiseID);

                        if (filterCompanies.length === 1) {
                            dispatch(addCompanyID(companies[index]?._id));
                            setSelectedCompany(companies[index]);

                            await subscriptionService
                                .getFranchiseCredits({ company_id: companies[index]?._id })
                                .then((response) => dispatch(addCredits(response)))
                                .catch((err) => console.log(err));
                        }
                    } else {
                        if (todos.companyID) {
                            index = companies?.findIndex((elem) => elem._id === todos.companyID);
                        }
                        franchiseID = companies[index].franchise_id;

                        dispatch(addCompanyID(companies[index]?._id));
                        dispatch(addFranchiseID(franchiseID));
                        setSelectedCompany(companies[index]);

                        index = franchises.findIndex((elem) => elem._id === franchiseID);

                        setSelectedFranchise(franchises[index]);
                        getMessages(franchiseID);

                        setReload(false);
                    }

                    const params = {
                        userID: todos.userInfo._id,
                        franchiseID: franchiseID,
                    };

                    if (todos.companyID) {
                        await subscriptionService
                            .getFranchiseCredits({ company_id: todos.companyID })
                            .then((response) => dispatch(addCredits(response)))
                            .catch((err) => console.log(err));
                    }

                    dispatch(addAllComData(result.records));

                    await teamService
                        .getUserCompanyDetails(params)
                        .then((data) => {
                            setRol(data.user_permission);
                            dispatch(addUserCompRol(data.user_permission));
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } catch (error) {
                    console.log(error);
                }
                setShowCompleteNavBar(true);
                setIsLoading(false);
            }

            if (!register) {
                getUserCompanies();
                // if (todos.steps?.step1) {
                //     showMainTutorial();
                // }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    useEffect(() => {
        if (todos.allComData && todos.companyID) {
            const newComp = todos.allComData.find((company) => company._id === todos.companyID);

            setSelectedCompany(newComp);
        }
    }, [todos.allComData, todos.companyID]);

    useEffect(() => {
        async function getFranchisesCompany() {
            try {
                if (selectedFranchise) {
                    const result = await userCompanyService.getFranchiseInfo(selectedFranchise._id);
                    setFranchisesCompany(result.franchiseChildren.slice(0, 6));
                    setShowCompleteNavBar(true);
                }
            } catch (error) {
                console.log(error);
            }
        }

        if (selectedFranchise?._id) {
            getFranchisesCompany();
        }
    }, [selectedFranchise, todos.allComData]);

    useEffect(() => {
        if (isEmployee) {
            async function getCases() {
                try {
                    const cases = await casesServices.getAdminCases({ count_only: true });
                    dispatch(addCases(cases.finalCount));
                } catch (error) {
                    console.log(error);
                }
            }
            async function getNoti() {
                try {
                    await notificationsService
                        .getNotifications()
                        .then((data) => {
                            let hasNoti = false;
                            for (const item of data) {
                                if (
                                    !item.notification_status.find((item) => item.email === todos.userInfo.email)?.read
                                ) {
                                    hasNoti = true;
                                }
                            }
                            dispatch(addInvisibleNoti(!hasNoti));
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } catch (error) {
                    console.log(error);
                }
            }
            getNoti();
            getCases();
        } else {
            async function getCompanyAccounts() {
                try {
                    if (selectedCompany) {
                        const accounts = await accountsService.getAccounts({ company_id: selectedCompany._id });

                        dispatch(addAccounts(accounts));
                        setShowCompleteNavBar(true);
                    }
                } catch (error) {
                    console.log(error);
                }
            }

            if (selectedCompany?._id) {
                getCompanyAccounts();
            }
        }
    }, [dispatch, isEmployee, selectedCompany, todos.userInfo?.email]);

    useEffect(() => {
        setDateFormat(todos.dateFormat);
        setAmountFormat(todos.amountFormat);
        setLanguage(sessionStorage.getItem("lng"));
    }, [todos.amountFormat, todos.dateFormat]);

    useEffect(() => {
        (async () => {
            if (todos.companyID !== null && !register) {
                setIsLoading(true);
                await userCompanyService
                    .getBusinessDirectoryDetails({ company_id: todos.companyID })
                    .then(async (data) => {
                        setCompanyDetails(data);
                        setActivePlan(data.subscription.subscription_plan);
                        setPaymentType(data?.last_invoice?.line_items[0]?.line_qty === 365 ? "annual" : "monthly");
                        await userCompanyService
                            .getBusinessDirectory({ franchise_id: data.franchise_id })
                            .then((data) => {
                                const companies = data.companies.map((item) => ({
                                    value: item._id,
                                    name: item.company_name,
                                }));

                                setAllCompanies(companies);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                setIsLoading(false);
            }
        })();
    }, [register, setIsLoading, todos.companyID]);

    async function getMessages(franchise) {
        let userId = todos.userInfo?._id;
        let chatMsg;
        if (!isEmployee) {
            chatMsg = await casesServices.getMessagesCases({
                user_id: userId,
                franchise_id: franchise,
                isEmployee: false,
            });
        } else {
            chatMsg = await casesServices.getMessagesCases({ user_id: userId, isEmployee: true });
        }
        dispatch(addMessages(chatMsg.countMsg));
    }

    const downloadReport = async () => {
        setIsLoading(true);
        await accountsService
            .generateGeneralReport()
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    "Kiiper - Reporte general al " + moment().format("DD-MM-YYYY HHmmss") + ".xlsx"
                );
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
    };

    const handleLogout = async () => {
        handleDrawerToggle();
        setIsLoading(true);

        await authService
            .logout()
            .then(() => {
                searchParams.delete("connected");
                searchParams.delete("connect");
                searchParams.delete("company_id");
                searchParams.delete("redirectType");
                setSearchParams(searchParams, { replace: true });
                setCompanyRedirect(null);
                dispatch(clearInfo());
            })
            .catch((err) => console.log(err));

        setIsLoading(false);
    };

    const handleClick = (event) => {
        setAnchorElConfig(event.currentTarget);
    };

    const handleClickCollapse = () => {
        setOpenCollapse((prevState) => !prevState);
    };

    const handleCloseConfig = () => {
        setAnchorElConfig(null);
        setSettings(false);
    };

    const handleClickKiiperLogo = () => {
        if (!register && !isEmployee) {
            navigate("/dashboard");
        }
        if (isEmployee) {
            navigate("/inbox");
            dispatch(addResetDash({ all: true, supp: false }));
        }
    };

    useEffect(() => {
        (async () => {
            const company_param = searchParams.get("company_id");
            const connected_param = searchParams.get("connected");
            const connect_param = searchParams.get("connect");
            const type_param = searchParams.get("redirectType");
            const alert_param = searchParams.get("alert");
            const type_2_param = searchParams.get("type");
            const open_m_param = searchParams.get("open_m");
            if (company_param && type_param?.toString() === "2" && !register) {
                // Redirección de Xero o Quickbooks al conectarse
                if (connected_param && connected_param === "true" && connect_param) {
                    await companyService
                        .getCompanyDetails(company_param)
                        .then((data) => {
                            setCompanyRedirect({
                                info: data,
                                integ: connect_param === "Xero" ? 1 : 2,
                            });
                            setShowModalConfig(true);
                            setAlertType("success");
                            setTexto(t("dialog.connectionEstablished"));
                            setShow(true);
                            searchParams.delete("connected");
                            searchParams.delete("connect");
                            searchParams.delete("company_id");
                            searchParams.delete("redirectType");
                            searchParams.delete("alert");
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else if (connected_param && connected_param === "false" && connect_param && alert_param) {
                    // Botón de regresar
                    await companyService
                        .getCompanyDetails(company_param)
                        .then((data) => {
                            setCompanyRedirect({
                                info: data,
                                integ: data.system_integration ? data.system_integration : 3,
                            });
                            setShowModalConfig(true);
                            if (alert_param && alert_param === "1") {
                                setAlertType("error");
                                setTexto(t("dialog.alreadyConnected"));
                                setShow(true);
                            } else if (alert_param && alert_param === "2") {
                                setAlertType("error");
                                setTexto(t("dialog.connectionError1"));
                                setShow(true);
                            } else if (alert_param && alert_param === "3") {
                                setAlertType("error");
                                setTexto(t("dialog.connectionError2"));
                                setShow(true);
                            } else if (alert_param && alert_param === "4") {
                                setAlertType("error");
                                setTexto(t("dialog.connectionError3"));
                                setShow(true);
                            }
                            searchParams.delete("connected");
                            searchParams.delete("connect");
                            searchParams.delete("company_id");
                            searchParams.delete("redirectType");
                            searchParams.delete("alert");
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            } else if (company_param && type_2_param?.toString() === "2" && open_m_param === "true" && !register) {
                // Botón de regresar
                await companyService
                    .getCompanyDetails(company_param)
                    .then((data) => {
                        setCompanyRedirect({
                            info: data,
                            integ: data.system_integration ? data.system_integration : 3,
                        });
                        setShowModalConfig(true);
                        searchParams.delete("connected");
                        searchParams.delete("connect");
                        searchParams.delete("company_id");
                        searchParams.delete("redirectType");
                        searchParams.delete("alert");
                        searchParams.delete("open_m");
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                setCompanyRedirect(null);
            }
        })();
    }, [register, searchParams, t]);

    const checkSession = async () => {
        await authService
            .checkSession()
            .then(async (isActive) => {
                if (!isActive) {
                    searchParams.delete("connected");
                    searchParams.delete("connect");
                    searchParams.delete("company_id");
                    searchParams.delete("redirectType");
                    setSearchParams(searchParams, { replace: true });
                    setCompanyRedirect(null);
                    dispatch(clearInfo());
                    navigate("/");
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
    };

    useEffect(() => {
        if (!register) {
            const intervalId = setInterval(checkSession, 60000);

            return () => clearInterval(intervalId);
        }
    });

    const goToInbox = () => {
        handleDrawerToggle();
        navigate("/inbox");
    };

    const goToInbox2 = () => {
        navigate("/inbox");
        dispatch(addResetDash({ all: false, supp: true }));
    };

    const handleOpenUserProfile = () => {
        handleDrawerToggle();
        navigate("/profile");
    };

    /** Component functions */
    const goToPlans = () => {
        navigate("/subscription", {
            state: {
                tab: 0,
            },
        });
    };

    const openModalAddCredits = () => {
        setOpenAddPacksDialog(true);
    };

    const handleCloseModalAddCredits = () => {
        setOpenAddPacksDialog(false);
    };

    const handleCloseModal = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowAddPay(false);
            setShowChangePay(true);
            getPaymentMethods(true);
        }
    };

    const handleCloseModal2 = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowAddPay(false);
            setShowChangePay(false);
            getPaymentMethods(true);
            setOpenAddPacksDialog(true);
        }
    };

    const handleCloseChangePay = () => {
        setShowChangePay(false);
        setOpenAddPacksDialog(true);
    };

    const handleAddPaymentM = () => {
        setShowAddPay(true);
        setShowChangePay(false);
    };

    const getPaymentMethods = async (reload) => {
        setIsLoading(true);

        const params = {
            franchise_id: todos.franchiseID,
            companyID: todos.companyID,
        };

        try {
            if (todos.companyID || todos.franchiseID) {
                const result = await paymentMethodsService.getPaymentMethods(params);
                const defaultPaymentMethod = result.find((card) => card.default_method);
                setDefaultPayMethod(defaultPaymentMethod);
                setPaymentMethods(result);
            }
        } catch (error) {
            console.log(error);
        }

        if (!reload) {
            await subscriptionService
                .getSubscription({
                    company_id: todos.companyID,
                })
                .then((response) => {
                    if (response) {
                        setActivePlan(response.subscription_plan);
                    } else {
                        setActivePlan(null);
                    }
                })
                .catch((err) => console.log(err));
        }

        setIsLoading(false);
    };

    const drawer = (
        <>
            <Box sx={{ textAlign: "center" }}>
                <Logo onClick={(event) => handleClickKiiperLogo(event)} className={classes2.kiiperLogoMobile} />
            </Box>
            <Divider />
            <List>
                {!register && showCompleteNavBar && !todos.isGrouped && !isEmployee && todos.companyID && (
                    <ListItem>
                        <CreditsComponent
                            credits={todos.credits}
                            mobile={true}
                            handleDrawerToggle={handleDrawerToggle}
                            rol={rol}
                            setSelectedCompany={setSelectedCompany}
                        />
                    </ListItem>
                )}
                {!todos.isGrouped && showCompleteNavBar && !register && todos.companyID && !isEmployee && (
                    <ListItem>
                        <ConvertButton
                            setSelectedCompany={setSelectedCompany}
                            setMobileOpen={setMobileOpen}
                            setOpenCollapse={setOpenCollapse}
                        />
                    </ListItem>
                )}
                {!register && !isEmployee && (
                    <ListItem disablePadding>
                        <ListItemButton onClick={goToInbox}>
                            <ListItemIcon>
                                <Badge color="secondary" variant="dot" invisible={todos.messages === 0}>
                                    <InboxIcon />
                                </Badge>
                            </ListItemIcon>
                            <ListItemText primary={t("inbox.mailbox")} sx={{ fontWeight: 600 }} disableTypography />
                        </ListItemButton>
                    </ListItem>
                )}
                {isEmployee && (
                    <>
                        <Button
                            variant="contained"
                            disableElevation
                            endIcon={<DownloadIcon />}
                            onClick={downloadReport}
                            sx={{ ml: 5, mb: 1 }}
                        >
                            {t("navBar.downloadRep")}
                        </Button>
                        <ListItemButton onClick={goToInbox2}>
                            <ListItemIcon>
                                <LiveHelpIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={t("inbox.openTickets")} sx={{ fontWeight: 600 }} disableTypography />
                            <Box
                                className={classes2.filterNum}
                                component="span"
                                sx={{
                                    ml: 3,
                                    px: todos.cases === 1 ? 1.2 : 1.1,
                                    fontSize: 13,
                                }}
                            >
                                {todos.cases}
                            </Box>
                        </ListItemButton>
                    </>
                )}
                <ListItemButton onClick={handleOpenUserProfile}>
                    <ListItemIcon>
                        <ManageAccountsIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={t("navBar.editProfile")} sx={{ fontWeight: 600 }} disableTypography />
                </ListItemButton>
                <ListItemButton onClick={handleClickCollapse}>
                    <ListItemIcon>
                        <LanguageIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={t("navBar.settings")} sx={{ fontWeight: 600 }} disableTypography />
                    {openCollapse ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                    <Settings
                        setLanguage={setLanguage}
                        setDateFormat={setDateFormat}
                        language={language}
                        dateFormat={dateFormat}
                        amountFormat={amountFormat}
                        setAmountFormat={setAmountFormat}
                        handleClose={handleCloseConfig}
                        setTexto={setTexto}
                        setalertType={setAlertType}
                        setShow={setShow}
                        setIsLoading={setIsLoading}
                        isMobile={true}
                    />
                </Collapse>
                <ListItemButton onClick={handleLogout}>
                    <ListItemIcon>
                        <LogoutIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={t("navBar.logOut")} sx={{ fontWeight: 600 }} disableTypography />
                </ListItemButton>
            </List>
        </>
    );

    const condBannerPlan = () => {
        return (
            !isEmployee &&
            showCompleteNavBar &&
            !register &&
            !todos.isGrouped &&
            todos.subscription?.value &&
            todos.subscription?.value === 3 &&
            todos.subscription?.plan >= 0
        );
    };

    const condBannerPlanFree = () => {
        return (
            !isEmployee &&
            showCompleteNavBar &&
            !register &&
            !todos.isGrouped &&
            todos.subscription?.value &&
            todos.subscription?.value === 1
        );
    };

    const SubscriptionBanner = ({ message, actionLabel, onClick }) => (
        <Box
            sx={{
                backgroundColor: "#E9E6EC",
                display: "flex",
                padding: "7px 13px",
                alignContent: "center",
                borderRadius: "6px",
            }}
        >
            <Typography sx={{ fontSize: { md: 12, lg: 14 } }}>
                {message}{" "}
                <Typography
                    component="span"
                    sx={{ fontWeight: "600", cursor: "pointer", color: "#EE2C66", fontSize: { md: 12, lg: 14 } }}
                    onClick={onClick}
                >
                    {actionLabel}
                </Typography>
            </Typography>
        </Box>
    );

    const CreditsBanner = ({ message, actionLabel, onClick }) => (
        <Box
            sx={{
                backgroundColor: "rgba(151, 71, 255, 0.15)",
                padding: "7px 13px",
                alignContent: "center",
                display: { xs: "none", sm: "flex" },
                borderRadius: "5px",
                minHeight: "36.5px",
                alignItems: "center",
                maxWidth: { md: "450px", lg: "100%" },
                whiteSpace: "nowrap",
            }}
        >
            <Typography
                sx={{
                    color: "#131F3E",
                    fontSize: { md: 12, lg: 13 },
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                }}
            >
                {message}{" "}
                <Typography
                    component="span"
                    sx={{ fontWeight: "600", cursor: "pointer", fontSize: { md: 12, lg: 13 }, color: "#361A52" }}
                    onClick={onClick}
                >
                    {actionLabel}
                </Typography>
            </Typography>
        </Box>
    );

    const isCompanyInactive = selectedCompany?.id_status === 6 ?? false;

    const isCompanyOnHold = selectedCompany?.id_status === 4 ?? false;
    const prevCompanyFreeTrial = selectedCompany?.id_last_status === 1 ?? false;

    const handleSubscriptionClick = isCompanyInactive
        ? () =>
              navigate("/subscription", {
                  state: { tab: 0, subscription: selectedFranchise.id_status, reactivate: true },
              })
        : !selectedCompany?._id && !selectedFranchise?._id
        ? () => setOpenModalNewCompany(true)
        : () => navigate("/accounts", { state: { addAccount: true } });

    const subscriptionMessage = isCompanyOnHold
        ? t("navBar.inactiveSubs")
        : !selectedCompany?._id && !selectedFranchise?._id
        ? t("navBar.createNewCompany")
        : t("navBar.withoutAccounts");

    const subscriptionActionLabel = isCompanyOnHold
        ? t("navBar.inactiveSubs1")
        : !selectedCompany?._id && !selectedFranchise?._id
        ? t("navBar.createNow")
        : t("navBar.configureNow");

    const creditsMessage = todos.credits === 0 ? t("navBar.noCredits1") : t("navBar.lowConversions");
    const creditsActionLabel = todos.credits === 0 ? t("navBar.noCredits2") : t("navBar.getMoreNow");

    const condBannerSelectedCompany = () => {
        return !(
            (!selectedCompany?._id && !selectedFranchise?._id) ||
            (selectedCompany &&
                Object.keys(selectedCompany).length > 0 &&
                todos.accounts?.pending.length === 0 &&
                todos.accounts?.archived.length === 0 &&
                todos.accounts?.setup.length === 0)
        );
    };

    const isSubscriptionBannerVisible =
        ((!selectedCompany?._id && !selectedFranchise?._id) ||
            (selectedCompany && Object.keys(selectedCompany).length > 0 && todos.accounts?.setup.length === 0)) &&
        showCompleteNavBar &&
        !register &&
        todos.companyID &&
        !isEmployee;

    const notifPlan = () =>
        newPlans?.flatMap((item) => item.levels).find((item) => item?.id === todos?.subscription?.plan)?.pages * 0.1 ??
        0;

    return (
        <Root className={classes.root}>
            <AppBar
                position="fixed"
                sx={{
                    zIndex: isEmployee || mobileOpen ? 1 : (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: "#FFFFFF",
                    boxShadow: "none",
                }}
                id="navbar-tutorial"
            >
                <Toolbar className="toolbarMain">
                    <IconButton
                        color="primary"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ ml: 0.7, display: { md: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Logo
                                    onClick={(event) => handleClickKiiperLogo(event)}
                                    className={classes2.kiiperLogo}
                                    id="navbar-tutorial2"
                                />
                                <Box id="navbar-tutorial3">
                                    {(selectedCompany?._id || selectedFranchise?._id) &&
                                        showCompleteNavBar &&
                                        !isEmployee &&
                                        !register && (
                                            <DropdownNavBar
                                                company={selectedCompany}
                                                franchise={selectedFranchise}
                                                setSelectedFranchise={setSelectedFranchise}
                                                openModalNewCompany={setOpenModalNewCompany}
                                                setSelectedCompany={setSelectedCompany}
                                                franchisesCompany={franchisesCompany}
                                                setReload={setReload}
                                                setShowModalConfig={setShowModalConfig}
                                                allData={todos.allComData ?? []}
                                                setAddNew={setAddNew}
                                                subscription={todos.subscription?.value}
                                                setIsLoading={setIsLoading}
                                                rol={rol}
                                                setFranchisesCompany={setFranchisesCompany}
                                                setRol={setRol}
                                                getMessages={getMessages}
                                                isLastFree={isLastFree}
                                            />
                                        )}
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item sx={{ display: { xs: "none", md: "block" }, ml: "auto" }}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                {isSubscriptionBannerVisible && (
                                    <SubscriptionBanner
                                        message={subscriptionMessage}
                                        actionLabel={subscriptionActionLabel}
                                        onClick={handleSubscriptionClick}
                                    />
                                )}
                                {/* Cuando no hay créditos o hay pocos y tiene un plan activo */}
                                {condBannerSelectedCompany() &&
                                    condBannerPlan() &&
                                    todos.credits <= notifPlan() &&
                                    todos.companyID && (
                                        <CreditsBanner
                                            message={creditsMessage}
                                            actionLabel={creditsActionLabel}
                                            onClick={todos.credits === 0 ? openModalAddCredits : goToPlans}
                                        />
                                    )}
                                {/* Cuando no hay créditos y es Free trial */}
                                {condBannerSelectedCompany() &&
                                    condBannerPlanFree() &&
                                    todos.credits === 0 &&
                                    todos.companyID && (
                                        <CreditsBanner
                                            message={t("navBar.freeEnded1")}
                                            actionLabel={t("navBar.freeEnded2")}
                                            onClick={goToPlans}
                                        />
                                    )}
                                {/* Cuando hay créditos y es Free trial */}
                                {condBannerSelectedCompany() &&
                                    condBannerPlanFree() &&
                                    todos.credits > 0 &&
                                    todos.companyID && (
                                        <CreditsBanner
                                            message={t("navBar.nowFreeT1")}
                                            actionLabel={t("navBar.nowFreeT2")}
                                            onClick={goToPlans}
                                        />
                                    )}
                                {/* Cuando hay créditos y es Plan micro */}
                                {condBannerSelectedCompany() &&
                                    condBannerPlan() &&
                                    todos.companyID &&
                                    todos.subscription?.plan === 0 &&
                                    todos.credits > notifPlan() &&
                                    !freeTrialIntegration && (
                                        <CreditsBanner
                                            message={t("navBar.intAccSys1")}
                                            actionLabel={t("navBar.intAccSys2")}
                                            onClick={goToPlans}
                                        />
                                    )}
                                {/* Cuando la franquicia está en estatus On hold y el estatus anterior era Free trial, se acabó prueba gratuita */}
                                {condBannerSelectedCompany() &&
                                    isCompanyOnHold &&
                                    prevCompanyFreeTrial &&
                                    todos.companyID && (
                                        <CreditsBanner
                                            message={t("navBar.freeEnded1")}
                                            actionLabel={t("navBar.freeEnded2")}
                                            onClick={goToPlans}
                                        />
                                    )}
                                <Box id="navbar-tutorial4">
                                    {!register &&
                                        showCompleteNavBar &&
                                        !todos.isGrouped &&
                                        !isEmployee &&
                                        todos.companyID && (
                                            <CreditsComponent
                                                credits={todos.credits}
                                                rol={rol}
                                                setSelectedCompany={setSelectedCompany}
                                            />
                                        )}
                                </Box>
                                <Box id="navbar-tutorial5">
                                    {!todos.isGrouped &&
                                        showCompleteNavBar &&
                                        !register &&
                                        todos.companyID &&
                                        !isEmployee && (
                                            <ConvertButton
                                                setSelectedCompany={setSelectedCompany}
                                                setMobileOpen={setMobileOpen}
                                                setOpenCollapse={setOpenCollapse}
                                            />
                                        )}
                                </Box>
                                {!register && !isEmployee && (
                                    <LightTooltip title={t("inbox.mailbox")}>
                                        <IconButton
                                            color="primary"
                                            aria-label="inbox"
                                            onClick={() => navigate("/inbox")}
                                            id="navbar-tutorial6"
                                        >
                                            <Badge color="secondary" variant="dot" invisible={todos.messages === 0}>
                                                <InboxIcon />
                                            </Badge>
                                        </IconButton>
                                    </LightTooltip>
                                )}
                                {isEmployee && (
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            endIcon={<DownloadIcon />}
                                            onClick={downloadReport}
                                        >
                                            {t("navBar.downloadRep")}
                                        </Button>
                                        <Box
                                            sx={{
                                                backgroundColor: "rgba(151, 71, 255, 0.15)",
                                                display: { xs: "none", md: "flex" },
                                                padding: "8px 13px 8px 13px",
                                                alignContent: "center",
                                                cursor: "pointer",
                                                borderRadius: "5px",
                                            }}
                                            onClick={goToInbox2}
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: "600",
                                                    color: "#131F3E",
                                                }}
                                            >
                                                {t("inbox.openTickets")}
                                                <Box
                                                    className={classes2.filterNum}
                                                    component="span"
                                                    sx={{
                                                        ml: 3,
                                                        px: todos.cases === 1 ? 1.2 : 1.1,
                                                        fontSize: 13,
                                                    }}
                                                >
                                                    {todos.cases}
                                                </Box>
                                            </Typography>
                                        </Box>
                                        <IconButton onClick={() => navigate("/notifications")}>
                                            <StyledBadge color="error" variant="dot" invisible={todos.read ?? true}>
                                                <NotificationsIcon color="primary" />
                                            </StyledBadge>
                                        </IconButton>
                                    </Stack>
                                )}
                                <IconButton id="navbar-tutorial7" onClick={handleClick}>
                                    <PersonIcon color="primary" />
                                </IconButton>
                            </Stack>
                            <DropdownUser
                                handleLogout={handleLogout}
                                id={id}
                                open={open}
                                anchorElConfig={anchorElConfig}
                                handleClose={handleCloseConfig}
                                settings={settings}
                                setSettings={setSettings}
                                language={language}
                                dateFormat={dateFormat}
                                amountFormat={amountFormat}
                                setLanguage={setLanguage}
                                setDateFormat={setDateFormat}
                                setAmountFormat={setAmountFormat}
                                setIsLoading={setIsLoading}
                                isLoading={isLoading}
                                register={register}
                            />
                        </Grid>
                    </Grid>
                    <AddCompanyDialog
                        open={openModalNewCompany}
                        onClose={() => setOpenModalNewCompany(false)}
                        setReload={setReload}
                        franchiseID={todos.franchiseID}
                        setIsLoading={setIsLoading}
                        setShow={setShow}
                        setTexto={setTexto}
                        setAlertType={setAlertType}
                        addNew={addNew}
                        franchiseInfo={selectedFranchise}
                        softwares={todos?.userInfo?.software}
                        companyRedirect={companyRedirect}
                        setCompanyRedirect={setCompanyRedirect}
                    />
                    <EditCompany
                        setReload={setReload}
                        setIsLoading={setIsLoading}
                        setShowModalConfig={setShowModalConfig}
                        franchiseID={todos.franchiseID}
                        companyID={todos.companyID}
                        setShow={setShow}
                        setTexto={setTexto}
                        setAlertType={setAlertType}
                        showModalConfig={showModalConfig}
                        setSelectedCompany={setSelectedCompany}
                        setSelectedFranchise={setSelectedFranchise}
                        subscription={todos.subscription?.value}
                        companyRedirect={companyRedirect}
                        setCompanyRedirect={setCompanyRedirect}
                    />
                </Toolbar>
                <Divider />
            </AppBar>
            {isEmployee ? (
                <nav>
                    <MuiDrawer
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: "block", md: "none" },
                            "& .MuiDrawer-paper": { boxSizing: "border-box", width: 270 },
                        }}
                    >
                        {drawer}
                    </MuiDrawer>
                </nav>
            ) : !register ? (
                <Box
                    component="nav"
                    sx={{
                        flexShrink: { sm: 0 },
                    }}
                    aria-label="mailbox folders"
                >
                    <MuiDrawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true,
                        }}
                        sx={{
                            display: { xs: "block", md: "none" },
                            "& .MuiDrawer-paper": {
                                boxSizing: "border-box",
                                width: drawerWidth,
                                borderRight: "none",
                                backgroundColor: "#F8F7F9",
                            },
                        }}
                    >
                        {listDrawerMobile}
                    </MuiDrawer>
                    <Drawer
                        variant="permanent"
                        open={openDrawer}
                        PaperProps={{ onMouseEnter: handleDrawerOpen, onMouseLeave: handleDrawerClose }}
                        sx={{
                            display: { xs: "none", md: "block" },
                            "& .MuiDrawer-paper": { borderRight: "none", backgroundColor: "#F8F7F9" },
                        }}
                    >
                        {listDrawer}
                    </Drawer>
                </Box>
            ) : null}
            {openAddPacksDialog && (
                <AddCreditPacksDialog
                    open={openAddPacksDialog}
                    onClose={handleCloseModalAddCredits}
                    setShowAddPay={setShowAddPay}
                    setShowChangePay={setShowChangePay}
                    getPaymentMethods={() => getPaymentMethods(false)}
                    activePlan={activePlan}
                    paymentMethod={defaultPayMethod}
                    setIsLoading={setIsLoading}
                    companyDetails={companyDetails}
                    allCompanies={allCompanies}
                    paymentType={paymentType}
                />
            )}
            {showAddPay && (
                <AddPaymentDialog
                    open={showAddPay}
                    handleClose={handleCloseModal}
                    maxWidth="sm"
                    setShow={setShow}
                    setMessage={setTexto}
                    setAlertType={setAlertType}
                    setIsLoading={setIsLoading}
                    handleClose2={handleCloseModal2}
                    converter={true}
                />
            )}
            {showChangePay && (
                <ChangePayDialog
                    open={showChangePay}
                    handleClose={handleCloseChangePay}
                    payments={paymentMethods}
                    setIsLoading={setIsLoading}
                    reloadData={() => getPaymentMethods(true)}
                    defaultCard={defaultPayMethod}
                    handleAddPaymentM={handleAddPaymentM}
                />
            )}
            <SimpleBackdrop open={isLoading} />
            <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleClose} />
        </Root>
    );
};

export default NavBar;
