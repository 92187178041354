import { useState } from "react";
import moment from "moment";

/* Componentes */
import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Typography,
    Popover,
    ListItemButton,
    Grid,
    Button,
    Stack,
    Link,
    Checkbox,
} from "@mui/material";

/* Estilos */
import classes from "./TeamList.module.css";

/* Iconos */
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { useTranslation } from "react-i18next";
import AlertDialog from "../ui/AlertDialog";
import FormDialog from "../ui/FormDialog";
import UserForm from "./UserForm";
import UpdateTemporaryForm from "./UpdateTemporaryForm";

import authService from "../../services/auth";
import teamService from "../../services/team";
import userCompanyService from "../../services/user_company";
import subscriptionService from "../../services/subscription";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearInfo } from "../../redux/userSlice";
import LightTooltip from "../ui/LightTooltip";
import InfoIcon from "@mui/icons-material/Info";

// const CustomSwitch = styled(Switch)(({ theme }) => ({
//     "& .MuiSwitch-switchBase.Mui-disabled .MuiSwitch-thumb": {
//         color: "#B3B3B3",
//     },
//     "& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track": {
//         backgroundColor: "#B3B3B3",
//         opacity: 0.3,
//     },
// }));

const TeamList = (props) => {
    const {
        franchiseID,
        setIsLoading,
        setTexto,
        setAlertType,
        setShow,
        invitations,
        hasAccess,
        companyName,
        setReload,
        subscription,
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");
    const dispatch = useDispatch();

    const todos = useSelector((state) => state.value);
    const navigate = useNavigate();

    const rolNames = [
        { id: 1, name: t("team.owner") },
        { id: 2, name: t("team.admin") },
        { id: 3, name: t("team.colab") },
    ];
    
    let filterInv = [];

    if (!todos.companyID) {
        filterInv = invitations.filter((item) => item.invite_status === 1);
    } else {
        filterInv = invitations.filter((item) => item.invite_status === 1 || item.invite_status === 4);
    }

    const ownerEmail = filterInv.find((item) => item.user_permission === 1);

    const [anchorEl, setAnchorEl] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [showEditDial, setShowEditDial] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [userRol, setUserRol] = useState("");
    const [isError, setIsError] = useState(false);
    const [verified, setVerified] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [userTemp, setUserTemp] = useState([]);
    const [userTemp2, setUserTemp2] = useState([]);
    const [usersFranq, setUsersFranq] = useState([]);
    const [invFranq, setInvFranq] = useState([]);
    const [showAlertComp, setShowAlertComp] = useState(false);
    const [showAlertTransfer, setShowAlertTransfer] = useState(false);

    const handleChangeCheck = (event, val) => {
        setSelectedUser(val);
        if (event.target.checked) {
            linkUser(val);
        } else {
            setShowAlertComp(true);
        }
    };

    const plansInfo = [
        {
            planId: 1,
            planTitle: "Plan 5",
            users: 1,
        },
        {
            planId: 2,
            planTitle: "Plan 15",
            users: 2,
        },
        {
            planId: 3,
            planTitle: "Plan 30",
            users: 3,
        },
        {
            planId: 4,
            planTitle: "Plan 50",
            users: 4,
        },
        {
            planId: 5,
            planTitle: "Plan 200",
            users: 5,
        },
        {
            planId: 6,
            planTitle: "Plan 500",
            users: "ilimitados",
        },
    ];

    const handleClick = (event, val) => {
        setAnchorEl(event.currentTarget);
        setSelectedUser(val);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAlert = () => {
        setShowAlert(true);
        handleClose();
    };

    const handleCloseDialog = () => {
        setShowAlert(false);
        setShowAlertComp(false);
        setShowAlertTransfer(false);
    };

    const handleEditDial = () => {
        const names = selectedUser.user_name;

        setShowEditDial(true);
        handleClose();
        setName(names);
        setEmail(selectedUser.invite_email);
        setUserRol(selectedUser.user_permission);
    };

    const handleCloseEdit = () => {
        setShowEditDial(false);
        setIsError(false);
        setIsValidEmail(false);
        setVerified(false);
        setName("");
        setEmail("");
        setUserRol("");
    };

    const verifyEmail = async (emailInput) => {
        if (emailInput.trim() && /.+@.+\..+/.test(emailInput)) {
            await authService
                .verifyExistingUser(emailInput)
                .then(() => {
                    setVerified(true);
                    setIsValidEmail(false);
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 404:
                            setVerified(false);
                            setIsValidEmail(true);
                            break;
                        default:
                            setTexto(t("team.verifyError500"));
                            setAlertType("error");
                            setShow(true);
                            break;
                    }
                });
        } else {
            setIsError(true);
        }
    };

    const unlinkUser = async (isAll) => {
        setIsLoading(true);

        const params = {
            invite_email: selectedUser.invite_email,
            company_id: !isAll ? todos.companyID : null,
            franchise_id: isAll ? todos.franchiseID : null,
        };

        await teamService
            .unlinkUser(params)
            .then(() => {
                setTexto(t("team.link200"));
                setAlertType("success");
                setShow(true);
                setReload((prevState) => !prevState);
            })
            .catch((err) => {
                switch (err.response.status) {
                    case 403:
                        setTexto(t("team.unlinkError403"));
                        break;
                    case 404:
                        setTexto(t("team.unlinkError404"));
                        break;
                    default:
                        setTexto(t("team.unlinkError500"));
                        break;
                }
                setAlertType("error");
                setShow(true);
            });

        handleCloseDialog();

        setIsLoading(false);
    };

    const linkUser = async (user) => {
        setIsLoading(true);

        const params = {
            invite_email: user.invite_email,
            company_id: todos.companyID,
            inv_company_id: user.company_id,
        };

        await teamService
            .linkUser(params)
            .then(() => {
                setTexto(t("team.link200"));
                setAlertType("success");
                setShow(true);
                setReload((prevState) => !prevState);
            })
            .catch((err) => {
                switch (err.response.status) {
                    case 401:
                        setTexto(t("team.linkError401"));
                        break;
                    case 404:
                        setTexto(t("team.linkError404"));
                        break;
                    default:
                        setTexto(t("team.linkError500"));
                        break;
                }
                setAlertType("error");
                setShow(true);
            });
    };

    const showModalTransfer = () => {
        setShowAlertTransfer(true);
        handleClose();
    };

    const handleTransfer = async () => {
        setIsLoading(true);

        const params = {
            invitation_id: selectedUser._id,
        };

        await teamService
            .transferOwnership(params)
            .then(() => {
                setTexto(t("team.transfer200"));
                setAlertType("success");
                setShow(true);
                setReload((prevState) => !prevState);
            })
            .catch((err) => {
                switch (err.response.status) {
                    case 403:
                        setTexto(t("team.transferError403"));
                        break;
                    case 404:
                        setTexto(t("team.transferError404"));
                        break;
                    default:
                        setTexto(t("team.transferError500"));
                        break;
                }
                setAlertType("error");
                setShow(true);
            });

        setShowAlertTransfer(false);
        setIsLoading(false);
    };

    const handleLogout = async () => {
        setIsLoading(true);

        await authService
            .logout()
            .then(() => {
                dispatch(clearInfo());
            })
            .catch((err) => console.log(err));

        setIsLoading(false);
    };

    const saveEdit = async () => {
        setIsLoading(true);
        if (/.+@.+\..+/.test(email) && name.trim() && userRol) {
            const params = {
                invitation_id: selectedUser._id,
                invite_email: email,
                invite_user_name: name,
                user_permission: userRol,
            };

            await teamService
                .updateInvitation(params)
                .then(() => {
                    setTexto(t("team.successUpdate"));
                    setAlertType("success");
                    setShow(true);
                    if (
                        todos.userInfo.email === selectedUser.invite_email &&
                        (selectedUser.invite_email !== email || userRol !== selectedUser.user_permission)
                    ) {
                        handleLogout();
                    }
                    setReload((prevState) => !prevState);
                    handleCloseEdit();
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setIsError(true);
        }

        setIsLoading(false);
    };

    const title = (
        <span>
            {t("team.editInfo")} <span style={{ color: "#361A52" }}>{selectedUser.user_name}</span>
        </span>
    );

    const content = (
        <UserForm
            name={name}
            email={email}
            userRol={userRol}
            isError={isError}
            setName={setName}
            setEmail={setEmail}
            setUserRol={setUserRol}
            isEdit={true}
            verifyEmail={verifyEmail}
            isValidEmail={isValidEmail}
            verified={verified}
        />
    );

    const actions = (
        <>
            <Button onClick={handleCloseEdit}>{t("team.cancel")}</Button>
            <Button variant="contained" onClick={saveEdit}>
                {t("team.save")}
            </Button>
        </>
    );

    const message = selectedUser.invite_email === todos.userInfo.email
        ? (
            <>
                <Typography
                    className={classes.text}
                    dangerouslySetInnerHTML={{
                        __html: `${t("team.unlinkText1A")} ${t(
                            "team.unlinkTextFirm2"
                        )} <b style="color: #361A52">${companyName}</b>?`,
                    }}
                ></Typography>
                <Typography className={classes.text2}>{t("team.unlinkTextFirmA")}</Typography>
            </>
        )
        : (
            <>
                <Typography
                    className={classes.text}
                    dangerouslySetInnerHTML={{
                        __html: `${t("team.unlinkText1")} <b>${selectedUser.user_name}</b> ${t(
                            "team.unlinkTextFirm2"
                        )} <b style="color: #361A52">${companyName}</b>?`,
                    }}
                ></Typography>
                <Typography className={classes.text2}>{t("team.unlinkTextFirm")}</Typography>
            </>
        );

    const messageTransfer = (
        <>
            <Typography sx={{ textAlign: "center", pb: 2 }}>
                {t("team.youSureTransf")}
                <b>{selectedUser.user_name}</b>?
            </Typography>
            <Typography sx={{ textAlign: "center" }}>{t("converter.notReversible")}</Typography>
        </>
    );

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const reactivate = () => {
        navigate("/subscription", { state: { subscription: subscription._id, reactivate: true } });
    };

    const handleCloseFormDown = () => {
        setOpenForm(false);
    };

    const finalChange = async () => {
        setIsLoading(true);
        let params = {
            subscription_id: subscription._id,
            excess_user: userTemp,
            excess_inv: userTemp2,
            franchise_id: franchiseID,
        };

        await subscriptionService
            .updateUsersTemporary(params)
            .then(() => {
                setReload((prevState) => !prevState);
                setOpenForm(false);
                setTexto(t("team.successUpdateTemp"));
                setAlertType("success");
                setShow(true);
            })
            .catch((err) => console.log(err));
        setIsLoading(false);
    };

    const changeTemporaryUsers = (
        <Grid container justifyContent="space-between" sx={{ width: 600 }}>
            <Grid item>
                <Button onClick={handleCloseFormDown}>{t("team.cancel")}</Button>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    onClick={finalChange}
                    disabled={
                        ((subscription &&
                            subscription.last_change &&
                            userTemp.length + userTemp2.length <
                            usersFranq.length +
                            invFranq.length -
                            plansInfo.find((item) => item.planId === subscription.last_change.changed_plan)
                                ?.users) ||
                            (subscription &&
                                subscription.last_change &&
                                subscription.last_change.changed_plan === 1)) ??
                        false
                    }
                >
                    {(subscription &&
                        subscription.last_change &&
                        usersFranq.length +
                        invFranq.length -
                        plansInfo.find((item) => item.planId === subscription.last_change.changed_plan)?.users) > 0
                        ? t("subscription.remove")
                        : t("dialog.accept")}
                </Button>
            </Grid>
        </Grid>
    );

    const handleClickUpdateTemp = async () => {
        setIsLoading(true);
        //Llamar a peticion para obtener los usuarios de la franquicia y pintarlos en radio buttons
        let params = {
            franchise_id: todos.franchiseID,
            company_id: todos.companyID,
            all: true,
        };
        await userCompanyService
            .getAllUsersFranchise(params)
            .then(async (data) => {
                setUsersFranq(data?.users);
                setInvFranq(data?.inv);

                let params2 = {
                    subscription_id: subscription._id,
                };

                await subscriptionService
                    .getUsersTemporary(params2)
                    .then((response) => {
                        setUserTemp(response.excess_user);
                        setUserTemp2(response.excess_inv);
                        setOpenForm(true);
                    })
                    .catch((err) => console.log(err));
            })
            .catch((error) => {
                console.log(error);
                setTexto("Error");
                setAlertType("error");
                setShow(true);
            });
        setIsLoading(false);
    };

    const getDueDate = moment.utc(subscription?.since_date).format(todos.dateFormat);

    return (
        <>
            {!todos.companyID && subscription?.last_change && (
                <Box bgcolor="#FF007A1A" py={2} px={5} borderRadius="10px" mb={2}>
                    {t("team.changePlanMsg1")}{" "}
                    <Typography fontWeight={600} component="span">
                        {getDueDate}
                    </Typography>{" "}
                    {t("team.changePlanMsg2")}{" "}
                    <Typography fontWeight={600} component="span">
                        {plansInfo.find((item) => item.planId === subscription.last_change.changed_plan).planTitle}
                    </Typography>
                    {t("team.changePlanMsg3")}{" "}
                    <Link fontWeight={600} sx={{ cursor: "pointer", textDecoration: "none" }} onClick={reactivate}>
                        {t("team.changePlanMsg4")}
                    </Link>{" "}
                    {t("team.changePlanMsg5")}{" "}
                    <Link
                        fontWeight={600}
                        sx={{ cursor: "pointer", textDecoration: "none" }}
                        onClick={handleClickUpdateTemp}
                    >
                        {t("team.changePlanMsg6")}
                    </Link>
                    .
                </Box>
            )}
            {!todos.companyID && subscription?.last_cancel && (
                <Box bgcolor="#FF007A1A" py={2} px={5} borderRadius="10px" mb={2}>
                    {t("team.yourSubsCancel")}{" "}
                    <Typography fontWeight={600} component="span">
                        {getDueDate}
                    </Typography>{" "}
                    {t("team.yourSubsCancelText")}{" "}
                    <Link fontWeight={600} sx={{ cursor: "pointer", textDecoration: "none" }} onClick={reactivate}>
                        {t("team.reactivePlan")}
                    </Link>
                </Box>
            )}
            <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="h3" className={classes.title}>
                    {todos.companyID
                        ? `${t("team.subsTeam")} (${filterInv.length}
                              /
                              ${todos?.subscription?.plan === 0 || !subscription
                            ? 2
                            : t("payment.unlimited")
                        })`
                        : t("team.firmTeam")}
                </Typography>
                <LightTooltip
                    position="right"
                    title={
                        <>
                            <Typography className={classes.toolTip}>
                                <span>{t("team.owner")}:</span> {t("team.ownerDesc")}
                            </Typography>
                            <br />
                            <Typography className={classes.toolTip}>
                                <span>{t("team.admin")}:</span> {t("team.adminDesc")}
                            </Typography>
                            <br />
                            <Typography className={classes.toolTip}>
                                <span>{t("team.colab")}:</span> {t("team.colabDesc")}
                            </Typography>
                        </>
                    }
                >
                    <InfoIcon fontSize="small" color="primary" />
                </LightTooltip>
            </Stack>
            <List className={classes.list}>
                {filterInv.map((item) => (
                    <ListItem
                        secondaryAction={
                            <Grid container alignItems="center" spacing={2}>
                                {item.last_access && (
                                    <Grid item xs={12} sm>
                                        <Typography
                                            className={classes.lastTime}
                                            sx={{ display: { xs: "none", md: "block" } }}
                                        >
                                            {`${t("team.lastTime")} ${moment(item.last_access).format(
                                                todos.dateFormat
                                            )}`}{" "}
                                            {`${t("team.at")} ${moment(item.last_access).format("HH:mm")}`}
                                        </Typography>
                                    </Grid>
                                )}
                                {hasAccess && (
                                    <Grid item>
                                        <IconButton
                                            aria-describedby={id}
                                            aria-label="more"
                                            onClick={(event) => handleClick(event, item)}
                                        >
                                            <MoreVertIcon sx={{ color: "#361A52" }} />
                                        </IconButton>
                                    </Grid>
                                )}
                            </Grid>
                        }
                        key={item._id}
                    >
                        <ListItemText
                            primary={
                                <Grid container alignItems="center" spacing={2}>
                                    {todos.companyID && (
                                        <Grid item>
                                            <Checkbox
                                                disabled={item.user_permission === 1}
                                                checked={
                                                    item.user_permission === 1 ||
                                                    ((item.franchise_id || item.company_id === todos.companyID) &&
                                                        item.invite_status !== 4)
                                                }
                                                onChange={(event) => handleChangeCheck(event, item)}
                                                size="small"
                                            />
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Typography
                                                variant="h3"
                                                sx={{ pl: 0.5 }}
                                                color={
                                                    (todos.companyID &&
                                                        !item.franchise_id &&
                                                        item.company_id !== todos.companyID) ||
                                                        item.invite_status === 4
                                                        ? "#ccc"
                                                        : "#361A52"
                                                }
                                            >
                                                {item.invite_user_name ?? item.user_name}
                                            </Typography>
                                            <Typography>•</Typography>
                                            <Typography
                                                color={
                                                    (todos.companyID &&
                                                        !item.franchise_id &&
                                                        item.company_id !== todos.companyID) ||
                                                        item.invite_status === 4
                                                        ? "#ccc"
                                                        : "#361A52"
                                                }
                                            >
                                                {item.invite_email}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item>
                                        <Box
                                            className={
                                                (todos.companyID &&
                                                    !item.franchise_id &&
                                                    item.company_id !== todos.companyID) ||
                                                    item.invite_status === 4
                                                    ? classes.disabled
                                                    : item.user_permission === 1
                                                        ? classes.owner
                                                        : item.user_permission === 2
                                                            ? classes.admin
                                                            : classes.colab
                                            }
                                        >
                                            <Typography>
                                                {rolNames.find((x) => x.id === item.user_permission)?.name}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    {(item.temporary || (subscription?.last_cancel && item.user_permission !== 1)) && (
                                        <Grid item>
                                            <Box className={classes.temporary}>
                                                <Typography>{t("team.temporary")}</Typography>
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            }
                        />
                    </ListItem>
                ))}
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <List>
                        {selectedUser.user_permission !== 1 && todos.userInfo.email === ownerEmail?.invite_email && (
                            <ListItem disablePadding>
                                <ListItemButton onClick={showModalTransfer}>
                                    <ListItemText primary={t("team.transferPro")} className={classes.listOp} />
                                </ListItemButton>
                            </ListItem>
                        )}
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleEditDial}>
                                <ListItemText primary={t("team.edit")} className={classes.listOp} />
                            </ListItemButton>
                        </ListItem>
                        {selectedUser.user_permission !== 1 && !todos.companyID && (
                            <ListItem disablePadding>
                                <ListItemButton onClick={handleAlert}>
                                    <ListItemText
                                        primary={t("team.unlink1")}
                                        className={classes.listOp}
                                        sx={{ color: "#FF007A" }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        )}
                    </List>
                </Popover>
            </List>
            <AlertDialog
                open={showAlert}
                onClose={handleCloseDialog}
                type="warning"
                maxWidth="sm"
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("dialog.cancel")}
                title={t("dialog.warning")}
                message={message}
                agreeAction={() => unlinkUser(true)}
            />
            <AlertDialog
                open={showAlertComp}
                onClose={handleCloseDialog}
                type="warning"
                maxWidth="sm"
                title={t("dialog.warning")}
                message={
                    <Typography textAlign="justify" fontSize={14}>
                        {t("miscellaneous.excludeUser")}
                    </Typography>
                }
                agreeAction={() => unlinkUser(false)}
                oneAction={() => unlinkUser(true)}
                agreeBtnLabel={t("team.onlyThis")}
                disagreeBtnLabel={t("miscellaneous.deleteSubs")}
                onlyAction={true}
                error={true}
            />
            <FormDialog
                open={showEditDial}
                handleClose={handleCloseEdit}
                title={title}
                content={content}
                actions={actions}
                maxWidth="sm"
            />
            <FormDialog
                open={openForm}
                handleClose={handleCloseFormDown}
                maxWidth="md"
                title={
                    <>
                        <span style={{ color: "#361A52", fontSize: "16px" }}>{t("subscription.selectUser")}</span>
                    </>
                }
                content={
                    <UpdateTemporaryForm
                        downgradePlan={
                            subscription && subscription.last_change
                                ? plansInfo.find((item) => item.planId === subscription.last_change.changed_plan)
                                : []
                        }
                        usersFranq={usersFranq}
                        userTemp={userTemp}
                        setUserTemp={setUserTemp}
                        getDueDate={getDueDate}
                        invFranq={invFranq}
                        userTemp2={userTemp2}
                        setUserTemp2={setUserTemp2}
                    />
                }
                actions={changeTemporaryUsers}
                custom={true}
            />
            <AlertDialog
                open={showAlertTransfer}
                onClose={handleCloseDialog}
                type="warning"
                maxWidth="sm"
                agreeBtnLabel={t("team.transfer")}
                disagreeBtnLabel={t("dialog.cancel")}
                title={t("dialog.warning")}
                message={messageTransfer}
                agreeAction={handleTransfer}
            />
        </>
    );
};

export default TeamList;
