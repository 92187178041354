/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

/** MUI icons */
import DeleteIcon from "@mui/icons-material/Delete";
import "moment/locale/es";

/** MUI imports */
import {
    Button,
    FilledInput,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";

import FiltersPopover from "../ui/FiltersPopover";
import { useDispatch } from "react-redux";
import { addCompanyID } from "../../redux/userSlice";
import companyService from "../../services/user_company";
import { getPlanInfo, getPlanLevelInfo, isCustomPlan } from "../../utils/utils";

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 45 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
    variant: "menu",
    getcontentanchorel: null,
};

const FiltersButtonCompany = (props) => {
    const { franchiseID, setRows, setRowsOG, filters, setFilters, rowsSkeleton, allCompanies, plansInfo } = props;

    const [t] = useTranslation("global");

    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const attributes = [
        { id: 1, name: t("register.business"), query: "company_name" },
        { id: 2, name: t("subscription.integ"), query: "system_integration" },
        { id: 3, name: t("management.activePlan"), query: "subscription_plan" },
    ];

    const allInteg = [
        {
            value: 1,
            name: "Xero",
        },
        {
            value: 2,
            name: "Quickbooks",
        },
        {
            value: 3,
            name: t("miscellaneous.withoutInt"),
        },
    ];

    const addFilter = () => {
        let array = [...filters.filtersArray];

        if (array.length <= 3) {
            setFilters({
                ...filters,
                filtersArray: [
                    ...filters.filtersArray,
                    {
                        type: "",
                        value: "",
                    },
                ],
            });
        }
    };

    const reloadTable = async (params) => {
        setRows(rowsSkeleton);

        const handleOpenCompany = (id) => {
            dispatch(addCompanyID(id));
        };

        const getPlanName = (item) => {
            let itemPlan = item.subscription_plan === 19 // Sin plan.
                ? t("management.noPlan")
                : (item?.subscription && "last_cancel" in item?.subscription)
                    ? (!isCustomPlan(item.subscription_plan)
                        ? getPlanInfo(item.subscription_plan).planTitle + ` - ${getPlanLevelInfo(item.subscription_plan).pages} ${t("plans.pageMonth")} (${t("navBar.cancelled")})`
                        : getPlanInfo(item.subscription_plan).planTitle + ` - ${t("plans.customize")} (${t("navBar.cancelled")})`)
                    : (!isCustomPlan(item.subscription_plan)
                        ? getPlanInfo(item.subscription_plan).planTitle + ` - ${getPlanLevelInfo(item.subscription_plan).pages} ${t("plans.pageMonth")}`
                        : getPlanInfo(item.subscription_plan).planTitle + ` - ${t("plans.customize")}`);
            return item.subscription_plan === 19 ? itemPlan : `Plan ${itemPlan}`;
        }

        await companyService
            .getBusinessDirectory(params)
            .then((data) => {
                const companies = [];

                const notAssingData = data.companies.map((item) => {

                    companies.push({
                        value: item._id,
                        name: item.company_name,
                    });

                    return {
                        id: item._id,
                        company_name: item.company_name,
                        company: (
                            <Button size="small" onClick={() => handleOpenCompany(item.id)}>
                                {item.company_name}
                            </Button>
                        ),
                        integ:
                            item.system_integration === 1
                                ? "Xero"
                                : item.system_integration === 2
                                ? "Quickbooks"
                                : t("miscellaneous.withoutInt"),
                        users: item.total_users,
                        usersData: item.users,
                        plans: getPlanName(item),
                    };
                });

                setRows(notAssingData);
                setRowsOG(notAssingData);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const removeFilter = (index) => {
        let array = [...filters.filtersArray];

        if (index !== -1) {
            array.splice(index, 1);
            setFilters({
                ...filters,
                filtersArray: array,
            });

            let objectFinal = {};

            array.forEach((item) => {
                if (item.value !== "" && item.type !== "") {
                    return (objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value);
                }
            });

            let params = {
                franchise_id: franchiseID,
                ...objectFinal,
            };
            reloadTable(params);
        }
    };

    const removeFirstFilter = () => {
        setFilters({
            filtersArray: [
                {
                    type: "",
                    value: "",
                },
            ],
        });

        let params = { franchise_id: franchiseID };

        reloadTable(params);
    };

    const cleanFilters = (e) => {
        if (e.target.classList.contains("MuiAvatar-img")) {
            setFilters({
                filtersArray: [
                    {
                        type: "",
                        value: "",
                    },
                ],
            });

            let params = { franchise_id: franchiseID };
            reloadTable(params);
        }
    };

    const handleChangeType = (event, index) => {
        let aux = [...filters.filtersArray];
        aux[index].type = event.target.value;
        aux[index].value = "";

        setFilters({
            ...filters,
            filtersArray: aux,
        });
    };

    const handleChangeFilter = (event, index) => {
        let aux = [...filters.filtersArray];

        aux[index].value = event.target.value;

        let objectFinal = {};

        aux.forEach((item) => {
            if (item.value !== "" && item.type !== "") {
                objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value;
            }
        });

        let params = {
            franchise_id: franchiseID,
            ...objectFinal,
        };

        setFilters({
            ...filters,
            filtersArray: aux,
        });
        reloadTable(params);
    };

    const allPlans = plansInfo.flatMap(plan =>
        plan.levels.map(level => {
            return (!isCustomPlan(level.id)
                ? plan.planTitle + ` - ${level.pages} ${t("plans.pageMonth")}`
                : plan.planTitle + ` - ${t("plans.customize")}`)
        })
    );

    return (
        <FiltersPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            cleanFilters={cleanFilters}
            handleClick={handleClick}
            length={filters.filtersArray.filter((item) => item.value !== "" && item.type !== "").length}
            addFilter={addFilter}
            disabled={filters.filtersArray.length === 3}
        >
            {filters.filtersArray.map((value, index) => (
                <Grid container spacing={2} alignItems="center" key={"convComponent_" + index} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={11}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel
                                        id="clientStatus-simple-select-label"
                                        sx={{
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            color: "#361A52",
                                        }}
                                    >
                                        {t("filters.attribute")}
                                    </InputLabel>
                                    <Select
                                        size="small"
                                        value={value.type}
                                        displayEmpty
                                        onChange={(e) => handleChangeType(e, index)}
                                        className="ddlGeneral"
                                        input={
                                            <FilledInput
                                                label={t("filters.attribute")}
                                                sx={{
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    color: "#361A52",
                                                }}
                                            />
                                        }
                                        MenuProps={MenuProps}
                                        defaultValue=""
                                    >
                                        {value.type !== ""
                                            ? attributes
                                                  .filter(
                                                      (item) =>
                                                          !filters.filtersArray
                                                              .map((item) => item.type)
                                                              .includes(item.id)
                                                  )
                                                  .concat(attributes.filter((item) => item.id === value.type))
                                                  .map((item) => (
                                                      <MenuItem value={item.id} key={"attr_" + item.id}>
                                                          <Typography
                                                              variant="body1"
                                                              component="span"
                                                              align="center"
                                                              color="textPrimary"
                                                              sx={{
                                                                  fontStyle: "normal",
                                                              }}
                                                          >
                                                              {item.name}
                                                          </Typography>
                                                      </MenuItem>
                                                  ))
                                            : attributes
                                                  .filter(
                                                      (item) =>
                                                          !filters.filtersArray
                                                              .map((item) => item.type)
                                                              .includes(item.id)
                                                  )
                                                  .map((item) => (
                                                      <MenuItem value={item.id} key={"attr_" + item.id}>
                                                          <Typography
                                                              variant="body1"
                                                              component="span"
                                                              align="center"
                                                              color="textPrimary"
                                                              sx={{
                                                                  fontStyle: "normal",
                                                              }}
                                                          >
                                                              {item.name}
                                                          </Typography>
                                                      </MenuItem>
                                                  ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {value.type === "" && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled" disabled>
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#361A52",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            value={value.type}
                                            displayEmpty
                                            onChange={(e) => handleChangeType(e, index)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#361A52",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                        ></Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 1 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#361A52",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#361A52",
                                                    }}
                                                />
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                            defaultValue=""
                                        >
                                            {allCompanies.map((item) => (
                                                <MenuItem value={item.name} key={"companies_" + item.name}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 2 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#361A52",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#361A52",
                                                    }}
                                                />
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                            defaultValue=""
                                        >
                                            {allInteg.map((item) => (
                                                <MenuItem value={item.value} key={"integ_" + item.value}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 3 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#361A52",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#361A52",
                                                    }}
                                                />
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                            defaultValue=""
                                        >
                                            {allPlans.map((item, index) => (
                                                <MenuItem value={index} key={"plans_" + index}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        {item}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={1} display="flex" justifyContent="center">
                        {filters.filtersArray.length > 1 && (
                            <IconButton color="primary" onClick={() => removeFilter(index)}>
                                <DeleteIcon />
                            </IconButton>
                        )}
                        {filters.filtersArray.length === 1 && value.value !== "" && (
                            <IconButton color="primary" onClick={removeFirstFilter}>
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            ))}
        </FiltersPopover>
    );
};

export default FiltersButtonCompany;
