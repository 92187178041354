/** Components ----------------------------------------------------------- */
import { createTheme } from "@mui/material/styles";

/* Theme settings */
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 300,
            sm: 600,
            md: 1280,
            lg: 1680,
            xl: 2560,
        },
    },
    mixins: {
        toolbar: {
            minHeight: 50,
        },
    },
    palette: {
        primary: {
            main: "#361A52",
        },
        secondary: {
            main: "#EE2C66",
        },
        text: {
            primary: "#4D3365",
        },
        error: {
            main: "#EE2C66",
        },
        "V100": "#20003f",
        "V090": "#361a52",
        "V080": "#4d3365",
        "V070": "#634d79",
        "V060": "#79568c",
        "V050": "#8f809f",
        "V040": "#a699b2",
        "V030": "#bcb3c5",
        "V020": "#d2ccd9",
        "V010": "#e9e6ec",
        "V003": "#f8f7f9",
        "C100": "#ed1556",
        "C090": "#ee2c66",
        "C080": "#ef4277",
        "C070": "#f05987",
        "C060": "#f26f97",
        "C050": "#f286a7",
        "C040": "#f8a1bb",
        "C030": "#f8b9cc",
        "C020": "#fbcdd7",
        "C010": "#fdbdee",
        "C003": "#fdf8fa",
        "P100": "#3e237b",
        "P020": "#d8d3e5",
        "G100": "#9470ee",
        "G020": "#dccfff",
        "A100": "#33b1ff",
        "A020": "#b8e4ff",
        "E100": "#47f3c5",
        "E020": "#d8f5ee",
        "CIT100": "#ffff9e",
        "CIT020": "#fff5e3",
        "H100": "#427878",
        "H020": "#ccd5d4",
        "O100": "#ff966b",
        "O020": "#ffdbc6",
        "B100": "#f35dab",
        "B020": "#f9cddd",
        "D100": "#caef70",
        "D020": "#e6f7c0",
        "S100": "#90d2ff",
        "S020": "#d7edff"
    },
    typography: {
        htmlFontSize: 16,
        fontSize: 16,
        fontFamily: "Gantari, sans-serif",
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightBold: 600,
        h1: {
            fontWeight: 600,
            fontSize: 32,
        },
        h2: {
            fontWeight: 600,
            fontSize: 24,
        },
        h3: {
            fontWeight: 600,
            fontSize: 18,
        },
        subtitle1: {
            fontWeight: 500,
            fontSize: 16,
        },
        subtitle2: {
            fontWeight: 600,
            fontSize: 25,
        },
        body1: {
            fontWeight: 500,
            fontSize: 14,
        },
        body2: {
            fontWeight: 500,
            fontSize: 16,
        },
        button: {
            fontWeight: 600,
            fontSize: 14,
            textTransform: "none",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 6,
                },
            },
        },
    },
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#4a22d4",
                "& button:hover": {
                    backgroundColor: "#927ae5",
                },
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                "& span": {
                    color: "#4a22d4",
                },
                "& p": {
                    fontWeight: "600",
                    color: "#4a22d4",
                    fontSize: 14,
                },
            },
            daysHeader: {
                "& span": {
                    fontWeight: "600",
                    color: "#927ae5",
                    fontSize: 10,
                },
            },
        },
        MuiPickersDay: {
            day: {
                color: "#131f3e",
                "& p": {
                    fontWeight: "600",
                    fontSize: 14,
                },
            },
            daySelected: {
                color: "#131f3e",
                backgroundColor: "#e4def9",
                "&:hover": {
                    backgroundColor: "#e4def9",
                },
            },
            current: {
                color: "#4a22d4",
            },
        },
    },
});

export default theme;
