/** React imports */
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/* Components */
import { Grid, Typography } from "@mui/material";
import BaseCard from "../ui/BaseCard";

/* Styles */
import classes from "./VerifiedCard.module.css";
import BaseButton from "../ui/BaseButton";

const VerifiedCard = (props) => {
    const { handleShowVerifiedCard, email, language } = props;

    /** Internationalization i18n */
    const [t, i18n] = useTranslation("global");

    const [searchParams, setSearchParams] = useSearchParams();

    /** Component functions */
    const handleGoLogin = () => {
        searchParams.delete("user-email");
        searchParams.delete("type");
        searchParams.delete("verified");
        searchParams.delete("language");

        setSearchParams(searchParams);
        handleShowVerifiedCard();
    };

    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language);
        }
    }, [language, i18n]);

    return (
        <BaseCard>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <>
                    <Grid item xs={12}>
                        <Typography className={classes.title} gutterBottom>
                            {t("login.verifiedUser")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.expiredText}
                            dangerouslySetInnerHTML={{
                                __html: t("login.verifiedUserText") + "  <b>" + email + "</b> " + t("login.verifiedUserText1"),
                            }}>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <BaseButton
                            fullWidth
                            onClick={handleGoLogin}
                        >
                            {t("login.backToLoginVerified")}
                        </BaseButton>
                    </Grid>
                </>
            </Grid>
        </BaseCard>
    );
};

export default VerifiedCard;
