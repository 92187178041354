/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//import moment from "moment";

/** MUI icons */
import DeleteIcon from "@mui/icons-material/Delete";
import "moment/locale/es";
import accountsService from "../../services/accounts";

/** MUI imports */
import {
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Select,
    FilledInput,
    MenuItem,
} from "@mui/material";
import FiltersPopover from "../ui/FiltersPopover";

const defaultFilter = {
    filtersArray: [
        {
            type: "",
            value: "",
        },
    ],
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 45 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
    variant: "menu",
    getcontentanchorel: null,
};

const FiltersButton = (props) => {
    const { banks, currencies, countries, setAccounts, companyID, franchiseID, grupal, setShowSkeleton, showSkeleton } = props;
    const [t] = useTranslation("global");

    const [anchorEl, setAnchorEl] = useState(null);
    const [filters, setFilters] = useState(defaultFilter);
    const [finalBanks, setFinalBanks] = useState(banks);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const attributes = [
        { id: 1, name: t("register.country"), query: "country_id" },
        { id: 2, name: t("dashboard.currency"), query: "currency_id" },
        { id: 3, name: t("dashboard.bank"), query: "bank_id" },
        { id: 4, name: t("accounts.platform"), query: "system_integration" },
    ];

    const platforms = [
        { id: 1, name: "Xero" },
        { id: 2, name: "Quickbooks" },
        { id: 3, name: "Manual" },
    ];

    const addFilter = () => {
        let array = [...filters.filtersArray];

        if (array.length <= 3) {
            setFilters({
                ...filters,
                filtersArray: [
                    ...filters.filtersArray,
                    {
                        type: "",
                        value: "",
                    },
                ],
            });
        }
    };

    const removeFilter = async (index) => {
        let array = [...filters.filtersArray];

        if (index !== -1) {
            array.splice(index, 1);
            setFilters({
                ...filters,
                filtersArray: array,
            });

            setShowSkeleton(true);
            let objectFinal = {};

            array.forEach((item) => {
                if (item.value && item.type) {
                    return (objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value);
                }
            });

            let objectFinalBanks = {};

            array.forEach((item) => {
                if (item.value && item.type && (item.type === 1 || item.type === 2)) {
                    return (objectFinalBanks[attributes.find((item2) => item2.id === item.type).query] = item.value);
                }
            });

            if (Object.keys(objectFinalBanks).length > 0) {
                //Filtrar los bancos de la empresa segun moneda o banco
                if (objectFinalBanks.currency_id && objectFinalBanks.country_id) {
                    setFinalBanks(banks.filter((item) => item.country_id.toString() === objectFinalBanks.country_id.toString() && item.currency_id.toString() === objectFinalBanks.currency_id.toString()))
                } else if (objectFinalBanks.currency_id) {
                    setFinalBanks(banks.filter((item) => item.currency_id.toString() === objectFinalBanks.currency_id.toString()))
                } else {
                    setFinalBanks(banks.filter((item) => item.country_id.toString() === objectFinalBanks.country_id.toString()))
                }
                // await accountsService
                //     .getBanksFilter(objectFinalBanks)
                //     .then((data) => {
                //         setFinalBanks(data);
                //     })
                //     .catch((error) => { });
            } else {
                setFinalBanks(banks)
            }

            await accountsService
                .getAccounts({
                    company_id: companyID,
                    language: sessionStorage.getItem("lng"),
                    ...objectFinal,
                })
                .then((response) => {
                    setAccounts(response);
                })
                .catch((err) => {
                    console.log(err);
                });
            setShowSkeleton(false);
        }
    };

    const handleChangeType = (event, index) => {
        let aux = [...filters.filtersArray];
        aux[index].type = event.target.value;
        aux[index].value = "";

        setFilters({
            ...filters,
            filtersArray: aux,
        });
    };

    const handleChangeFilter = async (event, index, filterType) => {
        let aux = [...filters.filtersArray];

        aux[index].value = event.target.value;

        setShowSkeleton(true);

        if (filterType === 1 || filterType === 2) {
            let objectFinalBanks = {};

            aux.forEach((item) => {
                if (item.value && item.type && (item.type === 1 || item.type === 2)) {
                    return (objectFinalBanks[attributes.find((item2) => item2.id === item.type).query] = item.value);
                }
            });

            if (Object.keys(objectFinalBanks).length > 0) {
                //Filtrar los bancos de la empresa segun moneda o banco
                if (objectFinalBanks.currency_id && objectFinalBanks.country_id) {
                    setFinalBanks(banks.filter((item) => item.country_id.toString() === objectFinalBanks.country_id.toString() && item.currency_id.toString() === objectFinalBanks.currency_id.toString()))
                } else if (objectFinalBanks.currency_id) {
                    setFinalBanks(banks.filter((item) => item.currency_id.toString() === objectFinalBanks.currency_id.toString()))
                } else {
                    setFinalBanks(banks.filter((item) => item.country_id.toString() === objectFinalBanks.country_id.toString()))
                }

                let index = aux.map((item) => item.type).indexOf(3);
                if (index !== -1) {
                    aux[index].value = "";
                }
                // await accountsService
                // .getBanksFilter(objectFinalBanks)
                // .then((data) => {
                //     setFinalBanks(data);

                //     let index = aux.map((item) => item.type).indexOf(3);
                //     if (index !== -1) {
                //         aux[index].value = "";
                //     }
                // })
                // .catch((error) => { });
            } else {
                setFinalBanks(banks)
            }
        }

        let objectFinal = {};

        aux.forEach((item) => {
            if (item.value && item.type) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value;
            }
        });

        await accountsService
            .getAccounts({
                company_id: companyID,
                language: sessionStorage.getItem("lng"),
                ...objectFinal,
            })
            .then((response) => {
                setAccounts(response);
            })
            .catch((err) => {
                console.log(err);
            });

        setFilters({
            ...filters,
            filtersArray: aux,
        });
        setShowSkeleton(false);
    };

    const removeFirstFilter = async () => {
        setShowSkeleton(true);
        setFilters({
            filtersArray: [
                {
                    type: "",
                    value: "",
                },
            ],
        });
        await accountsService
            .getAccounts({
                company_id: companyID,
                language: sessionStorage.getItem("lng")
            })
            .then((response) => {
                setAccounts(response);
            })
            .catch((err) => {
                console.log(err);
            });
        setFinalBanks(banks)
        setShowSkeleton(false);
    };

    const cleanFilters = async (e) => {
        if (e.target.classList.contains("MuiAvatar-img")) {
            setShowSkeleton(true);
            setFilters({
                filtersArray: [
                    {
                        type: "",
                        value: "",
                    },
                ],
            });
            await accountsService
                .getAccounts({
                    company_id: companyID,
                    language: sessionStorage.getItem("lng")
                })
                .then((response) => {
                    setAccounts(response);
                })
                .catch((err) => {
                    console.log(err);
                });
            setFinalBanks(banks)
            setShowSkeleton(false);
        }
    };

    useEffect(() => {
        setFilters({
            filtersArray: [
                {
                    type: "",
                    value: "",
                },
            ],
        });
    }, [companyID, franchiseID, grupal]);

    return (
        <FiltersPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            cleanFilters={cleanFilters}
            handleClick={handleClick}
            length={filters.filtersArray.filter((item) => item.type && item.value).length}
            addFilter={addFilter}
            disabled={filters.filtersArray.length === 4}
            disabledButton={showSkeleton}
        >
            {filters.filtersArray.map((value, index) => (
                <Grid container spacing={2} alignItems="center" key={"accComponent_" + index} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={11}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel
                                        id="clientStatus-simple-select-label-1"
                                        sx={{
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            color: "#361A52",
                                        }}
                                    >
                                        {t("filters.attribute")}
                                    </InputLabel>
                                    <Select
                                        size="small"
                                        value={value.type}
                                        displayEmpty
                                        onChange={(e) => handleChangeType(e, index)}
                                        input={
                                            <FilledInput
                                                label={t("filters.attribute")}
                                                sx={{
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    color: "#361A52",
                                                }}
                                            />
                                        }
                                        MenuProps={MenuProps}
                                        defaultValue=""
                                    >
                                        {value.type !== ""
                                            ? attributes
                                                .filter(
                                                    (item) =>
                                                        !filters.filtersArray
                                                            .map((item) => item.type)
                                                            .includes(item.id)
                                                )
                                                .concat(
                                                    attributes.filter((item) => item.id === value.type)
                                                )
                                                .map((item) => (
                                                    <MenuItem value={item.id} key={"attr_" + item.id}>
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                            sx={{
                                                                fontStyle: "normal",
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    </MenuItem>
                                                ))
                                            : attributes
                                                .filter(
                                                    (item) =>
                                                        !filters.filtersArray
                                                            .map((item) => item.type)
                                                            .includes(item.id)
                                                )
                                                .map((item) => (
                                                    <MenuItem value={item.id} key={"attr_" + item.id}>
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                            sx={{
                                                                fontStyle: "normal",
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {value.type === "" && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled" disabled>
                                        <InputLabel
                                            id="clientStatus-simple-select-label-2"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#361A52",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            value={value.type}
                                            displayEmpty
                                            onChange={(e) => handleChangeType(e, index)}
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#361A52",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                        ></Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 1 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label-3"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#361A52",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#361A52",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                        >
                                            {countries.map((item) => (
                                                <MenuItem value={item._id} key={"countries_" + item._id}>
                                                    {item.country_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 2 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label-4"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#361A52",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#361A52",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                        >
                                            {currencies.map((item) => (
                                                <MenuItem value={item._id} key={"currencies_" + item._id}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        {item.currency_name} ({item.currency_iso_code})
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 3 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label-5"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#361A52",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#361A52",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                        >
                                            {finalBanks.map((item) => (
                                                <MenuItem value={item._id} key={"banks_" + item._id}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        {item.bank_name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 4 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label-6"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#361A52",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#361A52",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                        >
                                            {platforms.map((item) => (
                                                <MenuItem value={item.id} key={"platforms_" + item.id}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={1} display="flex" justifyContent="center">
                        {filters.filtersArray.length > 1 && (
                            <DeleteIcon
                                color="primary"
                                sx={{ cursor: "pointer" }}
                                onClick={() => removeFilter(index)}
                            />
                        )}
                        {filters.filtersArray.length === 1 && value.value !== "" && (
                            <DeleteIcon
                                color="primary"
                                sx={{ cursor: "pointer" }}
                                onClick={() => removeFirstFilter()}
                            />
                        )}
                    </Grid>
                </Grid>
            ))}
        </FiltersPopover>
    );
};

export default FiltersButton;
