import axios from "axios";

/* Script que contiene las llamadas a la API */
const document_classi = {
    getInvoicesPending: (params) => {
        const request = axios.get("/getInvoicesPending", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    getCompaniesByFranchise: (params) => {
        const request = axios.get("/getCompaniesByFranchise", {
            params: params,
        });

        return request.then((response) => response.data);
    },
    
    reassignInvoice: (params) => {
        const request = axios.get("/reassignInvoice", {
            params: params,
        });

        return request.then((response) => response.data);
    },
};

export default document_classi;
