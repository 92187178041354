import {
    Autocomplete,
    Box,
    ButtonGroup,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    InputAdornment,
    MenuItem,
    MenuList,
    Paper,
    Popover,
    Popper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { useSelector } from "react-redux";
import CustomFilledSelect from "../../ui/CustomFilledSelect";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import conversionService from "../../../services/conversion";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import invoicesService from "../../../services/invoices";

import SearchIcon from "@mui/icons-material/Search";

import UploadFilePopover from "./UploadFilePopover";
import AddIcon from "@mui/icons-material/Add";
import NewInvoiceTableItems from "./NewInvoiceTableItems";
import NewInvoiceTableSummary from "./NewInvoiceTableSummary";
import { useEffect, useRef, useState } from "react";
import BaseButton from "../../ui/BaseButton";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { getContactIcon } from "../../../utils/utils";

const CustomTextField = styled(TextField)({
    "& label": {
        color: "#131F3E",
        fontWeight: 600,
    },
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: 0,
        },
    },
    [`& .${autocompleteClasses.paper}`]: {
        borderRadius: "15px",
    },
});

const NewInvoiceTab1 = (props) => {
    const {
        setShowCompanyDet,
        invInfo,
        setInvInfo,
        setCurrency,
        systemCurrency,
        setRate,
        editGlobal,
        setEditGlobal,
        edited,
        setEdited,
        setRows,
        setTexto,
        setAlertType,
        setShow,
        rows,
        xeroTaxRates,
        setIsLoading,
        setPreviewFile,
        setOpenPreview,
        contactsXeroOriginal,
        currency,
        rate,
        allCurrencies,
        selected,
        setSelected,
        accountsXero,
        replaceInvBill,
        setRowsModal,
        rowsModal,
        conversion_type,
        setCurrentInv,
        currentInv,
        handleAlertModalSendInv,
        goBack,
        setOpenAlertSendInv,
        invChanges,
        previousInvInfoRef,
        previousRowsRef,
        handleModalupdateInvoice,
        updateInvoice,
        handleEditRow,
        conversionData
    } = props;

    const [t] = useTranslation("global");

    const todos = useSelector((state) => state.value);
    const [isError] = useState(false);

    const anchorRefBtnGroup = useRef(null);
    const [openBtnGroupSend, setOpenBtnGroupSend] = useState(false);
    const [selectedIndexBtnGroup, setSelectedIndexBtnGroup] = useState(null);

    const optionsGroupSend = [{ data: t("invoicing.saveAndApprove"), id: 0 }];

    const generateUniqueId = () => Date.now().toString(36) + Math.random().toString(36).substring(2, 9);

    const [selectedContact, setSelectedContact] = useState({});

    const insertLine = {
        new: true,
        _id: generateUniqueId(),
        item_code: "",
        description: "",
        amount: 0,
        unit_price: 0,
        quantity: 0,
        account_code: "",
        tax_type: "",
        tax_amount: 0,
    };

    const showModalComp = () => {
        setShowCompanyDet(true);
    };

    const handleChangInvData = (event) => {
        event.preventDefault();

        setInvInfo({
            ...invInfo,
            [event.target.name]: event.target.value,
            // currency: contactsXeroOriginal.find((item) => item.value === event.target.value).currency,
        });
    };


    const handleChangeSelectContact = async (newValue) => {
        setSelectedContact(newValue);
        setInvInfo({
            ...invInfo,
            contactXeroID: newValue.value,
            contactXeroName: newValue.name
        });
    };

    // const invTypes = [{ id: 1, name: "FF" }];

    const handleChangeDate = async (event, key) => {
        setInvInfo({
            ...invInfo,
            [key]: event,
        });
    };

    const handleChangeCurrency = async (event) => {
        setCurrency(event.target.value);
        if (event.target.value === systemCurrency) {
            setRate("");
        } else {
            await conversionService
                .getXECurrencyRate({
                    from: event.target.value,
                    to: systemCurrency,
                    date: moment.utc(invInfo.invoiceDate).format("YYYY-MM-DD"),
                })
                .then((data) => {
                    setRate(data.amount.toString());
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleChangeRate = (event) => {
        setRate(event.target.value);
    };

    const handleNewRow = () => {
        let newId = generateUniqueId();
        let newLine = {
            ...insertLine,
            _id: newId,
        };

        setRows([...rows, newLine]);
        handleEditRow(newId, false, [...rows, newLine], true);
    };

    const handleCloseBtnGroup = (event) => {
        if (anchorRefBtnGroup.current && anchorRefBtnGroup.current.contains(event.target)) {
            return;
        }

        setOpenBtnGroupSend(false);
    };

    const handleMenuItemClickBtnGroup = async (event, index) => {
        setSelectedIndexBtnGroup(index);
        setOpenBtnGroupSend(false);
        if (index === 0) {
            await saveAndApp();
        }
    };

    const handleToggleBtnGroup = () => {
        setOpenBtnGroupSend((prevOpenBtnGroupSend) => !prevOpenBtnGroupSend);
    };

    const calcSubtotal = (obj) => {
        return parseFloat(obj.unit_price ?? 0) * parseFloat(obj.quantity ?? 1);
    };

    const calcTax = (obj) => {
        if (xeroTaxRates.length > 0 && "tax_type" in obj && obj.tax_type !== "") {
            const taxRate = xeroTaxRates.find((item) => item.value === obj.tax_type).taxRate;
            return (taxRate * calcSubtotal(obj)) / 100;
        }
    };

    const calcTotal = (obj) => {
        return calcTax(obj) + calcSubtotal(obj);
    };

    const saveAndApp = async () => {
        await saveInvoice(true);
        setOpenAlertSendInv(true);
    };

    const saveInvoice = async (setApprove = false) => {
        if (currentInv?._id) {
            await updateInvoice(setApprove);
        } else {
            const copyInv = JSON.parse(JSON.stringify(invInfo));
            const copyRows = [...rows];

            const fInvInfo = {
                contactXeroID: copyInv.contactXeroID !== "first" ? copyInv.contactXeroID : undefined,
                contactXeroName: copyInv.contactXeroName,
                // currency: "",
                // total: "",
                company_id: todos?.companyID,
                observations: copyInv.details,
                conversion_type: conversion_type,
                invoice_type: copyInv.invoiceType,
                invoice_id: copyInv.invoiceNumber,
                control_number: copyInv.controlNumber,
                invoice_date: copyInv.invoiceDate,
                due_date: copyInv.invoiceDueDate,
                currency_rate: rate, //Tasa de cambio.
                sub_total: copyInv.subtotal, //Subtotal.
                invoice_tax: copyInv.tax, //Tax.
                invoice_discount: copyInv.discount, //Descuento.
                invoice_total: copyInv.total, //Total.
                // line_items: //Array de objetos con las siguientes propiedades:
                approve: setApprove,
                line_items: copyRows.map((item) => ({
                    code: item.code, //Código.
                    description: item.description, //Descripción.
                    quantity: item.quantity, //Cantidad.
                    unit_price: item.unit_price, //Precio unitario.
                    account_code: item.account_code, //Cuenta contable.
                    discount: item.discount, //Descuento.
                    tracking1: item.tracking1, //Tracking 1,
                    tracking2: item.tracking2, //Tracking 2,
                    tax_type: item.tax_type,
                    tax_amount: calcTax(item), //Monto de impuestos.
                    amount: calcTotal(item), //Monto total de la línea
                })),
            };

            setIsLoading(true);
            await invoicesService
                .createNewInvoice(fInvInfo)
                .then((response) => {
                    setCurrentInv(response.data);
                    if (setApprove) {
                        previousRowsRef.current = rows;
                        previousInvInfoRef.current = invInfo;
                    }
                    setTexto(t("register.saveSuccessfully"));
                    setAlertType("success");
                    setShow(true);
                })
                .catch((err) => {
                    console.log(err);
                });
            setIsLoading(false);
        }
    };

    const notApprove = async () => {
        setIsLoading(true);
        await invoicesService
            .markInvoiceAsNotApproved({
                invoice_id: currentInv._id,
            })
            .then((response) => {
                setCurrentInv(response.data);
                setTexto(t("register.saveSuccessfully"));
                setAlertType("success");
                setShow(true);
                goBack();
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
    };

    const handleClearContact = async (newValue) => {
        setSelectedContact({});

        setInvInfo({
            ...invInfo,
            contactXeroID: "",
            contactXeroName: "",
        });
    };

    // const deleteInv = async () => {
    //     if (Object.keys(currentInv).length > 0) {
    //         setIsLoading(true)
    //         await invoicesService
    //             .deleteInvoice({
    //                 invoice_id: currentInv._id
    //             })
    //             .then((response) => {
    //                 setCurrentInv(response.data);
    //                 goBack();
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //             });
    //         setIsLoading(false)
    //     }
    //     setCurrentInv({})
    //     setInvInfo({
    //         invoiceType: 1,
    //         invoiceNumber: "",
    //         controlNumber: "",
    //         invoiceDate: "",
    //         invoiceDueDate: "",
    //         contactName: "",
    //         currency: "",
    //         details: "",
    //         subtotal: 0.00,
    //         discount: 0.00,
    //         tax: 0.00,
    //         total: 0.00,
    //     })
    // }
    useEffect(() => {
        (async () => {
            if (contactsXeroOriginal.length > 0 && Object.keys(conversionData).length > 0) {
                const contactXero = conversionData.contactXeroID;

                let selectedContactNew = {};

                if (contactXero && contactXero !== null && contactXero !== "first") { // Existe contacto sugerido o ya existe la sincronización
                    // console.log(conversionContacts)
                    // console.log(invoiceData.contactXeroID)
                    const findContactSim1 = contactsXeroOriginal.find((contact) => contact.value === conversionData.contactXeroID);

                    selectedContactNew.name = findContactSim1.name;
                    selectedContactNew.value = findContactSim1.value;
                } else { 
                    const contactName = conversionData.conversion_type === 1 ? conversionData.final_customer_name : conversionData.final_vendor_name;

                    selectedContactNew = {
                        value: "first",
                        name: contactName,
                    };
                }
                setSelectedContact(selectedContactNew);
            }
        })();
        
    }, [conversionData, contactsXeroOriginal]);


    return (
        <Box px={3} pb={3} bgcolor="#FFF">
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Box my={2}>
                            <Typography variant="h1" fontSize={20}>
                                {Object.keys(currentInv).length === 0 &&
                                    (conversion_type === 1 ? t("invoicing.newInv") : t("invoicing.newBill"))}
                                {Object.keys(currentInv).length > 0 &&
                                    (conversion_type === 1 ? t("invoicing.invoice") : t("invoicing.bill"))}
                                {Object.keys(currentInv).length > 0 && (
                                    <Typography component="span" color="primary" variant="h1" fontSize={20}>
                                        {invInfo.invoiceNumber !== "" ? " " + invInfo.invoiceNumber : ""}
                                    </Typography>
                                )}
                                {Object.keys(currentInv).length > 0 && currentInv.id_status === 2 && (
                                    <Chip label={t("invoicing.pendingApproval")} size="small" sx={{ px: 3, ml: 1 }} />
                                )}
                                {Object.keys(currentInv).length > 0 && currentInv.id_status === 4 && (
                                    <Chip
                                        label={t("invoicing.approved")}
                                        size="small"
                                        sx={{ px: 3, ml: 1, bgcolor: "#dbf4e5" }}
                                    />
                                )}
                            </Typography>
                            {Object.keys(currentInv).length > 0 && (
                                <Typography variant="body">
                                    {t("invoicing.createdOn")}{" "}
                                    {moment(currentInv.current_date).format(todos?.dateFormat ?? "DD/MM/YYYY")}
                                </Typography>
                            )}
                        </Box>
                        <UploadFilePopover
                            setTexto={setTexto}
                            setAlertType={setAlertType}
                            setShow={setShow}
                            setIsLoading={setIsLoading}
                            setPreviewFile={setPreviewFile}
                            setOpenPreview={setOpenPreview}
                            setRowsModal={setRowsModal}
                            rowsModal={rowsModal}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Stack direction="row" spacing={0.2}>
                        {/* <CustomFilledSelect
                            error={isError && !invInfo.contactXeroID}
                            required
                            label={t("converter.companyName")}
                            value={invInfo.contactXeroID ?? ""}
                            onChange={handleChangeSelectContact}
                            name="contactXeroID"
                            values={contactsXeroOriginal}
                            idName="value"
                            valueName="name"
                            warningIcon={!invInfo.contactXeroID || invInfo.contactXeroID === ""}
                        /> */}
                        <Autocomplete
                            PopperComponent={StyledPopper}
                            sx={{ minWidth: "100%", height: 42 }}
                            options={contactsXeroOriginal}
                            getOptionLabel={(option) => (option.name ? option.name : "")}
                            value={selectedContact}
                            onChange={(event, newValue, reason) => {
                                if (reason === 'clear') {
                                    handleClearContact()
                                } else {
                                    handleChangeSelectContact(newValue)
                                }
                            }}
                            renderOption={(props, option, { index }) => {
                                const { key, ...optionProps } = props;
                                return (
                                    <Box
                                        key={key + index}
                                        component="li"
                                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                        {...optionProps}
                                    >
                                        <Typography color="#131f3e">{option.name}</Typography>
                                    </Box>
                                );
                            }}
                            disableClearable
                            renderInput={(params) => (
                                <TextField
                                    sx={{height: 42}}
                                    {...params}
                                    label={
                                        <Typography fontWeight={600} color="#131F3E">
                                            {selectedContact?.value === "first"
                                                ? t("converter.createContact")
                                                : t("converter.chooseContact")
                                            }
                                        </Typography>
                                    }
                                    variant="filled"
                                    size="small"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <>
                                            {params.InputProps.endAdornment}
                                            <Box sx={{ right: "44px", position: "absolute", top: "50%" }}>
                                                <InputAdornment position="end">
                                                    {getContactIcon(conversion_type === 1 ? conversionData.final_customer_name : conversionData.final_vendor_name, contactsXeroOriginal, contactsXeroOriginal, selectedContact, t)}
                                                </InputAdornment>
                                            </Box>
                                          </>
                                        ),
                                      }}
                                />
                            )}
                            isOptionEqualToValue={(option, value) => {
                                if (!value || Object.keys(value).length === 0) return false;
                                return option.value === value.value;
                            }}
                            noOptionsText={
                                <Box sx={{ bgcolor: "#e8e9ec", p: 0.8, borderRadius: 1 }}>
                                    <Typography fontWeight={600} fontSize={13} mt={0.5} color="#131f3e">
                                        {t("converter.noContact")}
                                    </Typography>
                                </Box>
                            }
                            
                        />
                        <BaseButton
                            onClick={showModalComp}
                            sx={{
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                            }}
                        >
                            <SearchIcon />
                        </BaseButton>
                    </Stack>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {/* <Grid item xs={12} md={0.5}>
                    <CustomFilledSelect
                        error={isError && !invInfo.invoiceType}
                        required
                        label={t("converter.type")}
                        value={invInfo.invoiceType ?? ""}
                        onChange={handleChangInvData}
                        name="subsType"
                        values={invTypes}
                        idName="id"
                        valueName="name"
                    />
                </Grid> */}
                <Grid item xs={12} md={2}>
                    <CustomTextField
                        fullWidth
                        label={replaceInvBill(t("converter.invoiceNumber"))}
                        name="invoiceNumber"
                        required
                        onChange={handleChangInvData}
                        value={invInfo.invoiceNumber}
                        variant="filled"
                        inputProps={{
                            fontStyle: "normal",
                            color: "#131F3E",
                        }}
                        error={isError && !invInfo.invoiceNumber.trim()}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <CustomTextField
                        fullWidth
                        label={t("converter.controlNum")}
                        name="controlNumber"
                        onChange={handleChangInvData}
                        value={invInfo.controlNumber}
                        variant="filled"
                        inputProps={{
                            fontStyle: "normal",
                            color: "#131F3E",
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale={t("language.locale")}
                        localeText={{
                            clearButtonLabel: t("miscellaneous.clear"),
                        }}
                    >
                        <DatePicker
                            label={
                                <Typography
                                    sx={{
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        color: "#131F3E",
                                    }}
                                >
                                    {replaceInvBill(t("converter.invoiceDate"))}
                                </Typography>
                            }
                            slotProps={{
                                textField: {
                                    style: { width: "100%" },
                                    variant: "filled",
                                    readOnly: true,
                                },
                                actionBar: { actions: ["clear"] },
                            }}
                            value={invInfo.invoiceDate !== "" ? invInfo.invoiceDate : null}
                            onChange={(e) => handleChangeDate(e, "invoiceDate")}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={2}>
                    <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale={t("language.locale")}
                        localeText={{
                            clearButtonLabel: t("miscellaneous.clear"),
                        }}
                    >
                        <DatePicker
                            label={
                                <Typography
                                    sx={{
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        color: "#131F3E",
                                    }}
                                >
                                    {t("converter.dueDate")}
                                </Typography>
                            }
                            slotProps={{
                                textField: {
                                    style: { width: "100%" },
                                    variant: "filled",
                                    readOnly: true,
                                },
                                actionBar: { actions: ["clear"] },
                            }}
                            value={invInfo.invoiceDueDate !== "" ? invInfo.invoiceDueDate : null}
                            onChange={(e) => handleChangeDate(e, "invoiceDueDate")}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={2}>
                    <CustomFilledSelect
                        error={isError && !currency}
                        required
                        label={t("dashboard.currency")}
                        value={currency ?? ""}
                        onChange={handleChangeCurrency}
                        name="currency"
                        values={allCurrencies}
                        idName="id"
                        valueName="name"
                    />
                </Grid>
                {invInfo.currency !== currency && (
                    <Grid item xs={12} md={2}>
                        <CustomTextField
                            fullWidth
                            label={t("converter.rate")}
                            name="rate"
                            onChange={handleChangeRate}
                            value={rate}
                            variant="filled"
                            inputProps={{
                                fontStyle: "normal",
                            }}
                            type="text"
                            disabled={rate === ""}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography variant="h1" fontSize={20} mt={3}>
                        {replaceInvBill(t("converter.invItems"))}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <NewInvoiceTableItems
                        rows={rows}
                        setRows={setRows}
                        edited={edited}
                        setEdited={setEdited}
                        editGlobal={editGlobal}
                        setEditGlobal={setEditGlobal}
                        xeroTaxRates={xeroTaxRates}
                        setTexto={setTexto}
                        setAlertType={setAlertType}
                        setShow={setShow}
                        selected={selected}
                        setSelected={setSelected}
                        accountsXero={accountsXero}
                    />
                </Grid>
                <Grid item xs={12} mt={4}>
                    <Stack direction="row" alignItems="start" justifyContent="space-between">
                        <Stack direction="column" spacing={2} width="50%">
                            <BaseButton
                                variantType="primaryOutlined"
                                sx={{ justifyContent: "space-between" }}
                                disableElevation
                                endIcon={<AddIcon />}
                                onClick={handleNewRow}
                                disabled={editGlobal}
                                sizebutton="xl"
                            >
                                {t("converter.addRow")}
                            </BaseButton>
                            <CustomTextField
                                id="outlined-multiline-flexible"
                                label={t("converter.obs")}
                                variant="filled"
                                multiline
                                maxRows={4}
                                fullWidth
                                name="details"
                                value={invInfo.details}
                                onChange={handleChangInvData}
                                error={isError && !invInfo.details}
                                inputProps={{ style: { resize: "vertical" } }}
                            />
                        </Stack>
                        <Stack direction="column" spacing={2} width="25%">
                            <NewInvoiceTableSummary invInfo={invInfo} />
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Box>
                            {Object.keys(currentInv).length > 0 && (
                                <>
                                    {t("invoicing.lastChangesSavedOn")}{" "}
                                    {moment(currentInv?.updated_date ?? currentInv?.current_date).format(
                                        todos?.dateFormat + " - HH:mm A" ?? "DD/MM/YYYY - HH:mm A"
                                    )}
                                </>
                            )}
                        </Box>
                        {Object.keys(currentInv).length === 0 && (
                            <Box display="flex" justifyContent="end">
                                <Stack spacing={2} direction="row">
                                    {/* <Button variant="text" disableElevation size="large" sx={{ px: 5 }}  color="error" onClick={deleteInv}>
                                        Borrar factura
                                    </Button> */}
                                    <ButtonGroup
                                        disableElevation
                                        variant="outlined"
                                        ref={anchorRefBtnGroup}
                                        aria-label="split button"
                                        color="inherit"
                                    >
                                        <BaseButton
                                            disableElevation
                                            color="primary"
                                            sizebutton="m"
                                            onClick={() => saveInvoice(false)}
                                            disabled={invInfo.invoiceNumber === "" || invInfo.contactXeroID === ""}
                                        >
                                            {t("team.save")}
                                        </BaseButton>
                                        <BaseButton
                                            size="small"
                                            aria-controls={openBtnGroupSend ? "split-button-menu" : undefined}
                                            aria-expanded={openBtnGroupSend ? "true" : undefined}
                                            aria-label="select merge strategy"
                                            aria-haspopup="menu"
                                            disableElevation
                                            variant="contained"
                                            color="primary"
                                            onClick={handleToggleBtnGroup}
                                            disabled={invInfo.invoiceNumber === "" || invInfo.contactXeroID === ""}
                                        >
                                            <ArrowDropDownIcon />
                                        </BaseButton>
                                    </ButtonGroup>
                                    <Popover
                                        open={openBtnGroupSend}
                                        anchorEl={anchorRefBtnGroup.current}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }}
                                    >
                                        <ClickAwayListener onClickAway={handleCloseBtnGroup}>
                                            <Paper style={{ minWidth: 200 }}>
                                                <MenuList id="split-button-menu" autoFocusItem>
                                                    {optionsGroupSend.map(({ id, data }, index) => (
                                                        <MenuItem
                                                            key={"convertOption_" + id}
                                                            selected={index === selectedIndexBtnGroup}
                                                            onClick={(event) =>
                                                                handleMenuItemClickBtnGroup(event, index)
                                                            }
                                                            sx={{ color: "#4a22d4" }}
                                                        >
                                                            {data}
                                                        </MenuItem>
                                                    ))}
                                                </MenuList>
                                            </Paper>
                                        </ClickAwayListener>
                                    </Popover>
                                </Stack>
                            </Box>
                        )}
                        {/* Factura por aprobar */}
                        {Object.keys(currentInv).length > 0 && currentInv.id_status === 2 && (
                            <Box display="flex" justifyContent="end">
                                <Stack spacing={2} direction="row">
                                    <BaseButton
                                        variantType="errorText"
                                        disableElevation
                                        onClick={notApprove}
                                        sizebutton="m"
                                        disabled={editGlobal}
                                    >
                                        {t("invoicing.doNotApprove")}
                                    </BaseButton>
                                    <BaseButton
                                        disableElevation
                                        onClick={handleAlertModalSendInv}
                                        sizebutton="m"
                                        disabled={editGlobal}
                                    >
                                        {t("converter.aprove")}
                                    </BaseButton>
                                </Stack>
                            </Box>
                        )}
                        {/* Factura aprobada, se oculta mientras tanto el botón de anular */}
                        {/* {Object.keys(currentInv).length > 0 && currentInv.id_status === 4 && 
                            <Box display="flex" justifyContent="end">
                                <Stack spacing={2} direction="row">
                                    <Button variant="text" disableElevation size="large" sx={{ px: 5 }}  color="error">
                                        Anular factura
                                    </Button>
                                </Stack>
                            </Box>
                        } */}

                        {Object.keys(currentInv).length > 0 && currentInv.id_status === 4 && invChanges && (
                            <Box display="flex" justifyContent="end">
                                <Stack spacing={2} direction="row">
                                    <BaseButton
                                        variantType="errorText"
                                        disableElevation
                                        onClick={notApprove}
                                        sizebutton="m"
                                    >
                                        {t("invoicing.doNotApprove")}
                                    </BaseButton>
                                    <BaseButton disableElevation onClick={handleModalupdateInvoice} sizebutton="m">
                                        {t("dialog.saveChanges")}
                                    </BaseButton>
                                </Stack>
                            </Box>
                        )}
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

export default NewInvoiceTab1;
