/* Componentes */
import { Box, Grid } from "@mui/material";
import LangButton from "../auth/LangButton";

/* Iconos */
import { ReactComponent as LoginLine } from "../../assets/login-line.svg";
import { ReactComponent as Logo } from "../../assets/logo.svg";

/* Estilos */
import classes from "./BackLogin.module.css";

const BackLogin = (props) => {
    const { children, language, resetLogin } = props;

    return (
        <Box className={classes.background}>
            <LangButton lang={language} />
            <Box className={classes.svgcontainer}>
                <LoginLine />
            </Box>
            <Box className={classes.logocontainer}>
                <Logo onClick={resetLogin} className={classes.logo} />
            </Box>
            <Grid container justifyContent="center" alignItems="center" direction="column">
                <Grid item sx={{ paddingLeft: 2, paddingRight: 2 }}>
                    {children}
                </Grid>
            </Grid>
        </Box>
    );
};

export default BackLogin;
