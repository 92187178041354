import axios from "axios";

/* Script que contiene las llamadas a la API */
const subscription = {
    // Petición para actualizar el carrito
    updateCart: (params) => {
        const request = axios.post("/updateCart", params);

        return request.then((response) => response);
    },

    // Petición para obtener los elementos del carrito
    getShoppingCart: (params) => {
        const request = axios.get("/getShoppingCart", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    // Petición para obtener los elementos del carrito
    requestCustomSubscription: (params) => {
        const request = axios.post("/requestCustomSubscription", params);

        return request.then((response) => response);
    },

    // Petición para obtener el plan de suscripción
    getSubscription: (params) => {
        const request = axios.get("/getSubscription", {
            params: params,
        });
        return request.then((response) => response.data);
    },

    // Petición para obtener los creditos generales
    getCredits: (params) => {
        const request = axios.get("/getCredits", {
            params: params,
        });
        return request.then((response) => response.data);
    },

    // Petición para obtener los creditos de la franquicia
    getFranchiseCredits: (params) => {
        const request = axios.get("/getFranchiseCredits", {
            params: params,
        });
        return request.then((response) => response.data);
    },

    // Petición para cancelar una suscripción
    cancelSubscription: (params) => {
        const request = axios.post("/cancelSubscription", params);

        return request.then((response) => response);
    },

    // Petición para reactivar una suscripción cancelada
    reactivateSubscription: (params) => {
        const request = axios.post("/reactivateSubscription", params);

        return request.then((response) => response);
    },

    // Petición para hacer downgrade o upgrade de un plan
    changeSubscription: (params) => {
        const request = axios.post("/changeSubscription", params);

        return request.then((response) => response);
    },

    // Petición para reactivar una suscripción de un plan cambiado (downgrade o upgrade)
    reactivateSubscriptionChanged: (params) => {
        const request = axios.post("/reactivateSubscriptionChanged", params);

        return request.then((response) => response);
    },

    // Petición para obtener los usuarios temporales
    getUsersTemporary: (params) => {
        const request = axios.get("/getUsersTemporary", {
            params: params,
        });
        return request.then((response) => response.data);
    },

    // Petición para actualizar los usuarios temporales
    updateUsersTemporary: (params) => {
        const request = axios.post("/updateUsersTemporary", params);

        return request.then((response) => response);
    },
    // Petición para actualizar la suscripcion
    updateSubscription: (params) => {
        const request = axios.post("/updateSubscription", params);

        return request.then((response) => response);
    },
    // Petición para obtener las facturas
    getInvoices: (params) => {
        const request = axios.get("/getInvoices", {
            params: params,
        });
        return request.then((response) => response.data);
    },

    // Petición para obtener detalles de una factura
    getInvoiceDetails: (params) => {
        const request = axios.get("/getInvoiceDetails", {
            params: params,
        });
        return request.then((response) => response.data);
    },

    // Petición para descargar la factura
    generateInvoice: (params) => {
        const request = axios.get("/generateInvoice", {
            params: params,
            responseType: "blob",
        });
        return request.then((response) => response);
    },

    // Petición para obtener todas las facturas
    getAllInvoices: (params) => {
        const request = axios.get("/getAllInvoices", {
            params: params,
        });
        return request.then((response) => response.data);
    },

    // Petición para obtener el mejor plan
    getBestPlan: (params) => {
        const request = axios.get("/getBestPlan", {
            params: params,
        });
        return request.then((response) => response.data);
    },

    getHistorySubsDetails: (params) => {
        const request = axios.get("/getHistorySubsDetails", {
            params: params,
        });
        return request.then((response) => response.data);
    },
};

export default subscription;
