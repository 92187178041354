import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/** MUI icons */
import DescriptionIcon from "@mui/icons-material/Description";

/** MUI imports */
import { Grid, Table, TableHeaderRow, PagingPanel, Toolbar, SearchPanel } from "@devexpress/dx-react-grid-material-ui";
import { SearchState, IntegratedFiltering, PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import { Box, Stack, Typography, Skeleton, IconButton } from "@mui/material";
import LightTooltip from "../ui/LightTooltip";
import GridUI from "@mui/material/Grid";
import FiltersButton from "./FiltersButton";
import BarChartIcon from "@mui/icons-material/BarChart";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import moment from "moment";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import casesServices from "../../services/case";
import commServices from "../../services/communication";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { initiateSocket, subscribeToChat, newUserResponse } from "../../services/Socket";
import ChatIconCustom from "../../assets/chat-icon-inbox.svg";
import ChatIconCustomNotif from "../../assets/chat-icon-inbox-notif.svg";
import ChatModal from "../ui/Chat";
import { addMessages } from "../../redux/userSlice";
import AlertDialog from "../ui/AlertDialog";
import BaseButton from "../ui/BaseButton";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    backgroundColor: "#f7f8f9",
    "&:before": {
        // Esto es para quitar la línea horizontal por defecto en el encabezado del Accordion
        display: "none",
    },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
    "& .MuiAccordionSummary-content": {
        marginLeft: 0,
    },
    "&:hover": {
        background: "rgba(19, 31, 62, 0.05)",
    },
    borderRadius: 15,
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
}));

const PREFIX = "CasesTable";

const classes = {
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
    tableHeaderBand: `${PREFIX}-tableHeaderBand`,
    checkBtn: `${PREFIX}-checkBtn`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.tableHeaderBand}`]: {
        backgroundColor: "transparent",
        color: "#4a22d4",
        fontSize: "14px",
        fontWeight: 600,
        border: "none !important",
        textAlign: "center",
        paddingBottom: 0,
        [theme.breakpoints.up("md")]: {
            whiteSpace: "pre-wrap",
        },
    },

    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "#f8f8f9",
        fontSize: "15px",
        fontWeight: 600,
        color: "#131F3E !important",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
            paddingRight: "24px",
        },
    },

    [`& .${classes.tableBody}`]: {
        backgroundColor: "#fff",
        borderBottom: "2px solid #f8f8f9",
        fontSize: "14px",
        fontWeight: 400,
        color: "#131f3e",
        paddingTop: "10px",
        paddingBottom: "10px",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#361A52",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "#D8D3E5",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#131F3E",
        backgroundColor: "#f2f2f2",
        borderRadius: 8,
        borderBottom: "none",
        paddingTop: "11px",
        paddingBottom: "11px",
        paddingLeft: "20px",
        paddingRight: "40px",
        marginBottom: "35px",
        "&:before": {
            borderBottom: "none",
            color: "#131F3E",
        },
        "&:hover:before": {
            borderBottom: "none",
            color: "#131F3E",
        },
        width: "100%",
        //marginTop: "-155px"
    },

    [`& .${classes.checkBtn}`]: {
        "&:hover": {
            color: "#00B147",
        },
    },
}));

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const rowsSkeleton = [];
const rowsSolvedSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        number: <Skeleton variant="text" animation="wave" />,
        account: <Skeleton variant="text" animation="wave" />,
        bank: <Skeleton variant="text" animation="wave" />,
        concept: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        user: <Skeleton variant="text" animation="wave" />,
        creationDate: <Skeleton variant="text" animation="wave" />,
        actions: <Skeleton variant="text" animation="wave" />,
    });

    rowsSolvedSkeleton.push({
        id: i,
        number: <Skeleton variant="text" animation="wave" />,
        account: <Skeleton variant="text" animation="wave" />,
        bank: <Skeleton variant="text" animation="wave" />,
        concept: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        user: <Skeleton variant="text" animation="wave" />,
        creationDate: <Skeleton variant="text" animation="wave" />,
        actions: <Skeleton variant="text" animation="wave" />,
    });
}

const CaseTab1 = (props) => {
    const {
        franchise_id,
        company_id,
        setIsLoading,
        cases,
        user_id,
        setCases,
        showSkeleton,
        setShowSkeleton,
        accountsFilter,
        usersFilter,
        banksFilter,
        setShow,
        setTexto,
        setAlertType
    } = props;

    const [t] = useTranslation("global");

    const [searchValue, setSearchState] = useState("");
    const [expanded, setExpanded] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowsSolved, setRowsSolved] = useState([]);

    const [pageSize, setPageSize] = useState(5);
    const [pageSizes] = useState([5, 10, 25]);
    const [currentPage, setCurrentPage] = useState(0);

    const [pageSize2, setPageSize2] = useState(5);
    const [pageSizes2] = useState([5, 10, 25]);
    const [currentPage2, setCurrentPage2] = useState(0);

    const [room, setRoom] = useState(null);
    const [open, setOpen] = useState(false);
    const [chat, setChat] = useState([]);
    const [users, setUsers] = useState([]);
    const [chatType, setChatType] = useState();
    const [chatDisabled, setChatDisabled] = useState(false);
    const [caseInfo, setCaseInfo] = useState({});
    const [openAlert, setOpenAlert] = useState(false);
    const [currentCase, setCurrentCase] = useState(null);

    const dispatch = useDispatch();

    const connectSocketGen = async (case_id) => {
        setChatType(3);
        setRoom(case_id);

        let params1 = {
            case_id: case_id,
        };

        await casesServices
            .getCaseInfo(params1)
            .then(async (data) => {
                setCaseInfo(data.caseInfo)

                initiateSocket(case_id, todos.userInfo, "general", "");
                subscribeToChat((err, data) => {
                    if (err) return;
                    setChat((oldChats) => [...oldChats, data]);
                });
                newUserResponse((err, data) => {
                    if (err) return;
                    setUsers(data);
                });
                setOpen(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const handleChange = () => {
        setExpanded((prev) => !prev);
    };

    const columns = [
        //{ name: "id", title: "ID" },
        { name: "number", title: t("converter.number") },
        { name: "account", title: t("inbox.bankAcc") },
        { name: "bank", title: t("dashboard.bank") },
        { name: "concept", title: t("payment.concept") },
        { name: "status", title: t("converter.status") },
        { name: "user", title: t("converter.user") },
        { name: "creationDate", title: t("inbox.creationDate") },
        { name: "actions", title: <></> },
    ];

    const tableColumnExtensions = [
        { columnName: "number", width: "10%", align: "center" },
        { columnName: "account", width: "20%", align: "left" },
        { columnName: "bank", width: "10%", align: "left" },
        { columnName: "concept", width: "10%", align: "center" },
        { columnName: "status", align: "center" },
        { columnName: "user", align: "center" },
        { columnName: "creationDate", align: "center" },
        { columnName: "actions", align: "center" },
    ];

    const tableMessages = {
        noData: <Typography fontSize={14}>{t("dashboard.noInfo")}</Typography>,
    };

    const todos = useSelector((state) => state.value);

    const reloadCases = async () => {
        let params2 = {
            user_id: user_id,
            company_id: company_id,
            franchise_id: franchise_id,
        };

        await casesServices
            .getCases(params2)
            .then((data) => {
                setCases(data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (showSkeleton) {
            setRows(rowsSkeleton);
            setRowsSolved(rowsSolvedSkeleton);
        } else {

            const openDialogSolve = (case_id) => {
                setOpenAlert(true);
                setCurrentCase(case_id)
            };

            if (cases) {
                const connectSocketDetails = async (case_id, reason, bank, disabled) => {
                    setChatType("");
                    setRoom(case_id);

                    let params1 = {
                        case_id: case_id,
                    };

                    await casesServices
                        .getCaseInfo(params1)
                        .then(async (data) => {
                            setCaseInfo(data.caseInfo)

                            initiateSocket(
                                case_id,
                                todos.userInfo,
                                reason === 1 && bank ? "conversion" : "general",
                                ""
                            );
                            subscribeToChat((err, data) => {
                                if (err) return;
                                setChat((oldChats) => [...oldChats, data]);
                            });
                            newUserResponse((err, data) => {
                                if (err) return;
                                setUsers(data);
                            });
                            setChatDisabled(disabled);
                            setOpen(true);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                };

                const connectSocket = async (case_id, reason, bank, disabled) => {
                    await connectSocketDetails(case_id, reason, bank, disabled);

                    await commServices
                        .updateStatusMess({
                            room: case_id,
                            user: todos.userInfo._id,
                        })
                        .then()
                        .catch((err) => console.log(err));
                    await reloadCases();
                    await casesServices
                        .getMessagesCases({
                            user_id: todos.userInfo._id,
                            franchise_id: todos.franchiseID,
                            isEmployee: false,
                        })
                        .then((response) => {
                            dispatch(addMessages(response.countMsg));
                        })
                        .catch((err) => console.log(err));
                };

                setRows(
                    cases.pending.map((item) => ({
                        id: item.case_id,
                        number: item.case_number,
                        account: (
                            <Typography sx={{ fontWeight: 600 }}>
                                {item.account_name}
                            </Typography>
                        ),
                        bank: item.bank_name,
                        concept:
                            item.case_reason === 1
                                ? t("inbox.conversions")
                                : item.case_reason === 2
                                    ? t("inbox.newBank")
                                    : t("inbox.directRequest"),
                        status:
                            item.case_status === 1 ? (
                                <Typography color="#FFB23F">
                                    {t("inbox.analysis")} <BarChartIcon color="#FFB23F" fontSize="inherit" />
                                </Typography>
                            ) : item.case_status === 2 ? (
                                <Typography color="#3FA7D6">
                                    {t("inbox.progress")} <HourglassEmptyIcon color="#3FA7D6" fontSize="inherit" />
                                </Typography>
                            ) : item.case_status === 3 ? (
                                <Typography color="#005792">
                                    {t("inbox.test")} <FilterAltIcon color="#005792" fontSize="inherit" />
                                </Typography>
                            ) : item.case_status === 4 ? (
                                <Typography color="#00C8C8">
                                    {t("inbox.solvedKiiper")} <CheckIcon color="#00C8C8" fontSize="inherit" />
                                </Typography>
                            ) : (
                                <Typography color="#00B147">
                                    {t("inbox.solvedUser")} <CheckIcon color="#00B147" fontSize="inherit" />
                                </Typography>
                            ),
                        user: item.case_user,
                        creationDate: moment.utc(item.creation_date).format("DD/MM/YYYY"),
                        actions: (
                            <Stack direction="row" spacing={1}>
                                <BaseButton
                                    disableElevation
                                    endIcon={
                                        <Box
                                            component="img"
                                            src={item.unread > 0 ? ChatIconCustomNotif : ChatIconCustom}
                                            sx={{ width: "15px", height: "15px" }}
                                        />
                                    }
                                    onClick={() => connectSocket(item.case_id, item.case_reason, item.bank_name, false)}
                                >
                                    Chat
                                </BaseButton>
                                <LightTooltip title={t("inbox.solve")}>
                                    <IconButton onClick={() => openDialogSolve(item.case_id)} size="small">
                                        <CheckBoxIcon className={classes.checkBtn} sx={{ fontSize: 30 }} />
                                    </IconButton>
                                </LightTooltip>
                            </Stack>
                        ),
                    }))
                );
                setRowsSolved(
                    cases.resolved.map((item) => ({
                        id: item.case_id,
                        number: item.case_number,
                        account: (
                            <Typography sx={{ fontWeight: 600 }}>
                                {item.account_name}
                            </Typography>
                        ),
                        bank: item.bank_name,
                        concept:
                            item.case_reason === 1
                                ? t("inbox.conversions")
                                : item.case_reason === 2
                                    ? t("inbox.newBank")
                                    : t("inbox.directRequest"),
                        status:
                            item.case_status === 1 ? (
                                <Typography color="#FFB23F">
                                    {t("inbox.analysis")} <BarChartIcon color="#FFB23F" fontSize="inherit" />
                                </Typography>
                            ) : item.case_status === 2 ? (
                                <Typography color="#3FA7D6">
                                    {t("inbox.progress")} <HourglassEmptyIcon color="#3FA7D6" fontSize="inherit" />
                                </Typography>
                            ) : item.case_status === 3 ? (
                                <Typography color="#005792">
                                    {t("inbox.test")} <FilterAltIcon color="#005792" fontSize="inherit" />
                                </Typography>
                            ) : item.case_status === 4 ? (
                                <Typography color="#00C8C8">
                                    {t("inbox.solvedKiiper")} <CheckIcon color="#00C8C8" fontSize="inherit" />
                                </Typography>
                            ) : (
                                <Typography color="#00B147">
                                    {t("inbox.solvedUser")} <CheckIcon color="#00B147" fontSize="inherit" />
                                </Typography>
                            ),
                        user: item.case_user,
                        creationDate: moment.utc(item.creation_date).format("DD/MM/YYYY"),
                        actions: (
                            <BaseButton
                                disableElevation
                                endIcon={
                                    <Box
                                        component="img"
                                        src={item.unread > 0 ? ChatIconCustomNotif : ChatIconCustom}
                                        sx={{ width: "15px", height: "15px" }}
                                    />
                                }
                                onClick={() =>
                                    connectSocket(
                                        item.case_id,
                                        item.case_reason,
                                        item.bank_name,
                                        item.case_status === 5
                                    )
                                }
                            >
                                Chat
                            </BaseButton>
                        ),
                    }))
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        showSkeleton,
        cases,
        t,
        company_id,
        franchise_id,
        setCases,
        user_id,
        setIsLoading,
        setShowSkeleton,
        todos.userInfo,
    ]);

    const handleClickHelp = async () => {
        setIsLoading(true);

        let params = {
            company_id: todos.companyID,
            franchise_id: todos.franchiseID,
            user_email: todos.userInfo.email,
            type: "general",
        };

        await casesServices
            .createNewCase(params)
            .then(async (response) => {
                setCaseInfo(response);
                await connectSocketGen(response._id);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
    };

    const Root = React.useCallback(
        (props) => {
            return (
                <Stack
                    direction={{ xs: "column", sm: "row" }}
                    justifyContent={{ xs: "center", sm: "end" }}
                    alignItems={{ xs: "center", sm: "start" }}
                    spacing={{ xs: 2, sm: "25px" }}
                    sx={{ marginTop: { xs: 0, md: -12 } }}
                >
                    <BaseButton
                        disableElevation
                        endIcon={<DescriptionIcon />}
                        onClick={handleClickHelp}
                    >
                        {t("inbox.createNewCase")}
                    </BaseButton>
                    <Box sx={{ width: { xs: "100%", sm: "160px" } }}>
                        <FiltersButton
                            accounts={accountsFilter}
                            users={usersFilter}
                            company={company_id}
                            setShowSkeleton={setShowSkeleton}
                            franchise={franchise_id}
                            banks={banksFilter}
                            user_id={user_id}
                            setCases={setCases}
                        />
                    </Box>
                    <Box sx={{ width: { xs: "100%", sm: "auto" } }}>
                        <Toolbar.Root {...props} className={classes.toolbarSearch} />
                    </Box>
                </Stack>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [accountsFilter, banksFilter, company_id, franchise_id, setCases, setShowSkeleton, user_id, usersFilter]
    );
    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("dashboard.pagingOf")} ${count}`,
        rowsPerPage: t("dashboard.rowsPerPage"),
    };

    const searchMessages = {
        searchPlaceholder: t("dashboard.search"),
    };

    const handleCloseDialog = () => {
        setOpenAlert(false);
        setCurrentCase(null)
    };

    const bodyMess = (
        <>
            <Stack
                direction="column"
                justifyContent="space-between"
                spacing={2}
                sx={{ mt: 2, textAlign: "justify", mb: 3 }}
            >
                <Typography fontSize={14} textAlign="center">
                    {t("inbox.solveCaseMsg1")}
                </Typography>
                <Typography fontSize={14} textAlign="justify">
                    {t("inbox.solveCaseMsg2")}
                </Typography>
            </Stack>
        </>
    );

    const handleSolve = async () => {
        if (currentCase) {
            setIsLoading(true);

            let params = { case_id: currentCase };

            await casesServices
                .markCaseAsResolved(params)
                .then(async () => {
                    await reloadCases();
                    handleCloseDialog();
                    setIsLoading(false);
                    setTexto(t("inbox.solveCaseSuccess"));
                    setAlertType("success");
                    setShow(true);
                })
                .catch((err) => {
                    console.log(err);
                    handleCloseDialog();
                    setIsLoading(false);
                    setTexto(t("inbox.solveCaseError"));
                    setAlertType("error");
                    setShow(true);
                });
        }
    };

    return (
        <>
            <StyledContainer
                component="main"
                sx={{
                    flexGrow: 1,
                    px: { xs: 2, md: 5 },
                    //py: 6,
                    width: "100%",
                    backgroundColor: "#F8F8F9",
                    height: "100vh",
                }}
            >
                <GridUI container justifyContent="end" spacing={2}>
                    <GridUI item xs={12}>
                        <Grid rows={rows} columns={columns}>
                            <SearchState value={searchValue} onValueChange={handleSearch} />
                            <IntegratedFiltering />
                            {rows.length > 0 && (
                                <PagingState
                                    currentPage={currentPage}
                                    onCurrentPageChange={setCurrentPage}
                                    pageSize={pageSize}
                                    onPageSizeChange={setPageSize}
                                />
                            )}
                            {rows.length > 0 && <IntegratedPaging />}
                            <Table
                                columnExtensions={tableColumnExtensions}
                                cellComponent={TableCellComponent}
                                messages={tableMessages}
                            />
                            <TableHeaderRow cellComponent={tableHeaderCell} />
                            <Toolbar rootComponent={Root} />
                            <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                            {rows.length > 0 && (
                                <PagingPanel
                                    containerComponent={PagingPanelContainer}
                                    messages={pagingMessages}
                                    pageSizes={pageSizes}
                                />
                            )}
                        </Grid>
                    </GridUI>
                    <GridUI item xs={12}>
                        <Accordion expanded={expanded} onChange={handleChange}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant="h3" className={classes.title}>
                                    {t("inbox.solved")}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid rows={rowsSolved} columns={columns}>
                                    <IntegratedFiltering />
                                    {rowsSolved.length > 0 && (
                                        <PagingState
                                            currentPage={currentPage2}
                                            onCurrentPageChange={setCurrentPage2}
                                            pageSize={pageSize2}
                                            onPageSizeChange={setPageSize2}
                                        />
                                    )}
                                    {rowsSolved.length > 0 && <IntegratedPaging />}
                                    <Table
                                        columnExtensions={tableColumnExtensions}
                                        cellComponent={TableCellComponent}
                                        messages={tableMessages}
                                    />
                                    {rowsSolved.length > 0 && (
                                        <PagingPanel
                                            containerComponent={PagingPanelContainer}
                                            messages={pagingMessages}
                                            pageSizes={pageSizes2}
                                        />
                                    )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </GridUI>
                </GridUI>
            </StyledContainer>
            {open && <ChatModal
                room={room}
                setRoom={setRoom}
                open={open}
                setOpen={setOpen}
                setChat={setChat}
                chat={chat}
                users={users}
                type={chatType}
                franchiseName={
                    todos.allComData?.find(
                        (franchise) => franchise.franchise_name && franchise._id === todos.franchiseID
                    )?.franchise_name
                }
                reloadCases={reloadCases}
                chatDisabled={chatDisabled}
                setCaseInfo={setCaseInfo}
                caseInfo={caseInfo}
                createCase={true}
            />}
            <AlertDialog
                open={openAlert}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("dialog.cancel")}
                type={"warning"}
                title={t("dialog.warning")}
                message={bodyMess}
                agreeAction={() => handleSolve()}
                maxWidth="sm"
            />

        </>
    );
};

export default CaseTab1;
